import { takeLatest, put, all, call } from "redux-saga/effects";
import { getMerchantOrderDetails } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { ORDER_DETAILS_DRAWER_FETCH_ORDER_SAGA } from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  orderDetailsDrawerSetIsLoading,
  orderDetailsDrawerSetOrder,
} from "./actions";

function* orderDetailsDrawerFetchOrderHandler(action: any): any {
  try {
    yield put(orderDetailsDrawerSetIsLoading(true));

    const response = yield call(getMerchantOrderDetails, action.payload);

    if (response?.success) {
      yield put(orderDetailsDrawerSetOrder(response?.data));
    }

    yield put(orderDetailsDrawerSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(orderDetailsDrawerSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchOrderDetailsDrawer() {
  yield takeLatest(
    ORDER_DETAILS_DRAWER_FETCH_ORDER_SAGA,
    orderDetailsDrawerFetchOrderHandler
  );
}

import {
  CUSTOMER_SETTINGS_SET_IS_LOADING,
  CUSTOMER_SETTINGS_SET_SUBSCRIPTIONS,
  CUSTOMER_SETTINGS_SET_PAYMENT_METHODS,
  CUSTOMER_SETTINGS_ADD_PAYMENT_METHOD_SET_CONFIG,
  CUSTOMER_SETTINGS_FETCH_SUBSCRIPTIONS_SAGA,
  CUSTOMER_SETTINGS_FETCH_PAYMENT_METHODS_SAGA,
  CUSTOMER_SETTINGS_INITIATE_SETUP_INTENT_SAGA,
  CUSTOMER_SETTINGS_CONFIRM_SETUP_INTENT_SAGA,
  CUSTOMER_SETTINGS_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA,
} from "./types";

export const customerSettingsSetIsLoading = (payload: any) => ({
  type: CUSTOMER_SETTINGS_SET_IS_LOADING,
  payload,
});

export const customerSettingsSetSubscriptions = (payload: any) => ({
  type: CUSTOMER_SETTINGS_SET_SUBSCRIPTIONS,
  payload,
});

export const customerSettingsSetPaymentMethods = (payload: any) => ({
  type: CUSTOMER_SETTINGS_SET_PAYMENT_METHODS,
  payload,
});

export const customerSettingsAddPaymentMethodSetConfig = (payload: any) => ({
  type: CUSTOMER_SETTINGS_ADD_PAYMENT_METHOD_SET_CONFIG,
  payload,
});

// async

export const customerSettingsFetchSubscriptionsSaga = () => ({
  type: CUSTOMER_SETTINGS_FETCH_SUBSCRIPTIONS_SAGA,
});

export const customerSettingsFetchPaymentMethodsSaga = () => ({
  type: CUSTOMER_SETTINGS_FETCH_PAYMENT_METHODS_SAGA,
});

export const customerSettingsInitiateSetupIntentSaga = (payload: any) => ({
  type: CUSTOMER_SETTINGS_INITIATE_SETUP_INTENT_SAGA,
  payload,
});

export const customerSettingsConfirmSetupIntentSaga = (payload: any) => ({
  type: CUSTOMER_SETTINGS_CONFIRM_SETUP_INTENT_SAGA,
  payload,
});

export const customerSettingsConfirmAddNewPaymentMethodSaga = (
  payload: any,
  navigate: any
) => ({
  type: CUSTOMER_SETTINGS_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA,
  payload,
  navigate,
});

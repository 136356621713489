import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_COMMUNITY_CART_SET_IS_LOADING,
  MERCHANT_COMMUNITY_CART_SET_MASTER_CART,
  MERCHANT_COMMUNITY_CART_SET_MY_CART,
} from "./types";

type MerchantCommunityCartStateType = {
  isLoading: boolean;
  master: Array<any>;
  my: Array<any>;
};

const initialState: MerchantCommunityCartStateType = {
  isLoading: false,
  master: [],
  my: [],
};

const merchantCommunityCartReducer = (
  state: MerchantCommunityCartStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_COMMUNITY_CART_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_COMMUNITY_CART_SET_MASTER_CART:
      return { ...state, master: action.payload };

    case MERCHANT_COMMUNITY_CART_SET_MY_CART:
      return { ...state, my: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantCommunityCartReducer;

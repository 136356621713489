import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner";
import OrderDialog from "components/Customer/OrderDialog";
import CustomerSubscriptionCard from "components/Customer/SubscriptionCard";
import { orderDialogSetConfig } from "components/Customer/OrderDialog/state/actions"; // order dialog actions
import { customerSubscriptionsFetchSubscriptionsSaga } from "./state/actions";

export default function CustomerSubscriptionsContainer() {
  const dispatch = useDispatch();

  const { isLoading, subscriptions } = useSelector(
    (store: any) => store?.customerSubscription
  );

  const { open: shouldShowOrderDialog } = useSelector(
    (store: any) => store?.orderDialog
  );

  const handleOpenOrderDialog = (subscription: any) => {
    dispatch(orderDialogSetConfig({ open: true, subscription }));
  };

  const handleCloseOrderDialog = () => {
    dispatch(orderDialogSetConfig({ open: false }));
  };

  const handleUse = (subscription: any) => {
    handleOpenOrderDialog(subscription);
  };

  useEffect(() => {
    dispatch(customerSubscriptionsFetchSubscriptionsSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div className="p-4">
          <div className="mt-4">
            <div className="text-2xl font-semibold text-grey">
              My Subscriptions
            </div>

            <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-3 lg:gap-3 mt-4">
              {subscriptions?.map((subscription: any) => (
                <CustomerSubscriptionCard
                  key={subscription?._id}
                  subscription={subscription}
                  onUse={handleUse}
                />
              ))}
            </div>
          </div>

          {shouldShowOrderDialog && (
            <OrderDialog onClose={handleCloseOrderDialog} />
          )}
        </div>
      )}
    </div>
  );
}

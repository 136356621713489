import {
  MERCHANT_PLANS_SET_IS_LOADING,
  MERCHANT_PLANS_SET_PLANS,
  MERCHANT_PLANS_SET_SELECTED_PLAN,
  MERCHANT_PLANS_SET_PLAN_SUBSCRIBERS,
  MERCHANT_PLANS_SET_INVITE_SUBSCRIBER_DIALOG_CONFIG,
  MERCHANT_PLANS_FETCH_SAGA,
  MERCHANT_PLANS_CREATE_SAGA,
  MERCHANT_PLANS_UPDATE_SAGA,
  MERCHANT_PLANS_SUBSCRIBERS_FETCH_SAGA,
  MERCHANT_PLANS_FETCH_SELECTED_PLAN_SAGA,
  MERCHANT_PLANS_INVITE_SUBSCRIBER_SAGA,
} from "./types";

export const merchantPlansSetIsLoading = (payload: any) => ({
  type: MERCHANT_PLANS_SET_IS_LOADING,
  payload,
});

export const merchantPlansSetPlans = (payload: any) => ({
  type: MERCHANT_PLANS_SET_PLANS,
  payload,
});

export const merchantPlansSetSelectedPlan = (payload: any) => ({
  type: MERCHANT_PLANS_SET_SELECTED_PLAN,
  payload,
});

export const merchantPlansSetPlanSubscribers = (payload: any) => ({
  type: MERCHANT_PLANS_SET_PLAN_SUBSCRIBERS,
  payload,
});

export const merchantPlansSetInviteSubscriberDialogConfig = (payload: any) => ({
  type: MERCHANT_PLANS_SET_INVITE_SUBSCRIBER_DIALOG_CONFIG,
  payload,
});

// async

export const merchantPlansFetchSaga = () => ({
  type: MERCHANT_PLANS_FETCH_SAGA,
});

export const merchantPlansFetchSelectedPlanSaga = (payload: string) => ({
  type: MERCHANT_PLANS_FETCH_SELECTED_PLAN_SAGA,
  payload,
});

export const merchantPlansCreateSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_PLANS_CREATE_SAGA,
  payload,
  navigate,
});

export const merchantPlansUpdateSaga = (payload: any) => ({
  type: MERCHANT_PLANS_UPDATE_SAGA,
  payload,
});

export const merchantPlansFetchSubscribersSaga = (payload: any) => ({
  type: MERCHANT_PLANS_SUBSCRIBERS_FETCH_SAGA,
  payload,
});

export const merchantPlansInviteSubscriberSaga = (payload: any) => ({
  type: MERCHANT_PLANS_INVITE_SUBSCRIBER_SAGA,
  payload,
});

import { useDispatch } from "react-redux";
import DialogContent from "@mui/material/DialogContent";
import TextField from "components/TextField";
import { Dialog } from "components/Dialog";
import Button from "components/Button";
import moment from "moment-timezone";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { createMerchantRepeatOrder } from "api";
import { appSetError } from "app/state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";
import { useMergeState } from "utils/custom-hooks";
import { truncateString } from "utils/string";
import { OrderType, OrderScheduleType } from "enums/orders";
import { getPlanTitle } from "utils/common";
import { formatDate } from "utils/date";

type Props = {
  open: boolean;
  onClose: any;
  order: any;
};

export default function OrderDetailsDialog(props: Props) {
  const { open, onClose, order } = props;

  const dispatch = useDispatch();

  const [state, setState] = useMergeState({
    isReOrdering: false,
    shouldShowScheduleDetails: false,

    scheduledDate: moment(),
    scheduledTime: moment(),
  });

  const toggleScheduleDetails = () => {
    setState({ shouldShowScheduleDetails: !state.shouldShowScheduleDetails });
  };

  const handleDateChange = (newValue: any) => {
    setState({ scheduledDate: newValue });
  };

  const handleTimeChange = (newValue: any) => {
    setState({ scheduledTime: newValue });
  };

  const handleReOrder = async (scheduleType: string) => {
    try {
      setState({ isReOrdering: true });

      if (
        state?.shouldShowScheduleDetails &&
        scheduleType === OrderScheduleType.IMMEDIATE
      ) {
        setState({ shouldShowScheduleDetails: false });
      }

      const payload: any = {
        id: order._id,
        scheduleType,
      };

      if (scheduleType === OrderScheduleType.SCHEDULED) {
        const scheduledTime = `${
          moment(state?.scheduledDate).format().split("T")[0]
        }T${moment(state?.scheduledTime).format().split("T")[1]}`;

        console.log("scheduledTime : ", scheduledTime);

        payload.scheduledTime = scheduledTime;
      }

      const response = await createMerchantRepeatOrder(payload);

      setState({ isReOrdering: false });

      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    } finally {
      setState({ isReOrdering: false });
      onClose(true);
    }
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          width: 500,
        },
        "& .MuiDialogContent-root": {
          padding: 3,
        },
      }}
    >
      <DialogContent>
        <div>
          <div className="text-grey text-lg mb-2 font-medium">
            Past Order Details
          </div>

          <div className="text-grey text-sm my-1">
            {formatDate(order?.createdAt, "MM/DD/YYYY")}
          </div>

          <div className="text-grey text-xl font-semibold">
            {truncateString(
              order?.type === OrderType.REDEMPTION
                ? getPlanTitle(order)
                : order?.subscription?.product?.title,
              15
            )}
          </div>

          <div className="text-grey text-sm my-2">{order?.variation?.name}</div>

          <ul className="text-grey text-sm my-4">
            {order?.modifiers?.map((elem: any) => (
              <li className="my-1">{elem?.name}</li>
            ))}
          </ul>

          <div className="flex flex-col md:flex-row mt-3">
            <div className="my-2">
              <Button
                label="Re-Order"
                color="secondary"
                variant={
                  state?.shouldShowScheduleDetails ? "outlined" : "contained"
                }
                onClick={() => handleReOrder(OrderScheduleType.IMMEDIATE)}
                style={{
                  borderRadius: 40,
                  fontWeight: 500,
                  fontSize: 14,
                  color: state?.shouldShowScheduleDetails
                    ? "#000000"
                    : "#FFFFFF",
                  height: 40,
                }}
                loaderButton
                loadingPosition="center"
                loading={state?.isReOrdering}
                disabled={state?.isReOrdering}
              />
            </div>

            {/* <div className="my-2 md:ml-4">
              <Button
                label="Schedule For Later"
                color="secondary"
                variant={
                  state?.shouldShowScheduleDetails ? "contained" : "outlined"
                }
                onClick={toggleScheduleDetails}
                style={{
                  borderRadius: 40,
                  fontWeight: 500,
                  fontSize: 14,
                  color: state?.shouldShowScheduleDetails
                    ? "#FFFFFF"
                    : "#000000",
                  height: 40,
                }}
                disabled={state?.isReOrdering}
              />
            </div> */}
          </div>

          {state?.shouldShowScheduleDetails && (
            <div className="mt-4">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <div className="flex flex-col md:flex-row mt-3">
                  <div className="my-2">
                    <div className="text-grey text-sm font-normal mb-1">
                      Date
                    </div>
                    {/* <DesktopDatePicker
                      inputFormat="MM/DD/YYYY"
                      value={state?.scheduledDate}
                      onChange={handleDateChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: 180,
                            "& .MuiOutlinedInput-root": {
                              height: 40,
                              fontSize: 14,
                            },
                          }}
                        />
                      )}
                      disablePast
                    /> */}
                  </div>

                  <div className="my-2 md:ml-4">
                    <div className="text-grey text-sm font-normal mb-1">
                      Time
                    </div>
                    {/* <TimePicker
                      value={state?.scheduledTime}
                      onChange={handleTimeChange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          sx={{
                            width: 180,
                            "& .MuiOutlinedInput-root": {
                              height: 40,
                              fontSize: 14,
                            },
                          }}
                        />
                      )}
                    /> */}
                  </div>
                </div>
              </LocalizationProvider>

              <div className="mt-5">
                <Button
                  label="Confirm"
                  color="secondary"
                  onClick={() => handleReOrder(OrderScheduleType.SCHEDULED)}
                  style={{
                    borderRadius: 40,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 40,
                  }}
                  loaderButton
                  loadingPosition="center"
                  loading={state?.isReOrdering}
                  disabled={state?.isReOrdering}
                />
              </div>
            </div>
          )}
        </div>
      </DialogContent>
    </Dialog>
  );
}

export const CUSTOMER_AUTH_SET_MESSAGE = "CUSTOMER_AUTH_SET_MESSAGE";
export const CUSTOMER_AUTH_SET_IS_LOADING = "CUSTOMER_AUTH_SET_IS_LOADING";
export const CUSTOMER_AUTH_SET_IS_LOGGED_IN = "CUSTOMER_AUTH_SET_IS_LOGGED_IN";
export const CUSTOMER_AUTH_SET_CURRENT_USER = "CUSTOMER_AUTH_SET_CURRENT_USER";
export const CUSTOMER_AUTH_SET_MERCHANT_DETAILS =
  "CUSTOMER_AUTH_SET_MERCHANT_DETAILS";

// async
export const CUSTOMER_AUTH_SIGNUP_SAGA = "CUSTOMER_AUTH_SIGNUP_SAGA";
export const CUSTOMER_AUTH_SIGNIN_SAGA = "CUSTOMER_AUTH_SIGNIN_SAGA";
export const CUSTOMER_AUTH_GENERATE_OTP_SAGA =
  "CUSTOMER_AUTH_GENERATE_OTP_SAGA";
export const CUSTOMER_AUTH_VERIFY_OTP_SAGA = "CUSTOMER_AUTH_VERIFY_OTP_SAGA";
export const CUSTOMER_AUTH_FETCH_MERCHANT_DETAILS_SAGA =
  "CUSTOMER_AUTH_FETCH_MERCHANT_DETAILS_SAGA";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Spinner from "components/Spinner";
import { merchantConnectGetStripeAccountLinkSaga } from "../state/actions";

export default function MerchantConnectStripeContainer() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading } = useSelector((store: any) => store?.merchantConnect);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const type = searchParams.get("type");

    if (type === "refresh") {
      dispatch(merchantConnectGetStripeAccountLinkSaga());
      return;
    }

    if (type === "return") {
      navigate("/settings?tab=PAYMENT_PROCESSORS");
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : null}
    </div>
  );
}

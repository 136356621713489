import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Spinner from "components/Spinner";
import Images from "assets/images";
import { truncateString, renderVariationString } from "utils/string";
import { merchantCommunityCartFetchMyCartSaga } from "./state/actions";

export default function MerchantCommunityCartContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, my: cart } = useSelector(
    (store: any) => store?.merchantCommunityCart
  );

  const handleCreatYourCart = () => {
    navigate("/community-cart/create");
  };

  useEffect(() => {
    dispatch(merchantCommunityCartFetchMyCartSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey mb-4">Your Cart</div>

          {!cart.length && (
            <div className="mt-4">
              <div className="text-xl">Choose your cart to get started</div>

              <div className="w-3/4 mt-4">
                <Button
                  label="Choose Your Cart To Get Started"
                  color="secondary"
                  onClick={handleCreatYourCart}
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 50,
                  }}
                  fullWidth
                />
              </div>
            </div>
          )}

          {/* <div className="mt-10">
                {cart.map((elem: any) => (
                  <div
                    key={elem?._id}
                    className="sm:flex sm:flex-col md:flex md:flex-row mt-4"
                  >
                    <div className="sm:w-full md:w-1/2">
                      <img
                        src={elem?.product?.images[0]}
                        className="rounded-md"
                      />
                    </div>

                    <div className="sm:w-full md:w-1/2 flex flex-col justify-between ml-8">
                      <div>
                        <div className="text-2xl font-semibold text-grey mt-2">
                          {elem?.product?.title}
                        </div>

                        <div className="w-10/12 flex justify-between items-end mt-2">
                          <div className="text-lg text-grey">
                            ${elem?.product?.price?.amount} / Unit
                          </div>

                          <div className="text-green-3">
                            Only with Community Cart
                          </div>
                        </div>

                        <div className="text-grey text-sm font-medium mt-2">
                          LOCAL W/ CHERRY
                        </div>

                        <div className="text-grey mt-2">
                          {elem?.product?.description}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div> */}

          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-8 mt-6">
            {cart.map((elem: any) => (
              <div
                key={elem?._id}
                className="bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md mb-4 md:mb-0"
              >
                <div className="flex justify-between">
                  <div className="my-2">
                    <div className="text-grey font-semibold text-lg">
                      {elem?.product?.title}
                    </div>

                    <div className="text-grey text-sm mt-4">
                      {truncateString(elem?.product?.description, 50)}
                    </div>

                    <div className="hidden md:block text-grey text-sm italic mt-4">
                      {renderVariationString(elem?.product?.variations)}
                    </div>
                  </div>

                  <img
                    src={elem?.product?.images[0] || Images.NoImagePlaceholder}
                    className="w-36 h-36 rounded-r-md object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

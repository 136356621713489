import { RESET_APP_STATE } from "app/state/types";
import {
  CUSTOMER_DASHBOARD_SET_IS_LOADING,
  CUSTOMER_DASHBOARD_SET_STORES,
  CUSTOMER_DASHBOARD_SET_PLANS,
} from "./types";

type CustomerDashboardStateType = {
  isLoading: boolean;
  stores: Array<any>;
  plans: Array<any>;
};

const initialState: CustomerDashboardStateType = {
  isLoading: false,
  stores: [],
  plans: [],
};

const customerDashboardReducer = (
  state: CustomerDashboardStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CUSTOMER_DASHBOARD_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case CUSTOMER_DASHBOARD_SET_STORES:
      return { ...state, stores: action.payload };

    case CUSTOMER_DASHBOARD_SET_PLANS:
      return { ...state, plans: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default customerDashboardReducer;

import { combineReducers } from "redux";

// components
import buyDialogReducer from "components/Customer/BuyDialog/state/reducers";
import customerCartReducer from "components/Customer/Cart/state/reducers";
import customerAddNewDialogReducer from "components/Customer/AddNewCardDialog/state/reducers";
import orderDialogReducer from "components/Customer/OrderDialog/state/reducers";
import orderDetailsDrawerReducer from "components/OrderDetailsDrawer/state/reducers";

// containers
import appReducer from "app/state/reducers";

// customer
import customerAuthReducer from "containers/consumer/auth/state/reducers";
import customerDashboardReducer from "containers/consumer/dashboard/state/reducers";
import customerCheckoutReducer from "containers/consumer/checkout/state/reducers";
import customerSubscriptionReducer from "containers/consumer/subscription/state/reducers";
import publicBusinessPageReducer from "components/PublicBusinessPage/state/reducers";
import customerConfirmAddPaymentMethodReducer from "containers/consumer/confirm-add-payment-method/state/reducers";
import customerSettingsReducer from "containers/consumer/settings/state/reducers";
import consumerOrdersReducer from "containers/consumer/orders/state/reducers";
import consumerPlansReducer from "containers/consumer/plans/state/reducers";
import customerTextToPayReducer from "containers/consumer/text-to-pay/state/reducers";

// merchant
import merchantAuthReducer from "containers/merchant/auth/state/reducers";
import merchantDashboardReducer from "containers/merchant/dashboard/state/reducers";
import merchantPlansReducer from "containers/merchant/plans/state/reducers";
import merchantProductsReducer from "containers/merchant/products/state/reducers";
import merchantCommunityCartReducer from "containers/merchant/community-cart/state/reducers";
import merchantSettingsReducer from "containers/merchant/settings/state/reducers";
import merchantUserProfileReducer from "containers/merchant/profile/state/reducers";
import merchantConnectReducer from "containers/merchant/connect/state/reducers";
import merchantOrdersReducer from "containers/merchant/orders/state/reducers";
import merchantAlertsReducer from "containers/merchant/alerts/state/reducers";
import merchantInvitationsReducer from "containers/merchant/invitations/state/reducers";
import merchantCustomersReducer from "containers/merchant/customers/state/reducers";
import merchantDiscountsReducer from "containers/merchant/discounts/state/reducers";

export const rootReducer = combineReducers({
  // components
  buyDialog: buyDialogReducer,
  customerCart: customerCartReducer,
  customerAddNewDialog: customerAddNewDialogReducer,
  orderDialog: orderDialogReducer,
  orderDetailsDrawer: orderDetailsDrawerReducer,
  publicBusinessPage: publicBusinessPageReducer,

  // containers
  app: appReducer,

  // customer
  customerAuth: customerAuthReducer,
  customerDashboard: customerDashboardReducer,
  customerCheckout: customerCheckoutReducer,
  customerSubscription: customerSubscriptionReducer,
  customerConfirmAddPaymentMethod: customerConfirmAddPaymentMethodReducer,
  customerSettings: customerSettingsReducer,
  consumerOrders: consumerOrdersReducer,
  consumerPlans: consumerPlansReducer,
  customerTextToPay: customerTextToPayReducer,

  // merchant
  merchantAuth: merchantAuthReducer,
  merchantDashboard: merchantDashboardReducer,
  merchantPlans: merchantPlansReducer,
  merchantProducts: merchantProductsReducer,
  merchantCommunityCart: merchantCommunityCartReducer,
  merchantSettings: merchantSettingsReducer,
  merchantUserProfile: merchantUserProfileReducer,
  merchantConnect: merchantConnectReducer,
  merchantOrders: merchantOrdersReducer,
  merchantAlerts: merchantAlertsReducer,
  merchantInvitations: merchantInvitationsReducer,
  merchantCustomers: merchantCustomersReducer,
  merchantDiscounts: merchantDiscountsReducer,
});

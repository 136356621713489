import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createStripeConnectAccount,
  getStripeConnectAccount,
  getMerchantProfile,
  updateMerchantProfile,
  getMerchantMembers,
  inviteMerchantMember,
  merchantUpdateProcessingFeesType,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_SETTINGS_PAYMENT_PROCESSORS_CONNECT_STRIPE_ACCOUNT_SAGA,
  MERCHANT_SETTINGS_PAYMENT_PROCESSORS_FETCH_ACCOUNT_DETAILS_SAGA,
  MERCHANT_SETTINGS_BUSINESS_PROFILE_FETCH_PROFILE_DETAILS_SAGA,
  MERCHANT_SETTINGS_BUSINESS_PROFILE_UPDATE_PROFILE_DETAILS_SAGA,
  MERCHANT_SETTINGS_TEAMS_FETCH_DETAILS_SAGA,
  MERCHANT_SETTINGS_TEAMS_INVITE_MEMBER_SAGA,
  MERCHANT_SETTINGS_UPDATE_PROCESSING_FEES_TYPE_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantSettingsSetIsLoading,
  merchantSettingsPaymentProcessorsSetAccountDetails,
  merchantSettingsBusinessProfileSetConfig,
  merchantSettingsBusinessProfileSetProfileDetails,
  merchantSettingsTeamsSetDetails,
  merchantSettingsTeamsSetInviteMemberDialogConfig,
} from "./actions";

function* merchantSettingsPaymentProcessorsConnectStripeAccountHandler(): any {
  try {
    yield put(merchantSettingsSetIsLoading(true));

    const response = yield call(createStripeConnectAccount);

    if (response?.success) {
      window.location.href = response?.data?.url;
    }

    yield put(merchantSettingsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantSettingsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantSettingsPaymentProcessorsFetchAccountDetailsHandler(): any {
  try {
    yield put(merchantSettingsSetIsLoading(true));

    const response = yield call(getStripeConnectAccount);

    if (response?.success) {
      yield put(
        merchantSettingsPaymentProcessorsSetAccountDetails(response?.data)
      );
    }

    yield put(merchantSettingsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantSettingsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantSettingsBusinessProfileFetchProfileDetailsHandler(): any {
  try {
    yield put(merchantSettingsSetIsLoading(true));

    const response = yield call(getMerchantProfile);

    if (response?.success) {
      yield put(
        merchantSettingsBusinessProfileSetProfileDetails(response?.data)
      );
    }

    yield put(merchantSettingsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantSettingsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantSettingsBusinessProfileUpdateProfileDetailsHandler(
  action: any
): any {
  try {
    yield put(
      merchantSettingsBusinessProfileSetConfig({
        isSavingBusinessDetails: true,
      })
    );

    const response = yield call(updateMerchantProfile, action.payload);

    if (response?.success) {
      yield all([
        yield merchantSettingsBusinessProfileFetchProfileDetailsHandler(),
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
      ]);
    }

    yield put(
      merchantSettingsBusinessProfileSetConfig({
        shouldEditBusinessDetails: false,
        isSavingBusinessDetails: false,
      })
    );
  } catch (error: any) {
    yield all([
      yield put(
        merchantSettingsBusinessProfileSetConfig({
          isSavingBusinessDetails: false,
        })
      ),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantSettingsTeamsFetchDetailsHandler(): any {
  try {
    yield put(merchantSettingsSetIsLoading(true));

    const response = yield call(getMerchantMembers);

    if (response?.success) {
      yield put(merchantSettingsTeamsSetDetails(response?.data));
    }

    yield put(merchantSettingsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantSettingsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantSettingsTeamsInviteMemberHandler(action: any): any {
  try {
    yield put(
      merchantSettingsTeamsSetInviteMemberDialogConfig({ isLoading: true })
    );

    const response = yield call(inviteMerchantMember, action.payload);

    if (response?.success) {
      yield all([
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
        yield put(
          merchantSettingsTeamsSetInviteMemberDialogConfig({
            open: false,
            isLoading: false,
          })
        ),
      ]);
    }
  } catch (error: any) {
    yield all([
      yield put(
        merchantSettingsTeamsSetInviteMemberDialogConfig({ isLoading: false })
      ),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantSettingsUpdateProcessingFeesTypeHandler(action: any): any {
  try {
    const response = yield call(
      merchantUpdateProcessingFeesType,
      action.payload
    );

    if (response?.success) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  }
}

export default function* watchMerchantSettings() {
  yield takeLatest(
    MERCHANT_SETTINGS_PAYMENT_PROCESSORS_CONNECT_STRIPE_ACCOUNT_SAGA,
    merchantSettingsPaymentProcessorsConnectStripeAccountHandler
  );
  yield takeLatest(
    MERCHANT_SETTINGS_PAYMENT_PROCESSORS_FETCH_ACCOUNT_DETAILS_SAGA,
    merchantSettingsPaymentProcessorsFetchAccountDetailsHandler
  );
  yield takeLatest(
    MERCHANT_SETTINGS_BUSINESS_PROFILE_FETCH_PROFILE_DETAILS_SAGA,
    merchantSettingsBusinessProfileFetchProfileDetailsHandler
  );
  yield takeLatest(
    MERCHANT_SETTINGS_BUSINESS_PROFILE_UPDATE_PROFILE_DETAILS_SAGA,
    merchantSettingsBusinessProfileUpdateProfileDetailsHandler
  );
  yield takeLatest(
    MERCHANT_SETTINGS_TEAMS_FETCH_DETAILS_SAGA,
    merchantSettingsTeamsFetchDetailsHandler
  );
  yield takeLatest(
    MERCHANT_SETTINGS_TEAMS_INVITE_MEMBER_SAGA,
    merchantSettingsTeamsInviteMemberHandler
  );
  yield takeLatest(
    MERCHANT_SETTINGS_UPDATE_PROCESSING_FEES_TYPE_SAGA,
    merchantSettingsUpdateProcessingFeesTypeHandler
  );
}

import {
  CUSTOMER_SUBSCRIPTIONS_SET_IS_LOADING,
  CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTIONS,
  CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTION_DETAILS,
  CUSTOMER_SUBSCRIPTIONS_SET_ORDERS,
  CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SAGA,
  CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTION_DETAILS_SAGA,
  CUSTOMER_SUBSCRIPTIONS_PAUSE_SAGA,
  CUSTOMER_SUBSCRIPTIONS_CANCEL_SAGA,
} from "./types";

export const customerSubscriptionsSetIsLoading = (payload: any) => ({
  type: CUSTOMER_SUBSCRIPTIONS_SET_IS_LOADING,
  payload,
});

export const customerSubscriptionsSetSubscriptions = (payload: any) => ({
  type: CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTIONS,
  payload,
});

export const customerSubscriptionsSetSubscriptionDetails = (payload: any) => ({
  type: CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTION_DETAILS,
  payload,
});

export const customerSubscriptionsSetOrders = (payload: any) => ({
  type: CUSTOMER_SUBSCRIPTIONS_SET_ORDERS,
  payload,
});

// async

export const customerSubscriptionsFetchSubscriptionsSaga = () => ({
  type: CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SAGA,
});

export const customerSubscriptionsFetchSubscriptionDetailsSaga = (
  payload: any
) => ({
  type: CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTION_DETAILS_SAGA,
  payload,
});

export const customerSubscriptionsPauseSaga = (payload: any) => ({
  type: CUSTOMER_SUBSCRIPTIONS_PAUSE_SAGA,
  payload,
});

export const customerSubscriptionsCancelSaga = (payload: any) => ({
  type: CUSTOMER_SUBSCRIPTIONS_CANCEL_SAGA,
  payload,
});

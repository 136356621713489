import { takeLatest, put, all, call } from "redux-saga/effects";
import { getUserInfo, updateUserInfo } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { MERCHANT_USER_UPDATE_PROFILE_DETAILS_SAGA } from "./types";
import { appSetError } from "app/state/actions"; // app actions
import { merchantAuthSetCurrentUser } from "containers/merchant/auth/state/actions"; // merchant auth actions
import { merchantUserProfileSetConfig } from "./actions";

function* merchantUserProfileFetchProfileDetailsHandler(action: any): any {
  try {
    yield put(
      merchantUserProfileSetConfig({
        isSavingPersonalInfo: true,
      })
    );

    const response = yield call(updateUserInfo, action.payload);

    if (response?.success) {
      const userInfoResponse = yield call(getUserInfo);

      if (userInfoResponse?.success) {
        yield put(merchantAuthSetCurrentUser({ ...response.data }));
      }
    }

    yield all([
      yield put(
        merchantUserProfileSetConfig({
          shouldEditPersonalInfo: false,
          isSavingPersonalInfo: false,
        })
      ),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      ),
    ]);
  } catch (error: any) {
    yield all([
      yield put(merchantUserProfileSetConfig({ isSavingPersonalInfo: false })),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantUserProfile() {
  yield takeLatest(
    MERCHANT_USER_UPDATE_PROFILE_DETAILS_SAGA,
    merchantUserProfileFetchProfileDetailsHandler
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { nanoid } from "nanoid";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import Button from "components/Button";
import TextField from "components/TextField";
import ConfirmationDialog from "components/ConfirmationDialog";
import ErrorMessage from "components/ErrorMessage";
import { useMergeState } from "utils/custom-hooks";
import {
  merchantProductsModifiersUpdateSaga,
  merchantProductsModifiersCreateSaga,
  merchantProductsModifiersFetchSelectedModiferSaga,
} from "../../state/actions";
import { IconButton } from "@mui/material";

type ModifierType = {
  id: number;
  version: number;
  name: string;
  price: number;
};

const getModifierId = () => `#${nanoid()}`;

export default function MerchantProductsModifiersCreateContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const modifierId = searchParams.get("id");

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const { isLoading, modifier } = useSelector(
    (store: any) => store?.merchantProducts
  );

  const [state, setState] = useMergeState({
    title: "",

    modifiers: [],

    shouldShowConfirmDeleteProductDialog: false,

    isEditMode: false,
    errors: {},
  });

  const isDisabled = state?.isEditMode && !user?.merchant?.allowPushingToSquare;

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.title) {
      payload = { title: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const wrapModifiers = (modifiers: Array<ModifierType>) =>
    modifiers?.map((modifier: ModifierType) => ({
      ...modifier,
      price: Number(modifier?.price),
    }));

  const handleCreateProductModifier = async () => {
    if (!isFormValid()) {
      return;
    }

    if (state?.isEditMode) {
      dispatch(
        merchantProductsModifiersUpdateSaga({
          id: modifierId,
          title: state?.title,
          modifiers: wrapModifiers(state?.modifiers),
        })
      );
    } else {
      dispatch(
        merchantProductsModifiersCreateSaga(
          {
            title: state?.title,
            modifiers: wrapModifiers(state?.modifiers),
          },
          navigate
        )
      );
    }
  };

  // const handleOpenConfirmDeleteProductDialog = () => {
  //   setState({ shouldShowConfirmDeleteProductDialog: true });
  // };

  const handleCloseConfirmDeleteProductDialog = () => {
    setState({ shouldShowConfirmDeleteProductDialog: false });
  };

  const handleConfirmDeleteProductDialog = () => {
    // productId
    handleCloseConfirmDeleteProductDialog();
  };

  const handleChangeModifier = (event: any, index: number) => {
    const newModifiers = [...state?.modifiers];

    newModifiers[index][event?.target?.name] = event?.target?.value;

    setState({
      modifiers: [...newModifiers],
    });
  };

  const handleAddModifier = () => {
    const newModifiers = [...state?.modifiers];

    newModifiers.push({
      id: getModifierId(),
      name: "",
      price: 0,
    });

    setState({
      modifiers: newModifiers,
    });
  };

  const handleRemoveModifier = (index: number) => {
    const newModifiers = [...state?.modifiers];

    newModifiers.splice(index, 1);

    setState({
      modifiers: newModifiers,
    });
  };

  useEffect(() => {
    if (modifierId) {
      dispatch(merchantProductsModifiersFetchSelectedModiferSaga(modifierId));
      setState({ isEditMode: true });
    }
  }, []);

  useEffect(() => {
    if (state.isEditMode) {
      const payload = {
        title: modifier?.title,
        modifiers: modifier?.modifiers,
      };

      setState({ ...payload });
    } else {
      setState({
        modifiers: [
          {
            id: getModifierId(),
            name: "",
            price: 0,
          },
        ],
      });
    }
  }, [modifier]);

  return (
    <div>
      <div>
        <div className="text-4xl font-semibold text-grey mb-4">
          {state.isEditMode
            ? "Edit product modifier set"
            : "Create a product modifier set"}
        </div>

        <div className="w-full lg:w-3/5 my-8">
          <div>
            <div className="text-grey font-semibold">
              What do you want to call your product modifier set?
            </div>

            <div className="my-2">
              <TextField
                fullWidth
                label="TITLE"
                variant="outlined"
                name="title"
                value={state.title}
                onChange={handleChange}
                required
                error={state?.errors?.title}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                disabled={isDisabled}
              />

              {state?.errors?.title && (
                <ErrorMessage message="Title is required" />
              )}
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <div className="text-grey font-semibold">Modifiers</div>

            <div className="mt-3">
              {state?.modifiers?.map(
                (modifier: ModifierType, index: number) => (
                  <div
                    key={modifier?.id}
                    className="flex justify-between items-center mt-5"
                  >
                    <div className="w-full md:w-3/4 flex justify-between items-center">
                      <div>
                        <div className="text-grey font-medium">Name</div>

                        <div className="mt-2">
                          <TextField
                            variant="outlined"
                            name="name"
                            value={state?.modifiers[index]?.name}
                            onChange={(event) =>
                              handleChangeModifier(event, index)
                            }
                            color="secondary"
                            InputProps={{
                              style: {
                                height: 40,
                                borderRadius: 6,
                              },
                            }}
                            fullWidth
                            disabled={isDisabled}
                          />
                        </div>
                      </div>

                      <div className="ml-2">
                        <div className="text-grey font-medium">Price</div>

                        <div className="mt-2">
                          <TextField
                            type="number"
                            variant="outlined"
                            name="price"
                            value={state?.modifiers[index]?.price}
                            onWheel={(event: any) => event.target.blur()}
                            onChange={(event) =>
                              handleChangeModifier(event, index)
                            }
                            color="secondary"
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  $
                                </InputAdornment>
                              ),
                              style: {
                                height: 40,
                                borderRadius: 6,
                              },
                            }}
                            fullWidth
                            disabled={isDisabled}
                          />
                        </div>
                      </div>
                    </div>

                    {!isDisabled && (
                      <div className="w-1/4 flex justify-end mt-8">
                        {index === state?.modifiers?.length - 1 ? (
                          <IconButton onClick={handleAddModifier}>
                            <AddIcon />
                          </IconButton>
                        ) : (
                          <IconButton
                            onClick={() => handleRemoveModifier(index)}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}

                        {index !== 0 && index === state?.modifiers?.length - 1 && (
                          <IconButton
                            onClick={() => handleRemoveModifier(index)}
                            sx={{ ml: 1 }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </div>
                    )}
                  </div>
                )
              )}
            </div>

            <div className="my-8">
              <div className="w-full">
                <Button
                  label={
                    state.isEditMode
                      ? "Edit Product Modifier Set"
                      : "Create Product Modifier Set"
                  }
                  color="secondary"
                  onClick={handleCreateProductModifier}
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 50,
                  }}
                  fullWidth
                  loaderButton
                  loadingPosition="center"
                  loading={isLoading}
                  disabled={isLoading}
                />
              </div>

              {/* {state.isEditMode && (
                <div className="w-full mt-4">
                  <Button
                    label="Delete Product"
                    color="error"
                    onClick={handleOpenConfirmDeleteProductDialog}
                    style={{
                      borderRadius: 10,
                      fontSize: 14,
                      color: "#FFFFFF",
                      height: 50,
                    }}
                    fullWidth
                    loaderButton
                    loadingPosition="center"
                    loading={isLoading}
                    disabled={isLoading}
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {state?.shouldShowConfirmDeleteProductDialog && (
        <ConfirmationDialog
          open={state?.shouldShowConfirmDeleteProductDialog}
          onClose={handleCloseConfirmDeleteProductDialog}
          onConfirm={handleConfirmDeleteProductDialog}
          title="Delete product?"
          message="Are you sure you want to delete this product? This is an irreversible step."
          confirmButtonLabel="Delete"
        />
      )}
    </div>
  );
}

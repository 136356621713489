import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { styled } from "@mui/material/styles";
import MuiInput from "@mui/material/Input";
import Chip from "@mui/material/Chip";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import IconButton from "@mui/material/IconButton";
import RemoveIcon from "@mui/icons-material/Remove";
import AddIcon from "@mui/icons-material/Add";
import Spinner from "components/Spinner";
import { truncateString, renderVariationString } from "utils/string";
import Images from "assets/images";
import { useMergeState } from "utils/custom-hooks";
import { appSetError } from "app/state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";
import { getMerchantProducts, updateMerchantProductUnits } from "api";

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function MerchantProductsInventoryContainer() {
  const dispatch = useDispatch();

  const [state, setState] = useMergeState({
    isLoading: false,
    units: {},
    products: [],
  });

  const handleChangeUnits = async (event: any, product: any) => {
    try {
      const units = event?.target?.value;

      const updatedProducts = [...state?.products];

      const index = updatedProducts.findIndex(
        (elem) => elem?._id === product?._id
      );

      updatedProducts[index].units = units;

      setState({ products: updatedProducts });

      await updateMerchantProductUnits({
        id: product?._id,
        units: updatedProducts[index]?.units,
      });
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  const handleDecrementInventory = async (product: any) => {
    try {
      const updatedProducts = [...state?.products];

      const index = updatedProducts.findIndex(
        (elem) => elem?._id === product?._id
      );

      const { units } = updatedProducts[index];

      if (!units) {
        return;
      }

      updatedProducts[index].units = Number(units) - 1;

      setState({ products: updatedProducts });

      await updateMerchantProductUnits({
        id: product?._id,
        units: updatedProducts[index]?.units,
      });
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  const handleIncrementInventory = async (product: any) => {
    try {
      const updatedProducts = [...state?.products];

      const index = updatedProducts.findIndex(
        (elem) => elem?._id === product?._id
      );

      let { units } = updatedProducts[index];

      if (units === null || units === undefined) {
        units = 0;
      }

      updatedProducts[index].units = Number(units) + 1;

      setState({ products: updatedProducts });

      await updateMerchantProductUnits({
        id: product?._id,
        units: updatedProducts[index]?.units,
      });
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await getMerchantProducts();

      setState({ products: response?.data });
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    } finally {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {state?.isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey">Inventory</div>

          <div className="mt-6">
            {state?.products?.map((product: any) => (
              <div key={product?._id} className="flex my-8">
                <div className="bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md mb-4 md:mb-0 w-1/2">
                  <div className="flex justify-between">
                    <div className="my-2">
                      <div className="flex items-center">
                        <div className="text-grey font-semibold text-lg">
                          {truncateString(product?.title, 30)}
                        </div>
                      </div>

                      <div className="text-grey text-sm mt-2">
                        {truncateString(product?.description, 50)}
                      </div>

                      <div className="hidden md:block text-grey text-sm italic mt-2">
                        {truncateString(
                          renderVariationString(product?.variations),
                          40
                        )}
                      </div>

                      {product?.showOnMenu && (
                        <div className="mt-2">
                          <CheckBoxIcon color="secondary" />
                        </div>
                      )}

                      {product?.deleted && (
                        <div className="mt-2">
                          <Chip
                            label="Deleted"
                            variant="outlined"
                            sx={{
                              fontSize: 12,
                              border: "1px solid",
                            }}
                          />
                        </div>
                      )}
                    </div>

                    <img
                      src={product?.images[0] || Images.NoImagePlaceholder}
                      className="w-36 h-36 rounded-r-md object-cover"
                    />
                  </div>
                </div>

                <div className="w-2/5 ml-10">
                  <div className="flex justify-between items-center">
                    <div className="w-1/3 text-center">
                      <div className="text-grey font-medium text-xl">Units</div>
                      <div className="mt-2">
                        <Input
                          value={product?.units}
                          onChange={(event: any) =>
                            handleChangeUnits(event, product)
                          }
                          autoFocus
                          inputProps={{
                            type: "number",
                            style: {
                              fontSize: 24,
                            },
                          }}
                          onKeyPress={(event: any) => {
                            const regex = new RegExp("^[0-9]");

                            const key = String.fromCharCode(
                              !event.charCode ? event.which : event.charCode
                            );

                            if (!regex.test(key)) {
                              event.preventDefault();
                              return false;
                            }
                          }}
                          disableUnderline
                        />
                      </div>
                    </div>

                    <div className="w-1/3 text-center">
                      <IconButton
                        onClick={() => handleDecrementInventory(product)}
                      >
                        <RemoveIcon sx={{ height: 36, width: 36 }} />
                      </IconButton>
                    </div>

                    <div className="w-1/3 text-center">
                      <IconButton
                        onClick={() => handleIncrementInventory(product)}
                      >
                        <AddIcon sx={{ height: 36, width: 36 }} />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import React, { useRef } from "react";
import { useDispatch } from "react-redux";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Button from "components/Button";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import { useMergeState } from "utils/custom-hooks";
import { toBase64 } from "utils/common";
import { appSetError } from "app/state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";
import { createDemoMerchantAccount } from "api";

const BUSINESS_TYPES = [
  "B2B",
  "Coffee Shops",
  "Food and Beverage",
  "Health and Beauty",
  "Salons or Barbers",
  "Landscaping Services",
  "Personal Services",
  "Counseling",
  "Others",
];

export default function MerchantSettingsDemoCreateContainer() {
  const dispatch = useDispatch();

  const [state, setState] = useMergeState({
    subdomain: "",
    businessName: "",
    businessDescription: "",
    businessType: "",

    businessBanner: "",
    businessBannerBase64: null,
    businessBannerFile: null,
    isEditingBusinessBanner: false,
    businessLogo: "",
    businessLogoFile: null,
    businessLogoBase64: null,
    isEditingBusinessLogo: false,

    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "US",
    zipcode: "",

    isLoading: false,
    errors: {},
  });

  const businessBannerRef = useRef<any>();

  const businessLogoRef = useRef<any>();

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const handleBusinessBanner = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];

    if (!file) {
      return;
    }

    event.target.value = "";

    const base64 = await toBase64(file);

    setState({
      businessBannerFile: file,
      businessBannerBase64: base64,
      isEditingBusinessBanner: true,
    });
  };

  const handleBusinessLogo = async (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const file = event.target.files && event.target.files[0];

    if (!file) {
      return;
    }

    event.target.value = "";

    const base64 = await toBase64(file);

    setState({
      businessLogoFile: file,
      businessLogoBase64: base64,
      isEditingBusinessLogo: true,
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.subdomain) {
      payload = { subdomain: true, ...payload };
      isValid = false;
    }

    if (!state.businessName) {
      payload = { businessName: true, ...payload };
      isValid = false;
    }

    if (!state.businessDescription) {
      payload = { businessDescription: true, ...payload };
      isValid = false;
    }

    if (!state.businessType) {
      payload = { businessType: true, ...payload };
      isValid = false;
    }

    if (!state.addressLine1) {
      payload = { addressLine1: true, ...payload };
      isValid = false;
    }

    if (!state.city) {
      payload = { city: true, ...payload };
      isValid = false;
    }

    if (!state.state) {
      payload = { state: true, ...payload };
      isValid = false;
    }

    if (!state.zipcode) {
      payload = { zipcode: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleBusinessBannerRef = () => {
    businessBannerRef.current.click();
  };

  const handleBusinessLogoRef = () => {
    businessLogoRef.current.click();
  };

  const shouldShowBusinessBanner = () => {
    if (state?.isEditingBusinessBanner && state?.businessBannerBase64) {
      return true;
    }

    if (!state.isEditingBusinessBanner && state?.businessBanner) {
      return true;
    }

    return false;
  };

  const shouldShowBusinessLogo = () => {
    if (state?.isEditingBusinessLogo && state?.businessLogoBase64) {
      return true;
    }

    if (!state.isEditingBusinessLogo && state?.businessLogo) {
      return true;
    }

    return false;
  };

  const handleCreateDemoAccount = async () => {
    try {
      if (!isFormValid()) {
        return;
      }

      const payload = {
        subdomain: state?.subdomain,
        businessName: state?.businessName,
        businessDescription: state?.businessDescription,
        businessType: state?.businessType,

        businessBannerBase64: null,
        businessBannerContentType: null,
        businessLogoBase64: null,
        businessLogoContentType: null,

        addressLine1: state?.addressLine1,
        addressLine2: state?.addressLine2,
        city: state?.city,
        state: state?.state,
        country: state?.country,
        zipcode: state?.zipcode,
      };

      if (state.businessBannerBase64) {
        payload.businessBannerBase64 = state.businessBannerBase64;
        payload.businessBannerContentType = state.businessBannerFile?.type;
      }

      if (state.businessLogoBase64) {
        payload.businessLogoBase64 = state.businessLogoBase64;
        payload.businessLogoContentType = state.businessLogoFile?.type;
      }

      const response = await createDemoMerchantAccount(payload);

      if (response?.success) {
        dispatch(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        );
      }
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  return (
    <div>
      <div className="flex justify-between">
        <div className="w-full lg:w-8/12">
          <div className="profile-box p-4">
            <div className="text-lg text-grey font-semibold">
              Business Overview
            </div>

            <div className="w-full mt-5">
              <div>
                <TextField
                  fullWidth
                  label="BUSINESS SUBDOMAIN"
                  variant="outlined"
                  name="subdomain"
                  value={state.subdomain}
                  onChange={handleChange}
                  required
                  error={state?.errors?.subdomain}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.subdomain && (
                  <ErrorMessage message="Subdomain is required" />
                )}
              </div>

              <div className="mt-4">
                <TextField
                  fullWidth
                  label="BUSINESS NAME"
                  variant="outlined"
                  name="businessName"
                  value={state.businessName}
                  onChange={handleChange}
                  required
                  error={state?.errors?.businessName}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.businessName && (
                  <ErrorMessage message="Business name is required" />
                )}
              </div>

              <div className="mt-4">
                <TextField
                  fullWidth
                  label="BUSINESS DESCRIPTION"
                  variant="outlined"
                  name="businessDescription"
                  value={state.businessDescription}
                  onChange={handleChange}
                  required
                  error={state?.errors?.businessDescription}
                  multiline
                  minRows={4}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.businessDescription && (
                  <ErrorMessage message="Business description is required" />
                )}
              </div>

              <div className="w-full mt-4">
                <FormControl variant="outlined" className="input-select-field">
                  <InputLabel
                    shrink
                    disableAnimation
                    className="input-label"
                    required
                  >
                    BUSINESS TYPE
                  </InputLabel>

                  <Select
                    fullWidth
                    label="BUSINESS TYPE"
                    variant="outlined"
                    name="businessType"
                    value={state.businessType}
                    onChange={handleChange}
                    autoComplete="off"
                    inputProps={{
                      autoComplete: "off",
                    }}
                    required
                    error={state?.errors?.businessType}
                  >
                    {BUSINESS_TYPES.map((businessType: any) => (
                      <MenuItem key={businessType} value={businessType}>
                        {businessType}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>

                {state?.errors?.businessType && (
                  <ErrorMessage message="Business type is required" />
                )}
              </div>

              <hr className="mt-5 mb-2" />

              <div className="text-lg text-grey font-semibold">
                Business Banner
              </div>

              <div className="mt-5">
                <div className="text-sm">
                  Your business banner shows up on the consumer platform, so
                  make sure you put a nice enlarged image, usually 1600 * 500
                </div>

                <div className="w-full mt-4">
                  {shouldShowBusinessBanner() && (
                    <img
                      className="w-full h-[250px] rounded-md object-cover"
                      src={
                        state.isEditingBusinessBanner
                          ? state?.businessBannerBase64
                          : state?.businessBanner
                      }
                    />
                  )}
                </div>

                <div className="mt-4">
                  <Button
                    label="Upload Business Banner"
                    color="info"
                    onClick={handleBusinessBannerRef}
                    style={{
                      borderRadius: 10,
                      fontSize: 16,
                      color: "#FFFFFF",
                      height: 40,
                    }}
                    startIcon={<UploadFileIcon />}
                  />
                </div>

                <input
                  type="file"
                  className="hidden"
                  ref={businessBannerRef}
                  onChange={handleBusinessBanner}
                />
              </div>

              <hr className="mt-5 mb-2" />

              <div className="text-lg text-grey font-semibold">
                Business Logo
              </div>

              <div className="mt-5">
                <div className="text-sm">
                  Your business logo shows up on the consumer platform, so make
                  sure you put a nice image, usually 200 * 200
                </div>

                <div className="mt-4">
                  {shouldShowBusinessLogo() && (
                    <img
                      className="w-52 h-52 rounded-full object-cover"
                      src={
                        state.isEditingBusinessLogo
                          ? state?.businessLogoBase64
                          : state?.businessLogo
                      }
                    />
                  )}
                </div>

                <div className="mt-4">
                  <Button
                    label="Upload Business Logo"
                    color="info"
                    onClick={handleBusinessLogoRef}
                    style={{
                      borderRadius: 10,
                      fontSize: 16,
                      color: "#FFFFFF",
                      height: 40,
                    }}
                    startIcon={<UploadFileIcon />}
                  />
                </div>

                <input
                  type="file"
                  className="hidden"
                  ref={businessLogoRef}
                  onChange={handleBusinessLogo}
                />
              </div>

              <hr className="mt-5 mb-2" />

              <div className="text-lg text-grey font-semibold">
                Business Address
              </div>

              <div className="mt-5">
                <TextField
                  fullWidth
                  label="ADDRESS LINE 1"
                  variant="outlined"
                  name="addressLine1"
                  value={state.addressLine1}
                  onChange={handleChange}
                  required
                  error={state?.errors?.addressLine1}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.addressLine1 && (
                  <ErrorMessage message="Address line 1 is required" />
                )}
              </div>

              <div className="mt-4">
                <TextField
                  fullWidth
                  label="ADDRESS LINE 2"
                  variant="outlined"
                  name="addressLine2"
                  value={state.addressLine2}
                  onChange={handleChange}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />
              </div>

              <div className="flex justify-between mt-4">
                <div className="w-1/2">
                  <TextField
                    fullWidth
                    label="CITY"
                    variant="outlined"
                    name="city"
                    value={state.city}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: true,
                    }}
                    required
                    error={state?.errors?.city}
                  />

                  {state?.errors?.city && (
                    <ErrorMessage message="City is required" />
                  )}
                </div>

                <div className="w-1/2 ml-2">
                  <TextField
                    fullWidth
                    label="STATE"
                    variant="outlined"
                    name="state"
                    value={state.state}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: true,
                    }}
                    required
                    error={state?.errors?.state}
                  />

                  {state?.errors?.state && (
                    <ErrorMessage message="State is required" />
                  )}
                </div>
              </div>

              <div className="flex justify-between mt-4">
                <div className="w-1/2">
                  <TextField
                    fullWidth
                    label="ZIP CODE"
                    variant="outlined"
                    name="zipcode"
                    value={state.zipcode}
                    onChange={handleChange}
                    required
                    error={state?.errors?.zipcode}
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: true,
                    }}
                  />

                  {state?.errors?.zipcode && (
                    <ErrorMessage message="Zipcode is required" />
                  )}
                </div>

                <div className="w-1/2 ml-2">
                  <TextField
                    fullWidth
                    label="COUNTRY"
                    variant="outlined"
                    name="country"
                    value={state.country}
                    onChange={handleChange}
                    InputLabelProps={{
                      shrink: true,
                      disableAnimation: true,
                    }}
                    disabled
                  />
                </div>
              </div>

              <div className="mt-4">
                <Button
                  label="Save"
                  color="info"
                  onClick={handleCreateDemoAccount}
                  style={{
                    borderRadius: 10,
                    fontSize: 16,
                    color: "#FFFFFF",
                    height: 40,
                  }}
                  loaderButton
                  loadingPosition="center"
                  loading={state?.isLoading}
                  disabled={state?.isLoading}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { UserTypes } from "enums/userTypes";
import { MERCHANT_PLATFORM_SUBDOMAIN } from "./constants";
import { ProcessingFeesType } from "enums/Payments";

export const getSubdomain = () => {
  const host = window.location.host; // eslint-disable-line

  const parts = host.split(".").slice(0, host.includes("localhost") ? -1 : -2);

  return parts[0];
};

export const getPlatformType = () => {
  const subdomain = getSubdomain();

  let platformType = "";

  if (subdomain === MERCHANT_PLATFORM_SUBDOMAIN) {
    platformType = UserTypes.MERCHANT;
  } else {
    platformType = UserTypes.CONSUMER;
  }

  return platformType;
};

export const toBase64 = (file: any) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });

export const wrapFullName = (firstName: string, lastName?: string) =>
  `${firstName}${lastName ? ` ${lastName}` : ""}`;

export const getMerchantStoreUrl = (subdomain: string) =>
  `https://${subdomain}.${process.env.REACT_APP_HOST_URL}/menu?showIOSInstallPopup=true`;

export const getCommunitySignupUrl = () =>
  `https://app.${
    process.env.REACT_APP_HOST_URL
  }/signup?subdomain=${getSubdomain()}`;

export const isMasterAccount = () => getSubdomain() === "master";

export const getPlanTitle = (order: any) => {
  const variationId = order.variation.id;

  let plan = { title: "" };

  order.subscription.product.products.forEach((elem: any) => {
    if (elem.variations.some((item: any) => item?.id === variationId)) {
      plan = elem;
    }
  });

  return plan?.title;
};

export const debounce = (func: any, wait: number) => {
  let timeout: any;

  return (...args: any) => {
    const context = this;

    if (timeout) clearTimeout(timeout);

    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

// checkout

export const getSubtotal = (items: Array<unknown>) => {
  let subtotal = 0;

  items?.forEach((item: any) => {
    subtotal += item?.price?.totalAmount;
  });

  return Number(subtotal);
};

export const getProcessingFees = (items: Array<unknown>) => {
  let amount = 0;

  items?.forEach((elem: any) => {
    amount += elem?.price?.totalAmount;
  });

  const processingFees = amount * (2.9 / 100) + 0.3; // Stripe processing fees

  return Number(processingFees);
};

export const getTaxes = (items: Array<unknown>, taxes: Array<unknown>) => {
  let amount = 0;

  items?.forEach((item: any) => {
    amount += Number(item?.price?.totalAmount);
  });

  let taxAmount = 0;

  taxes?.forEach((tax: any) => {
    taxAmount += amount * (Number(tax?.taxData?.percentage) / 100);
  });

  return Number(taxAmount);
};

export const getCheckoutAmount = (
  items: Array<unknown>,
  taxes: Array<unknown>,
  processingFeesType: string
) => {
  if (processingFeesType === ProcessingFeesType.CUSTOMER) {
    return (
      getSubtotal(items) + getProcessingFees(items) + getTaxes(items, taxes)
    );
  }

  return getSubtotal(items) + getTaxes(items, taxes);
};

import { useEffect } from "react";
import DialogContent from "@mui/material/DialogContent";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, DialogTitle } from "components/Dialog";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { useMergeState } from "utils/custom-hooks";
import { getMerchantProductsModifiers } from "api";

type Props = {
  open: boolean;
  onClose: any;
  onSave: any;
};

export default function MerchantSelectModifiersDialog(props: Props) {
  const { open, onClose, onSave } = props;

  const [state, setState] = useMergeState({
    modifiers: [],
  });

  const handleSelectModifier = (modifierId: string) => {
    const updatedModifiers = [...state?.modifiers];

    const index = state?.modifiers.findIndex(
      (elem: any) => elem._id === modifierId
    );

    updatedModifiers[index].checked = !updatedModifiers[index]?.checked;

    setState({ modifiers: updatedModifiers });
  };

  const handleSave = () => {
    onSave(state?.modifiers?.filter((elem: any) => elem?.checked));
    onClose();
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await getMerchantProductsModifiers();

      setState({ isLoading: false, modifiers: response?.data });
    } catch (error) {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          width: 500,
        },
        "& .MuiDialogContent-root": {
          padding: 3,
        },
      }}
    >
      <DialogTitle onClose={onClose}>Select Modifiers</DialogTitle>

      <DialogContent>
        {state?.isLoading ? (
          <Spinner loading={state?.isLoading} />
        ) : (
          <div>
            {state?.modifiers?.map((modifier: any, index: number) => (
              <div key={modifier._id}>
                <div className="flex justify-between items-center">
                  <div>
                    <div className="text-grey font-medium">
                      {modifier?.title}
                    </div>
                    {modifier?.modifiers?.map(
                      (elem: { name: string }, index: number) => (
                        <span className="text-grey-2 text-sm">
                          {elem?.name}
                          {index + 1 === modifier?.modifiers?.length
                            ? ""
                            : ", "}
                        </span>
                      )
                    )}
                  </div>

                  <Checkbox
                    color="secondary"
                    checked={modifier?.checked}
                    onChange={() => handleSelectModifier(modifier._id)}
                  />
                </div>

                {index + 1 !== state?.modifiers?.length && (
                  <hr className="my-4" />
                )}
              </div>
            ))}

            <div className="flex justify-end mt-6">
              <Button
                label="Save"
                color="secondary"
                onClick={handleSave}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

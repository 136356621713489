import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  getMerchantMasterCart,
  getMerchantCommunityCart,
  addMerchantCommunityCart,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_COMMUNITY_CART_FETCH_MASTER_CART_SAGA,
  MERCHANT_COMMUNITY_CART_FETCH_MY_CART_SAGA,
  MERCHANT_COMMUNITY_CART_ADD_TO_CART_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantCommunityCartSetIsLoading,
  merchantCommunityCartSetMasterCart,
  merchantCommunityCartSetMyCart,
} from "./actions";

function* merchantCommunityCartFetchMasterCartHandler(): any {
  try {
    yield put(merchantCommunityCartSetIsLoading(true));

    const response = yield call(getMerchantMasterCart);

    if (response?.success) {
      yield put(merchantCommunityCartSetMasterCart(response?.data));
    }

    yield put(merchantCommunityCartSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantCommunityCartSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantCommunityCartFetchMyCartHandler(): any {
  try {
    yield put(merchantCommunityCartSetIsLoading(true));

    const response = yield call(getMerchantCommunityCart);

    if (response?.success) {
      yield put(merchantCommunityCartSetMyCart(response?.data));
    }

    yield put(merchantCommunityCartSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantCommunityCartSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantCommunityCartAddToCartHandler(action: any): any {
  try {
    yield put(merchantCommunityCartSetIsLoading(true));

    const response = yield call(addMerchantCommunityCart, action?.payload);

    if (response?.success) {
      action?.navigate("/community-cart");
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantCommunityCartSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantCommunityCartSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantCommunityCart() {
  yield takeLatest(
    MERCHANT_COMMUNITY_CART_FETCH_MASTER_CART_SAGA,
    merchantCommunityCartFetchMasterCartHandler
  );
  yield takeLatest(
    MERCHANT_COMMUNITY_CART_FETCH_MY_CART_SAGA,
    merchantCommunityCartFetchMyCartHandler
  );
  yield takeLatest(
    MERCHANT_COMMUNITY_CART_ADD_TO_CART_SAGA,
    merchantCommunityCartAddToCartHandler
  );
}

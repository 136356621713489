export const MERCHANT_SETTINGS_SET_IS_LOADING =
  "MERCHANT_SETTINGS_SET_IS_LOADING";
export const MERCHANT_SETTINGS_PAYMENT_PROCESSORS_SET_ACCOUNT_DETAILS =
  "MERCHANT_SETTINGS_PAYMENT_PROCESSORS_SET_ACCOUNT_DETAILS";

export const MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_CONFIG =
  "MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_CONFIG";
export const MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_PROFILE_DETAILS =
  "MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_PROFILE_DETAILS";

export const MERCHANT_SETTINGS_TEAMS_SET_DETAILS =
  "MERCHANT_SETTINGS_TEAMS_SET_DETAILS";
export const MERCHANT_SETTINGS_TEAMS_SET_INVITE_MEMBER_DIALOG_CONFIG =
  "MERCHANT_SETTINGS_TEAMS_SET_INVITE_MEMBER_DIALOG_CONFIG";

// async

export const MERCHANT_SETTINGS_PAYMENT_PROCESSORS_CONNECT_STRIPE_ACCOUNT_SAGA =
  "MERCHANT_SETTINGS_PAYMENT_PROCESSORS_CONNECT_STRIPE_ACCOUNT_SAGA";
export const MERCHANT_SETTINGS_PAYMENT_PROCESSORS_FETCH_ACCOUNT_DETAILS_SAGA =
  "MERCHANT_SETTINGS_PAYMENT_PROCESSORS_FETCH_ACCOUNT_DETAILS_SAGA";

export const MERCHANT_SETTINGS_BUSINESS_PROFILE_FETCH_PROFILE_DETAILS_SAGA =
  "MERCHANT_SETTINGS_BUSINESS_PROFILE_FETCH_PROFILE_DETAILS_SAGA";
export const MERCHANT_SETTINGS_BUSINESS_PROFILE_UPDATE_PROFILE_DETAILS_SAGA =
  "MERCHANT_SETTINGS_BUSINESS_PROFILE_UPDATE_PROFILE_DETAILS_SAGA";

export const MERCHANT_SETTINGS_TEAMS_FETCH_DETAILS_SAGA =
  "MERCHANT_SETTINGS_TEAMS_FETCH_DETAILS_SAGA";

export const MERCHANT_SETTINGS_TEAMS_INVITE_MEMBER_SAGA =
  "MERCHANT_SETTINGS_TEAMS_INVITE_MEMBER_SAGA";

export const MERCHANT_SETTINGS_UPDATE_PROCESSING_FEES_TYPE_SAGA =
  "MERCHANT_SETTINGS_UPDATE_PROCESSING_FEES_TYPE_SAGA";

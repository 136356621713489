import { takeLatest, put, all, call } from "redux-saga/effects";
import { createStripeSetupIntent, addPaymentMethod } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  CUSTOMER_ADD_NEW_CARD_DIALOG_CREATE_SETUP_INTENT_SAGA,
  CUSTOMER_ADD_NEW_CARD_DIALOG_SAVE_CARD_DETAILS_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import { customerAddNewCardDialogSetConfig } from "./actions";
import { customerCheckoutGetPaymentMethodSaga } from "containers/consumer/checkout/state/actions"; // customer checkout actions

function* createSetupIntentHandler(action: any): any {
  try {
    yield put(
      customerAddNewCardDialogSetConfig({ isCreatingSetupIntent: true })
    );

    const response = yield call(createStripeSetupIntent, action?.payload);

    if (response?.success) {
      yield put(
        customerAddNewCardDialogSetConfig({
          isCreatingSetupIntent: false,
          clientSecret: response?.data?.clientSecret,
        })
      );
    }
  } catch (error: any) {
    yield all([
      yield put(
        customerAddNewCardDialogSetConfig({ isCreatingSetupIntent: false })
      ),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* saveCardDetailsHandler(action: any): any {
  try {
    yield put(customerAddNewCardDialogSetConfig({ isSavingCardDetails: true }));

    const {
      stripe,
      elements,
      CardElement,
      clientSecret,
      platformCustomerId,
      isTextToPay,
      flow,
    } = action.payload;

    const response = yield stripe.confirmCardSetup(clientSecret, {
      payment_method: {
        // @ts-ignore
        card: elements.getElement(CardElement),
        // billing_details: {
        // },
      },
    });

    if (response?.error) {
      yield all([
        put(
          customerAddNewCardDialogSetConfig({
            isSavingCardDetails: false,
            open: false,
          })
        ),
        put(
          appSetError({
            severity: MESSAGE_SEVERITY.ERROR,
            message: `${response?.error?.code} - ${
              response?.error?.decline_code || ""
            } - ${response?.error?.message}`,
          })
        ),
      ]);

      return;
    }

    if (response?.setupIntent?.payment_method) {
      const paymentMethodResponse = yield call(addPaymentMethod, {
        pmId: response?.setupIntent?.payment_method,
        pcId: platformCustomerId,
        isTextToPay,
        flow,
      });

      if (paymentMethodResponse?.success) {
        yield all([
          yield put(
            customerAddNewCardDialogSetConfig({
              isSavingCardDetails: false,
              open: false,
            })
          ),
          yield put(
            appSetError({
              severity: MESSAGE_SEVERITY.SUCCESS,
              message: paymentMethodResponse?.message,
            })
          ),
          yield put(customerCheckoutGetPaymentMethodSaga()),
        ]);
      }
    }
  } catch (error: any) {
    yield all([
      yield put(
        customerAddNewCardDialogSetConfig({
          isSavingCardDetails: false,
          open: false,
        })
      ),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchCustomerAddNewCardDialog() {
  yield takeLatest(
    CUSTOMER_ADD_NEW_CARD_DIALOG_CREATE_SETUP_INTENT_SAGA,
    createSetupIntentHandler
  );
  yield takeLatest(
    CUSTOMER_ADD_NEW_CARD_DIALOG_SAVE_CARD_DETAILS_SAGA,
    saveCardDetailsHandler
  );
}

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_DISCOUNTS_SET_IS_LOADING,
  MERCHANT_DISCOUNTS_SET_DISCOUNTS,
  MERCHANT_DISCOUNTS_SET_SELECTED_DISCOUNT,
} from "./types";

type MerchantDiscountsStateType = {
  isLoading: boolean;
  discounts: Array<any>;
  discount: any;
};

const initialState: MerchantDiscountsStateType = {
  isLoading: false,
  discounts: [],
  discount: {},
};

const merchantDiscountsReducer = (
  state: MerchantDiscountsStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_DISCOUNTS_SET_IS_LOADING:
      return { ...state, isLoading: action?.payload };

    case MERCHANT_DISCOUNTS_SET_DISCOUNTS:
      return { ...state, discounts: action?.payload };

    case MERCHANT_DISCOUNTS_SET_SELECTED_DISCOUNT:
      return { ...state, discount: action?.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantDiscountsReducer;

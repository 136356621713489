import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MerchantInviteCustomerDialog from "components/MerchantInviteCustomerDialog";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { formatDate } from "utils/date";
import {
  merchantInvitationsSetInviteCustomerDialogConfig,
  merchantInvitationsFetchInvitationsSaga,
  merchantInvitationsSendInvitationsSaga,
} from "./state/actions";

export default function MerchantInvitationsContainer() {
  const dispatch = useDispatch();

  const {
    isLoading,
    invitations,
    inviteCustomerDialogConfig: { open: shouldShowInviteCustomerDialog },
  } = useSelector((store: any) => store?.merchantInvitations);

  const handleOpenInviteCustomerDialog = () => {
    dispatch(
      merchantInvitationsSetInviteCustomerDialogConfig({
        open: true,
      })
    );
  };

  const handleCloseInviteCustomerDialog = () => {
    dispatch(
      merchantInvitationsSetInviteCustomerDialogConfig({
        open: false,
      })
    );
  };

  const handleSendInvitation = (customers: Array<any>) => {
    dispatch(merchantInvitationsSendInvitationsSaga({ customers }));
  };

  useEffect(() => {
    dispatch(merchantInvitationsFetchInvitationsSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="md:flex md:justify-between md:items-center">
            <div className="text-4xl font-semibold text-grey">Invitations</div>
            <div className="mt-4 md:mt-0">
              <Button
                label="Invite Customers"
                color="secondary"
                onClick={handleOpenInviteCustomerDialog}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>
          </div>

          <TableContainer className="mt-10">
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="text-grey">#</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Name</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Email</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Phone</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Sent On</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "visible" }}>
                {invitations.map((invitation: any, index: number) => (
                  <TableRow key={invitation._id}>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{index + 1}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{invitation?.name}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{invitation?.email}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{invitation?.phone}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">
                        {formatDate(invitation?.createdAt, "lll")}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {shouldShowInviteCustomerDialog && (
        <MerchantInviteCustomerDialog
          onSendInvitation={handleSendInvitation}
          onClose={handleCloseInviteCustomerDialog}
        />
      )}
    </div>
  );
}

export const OrderType = {
  PURCHASE: "PURCHASE",
  REDEMPTION: "REDEMPTION",
};

export const OrderScheduleType = {
  IMMEDIATE: "IMMEDIATE",
  SCHEDULED: "SCHEDULED",
};

export const OrderStatus = {
  PENDING: "PENDING",
  CONFIRMED: "CONFIRMED",
  IN_PROGRESS: "IN_PROGRESS",
  COMPLETED: "COMPLETED",
  FAILED: "FAILED",
};

export const OrderDurationFilter = {
  TODAY: "TODAY",
  FUTURE: "FUTURE",
  PAST: "PAST",
};

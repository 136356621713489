import { takeLatest, put, all, call } from "redux-saga/effects";
import { createMerchantOrder } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { ORDER_DIALOG_CREATE_ORDER_SAGA } from "./types";
import { appSetError } from "app/state/actions"; // app actions
import { orderDialogSetConfig } from "./actions";
import { customerSubscriptionsFetchSubscriptionDetailsSaga } from "containers/consumer/subscription/state/actions"; // subscription actions

function* orderDialogCreateOrderHandler(action: any): any {
  try {
    yield put(orderDialogSetConfig({ isLoading: true }));

    const response = yield call(createMerchantOrder, action?.payload);

    if (response?.success) {
      action.navigate(`/subscription/details?id=${action?.payload?.sId}`);

      yield all([
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
        yield put(
          customerSubscriptionsFetchSubscriptionDetailsSaga({
            subscriptionId: action?.payload?.sId,
          })
        ),
      ]);
    }

    yield put(orderDialogSetConfig({ isLoading: false, open: false }));
  } catch (error: any) {
    yield all([
      yield put(orderDialogSetConfig({ isLoading: false, open: false })),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchOrderDialog() {
  yield takeLatest(
    ORDER_DIALOG_CREATE_ORDER_SAGA,
    orderDialogCreateOrderHandler
  );
}

export const MERCHANT_PRODUCTS_SET_IS_LOADING =
  "MERCHANT_PRODUCTS_SET_IS_LOADING";
export const MERCHANT_PRODUCTS_SET_PRODUCTS = "MERCHANT_PRODUCTS_SET_PRODUCTS";
export const MERCHANT_PRODUCTS_SET_SELECTED_PRODUCT =
  "MERCHANT_PRODUCTS_SET_SELECTED_PRODUCT";
export const MERCHANT_PRODUCTS_SET_SETTINGS_CONFIG =
  "MERCHANT_PRODUCTS_SET_SETTINGS_CONFIG";
export const MERCHANT_PRODUCTS_MODIFIERS_SET_MODIFIERS =
  "MERCHANT_PRODUCTS_MODIFIERS_SET_MODIFIERS";
export const MERCHANT_PRODUCTS_MODIFIERS_SET_SELECTED_MODIFIER =
  "MERCHANT_PRODUCTS_MODIFIERS_SET_SELECTED_MODIFIER";
export const MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_RULES =
  "MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_RULES";
export const MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_SELECTED_RULE =
  "MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_SELECTED_RULE";

// async

export const MERCHANT_PRODUCTS_FETCH_SAGA = "MERCHANT_PRODUCTS_FETCH_SAGA";
export const MERCHANT_PRODUCTS_FETCH_SELECTED_PRODUCT_SAGA =
  "MERCHANT_PRODUCTS_FETCH_SELECTED_PRODUCT_SAGA";
export const MERCHANT_PRODUCTS_CREATE_SAGA = "MERCHANT_PRODUCTS_CREATE_SAGA";
export const MERCHANT_PRODUCTS_UPDATE_SAGA = "MERCHANT_PRODUCTS_UPDATE_SAGA";
export const MERCHANT_PRODUCTS_CLONE_SAGA = "MERCHANT_PRODUCTS_CLONE_SAGA";
export const MERCHANT_PRODUCTS_DELETE_SAGA = "MERCHANT_PRODUCTS_DELETE_SAGA";

export const MERCHANT_PRODUCTS_MODIFIERS_FETCH_SAGA =
  "MERCHANT_PRODUCTS_MODIFIERS_FETCH_SAGA";
export const MERCHANT_PRODUCTS_MODIFIERS_FETCH_SELECTED_MODIFIER_SAGA =
  "MERCHANT_PRODUCTS_MODIFIERS_FETCH_SELECTED_MODIFIER_SAGA";
export const MERCHANT_PRODUCTS_MODIFIERS_CREATE_SAGA =
  "MERCHANT_PRODUCTS_MODIFIERS_CREATE_SAGA";
export const MERCHANT_PRODUCTS_MODIFIERS_UPDATE_SAGA =
  "MERCHANT_PRODUCTS_MODIFIERS_UPDATE_SAGA";
export const MERCHANT_PRODUCTS_MODIFIERS_DELETE_SAGA =
  "MERCHANT_PRODUCTS_MODIFIERS_DELETE_SAGA";

export const MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SAGA =
  "MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SAGA";
export const MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SELECTED_RULE_SAGA =
  "MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SELECTED_RULE_SAGA";
export const MERCHANT_PRODUCTS_DISCOUNT_RULES_CREATE_SAGA =
  "MERCHANT_PRODUCTS_DISCOUNT_RULES_CREATE_SAGA";
export const MERCHANT_PRODUCTS_DISCOUNT_RULES_UPDATE_SAGA =
  "MERCHANT_PRODUCTS_DISCOUNT_RULES_UPDATE_SAGA";
export const MERCHANT_PRODUCTS_DISCOUNT_RULES_DELETE_SAGA =
  "MERCHANT_PRODUCTS_DISCOUNT_RULES_DELETE_SAGA";

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomerSettingsSubscriptionsContainer from "./subscriptions";
import CustomerSettingsPaymentMethodsContainer from "./payment-methods";
import { useMergeState } from "utils/custom-hooks";
import { customerSettingsAddPaymentMethodSetConfig } from "./state/actions";

const SETTINGS_TABS_TYPE = {
  SUBSCRIPTIONS: "SUBSCRIPTIONS",
  PAYMENT_METHODS: "PAYMENT_METHODS",
};

const SETTINGS_TABS = [
  {
    label: "Subscriptions",
    value: SETTINGS_TABS_TYPE.SUBSCRIPTIONS,
  },
  {
    label: "Payment Methods",
    value: SETTINGS_TABS_TYPE.PAYMENT_METHODS,
  },
];

export default function CustomerSettingsContainer() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTabType = searchParams.get("tab");

  const [state, setState] = useMergeState({
    selectedTab: {},
  });

  const handleTabChange = (event: any, newTabType: number) => {
    setState({
      selectedTab: SETTINGS_TABS.find((elem: any) => elem.value === newTabType),
    });

    dispatch(
      customerSettingsAddPaymentMethodSetConfig({
        isAddingNewPaymentMethod: false,
        clientSecret: "",
      })
    );
  };

  useEffect(() => {
    let tab = "";

    if (state?.selectedTab?.value !== selectedTabType) {
      if (selectedTabType && !state?.selectedTab?.value) {
        tab = selectedTabType;
      } else if (state?.selectedTab?.value) {
        tab = state?.selectedTab?.value;
      } else {
        tab = SETTINGS_TABS_TYPE.SUBSCRIPTIONS;
      }

      const selectedTab =
        SETTINGS_TABS.find((elem) => elem.value === tab) || SETTINGS_TABS[0];

      setSearchParams({ tab });

      setState({ selectedTab });
    }
  }, [state?.selectedTab]);

  return (
    <div className="p-4 lg:px-12 lg:py-8">
      <div className="text-3xl font-semibold text-grey">Settings</div>

      {state?.selectedTab?.value && (
        <div className="mt-8">
          <Tabs value={state?.selectedTab?.value} onChange={handleTabChange}>
            {SETTINGS_TABS.map((tab) => (
              <Tab
                key={tab.label}
                label={tab.label}
                value={tab.value}
                style={{
                  textTransform: "none",
                  color: "#363333",
                  fontSize: 16,
                  fontFamily: "Poppins",
                }}
              />
            ))}
          </Tabs>
        </div>
      )}

      <div className="px-4">
        {state?.selectedTab?.value === SETTINGS_TABS_TYPE.SUBSCRIPTIONS && (
          <CustomerSettingsSubscriptionsContainer />
        )}

        {state?.selectedTab?.value === SETTINGS_TABS_TYPE.PAYMENT_METHODS && (
          <CustomerSettingsPaymentMethodsContainer />
        )}
      </div>
    </div>
  );
}

import { useEffect } from "react";
import { useNavigate, Link } from "react-router-dom";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { humanize } from "utils/string";
import { humanizeSchedule } from "enums/PaymentSchedules";
import { useMergeState } from "utils/custom-hooks";
import { getMerchantPlans } from "api";

export default function MerchantPlansContainer() {
  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    isLoading: false,
    plans: [],
  });

  const handleOpenPlanDetails = (planId: string) => {
    navigate(`/plans/create?planId=${planId}`);
  };

  const handleCreatePlan = () => {
    navigate("/plans/create");
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await getMerchantPlans();

      setState({ isLoading: false, plans: response?.data });
    } catch (error) {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {state?.isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey mb-4">
            Your Plans
          </div>

          <div className="flex flex-col lg:flex-row my-8">
            <div className="w-full lg:w-3/4">
              {!state?.plans.length && (
                <div className="mt-4">
                  <div className="text-xl">Create your plan to get started</div>

                  <div className="w-3/4 mt-4">
                    <Button
                      label="Create A Plan To Get Started"
                      color="secondary"
                      onClick={handleCreatePlan}
                      style={{
                        borderRadius: 10,
                        fontSize: 14,
                        color: "#FFFFFF",
                        height: 50,
                      }}
                      fullWidth
                    />
                  </div>
                </div>
              )}

              {state?.plans.map((plan: any) => (
                <div
                  key={plan?._id}
                  className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 mt-8"
                >
                  <div className="grid grid-cols-1 sm:grid-cols-2 gap-2">
                    <div className="w-11/12">
                      <div className="text-grey font-semibold text-lg">
                        {plan?.title}
                      </div>

                      <div className="text-grey mt-2">
                        ${plan?.price?.amount}/
                        {String(humanize(plan?.price?.schedule)).toLowerCase()}
                      </div>

                      <div className="text-grey text-sm mt-4">
                        {plan?.description}
                      </div>

                      {plan?.images[0] && (
                        <div className="mt-4">
                          <img
                            src={plan?.images[0]}
                            className="rounded-lg h-52"
                          />
                        </div>
                      )}

                      <div className="mt-4">
                        <Button
                          label="Open Plan Details"
                          color="secondary"
                          onClick={() => handleOpenPlanDetails(plan?._id)}
                          style={{
                            borderRadius: 10,
                            fontSize: 14,
                            color: "#FFFFFF",
                          }}
                          fullWidth
                        />
                      </div>
                    </div>

                    <div className="w-11/12 mt-2 sm:mt-0">
                      <div className="text-grey font-semibold">
                        Active subscribers
                        <span className="text-green font-bold ml-2">
                          {plan?.stats?.activeSubscribers}
                        </span>
                      </div>

                      <div className="text-grey font-semibold">
                        Previous month new subscribers
                        <span className="text-green font-bold ml-2">
                          {plan?.stats?.previousMonthNewSubscribers}
                        </span>
                      </div>

                      <div className="text-grey font-semibold">
                        Current month new subscribers
                        <span className="text-green font-bold ml-2">
                          {plan?.stats?.currentMonthNewSubscribers}
                        </span>
                      </div>

                      <div className="text-grey font-semibold">
                        {humanizeSchedule(plan?.price?.schedule)} revenue
                        <span className="text-green font-bold ml-2">
                          {plan?.stats?.revenue?.recurring}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 w-full lg:w-1/3 mt-8 lg:ml-2 h-fit">
              <div className="text-3xl font-semibold text-grey mb-2">
                Things You Can Do
              </div>

              <div className="my-1">
                <Link to="/plans/create" className="text-grey-2 underline">
                  Add A Subscription
                </Link>
              </div>

              <div className="my-1">
                <Link to="/plans/subscribers" className="text-grey-2 underline">
                  Add A Subscriber
                </Link>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { io } from "socket.io-client";
import { SelectChangeEvent } from "@mui/material/Select";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import OrderStatusSelect from "components/OrderStatusSelect";
import OrderDetailsDrawer from "components/OrderDetailsDrawer";
import Spinner from "components/Spinner";
import { useMergeState } from "utils/custom-hooks";
import {
  OrderScheduleType,
  OrderDurationFilter,
  OrderType,
} from "enums/orders";
import {
  merchantOrdersUpdateSaga,
  merchantOrdersFetchSaga,
} from "./state/actions";
import { formatDate } from "utils/date";

const ORDER_TABS = [
  {
    label: "Orders for today",
    value: OrderDurationFilter.TODAY,
  },
  {
    label: "Future orders",
    value: OrderDurationFilter.FUTURE,
  },
  {
    label: "Past orders",
    value: OrderDurationFilter.PAST,
  },
];

export default function MerchantOrdersContainer() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedDurationParam = searchParams.get("duration") || "";

  const orderIdParam = searchParams.get("orderId") || "";

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const { isLoading, todayOrders, futureOrders, pastOrders } = useSelector(
    (store: any) => store?.merchantOrders
  );

  const [state, setState] = useMergeState({
    selectedTab: {},
    selectedOrder: {},
    shouldShowOrderDrawer: false,
  });

  const handleTabChange = (event: any, newTabType: number) => {
    setState({
      selectedTab: ORDER_TABS.find((elem: any) => elem.value === newTabType),
    });
  };

  const handleOrderStatusChange = (event: SelectChangeEvent, order: any) => {
    dispatch(
      merchantOrdersUpdateSaga(order?._id, { status: event.target.value })
    );
  };

  const handleCloseOrderDrawer = () => {
    setState({ shouldShowOrderDrawer: false });
    setSearchParams({ duration: state?.selectedTab?.value });
  };

  const handleSelectOrder = (event: any, selectedOrder: any) => {
    setState({ selectedOrder, shouldShowOrderDrawer: true });
    setSearchParams({
      duration: state?.selectedTab?.value,
      orderId: selectedOrder._id,
    });
  };

  const getTotalAmount = (order: any) => {
    if (order?.type === OrderType.PURCHASE) {
      let totalAmount = 0;

      order?.cart?.items?.forEach((item: any) => {
        totalAmount += Number(item?.price?.totalAmount);
      });

      return `$${totalAmount}`;
    }

    if (order?.type === OrderType.REDEMPTION) {
      return "PLAN";
    }
  };

  const init = (duration: string) => {
    dispatch(merchantOrdersFetchSaga({ duration }));
  };

  useEffect(() => {
    let duration = "";

    let params = {};

    if (orderIdParam) {
      params = { orderId: orderIdParam };
    }

    if (state?.selectedTab?.value !== selectedDurationParam) {
      if (selectedDurationParam && !state?.selectedTab?.value) {
        duration = selectedDurationParam;
      } else if (state?.selectedTab?.value) {
        duration = state?.selectedTab?.value;
      } else {
        duration = ORDER_TABS[0].value;
      }

      const selectedTab =
        ORDER_TABS.find((elem) => elem.value === duration) || ORDER_TABS[0];

      params = { ...params, duration };

      setSearchParams({ ...params });

      init(selectedTab?.value);

      const payload: any = { selectedTab };

      if (orderIdParam) {
        payload.shouldShowOrderDrawer = true;
      }

      setState(payload);
    }
  }, [state?.selectedTab]);

  useEffect(() => {
    const socket = io(process.env.REACT_APP_SERVER_BASE_URL || "");

    socket.on("connect", () => {
      console.log(socket.id);
    });

    socket?.on("order:refresh", (payload: any) => {
      console.log("order:refresh - payload : ", payload);

      if (user?.merchant?._id === payload?.mId) {
        console.log("refreshed");
        init(selectedDurationParam);
      }
    });

    return () => {
      socket.disconnect();
    };
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey mb-4">Orders</div>

          {state?.selectedTab?.value && (
            <div className="mt-8">
              <Tabs
                value={state?.selectedTab?.value}
                onChange={handleTabChange}
              >
                {ORDER_TABS.map((tab) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    value={tab.value}
                    style={{
                      textTransform: "none",
                      color: "#363333",
                      fontSize: 16,
                      fontFamily: "Poppins",
                    }}
                  />
                ))}
              </Tabs>
            </div>
          )}

          {state?.selectedTab?.value === OrderDurationFilter.TODAY && (
            <div>
              <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md px-10 py-4 mt-10">
                <div className="text-2xl font-medium text-grey">
                  Orders for today
                </div>

                <TableContainer className="mt-4">
                  <Table sx={{ minWidth: 750 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <span className="text-grey">Customer Name</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Phone Number</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Status</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Item</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Service Time</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Total Paid</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {todayOrders.map((order: any) => (
                        <TableRow
                          key={order._id}
                          onClick={(event: any) =>
                            handleSelectOrder(event, order)
                          }
                          className="cursor-pointer"
                        >
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {order?.user?.firstName} {order?.user?.lastName}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {order?.user?.phone || "-"}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <OrderStatusSelect
                              value={order?.status}
                              onChange={(event: any) =>
                                handleOrderStatusChange(event, order)
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {order?.product?.title}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {order?.scheduleType ===
                              OrderScheduleType.IMMEDIATE
                                ? "Now"
                                : ""}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {getTotalAmount(order)}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>

              <div className="order-box px-10 py-4 mt-10">
                <div className="text-2xl font-medium text-grey">
                  Future orders
                </div>

                <TableContainer className="mt-4">
                  <Table sx={{ minWidth: 750 }}>
                    <TableHead>
                      <TableRow>
                        <TableCell align="left">
                          <span className="text-grey">Customer Name</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Phone Number</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Status</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Item</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Service Time</span>
                        </TableCell>
                        <TableCell align="left">
                          <span className="text-grey">Total Paid</span>
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {futureOrders.map((order: any) => (
                        <TableRow
                          key={order._id}
                          onClick={(event: any) =>
                            handleSelectOrder(event, order)
                          }
                          className="cursor-pointer"
                        >
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {order?.user?.firstName} {order?.user?.lastName}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {order?.user?.phone || "-"}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <OrderStatusSelect
                              value={order?.status}
                              onChange={(event: any) =>
                                handleOrderStatusChange(event, order)
                              }
                            />
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {order?.product?.title}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {order?.scheduleType ===
                              OrderScheduleType.IMMEDIATE
                                ? "Now"
                                : `${formatDate(
                                    order?.scheduledDate,
                                    "ll"
                                  )} - ${formatDate(
                                    order?.scheduledTime,
                                    "hh:mm A"
                                  )}`}
                            </span>
                          </TableCell>
                          <TableCell component="th" scope="row">
                            <span className="text-grey text-xs">
                              {getTotalAmount(order)}
                            </span>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
            </div>
          )}

          {state?.selectedTab?.value === OrderDurationFilter.FUTURE && (
            <div className="order-box px-10 py-4 mt-10">
              <div className="text-2xl font-medium text-grey">
                Future orders
              </div>

              <TableContainer className="mt-4">
                <Table sx={{ minWidth: 750 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <span className="text-grey">Customer Name</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Phone Number</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Status</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Item</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Service Time</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Total Paid</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {futureOrders.map((order: any) => (
                      <TableRow
                        key={order._id}
                        onClick={(event: any) =>
                          handleSelectOrder(event, order)
                        }
                        className="cursor-pointer"
                      >
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {order?.user?.firstName} {order?.user?.lastName}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {order?.user?.phone || "-"}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <OrderStatusSelect
                            value={order?.status}
                            onChange={(event: any) =>
                              handleOrderStatusChange(event, order)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {order?.product?.title}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {order?.scheduleType === OrderScheduleType.IMMEDIATE
                              ? "Now"
                              : `${formatDate(
                                  order?.scheduledDate,
                                  "ll"
                                )} - ${formatDate(
                                  order?.scheduledTime,
                                  "hh:mm A"
                                )}`}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {getTotalAmount(order)}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}

          {state?.selectedTab?.value === OrderDurationFilter.PAST && (
            <div className="order-box px-10 py-4 mt-10">
              <div className="text-2xl font-medium text-grey">Past orders</div>

              <TableContainer className="mt-4">
                <Table sx={{ minWidth: 750 }}>
                  <TableHead>
                    <TableRow>
                      <TableCell align="left">
                        <span className="text-grey">Customer Name</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Phone Number</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Status</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Item</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Service Time</span>
                      </TableCell>
                      <TableCell align="left">
                        <span className="text-grey">Total Paid</span>
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {pastOrders.map((order: any) => (
                      <TableRow
                        key={order._id}
                        onClick={(event: any) =>
                          handleSelectOrder(event, order)
                        }
                        className="cursor-pointer"
                      >
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {order?.user?.firstName} {order?.user?.lastName}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {order?.user?.phone || "-"}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <OrderStatusSelect
                            value={order?.status}
                            onChange={(event: any) =>
                              handleOrderStatusChange(event, order)
                            }
                          />
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {order?.product?.title}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {order?.scheduleType === OrderScheduleType.IMMEDIATE
                              ? "Now"
                              : `${formatDate(
                                  order?.scheduledDate,
                                  "MM-DD-YYYY"
                                )} ${formatDate(
                                  order?.scheduledTime,
                                  "hh:mm A"
                                )}`}
                          </span>
                        </TableCell>
                        <TableCell component="th" scope="row">
                          <span className="text-grey text-xs">
                            {getTotalAmount(order)}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )}
        </div>
      )}

      {state.shouldShowOrderDrawer && (
        <OrderDetailsDrawer
          open={state.shouldShowOrderDrawer}
          onClose={handleCloseOrderDrawer}
        />
      )}
    </div>
  );
}

import { takeLatest, put, call, all } from "redux-saga/effects";
import {
  getCustomerSubscriptionDetails,
  getCustomerOrders,
  pauseStripeSubscription,
  cancelStripeSubscription,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SAGA,
  CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTION_DETAILS_SAGA,
  CUSTOMER_SUBSCRIPTIONS_PAUSE_SAGA,
  CUSTOMER_SUBSCRIPTIONS_CANCEL_SAGA,
} from "./types";
import {
  customerSubscriptionsSetIsLoading,
  customerSubscriptionsSetSubscriptionDetails,
  customerSubscriptionsSetOrders,
} from "./actions";
import { appSetError } from "app/state/actions"; // app actions
import { OrderType } from "enums/orders";

function* customerDashboardFetchSubscriptionDetailsHandler(action: any): any {
  try {
    yield put(customerSubscriptionsSetIsLoading(true));

    const subscriptionResponse = yield call(
      getCustomerSubscriptionDetails,
      action.payload
    );

    const ordersResponse = yield call(getCustomerOrders, {
      subscriptionId: action?.payload?.subscriptionId,
      type: OrderType.REDEMPTION,
    });

    yield all([
      yield put(
        customerSubscriptionsSetSubscriptionDetails(subscriptionResponse?.data)
      ),
      yield put(customerSubscriptionsSetOrders(ordersResponse?.data)),
      yield put(customerSubscriptionsSetIsLoading(false)),
    ]);
  } catch (error: any) {
    yield all([
      yield put(customerSubscriptionsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerSubscriptionsPauseHandler(action: any): any {
  try {
    yield put(customerSubscriptionsSetIsLoading(true));

    const response = yield call(pauseStripeSubscription, action.payload);

    if (response?.success) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(customerSubscriptionsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerSubscriptionsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerSubscriptionsCancelHandler(action: any): any {
  try {
    yield put(customerSubscriptionsSetIsLoading(true));

    const response = yield call(cancelStripeSubscription, action.payload);

    if (response?.success) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(customerSubscriptionsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerSubscriptionsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchCustomerSubscription() {
  yield takeLatest(
    CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTION_DETAILS_SAGA,
    customerDashboardFetchSubscriptionDetailsHandler
  );
  yield takeLatest(
    CUSTOMER_SUBSCRIPTIONS_PAUSE_SAGA,
    customerSubscriptionsPauseHandler
  );
  yield takeLatest(
    CUSTOMER_SUBSCRIPTIONS_CANCEL_SAGA,
    customerSubscriptionsCancelHandler
  );
}

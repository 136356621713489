import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "components/Spinner";
import { appSetError } from "app/state/actions"; // app actions
import { merchantAuthCheckResetPasswordLinkExpirySaga } from "../state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";

export default function MerchantResetPasswordCheckExpiryContainer() {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((store: any) => store?.merchantAuth);

  const { token } = useParams();

  const navigate = useNavigate();

  useEffect(() => {
    if (!token) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: "Something went wrong, please contact support.",
        })
      );
      return;
    }

    dispatch(merchantAuthCheckResetPasswordLinkExpirySaga({ token }, navigate));
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      )}
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import Spinner from "components/Spinner";
import { appSetError } from "app/state/actions";
import { customerSettingsConfirmAddNewPaymentMethodSaga } from "../state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";

export default function CustomerSettingsConfirmSetupIntentContainer() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const setupIntentId = searchParams.get("setup_intent");

  const platformCustomerId = searchParams.get("id");

  useEffect(() => {
    if (!setupIntentId || !platformCustomerId) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: "Something went wrong, please contact support.",
        })
      );
      return;
    }

    dispatch(
      customerSettingsConfirmAddNewPaymentMethodSaga(
        {
          setupIntentId,
          platformCustomerId,
        },
        navigate
      )
    );
  }, []);

  return (
    <div className="mt-10 w-full h-screen flex justify-center">
      <Spinner loading />
    </div>
  );
}

import { useEffect } from "react";
import { styled } from "@mui/material/styles";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import DialogContent from "@mui/material/DialogContent";
import Checkbox from "@mui/material/Checkbox";
import { Dialog, DialogTitle } from "components/Dialog";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { useMergeState } from "utils/custom-hooks";
import { getMerchantProducts } from "api";

const StyledTableCell = styled(TableCell)(() => ({
  padding: 0,
}));

type Props = {
  open: boolean;
  onClose: any;
  onSave: any;
};

export default function MerchantSelectProductsDialog(props: Props) {
  const { open, onClose, onSave } = props;

  const [state, setState] = useMergeState({
    products: [],
  });

  const handleSelectProduct = (productId: string) => {
    const updatedProducts = [...state?.products];

    const index = state?.products.findIndex(
      (elem: any) => elem._id === productId
    );

    updatedProducts[index].checked = !updatedProducts[index]?.checked;

    setState({ products: updatedProducts });
  };

  const handleSave = () => {
    onSave(state?.products?.filter((elem: any) => elem?.checked));
    onClose();
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await getMerchantProducts();

      setState({ isLoading: false, products: response?.data });
    } catch (error) {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          width: 500,
        },
        "& .MuiDialogContent-root": {
          padding: 3,
        },
      }}
    >
      <DialogTitle onClose={onClose}>Select Products</DialogTitle>

      <DialogContent>
        {state?.isLoading ? (
          <Spinner loading={state?.isLoading} />
        ) : (
          <div>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <StyledTableCell />
                  </TableRow>
                </TableHead>
                <TableBody style={{ overflow: "visible" }}>
                  {state?.products?.map((product: any) => (
                    <TableRow key={product._id}>
                      <StyledTableCell component="th" scope="row">
                        <Checkbox
                          color="secondary"
                          checked={product?.checked}
                          onChange={() => handleSelectProduct(product._id)}
                        />

                        <span className="text-grey">{product?.title}</span>
                      </StyledTableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>

            <div className="flex justify-end mt-6">
              <Button
                label="Save"
                color="secondary"
                onClick={handleSave}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

import Spinner from "components/Spinner";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useNavigate } from "react-router-dom";
import { merchantAuthSetupSaga } from "../state/actions";

export default function MerchantSetupContainer() {
  const { userId } = useParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading } = useSelector((store: any) => store?.merchantAuth);

  useEffect(() => {
    dispatch(merchantAuthSetupSaga({ id: userId }, navigate));
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      )}
    </div>
  );
}

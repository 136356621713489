import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { getMerchantOrders, updateMerchantOrder } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_ORDERS_FETCH_SAGA,
  MERCHANT_ORDERS_UPDATE_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantOrdersSetIsLoading,
  merchantOrderesSetOrdersForToday,
  merchantOrderesSetFutureOrders,
  merchantOrderesSetPastOrders,
} from "./actions";
import { OrderDurationFilter } from "enums/orders";

function* merchantOrdersFetchHandler(action: any): any {
  try {
    yield put(merchantOrdersSetIsLoading(true));

    const response = yield call(getMerchantOrders, action.payload);

    if (response?.success) {
      if (action?.payload?.duration === OrderDurationFilter.TODAY) {
        yield put(merchantOrderesSetOrdersForToday(response?.data?.today));
        yield put(merchantOrderesSetFutureOrders(response?.data?.future));
      }

      if (action?.payload?.duration === OrderDurationFilter.FUTURE) {
        yield put(merchantOrderesSetFutureOrders(response?.data));
      }

      if (action?.payload?.duration === OrderDurationFilter.PAST) {
        yield put(merchantOrderesSetPastOrders(response?.data));
      }
    }

    yield put(merchantOrdersSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantOrdersSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantOrdersUpdateHandler(action: any): any {
  try {
    const response = yield call(updateMerchantOrder, action.id, action.payload);

    const { todayOrders } = yield select((store: any) => store?.merchantOrders);

    const orderIndex = todayOrders.findIndex(
      (elem: any) => elem._id === action.id
    );

    const newTodayOrders = [...todayOrders];

    newTodayOrders[orderIndex] = {
      ...newTodayOrders[orderIndex],
      ...action.payload,
    };

    if (response?.success) {
      yield put(merchantOrderesSetOrdersForToday(newTodayOrders));
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }
  } catch (error: any) {
    yield all([
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantOrders() {
  yield takeLatest(MERCHANT_ORDERS_FETCH_SAGA, merchantOrdersFetchHandler);
  yield takeLatest(MERCHANT_ORDERS_UPDATE_SAGA, merchantOrdersUpdateHandler);
}

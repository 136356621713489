import { RESET_APP_STATE } from "app/state/types";
import {
  ORDER_DETAILS_DRAWER_SET_IS_LOADING,
  ORDER_DETAILS_DRAWER_SET_ORDER,
} from "./types";

type OrderDetailsDrawerStateType = {
  isLoading: boolean;
  order: any;
};

const initialState: OrderDetailsDrawerStateType = {
  isLoading: false,
  order: {},
};

const orderDetailsDrawerReducer = (
  state: OrderDetailsDrawerStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case ORDER_DETAILS_DRAWER_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case ORDER_DETAILS_DRAWER_SET_ORDER:
      return { ...state, order: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default orderDetailsDrawerReducer;

import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { nanoid } from "nanoid";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import TextField from "components/TextField";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { useMergeState } from "utils/custom-hooks";
import { getMerchantPlanDiscounts, updateMerchantPlanDiscounts } from "api";
import { appSetError } from "app/state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";

const getDiscountId = () => `#${nanoid()}`;

export default function MerchantPlanDiscountsContainer() {
  const dispatch = useDispatch();

  const [state, setState] = useMergeState({
    isLoading: false,
    individualYearlyDiscountPercentage: 0,
    teamPlanDiscounts: [],
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const handleChangeTeamPlanDiscount = (event: any, index: number) => {
    const newDiscounts = [...state?.teamPlanDiscounts];

    newDiscounts[index][event?.target?.name] = event?.target?.value;

    setState({
      teamPlanDiscounts: [...newDiscounts],
    });
  };

  const handleAddTeamPlanDiscount = () => {
    const newDiscounts = [...state?.teamPlanDiscounts];

    newDiscounts.push({
      id: getDiscountId(),
      members: "",
      discountPercentage: "",
      yearlyDiscountPercentage: "",
    });

    setState({
      teamPlanDiscounts: newDiscounts,
    });
  };

  const handleRemoveTeamPlanDiscount = (index: number) => {
    const newDiscounts = [...state?.teamPlanDiscounts];

    newDiscounts.splice(index, 1);

    setState({
      teamPlanDiscounts: newDiscounts,
    });
  };

  const isFormValid = () => {
    let isValid = true;

    state?.teamPlanDiscounts?.forEach((elem: any) => {
      if (
        !elem?.members ||
        !elem?.discountPercentage ||
        !elem?.yearlyDiscountPercentage
      ) {
        isValid = false;
      }
    });

    return isValid;
  };

  const handleUpdatePlanDiscounts = async () => {
    try {
      // if (!isFormValid()) {
      //   return;
      // }

      setState({ isSaving: true });

      const response = await updateMerchantPlanDiscounts({
        individualYearlyDiscountPercentage: Number(
          state?.individualYearlyDiscountPercentage
        ),
        teamPlanDiscounts: state?.teamPlanDiscounts?.map((elem: any) => ({
          ...elem,
          members: Number(elem?.members),
          discountPercentage: Number(elem?.discountPercentage),
          yearlyDiscountPercentage: Number(elem?.yearlyDiscountPercentage),
        })),
      });

      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    } finally {
      setState({ isSaving: false });
    }
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await getMerchantPlanDiscounts();

      let teamPlanDiscounts = [
        {
          id: getDiscountId(),
          members: "",
          discountPercentage: "",
          yearlyDiscountPercentage: "",
        },
      ];

      let individualYearlyDiscountPercentage = 0;

      if (response?.data?.teamPlanDiscounts?.length) {
        teamPlanDiscounts = response?.data?.teamPlanDiscounts;
      }

      if (response?.data?.individualYearlyDiscountPercentage) {
        individualYearlyDiscountPercentage =
          response?.data?.individualYearlyDiscountPercentage;
      }

      setState({
        isLoading: false,
        individualYearlyDiscountPercentage,
        teamPlanDiscounts,
      });
    } catch (error: any) {
      setState({ isLoading: false });
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {state?.isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey mb-4">
            Your Plan Discounts
          </div>

          <div className="w-full lg:w-3/4 my-8">
            <div>
              <div className="text-xl font-medium text-grey mb-4">
                Individual
              </div>

              <div className="text-grey text-sm">
                Additional Yearly Discount
              </div>

              <div className="mt-2">
                <TextField
                  type="number"
                  variant="outlined"
                  name="individualYearlyDiscountPercentage"
                  value={state?.individualYearlyDiscountPercentage}
                  onChange={handleChange}
                  onWheel={(event: any) => event.target.blur()}
                  color="secondary"
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">%</InputAdornment>
                    ),
                    style: {
                      height: 40,
                      borderRadius: 6,
                      fontSize: 14,
                    },
                  }}
                />
              </div>
            </div>

            <hr className="my-4" />

            <div className="text-xl font-medium text-grey mb-4">Team Plans</div>

            <div className="mt-3">
              {state?.teamPlanDiscounts?.map((discount: any, index: number) => (
                <div
                  key={discount?.id}
                  className="flex justify-between items-center mt-2"
                >
                  <div className="w-full md:w-3/4 flex justify-between items-center">
                    <div>
                      <div className="text-grey text-sm">Members (upto)</div>

                      <div className="mt-2">
                        <TextField
                          variant="outlined"
                          name="members"
                          value={state?.teamPlanDiscounts[index]?.members}
                          onChange={(event) =>
                            handleChangeTeamPlanDiscount(event, index)
                          }
                          color="secondary"
                          InputProps={{
                            style: {
                              height: 40,
                              borderRadius: 6,
                              fontSize: 14,
                            },
                          }}
                          fullWidth
                        />
                      </div>
                    </div>

                    <div className="ml-2">
                      <div className="text-grey text-sm">Discount</div>

                      <div className="mt-2">
                        <TextField
                          type="number"
                          variant="outlined"
                          name="discountPercentage"
                          value={
                            state?.teamPlanDiscounts[index]?.discountPercentage
                          }
                          onWheel={(event: any) => event.target.blur()}
                          onChange={(event) =>
                            handleChangeTeamPlanDiscount(event, index)
                          }
                          color="secondary"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            style: {
                              height: 40,
                              borderRadius: 6,
                              fontSize: 14,
                            },
                          }}
                          fullWidth
                        />
                      </div>
                    </div>

                    <div className="ml-2">
                      <div className="text-grey text-sm">
                        Additional Yearly Discount
                      </div>

                      <div className="mt-2">
                        <TextField
                          type="number"
                          variant="outlined"
                          name="yearlyDiscountPercentage"
                          value={
                            state?.teamPlanDiscounts[index]
                              ?.yearlyDiscountPercentage
                          }
                          onWheel={(event: any) => event.target.blur()}
                          onChange={(event) =>
                            handleChangeTeamPlanDiscount(event, index)
                          }
                          color="secondary"
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">%</InputAdornment>
                            ),
                            style: {
                              height: 40,
                              borderRadius: 6,
                              fontSize: 14,
                            },
                          }}
                          fullWidth
                        />
                      </div>
                    </div>
                  </div>

                  <div className="w-1/4 flex justify-end mt-8">
                    {index === state?.teamPlanDiscounts?.length - 1 ? (
                      <IconButton onClick={handleAddTeamPlanDiscount}>
                        <AddIcon />
                      </IconButton>
                    ) : (
                      <IconButton
                        onClick={() => handleRemoveTeamPlanDiscount(index)}
                      >
                        <RemoveIcon />
                      </IconButton>
                    )}

                    {index !== 0 &&
                      index === state?.teamPlanDiscounts?.length - 1 && (
                        <IconButton
                          onClick={() => handleRemoveTeamPlanDiscount(index)}
                          sx={{ ml: 1 }}
                        >
                          <RemoveIcon />
                        </IconButton>
                      )}
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="my-8">
            <Button
              label="Save"
              color="secondary"
              onClick={handleUpdatePlanDiscounts}
              style={{
                borderRadius: 4,
                fontSize: 14,
                height: 40,
                color: "#FFFFFF",
              }}
              loaderButton
              loadingPosition="center"
              loading={state?.isSaving}
              disabled={state?.isSaving}
            />
          </div>
        </div>
      )}
    </div>
  );
}

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_PLANS_SET_IS_LOADING,
  MERCHANT_PLANS_SET_PLANS,
  MERCHANT_PLANS_SET_SELECTED_PLAN,
  MERCHANT_PLANS_SET_PLAN_SUBSCRIBERS,
  MERCHANT_PLANS_SET_INVITE_SUBSCRIBER_DIALOG_CONFIG,
} from "./types";

type MerchantPlansStateType = {
  isLoading: boolean;
  plans: Array<any>;
  plan: any;
  subscribers: Array<any>;
  subscribersPaginationConfig: {
    pageIndex: number;
    pageSize: number;
  };
  inviteSubscriberDialogConfig: {
    open: boolean;
    isLoading: boolean;
  };
};

const initialState: MerchantPlansStateType = {
  isLoading: false,
  plans: [],
  plan: {},
  subscribers: [],
  subscribersPaginationConfig: {
    pageIndex: 0,
    pageSize: 100,
  },
  inviteSubscriberDialogConfig: {
    open: false,
    isLoading: false,
  },
};

const merchantPlansReducer = (
  state: MerchantPlansStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_PLANS_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_PLANS_SET_PLANS:
      return { ...state, plans: action.payload };

    case MERCHANT_PLANS_SET_SELECTED_PLAN:
      return { ...state, plan: action.payload };

    case MERCHANT_PLANS_SET_PLAN_SUBSCRIBERS:
      return { ...state, subscribers: action.payload };

    case MERCHANT_PLANS_SET_INVITE_SUBSCRIBER_DIALOG_CONFIG:
      return {
        ...state,
        inviteSubscriberDialogConfig: {
          ...state.inviteSubscriberDialogConfig,
          ...action.payload,
        },
      };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantPlansReducer;

import {
  ORDER_DETAILS_DRAWER_SET_IS_LOADING,
  ORDER_DETAILS_DRAWER_SET_ORDER,
  ORDER_DETAILS_DRAWER_FETCH_ORDER_SAGA,
} from "./types";

export const orderDetailsDrawerSetIsLoading = (payload: any) => ({
  type: ORDER_DETAILS_DRAWER_SET_IS_LOADING,
  payload,
});

export const orderDetailsDrawerSetOrder = (payload: any) => ({
  type: ORDER_DETAILS_DRAWER_SET_ORDER,
  payload,
});

// async

export const orderDetailsDrawerFetchOrderSaga = (payload: string) => ({
  type: ORDER_DETAILS_DRAWER_FETCH_ORDER_SAGA,
  payload,
});

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import moment from "moment-timezone";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Tooltip from "@mui/material/Tooltip";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import DialogContent from "@mui/material/DialogContent";
import Chip from "@mui/material/Chip";
import TextField from "components/TextField";
import { Dialog } from "components/Dialog";
import Button from "components/Button";
import { humanizeSchedule } from "enums/PaymentSchedules";
import { PlanType } from "enums/PlanType";
import { OrderScheduleType } from "enums/orders";
import { ItemType } from "enums/Products";
import { useMergeState } from "utils/custom-hooks";
import { orderDialogCreateOrderSaga } from "./state/actions";
import ErrorMessage from "components/ErrorMessage";

type Props = {
  onClose: any;
};

const Accordion = styled((props) => (
  // @ts-ignore
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  // backgroundColor:
  //   theme.palette.mode === "dark"
  //     ? "rgba(255, 255, 255, .05)"
  //     : "rgba(0, 0, 0, .03)",
  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  // "& .MuiAccordionSummary-content": {
  // marginLeft: theme.spacing(1),
  // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function OrderDialog(props: Props) {
  const { onClose } = props;

  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { open, isLoading, plan } = useSelector(
    (store: any) => store?.orderDialog
  );

  const [state, setState] = useMergeState({
    shouldShowScheduleDetails: false,
    scheduledDate: moment(),
    scheduledTime: moment(),
    selectedVariation: {},
    selectedModifiers: [],

    isStandalonePlan: false,
    selectedProduct: {},

    erros: {},
  });

  const handleDateChange = (newValue: any) => {
    setState({ scheduledDate: newValue });
  };

  const handleTimeChange = (newValue: any) => {
    setState({ scheduledTime: newValue });
  };

  const handleProductChange = (newProductId: string) => {
    setState({
      selectedProduct: state?.products?.find(
        (elem: any) => elem._id === newProductId
      ),
    });
  };

  const handleProductChangeMobile = (event: any) => {
    setState({
      selectedProduct: state?.products?.find(
        (elem: any) => elem._id === event?.target?.value
      ),
    });
  };

  const handleWindowSizeChange = () => {
    setState({ width: window.innerWidth });
  };

  const toggleScheduleDetails = () => {
    setState({ shouldShowScheduleDetails: !state.shouldShowScheduleDetails });
  };

  const getVariations = () => {
    if (state?.isStandalonePlan) {
      return state?.selectedProduct?.variations;
    }

    return plan?.product?.variations;
  };

  const getModifiers = () => {
    if (state?.isStandalonePlan) {
      return state?.selectedProduct?.modifiers;
    }

    return plan?.product?.modifiers;
  };

  const handleSelectVariation = (variation: any) => {
    setState({ selectedVariation: variation, errors: { variation: false } });
  };

  const handleSelectVariationMobile = (event: any) => {
    setState({
      selectedVariation: getVariations().find(
        (elem: any) => elem?.id === event?.target?.value
      ),
      errors: { variation: false },
    });
  };

  const handleSelectModifiers = (modifierListId: string, modifier: any) => {
    const newModifiers = [...state?.selectedModifiers];

    const existingModifier = newModifiers.find(
      (elem) => elem.id === modifier.id
    );

    if (existingModifier) {
      newModifiers.splice(
        newModifiers.findIndex((elem) => elem.id === modifier.id),
        1
      );
    } else {
      newModifiers.push(modifier);
    }

    setState({ selectedModifiers: newModifiers });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state?.selectedVariation?.id) {
      payload = { ...payload, variation: true };
      isValid = false;
    }

    setState({ errors: payload });

    return isValid;
  };

  const handlePlaceOrder = (scheduleType: string) => {
    if (!isFormValid()) {
      return;
    }

    if (
      state?.shouldShowScheduleDetails &&
      scheduleType === OrderScheduleType.IMMEDIATE
    ) {
      setState({ shouldShowScheduleDetails: false });
    }

    const payload: any = {
      sId: plan._id,
      product: state?.selectedProduct?._id,
      variation: state?.selectedVariation,
      modifiers: state?.selectedModifiers,
      scheduleType,
    };

    if (scheduleType === OrderScheduleType.SCHEDULED) {
      const scheduledTime = `${
        moment(state?.scheduledDate).format().split("T")[0]
      }T${moment(state?.scheduledTime).format().split("T")[1]}`;

      console.log("scheduledTime : ", scheduledTime);

      payload.scheduledTime = scheduledTime;
    }

    if (state?.isStandalonePlan) {
      payload.product = state?.selectedProduct?._id;
    }

    dispatch(orderDialogCreateOrderSaga(payload, navigate));
  };

  useEffect(() => {
    const payload: any = {};

    if (plan?.product?.type === ItemType.PLAN) {
      payload.isStandalonePlan = true;
      payload.products = plan?.product?.products;
      payload.selectedProduct = plan?.product?.products[0];
    } else {
      payload.selectedVariation = plan?.product?.variations[0];
    }

    setState(payload);

    window.addEventListener("resize", handleWindowSizeChange);

    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      sx={{
        "& .MuiDialog-paper": {
          width: 500,
        },
        "& .MuiDialogContent-root": {
          padding: 3,
        },
      }}
    >
      <DialogContent>
        <div>
          <div key={plan._id} className="mb-4">
            <div className="relative">
              <img
                src={plan?.merchant?.businessBanner}
                className="rounded-md w-full h-[170px] object-cover"
              />

              <div>
                <img
                  src={plan?.merchant?.businessLogo}
                  className="rounded-full absolute right-0 -mt-10 mr-4 w-20 h-20"
                />
              </div>
            </div>

            <div className="mt-4">
              <div className="text-grey text-lg font-semibold">
                {plan?.merchant?.businessName}
              </div>

              <div className="text-grey text-sm font-normal mb-2">
                {plan?.product?.title}
              </div>

              <div className="text-grey text-sm font-normal">
                {plan?.product?.description}
              </div>

              {plan?.product?.type === ItemType.PLAN && (
                <div>
                  <div className="text-grey text-lg font-medium my-1">
                    ${plan?.product?.price?.amount}{" "}
                    {humanizeSchedule(plan?.product?.price?.schedule)}
                  </div>

                  <div className="text-grey text-xs font-medium my-1">
                    {plan?.product?.price?.type === PlanType.UNLIMITED_OFFERING
                      ? "UNLIMITED"
                      : `${plan?.product?.price?.quantity} UNITS PER ${plan?.product?.price?.schedule}`}
                  </div>
                </div>
              )}

              {plan?.product?.type === ItemType.PRODUCT && (
                <div className="text-grey text-xs font-medium mt-4">
                  {plan?.quantity} UNITS{" "}
                  {plan?.type !== ItemType.BMPL && `PER ${plan?.schedule}`}
                </div>
              )}

              {plan?.product?.price?.type !== PlanType.UNLIMITED_OFFERING && (
                <div>
                  <div className="text-grey text-xs font-medium mt-1">
                    {parseFloat(plan?.used).toFixed(2)} UNITS USED
                    <Tooltip
                      title="You have flexibility with Cherry. You can use your credits to redeem an item not on your base subscription and Cherry will adjust using less or more credits as needed."
                      placement="right-start"
                    >
                      <InfoOutlinedIcon sx={{ width: 15, height: 15, ml: 1 }} />
                    </Tooltip>
                  </div>

                  <div className="text-grey text-xs font-medium mt-1">
                    {parseFloat(plan?.remaining).toFixed(2)} UNITS REMAINING
                  </div>
                </div>
              )}

              <div className="hidden md:grid grid-cols-2 gap-2 my-4">
                {state?.products?.map((product: any) => (
                  <Chip
                    key={product?._id}
                    label={product?.title}
                    onClick={() => handleProductChange(product?._id)}
                    variant={
                      state?.selectedProduct?._id === product?._id
                        ? "filled"
                        : "outlined"
                    }
                    style={{
                      borderRadius: 4,
                      border:
                        state?.selectedProduct?._id === product?._id
                          ? "1px solid #000000"
                          : "",
                    }}
                  />
                ))}
              </div>

              <div className="visible md:hidden my-4">
                <div className="text-grey font-medium mb-2">Select item</div>

                <FormControl variant="outlined" className="input-select-field">
                  <Select
                    variant="outlined"
                    value={state?.selectedProduct?._id}
                    onChange={handleProductChangeMobile}
                    color="secondary"
                  >
                    {state?.products?.map((product: any) => (
                      <MenuItem key={product?._id} value={product?._id}>
                        {product?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="hidden md:block my-8">
                <div className="text-grey text-sm font-medium mb-4">
                  Select an option
                </div>

                <div className="grid grid-cols-3 gap-3">
                  {getVariations()?.map((variation: any) => (
                    <Chip
                      key={variation?.id}
                      label={variation?.name}
                      onClick={() => handleSelectVariation(variation)}
                      variant={
                        state?.selectedVariation?.id === variation?.id
                          ? "filled"
                          : "outlined"
                      }
                      style={{
                        borderRadius: 4,
                        border:
                          state?.selectedVariation?.id === variation?.id
                            ? "1px solid #000000"
                            : "",
                      }}
                    />
                  ))}
                </div>
              </div>

              <div className="visible md:hidden my-4">
                <div className="text-grey font-medium mb-2">
                  Select an option
                </div>

                <FormControl variant="outlined" className="input-select-field">
                  <Select
                    variant="outlined"
                    value={state?.selectedVariation?.id}
                    onChange={handleSelectVariationMobile}
                    color="secondary"
                  >
                    {getVariations()?.map((variation: any) => (
                      <MenuItem key={variation?.id} value={variation?.id}>
                        {variation?.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              {state?.errors?.variation && (
                <ErrorMessage message="Please select a size" />
              )}

              {getModifiers()?.map((modifier: any) => (
                <div key={modifier._id}>
                  {/* @ts-ignore */}
                  <Accordion>
                    {/* @ts-ignore */}
                    <AccordionSummary>
                      <div className="text-grey text-sm font-medium mb-2">
                        {modifier?.title}
                      </div>
                    </AccordionSummary>

                    <AccordionDetails>
                      <div className="grid grid-cols-3 gap-3 my-4">
                        {modifier?.modifiers?.map((elem: any) => (
                          <Chip
                            key={elem?.id}
                            label={elem?.name}
                            onClick={() =>
                              handleSelectModifiers(modifier?._id, elem)
                            }
                            variant={
                              state?.selectedModifiers?.some(
                                (item: any) => item?.id === elem?.id
                              )
                                ? "filled"
                                : "outlined"
                            }
                            style={{
                              borderRadius: 4,
                              border: state?.selectedModifiers?.some(
                                (item: any) => item?.id === elem?.id
                              )
                                ? "1px solid #000000"
                                : "",
                            }}
                          />
                        ))}
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}

              {/* <div className="text-grey text-sm font-normal mt-6">
                Would you like to use now or schedule the service for later?
              </div> */}

              <div className="flex flex-col md:flex-row mt-3">
                <div className="my-2">
                  <Button
                    label="Order Now"
                    color="secondary"
                    variant={
                      state?.shouldShowScheduleDetails
                        ? "outlined"
                        : "contained"
                    }
                    onClick={() =>
                      handlePlaceOrder(OrderScheduleType.IMMEDIATE)
                    }
                    style={{
                      borderRadius: 4,
                      fontWeight: 500,
                      fontSize: 14,
                      color: state?.shouldShowScheduleDetails
                        ? "#000000"
                        : "#FFFFFF",
                      height: 40,
                    }}
                    loaderButton
                    loadingPosition="center"
                    loading={isLoading}
                    disabled={isLoading}
                  />
                </div>

                {/* <div className="my-2 md:ml-4">
                  <Button
                    label="Schedule For Later"
                    color="secondary"
                    variant={
                      state?.shouldShowScheduleDetails
                        ? "contained"
                        : "outlined"
                    }
                    onClick={toggleScheduleDetails}
                    style={{
                      borderRadius: 4,
                      fontWeight: 500,
                      fontSize: 14,
                      color: state?.shouldShowScheduleDetails
                        ? "#FFFFFF"
                        : "#000000",
                      height: 40,
                    }}
                    disabled={isLoading}
                  />
                </div> */}
              </div>

              {state?.shouldShowScheduleDetails && (
                <div className="mt-4">
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <div className="flex flex-col md:flex-row mt-3">
                      <div className="my-2">
                        <div className="text-grey text-sm font-normal mb-1">
                          Date
                        </div>
                        {/* <DesktopDatePicker
                          inputFormat="MM/DD/YYYY"
                          value={state?.scheduledDate}
                          onChange={handleDateChange}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              sx={{
                                width: 180,
                                "& .MuiOutlinedInput-root": {
                                  height: 40,
                                  fontSize: 14,
                                },
                              }}
                            />
                          )}
                          disablePast
                        /> */}
                      </div>

                      <div className="my-2 md:ml-4">
                        <div className="text-grey text-sm font-normal mb-1">
                          Time
                        </div>
                        {/* <TimePicker
                          value={state?.scheduledTime}
                          onChange={handleTimeChange}
                          renderInput={(params: any) => (
                            <TextField
                              {...params}
                              sx={{
                                width: 180,
                                "& .MuiOutlinedInput-root": {
                                  height: 40,
                                  fontSize: 14,
                                },
                              }}
                            />
                          )}
                        /> */}
                      </div>
                    </div>
                  </LocalizationProvider>

                  <div className="mt-5">
                    <Button
                      label="Confirm"
                      color="secondary"
                      onClick={() =>
                        handlePlaceOrder(OrderScheduleType.SCHEDULED)
                      }
                      style={{
                        borderRadius: 4,
                        fontSize: 14,
                        color: "#FFFFFF",
                        height: 40,
                      }}
                      loaderButton
                      loadingPosition="center"
                      loading={isLoading}
                      disabled={isLoading}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  getStripeConnectAccountLink,
  connectSquareAccount,
  connectStripeAccount,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_CONNECT_GET_STRIPE_ACCOUNT_LINK_SAGA,
  MERCHANT_CONNECT_SQUARE_ACCOUNT_SAGA,
  MERCHANT_CONNECT_OAUTH_STRIPE_ACCOUNT_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import { merchantConnectSetIsLoading } from "./actions";

function* merchantConnectGetStripeAccountLinkHandler(): any {
  try {
    yield put(merchantConnectSetIsLoading(true));

    const response = yield call(getStripeConnectAccountLink);

    if (response?.success) {
      window.location.href = response?.data?.url;
    }

    yield put(merchantConnectSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantConnectSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantConnectSquareAccountHandler(action: any): any {
  try {
    yield put(merchantConnectSetIsLoading(true));

    const response = yield call(connectSquareAccount, action?.payload);

    if (response?.success) {
      window.location.href = response?.data?.url;
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantConnectSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantConnectSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantConnectOauthStripeAccountHandler(action: any): any {
  try {
    yield put(merchantConnectSetIsLoading(true));

    const response = yield call(connectStripeAccount, action?.payload);

    if (response?.success) {
      window.location.href = response?.data?.url;
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantConnectSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantConnectSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantConnect() {
  yield takeLatest(
    MERCHANT_CONNECT_GET_STRIPE_ACCOUNT_LINK_SAGA,
    merchantConnectGetStripeAccountLinkHandler
  );
  yield takeLatest(
    MERCHANT_CONNECT_SQUARE_ACCOUNT_SAGA,
    merchantConnectSquareAccountHandler
  );
  yield takeLatest(
    MERCHANT_CONNECT_OAUTH_STRIPE_ACCOUNT_SAGA,
    merchantConnectOauthStripeAccountHandler
  );
}

export const MERCHANT_COMMUNITY_CART_SET_IS_LOADING =
  "MERCHANT_COMMUNITY_CART_SET_IS_LOADING";
export const MERCHANT_COMMUNITY_CART_SET_MASTER_CART =
  "MERCHANT_COMMUNITY_CART_SET_MASTER_CART";
export const MERCHANT_COMMUNITY_CART_SET_MY_CART =
  "MERCHANT_COMMUNITY_CART_SET_MY_CART";

// async

export const MERCHANT_COMMUNITY_CART_FETCH_MASTER_CART_SAGA =
  "MERCHANT_COMMUNITY_CART_FETCH_MASTER_CART_SAGA";
export const MERCHANT_COMMUNITY_CART_FETCH_MY_CART_SAGA =
  "MERCHANT_COMMUNITY_CART_FETCH_MY_CART_SAGA";
export const MERCHANT_COMMUNITY_CART_ADD_TO_CART_SAGA =
  "MERCHANT_COMMUNITY_CART_ADD_TO_CART_SAGA";

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_AUTH_SET_CURRENT_USER,
  MERCHANT_AUTH_SET_IS_LOADING,
  MERCHANT_AUTH_SET_IS_LOGGED_IN,
  MERCHANT_AUTH_SET_MESSAGE,
} from "./types";

type MerchantAuthStateType = {
  message: {
    type: string;
    value: string;
  };
  isLoading: boolean;
  isLoggedIn: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    avatar: string;
    status: string;
    type: string;
    merchant: any;
  };
};

const initialState: MerchantAuthStateType = {
  isLoading: false,
  message: {
    type: "",
    value: "",
  },
  isLoggedIn: false,
  user: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    avatar: "",
    status: "",
    type: "",
    merchant: {},
  },
};

const merchantAuthReducer = (
  state: MerchantAuthStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_AUTH_SET_MESSAGE:
      return { ...state, message: { ...action.payload } };

    case MERCHANT_AUTH_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_AUTH_SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload };

    case MERCHANT_AUTH_SET_CURRENT_USER:
      return { ...state, user: { ...action.payload } };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantAuthReducer;

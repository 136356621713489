export const PaymentType = {
  ONE_TIME: "ONE_TIME",
  SUBSCRIPTION: "SUBSCRIPTION",
  BUY_MORE_PAY_LESS: "BUY_MORE_PAY_LESS",
};

export const TransactionType = {
  RECURRING: "RECURRING",
  NON_RECURRING: "NON_RECURRING",
};

export const ProcessingFeesType = {
  MERCHANT: "MERCHANT",
  CUSTOMER: "CUSTOMER",
  SPLIT: "SPLIT",
};

export const StripePaymentStatus = {
  SUCCEEDED: "succeeded",
};

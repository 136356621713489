import {
  PUBLIC_BUSINESS_PAGE_SET_IS_LOADING,
  PUBLIC_BUSINESS_PAGE_SET_DETAILS,
  PUBLIC_BUSINESS_PAGE_FETCH_DETAILS_SAGA,
} from "./types";

export const publicBusinessPageSetIsLoading = (payload: any) => ({
  type: PUBLIC_BUSINESS_PAGE_SET_IS_LOADING,
  payload,
});

export const publicBusinessPageSetDetails = (payload: any) => ({
  type: PUBLIC_BUSINESS_PAGE_SET_DETAILS,
  payload,
});

// async

export const publicBusinessPageFetchDetailsSaga = (payload: any) => ({
  type: PUBLIC_BUSINESS_PAGE_FETCH_DETAILS_SAGA,
  payload,
});

import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  getCustomerPlans,
  getStripePaymentMethods,
  createStripeSetupIntent,
  confirmAddPaymentMethod,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  CUSTOMER_SETTINGS_FETCH_SUBSCRIPTIONS_SAGA,
  CUSTOMER_SETTINGS_FETCH_PAYMENT_METHODS_SAGA,
  CUSTOMER_SETTINGS_INITIATE_SETUP_INTENT_SAGA,
  CUSTOMER_SETTINGS_CONFIRM_SETUP_INTENT_SAGA,
  CUSTOMER_SETTINGS_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  customerSettingsSetIsLoading,
  customerSettingsSetSubscriptions,
  customerSettingsSetPaymentMethods,
  customerSettingsAddPaymentMethodSetConfig,
} from "./actions";
import { getSubdomain } from "utils/common";

function* customerSettingsFetchSubscriptionsHandler(): any {
  try {
    yield put(customerSettingsSetIsLoading(true));

    const response = yield call(getCustomerPlans);

    if (response?.success) {
      yield put(customerSettingsSetSubscriptions(response?.data));
    }

    yield put(customerSettingsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerSettingsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerSettingsFetchPaymentMethodsHandler(): any {
  try {
    yield put(customerSettingsSetIsLoading(true));

    const response = yield call(getStripePaymentMethods);

    if (response?.success) {
      yield put(customerSettingsSetPaymentMethods(response?.data));
    }

    yield put(customerSettingsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerSettingsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerSettingsInitiateSetupIntentHandler(action: any): any {
  try {
    yield put(customerSettingsSetIsLoading(true));

    const setupIntentResponse = yield call(createStripeSetupIntent, {
      platformCustomerId: action?.payload?.platformCustomerId,
    });

    if (setupIntentResponse?.success) {
      yield put(
        customerSettingsAddPaymentMethodSetConfig({
          isAddingNewPaymentMethod: true,
          clientSecret: setupIntentResponse?.data?.clientSecret,
        })
      );
    }

    yield put(customerSettingsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerSettingsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerSettingsConfirmSetupIntentHandler(action: any): any {
  try {
    const { stripe, elements, platformCustomerId } = action?.payload;

    const confirmSetupResponse = yield stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `https://${getSubdomain()}.${
          process.env.REACT_APP_HOST_URL
        }/settings/confirm?id=${platformCustomerId}`,
      },
    });

    if (confirmSetupResponse?.error) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: confirmSetupResponse?.error?.message,
        })
      );
    }
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  }
}

function* customerSettingsConfirmAddNewPaymentMethodHandler(action: any): any {
  try {
    const response = yield call(confirmAddPaymentMethod, action.payload);

    if (response?.success) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  } finally {
    action.navigate("/settings?tab=PAYMENT_METHODS");
  }
}

export default function* watchCustomerSettings() {
  yield takeLatest(
    CUSTOMER_SETTINGS_FETCH_SUBSCRIPTIONS_SAGA,
    customerSettingsFetchSubscriptionsHandler
  );
  yield takeLatest(
    CUSTOMER_SETTINGS_FETCH_PAYMENT_METHODS_SAGA,
    customerSettingsFetchPaymentMethodsHandler
  );
  yield takeLatest(
    CUSTOMER_SETTINGS_INITIATE_SETUP_INTENT_SAGA,
    customerSettingsInitiateSetupIntentHandler
  );
  yield takeLatest(
    CUSTOMER_SETTINGS_CONFIRM_SETUP_INTENT_SAGA,
    customerSettingsConfirmSetupIntentHandler
  );
  yield takeLatest(
    CUSTOMER_SETTINGS_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA,
    customerSettingsConfirmAddNewPaymentMethodHandler
  );
}

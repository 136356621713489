import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { merchantProductsModifiersFetchSaga } from "../state/actions";

export default function MerchantProductsModifiersContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, modifiers } = useSelector(
    (store: any) => store?.merchantProducts
  );

  const handleOpenProductModifierDetails = (planId: string) => {
    navigate(`/products/modifiers/create?id=${planId}`);
  };

  const handleCreateProductModifier = () => {
    navigate("/products/modifiers/create");
  };

  useEffect(() => {
    dispatch(merchantProductsModifiersFetchSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="md:flex md:justify-between md:items-center">
            <div className="text-4xl font-semibold text-grey">
              Your Product Modifiers
            </div>

            <div className="mt-4 md:mt-0">
              <Button
                label="Create New Product Modifier Set"
                color="secondary"
                onClick={handleCreateProductModifier}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  fontWeight: 500,
                  height: 40,
                }}
              />
            </div>
          </div>

          <div className="w-full lg:w-3/4">
            {!modifiers.length && (
              <div className="mt-4">
                <div className="text-xl">
                  Create your first product modifier set to get started
                </div>

                <div className="w-3/4 mt-4">
                  <Button
                    label="Create A Product Modifier Set To Get Started"
                    color="secondary"
                    onClick={handleCreateProductModifier}
                    style={{
                      borderRadius: 10,
                      fontSize: 14,
                      color: "#FFFFFF",
                      height: 50,
                    }}
                    fullWidth
                  />
                </div>
              </div>
            )}

            <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-8 mt-6">
              {modifiers.map((set: any) => (
                <div
                  key={set?._id}
                  className="bg-white border-[1px] border-solid border-[#e7e7e7] p-4 rounded-md cursor-pointer mb-4 md:mb-0"
                  onClick={() => handleOpenProductModifierDetails(set?._id)}
                >
                  <div className="text-grey font-semibold text-lg">
                    {set?.title}
                  </div>

                  <div className="text-grey text-sm mt-4">
                    {set?.description}
                  </div>

                  <div className="text-grey mt-4">
                    {set?.modifiers?.map(
                      (elem: any, index: number) =>
                        `$${elem?.price} ${
                          index === set?.modifiers?.length - 1 ? "" : " - "
                        }`
                    )}{" "}
                    / UNIT
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

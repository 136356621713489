export const PlanType = {
  UNLIMITED_OFFERING: "UNLIMITED_OFFERING",
  QUANTITY_LIMIT: "QUANTITY_LIMIT",
  MONTHLY_DISCOUNT: "MONTHLY_DISCOUNT",
};

export const humanizePlanType = (planType: string) => {
  const mapper = {
    [PlanType.UNLIMITED_OFFERING]: "Unlimited Offering",
    [PlanType.QUANTITY_LIMIT]: "Quantity Limit",
    [PlanType.MONTHLY_DISCOUNT]: "Monthly Discount",
  };

  return mapper[planType] || null;
};

import { takeLatest, put, call, all } from "redux-saga/effects";
import { getCustomerPlans } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { CONSUMER_PLANS_FETCH_PLANS_SAGA } from "./types";
import { consumerPlansSetIsLoading, consumerPlansSetPlans } from "./actions";
import { appSetError } from "app/state/actions"; // app actions

function* consumerPlansFetchPlansHandler(): any {
  try {
    yield put(consumerPlansSetIsLoading(true));

    const response = yield call(getCustomerPlans);

    if (response?.success) {
      yield put(consumerPlansSetPlans(response?.data));
    }

    yield put(consumerPlansSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(consumerPlansSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchConsumerPlans() {
  yield takeLatest(
    CONSUMER_PLANS_FETCH_PLANS_SAGA,
    consumerPlansFetchPlansHandler
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import Button from "components/Button";
import Spinner from "components/Spinner";
import MerchantInviteCustomerDialog from "components/MerchantInviteCustomerDialog";
import { useMergeState } from "utils/custom-hooks";
import {
  merchantDiscountsFetchSaga,
  merchantDiscountsInviteCustomerSaga,
} from "./state/actions";
import { merchantInvitationsSetInviteCustomerDialogConfig } from "containers/merchant/invitations/state/actions"; // merchant invitation actions

export default function MerchantDiscountsContainer() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading, discounts } = useSelector(
    (store: any) => store?.merchantDiscounts
  );

  const [state, setState] = useMergeState({
    selectedDiscountId: "",
  });

  const {
    inviteCustomerDialogConfig: { open: shouldShowInviteCustomerDialog },
  } = useSelector((store: any) => store?.merchantInvitations);

  const handleCreateDiscount = () => {
    navigate("/customers/discounts/create");
  };

  const handleOpenDiscountDetails = (discountId: string) => {
    navigate(`/customers/discounts/create?id=${discountId}`);
  };

  const handleOpenInviteCustomerDialog = (selectedDiscountId: string) => {
    setState({ selectedDiscountId });
    dispatch(
      merchantInvitationsSetInviteCustomerDialogConfig({
        open: true,
      })
    );
  };

  const handleCloseInviteCustomerDialog = () => {
    setState({ selectedDiscountId: "" });
    dispatch(
      merchantInvitationsSetInviteCustomerDialogConfig({
        open: false,
      })
    );
  };

  const handleSendInvitation = (customers: Array<any>) => {
    dispatch(
      merchantDiscountsInviteCustomerSaga({
        customers,
        discountId: state?.selectedDiscountId,
      })
    );
    handleCloseInviteCustomerDialog();
  };

  useEffect(() => {
    dispatch(merchantDiscountsFetchSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="md:flex md:justify-between md:items-center">
            <div className="text-4xl font-semibold text-grey">Discounts</div>

            <div className="md:flex mt-4 md:mt-0">
              <Button
                label="Create New Discount"
                color="secondary"
                onClick={handleCreateDiscount}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  fontWeight: 500,
                  height: 40,
                }}
              />
            </div>
          </div>

          <TableContainer className="mt-10">
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="text-grey">#</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Title</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Amount</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Type</span>
                  </TableCell>
                  <TableCell />
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "visible" }}>
                {discounts.map((discount: any, index: number) => (
                  <TableRow key={discount._id}>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{index + 1}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{discount?.title}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">
                        {discount?.discountAmount}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">
                        {discount?.discountType}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() => handleOpenDiscountDetails(discount?._id)}
                      >
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <IconButton
                        onClick={() =>
                          handleOpenInviteCustomerDialog(discount?._id)
                        }
                      >
                        <GroupAddOutlinedIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}

      {shouldShowInviteCustomerDialog && (
        <MerchantInviteCustomerDialog
          onSendInvitation={handleSendInvitation}
          onClose={handleCloseInviteCustomerDialog}
          discountId={state?.selectedDiscountId}
        />
      )}
    </div>
  );
}

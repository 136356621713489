import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_DASHBOARD_SET_IS_LOADING,
  MERCHANT_DASHBOARD_SET_PLANS,
  MERCHANT_DASHBOARD_SET_STATS,
} from "./types";

type MerchantDashboardStateType = {
  isLoading: boolean;
  plans: Array<any>;
  stats: any;
};

const initialState: MerchantDashboardStateType = {
  isLoading: false,
  plans: [],
  stats: {},
};

const merchantDashboardReducer = (
  state: MerchantDashboardStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_DASHBOARD_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_DASHBOARD_SET_PLANS:
      return { ...state, plans: action.payload };

    case MERCHANT_DASHBOARD_SET_STATS:
      return { ...state, stats: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantDashboardReducer;

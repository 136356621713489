import { takeLatest, put, call, all } from "redux-saga/effects";
import { getCustomerOrders } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { CONSUMER_ORDERS_FETCH_ORDERS_SAGA } from "./types";
import { consumerOrdersSetIsLoading, consumerOrdersSetOrders } from "./actions";
import { appSetError } from "app/state/actions"; // app actions

function* consumerOrdersFetchOrdersHandler(action: any): any {
  try {
    yield put(consumerOrdersSetIsLoading(true));

    const response = yield call(getCustomerOrders, action?.payload);

    if (response?.success) {
      yield put(consumerOrdersSetOrders(response?.data));
    }

    yield put(consumerOrdersSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(consumerOrdersSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchConsumerOrders() {
  yield takeLatest(
    CONSUMER_ORDERS_FETCH_ORDERS_SAGA,
    consumerOrdersFetchOrdersHandler
  );
}

import { BUY_DIALOG_SET_CONFIG, BUY_DIALOG_BUY_SAGA } from "./types";

export const buyDialogSetConfig = (payload: any) => ({
  type: BUY_DIALOG_SET_CONFIG,
  payload,
});

// async

export const buyDialogBuySaga = (payload: any, navigate: any) => ({
  type: BUY_DIALOG_BUY_SAGA,
  payload,
  navigate,
});

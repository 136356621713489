import {
  CONSUMER_ORDERS_SET_IS_LOADING,
  CONSUMER_ORDERS_SET_ORDERS,
  CONSUMER_ORDERS_FETCH_ORDERS_SAGA,
} from "./types";

export const consumerOrdersSetIsLoading = (payload: any) => ({
  type: CONSUMER_ORDERS_SET_IS_LOADING,
  payload,
});

export const consumerOrdersSetOrders = (payload: any) => ({
  type: CONSUMER_ORDERS_SET_ORDERS,
  payload,
});

// async

export const consumerOrdersFetchOrdersSaga = (payload: any) => ({
  type: CONSUMER_ORDERS_FETCH_ORDERS_SAGA,
  payload,
});

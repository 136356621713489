import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

// layouts
import MerchantDefaultLayout from "layouts/MerchantDefaultLayout";
import ConsumerDefaultLayout from "layouts/ConsumerDefaultLayout";

// containers

// --------------------- MERCHANT ---------------------
// auth
import MerchantSignupContainer from "containers/merchant/auth/signup";
import MerchantLoginContainer from "containers/merchant/auth/login";
import MerchantSetupContainer from "containers/merchant/auth/setup";
import MerchantForgotPasswordContainer from "containers/merchant/auth/forgot-password";
import MerchantResetPasswordCheckExpiryContainer from "containers/merchant/auth/reset-password-check-expiry";
import MerchantResetPasswordContainer from "containers/merchant/auth/reset-password";

// onboarding
import MerchantOnboardingContainer from "containers/merchant/auth/onboarding";

// dashboard
import MerchantDashboardOverviewContainer from "containers/merchant/dashboard/overview";
import MerchantDashboardGettingStartedContainer from "containers/merchant/dashboard/getting-started";

// subscription plans
import MerchantPlansContainer from "containers/merchant/plans";
import MerchantPlansCreateContainer from "containers/merchant/plans/create";
import MerchantPlansSubscribersContainer from "containers/merchant/plans/subscribers";
import MerchantPlanDiscountsContainer from "containers/merchant/plans/discounts";

// products
import MerchantProductsContainer from "containers/merchant/products";
import MerchantProductsCreateContainer from "containers/merchant/products/create";
import MerchantProductsInventoryContainer from "containers/merchant/products/inventory";

// modifiers
import MerchantProductsModifiersContainer from "containers/merchant/products/modifiers";
import MerchantProductsModifiersCreateContainer from "containers/merchant/products/modifiers/create";

// discount rules
import MerchantProductsDiscountRulesContainer from "containers/merchant/products/discount-rules";
import MerchantProductsDiscountRulesCreateContainer from "containers/merchant/products/discount-rules/create";

// community cart
import MerchantCommunityCartContainer from "containers/merchant/community-cart";
import MerchantCommunityCartCreateContainer from "containers/merchant/community-cart/create";

// communications
import MerchantCommunicationsContainer from "containers/merchant/communications";
import MerchantMessagesContainer from "containers/merchant/communications/messages";
import MerchantReviewsContainer from "containers/merchant/communications/reviews";
import MerchantMarketingPostsContainer from "containers/merchant/communications/marketing-posts";

// reports
import MerchantReportsContainer from "containers/merchant/reports";
import MerchantBusinessReportsContainer from "containers/merchant/reports/income/business";
import MerchantCustomerReportsContainer from "containers/merchant/reports/income/customer";

// profile
import MerchantProfileContainer from "containers/merchant/profile";

// settings
import MerchantSettingsContainer from "containers/merchant/settings";
import MerchantSettingsTeamContainer from "containers/merchant/settings/team";
import MerchantSettingsDemoContainer from "containers/merchant/settings/demo";

// connect
import MerchantConnectStripeContainer from "containers/merchant/connect/stripe";
import MerchantConnectSquareContainer from "containers/merchant/connect/square";
import MerchantConnectSquareCallbackContainer from "containers/merchant/connect/square/callback";
import MerchantConnectOauthStripeContainer from "containers/merchant/connect/oauth/stripe";

// orders
import MerchantOrdersContainer from "containers/merchant/orders";

// notifications
import MerchantAlertsContainer from "containers/merchant/alerts";

// invitations
import MerchantInvitationsContainer from "containers/merchant/invitations";

// customers
import MerchantCustomersContainer from "containers/merchant/customers";

// discounts
import MerchantDiscountsContainer from "containers/merchant/discounts";
import MerchantDiscountsCreateContainer from "containers/merchant/discounts/create";

// merchant public business page
import PublicBusinessPageContainer from "components/PublicBusinessPage";

// components
import ProtectedRoute from "components/ProtectedRoute";
import PageNotFound from "components/PageNotFound";
import Spinner from "components/Spinner";

// enums
import { UserRoles } from "enums/userRoles";

// utils
import { CHERRY_TOKEN } from "utils/constants";

const MerchantRoutesContainer = () => {
  const { isLoggedIn } = useSelector((store: any) => store?.merchantAuth);

  const isAppLoading = localStorage.getItem(CHERRY_TOKEN) && !isLoggedIn;

  return (
    <div>
      {isAppLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Spinner loading={isAppLoading} />
        </div>
      ) : (
        <Routes>
          <Route path="onboarding" element={<MerchantOnboardingContainer />} />

          <Route path="signup" element={<MerchantSignupContainer />} />

          <Route path="login" element={<MerchantLoginContainer />} />

          <Route path="setup/:userId" element={<MerchantSetupContainer />} />

          <Route
            path="forgot-password"
            element={<MerchantForgotPasswordContainer />}
          />

          <Route
            path="reset-password-check-expiry/:token"
            element={<MerchantResetPasswordCheckExpiryContainer />}
          />

          <Route
            path="reset-password"
            element={<MerchantResetPasswordContainer />}
          />

          <Route element={<ConsumerDefaultLayout />}>
            <Route path="menu" element={<PublicBusinessPageContainer />} />
          </Route>

          <Route path="/connect">
            <Route path="stripe" element={<MerchantConnectStripeContainer />} />
            <Route path="square">
              <Route path="" element={<MerchantConnectSquareContainer />} />
              <Route
                path="callback"
                element={<MerchantConnectSquareCallbackContainer />}
              />
            </Route>

            <Route
              path="oauth"
              element={<MerchantConnectOauthStripeContainer />}
            >
              <Route
                path="stripe"
                element={<MerchantConnectOauthStripeContainer />}
              />
            </Route>
          </Route>

          <Route path="/" element={<MerchantDefaultLayout />}>
            <Route
              element={
                <ProtectedRoute
                  allowedRoles={[UserRoles.ADMIN, UserRoles.MEMBER]}
                />
              }
            >
              <Route path="dashboard">
                <Route
                  path="getting-started"
                  element={<MerchantDashboardGettingStartedContainer />}
                />

                <Route
                  path="overview"
                  element={<MerchantDashboardOverviewContainer />}
                />
              </Route>

              <Route path="plans">
                <Route path="" element={<MerchantPlansContainer />} />

                <Route
                  path="create"
                  element={<MerchantPlansCreateContainer />}
                />

                <Route
                  path="subscribers"
                  element={<MerchantPlansSubscribersContainer />}
                />

                <Route
                  path="discounts"
                  element={<MerchantPlanDiscountsContainer />}
                />
              </Route>

              <Route path="products">
                <Route path="" element={<MerchantProductsContainer />} />

                <Route
                  path="create"
                  element={<MerchantProductsCreateContainer />}
                />

                <Route path="modifiers">
                  <Route
                    path=""
                    element={<MerchantProductsModifiersContainer />}
                  />
                  <Route
                    path="create"
                    element={<MerchantProductsModifiersCreateContainer />}
                  />
                </Route>

                <Route path="discount-rules">
                  <Route
                    path=""
                    element={<MerchantProductsDiscountRulesContainer />}
                  />
                  <Route
                    path="create"
                    element={<MerchantProductsDiscountRulesCreateContainer />}
                  />
                </Route>

                <Route
                  path="inventory"
                  element={<MerchantProductsInventoryContainer />}
                />
              </Route>

              <Route path="community-cart">
                <Route path="" element={<MerchantCommunityCartContainer />} />

                <Route
                  path="create"
                  element={<MerchantCommunityCartCreateContainer />}
                />
              </Route>

              <Route
                path="communications"
                element={<MerchantCommunicationsContainer />}
              />

              <Route
                path="communications/messages"
                element={<MerchantMessagesContainer />}
              />

              <Route
                path="communications/reviews"
                element={<MerchantReviewsContainer />}
              />

              <Route
                path="communications/marketing-posts"
                element={<MerchantMarketingPostsContainer />}
              />

              <Route path="reports" element={<MerchantReportsContainer />} />

              <Route
                path="reports/income/business"
                element={<MerchantBusinessReportsContainer />}
              />

              <Route
                path="reports/income/customer"
                element={<MerchantCustomerReportsContainer />}
              />

              <Route path="profile" element={<MerchantProfileContainer />} />

              <Route path="/settings">
                <Route path="" element={<MerchantSettingsContainer />} />
                <Route
                  path="team"
                  element={<MerchantSettingsTeamContainer />}
                />
                <Route
                  path="demo"
                  element={<MerchantSettingsDemoContainer />}
                />
              </Route>

              <Route path="orders" element={<MerchantOrdersContainer />} />

              <Route path="alerts" element={<MerchantAlertsContainer />} />

              <Route path="customers">
                <Route path="" element={<MerchantCustomersContainer />} />

                <Route
                  path="invitations"
                  element={<MerchantInvitationsContainer />}
                />

                <Route path="discounts">
                  <Route path="" element={<MerchantDiscountsContainer />} />
                  <Route
                    path="create"
                    element={<MerchantDiscountsCreateContainer />}
                  />
                </Route>
              </Route>
            </Route>

            <Route
              path="/"
              element={
                <Navigate to={isLoggedIn ? "/dashboard/overview" : "/login"} />
              }
            />
          </Route>
          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </div>
  );
};

export default MerchantRoutesContainer;

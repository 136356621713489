import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import ShoppingBagIcon from "@mui/icons-material/ShoppingBag";
// import SubscriptionsIcon from "@mui/icons-material/Subscriptions";
import Spinner from "components/Spinner";
import { useMergeState } from "utils/custom-hooks";
import { AlertType } from "enums/AlertType";
import { formatDate } from "utils/date";
import { merchantAlertsFetchSaga } from "./state/actions";

const ALERT_TABS = [
  {
    label: "New orders",
    type: AlertType.ORDERS,
  },
  // {
  //   label: "New subscriptions",
  //   type: AlertType.SUBSCRIPTIONS,
  // },
  // {
  //   label: "Miscellaneous",
  //   value: AlertType.MISC,
  // },
];

export default function MerchantAlertsContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const selectedTypeParam = searchParams.get("type");

  const { isLoading, alerts } = useSelector(
    (store: any) => store?.merchantAlerts
  );

  const [state, setState] = useMergeState({
    selectedTab: {},
  });

  const handleTabChange = (event: any, newTabType: number) => {
    setState({
      selectedTab: ALERT_TABS.find((elem: any) => elem.type === newTabType),
    });
  };

  useEffect(() => {
    let type = "";

    if (state?.selectedTab?.type !== selectedTypeParam) {
      if (selectedTypeParam && !state?.selectedTab?.type) {
        type = selectedTypeParam;
      } else if (state?.selectedTab?.type) {
        type = state?.selectedTab?.type;
      } else {
        type = ALERT_TABS[0].type;
      }

      const selectedTab =
        ALERT_TABS.find((elem) => elem.type === type) || ALERT_TABS[0];

      navigate(`/alerts?type=${type}`, { replace: true });

      dispatch(merchantAlertsFetchSaga({ type: selectedTab?.type }));

      setState({
        selectedTab,
      });
    }
  }, [state?.selectedTab]);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey mb-4">Alerts</div>

          {state?.selectedTab?.type && (
            <div className="mt-8">
              <Tabs value={state?.selectedTab?.type} onChange={handleTabChange}>
                {ALERT_TABS.map((tab) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    value={tab.type}
                    style={{
                      textTransform: "none",
                      color: "#363333",
                      fontSize: 16,
                      fontFamily: "Poppins",
                    }}
                  />
                ))}
              </Tabs>
            </div>
          )}

          {state?.selectedTab?.type === AlertType.ORDERS && (
            <div className="mt-8">
              {alerts.map((alert: any) => (
                <Link
                  to={`/orders?orderId=${alert?.order?._id}`}
                  key={alert._id}
                >
                  <div className="flex items-center">
                    <div className="flex justify-center items-center bg-slate-200 w-14 h-14 rounded-full">
                      <ShoppingBagIcon color="success" />
                    </div>

                    <div className="pl-8 py-4">
                      <div className="text-grey">
                        An order has been placed by{" "}
                        <b>
                          {alert?.user?.firstName} {alert?.user?.lastName}
                        </b>
                      </div>
                      <div className="text-grey-2 text-sm mt-2">
                        {formatDate(alert?.createdAt, "ll")}
                      </div>
                    </div>
                    <hr />
                  </div>
                </Link>
              ))}
            </div>
          )}

          {/* {state?.selectedTab?.type === AlertType.SUBSCRIPTIONS && (
            <div className="mt-8">
              {alerts.map((alert: any) => (
                <div key={alert._id}>
                  <div className="flex items-center">
                    <div className="flex justify-center items-center bg-slate-200 w-14 h-14 rounded-full">
                      <SubscriptionsIcon color="success" />
                    </div>

                    <div className="pl-8 py-4">
                      <div className="text-grey">
                        A new subscription has been purchased by{" "}
                        <b>
                          {alert?.user?.firstName} {alert?.user?.lastName}
                        </b>
                      </div>
                      <div className="text-grey-2 text-sm mt-2">
                        {formatDate(alert?.createdAt, "ll")}
                      </div>
                    </div>
                  </div>
                  <hr />
                </div>
              ))}
            </div>
          )} */}
        </div>
      )}
    </div>
  );
}

import { useEffect } from "react";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Spinner from "components/Spinner";
import { useMergeState } from "utils/custom-hooks";
import { getMerchantStoreUrl } from "utils/common";
import { listDemoMerchantAccounts } from "api";

export default function MerchantSettingsDemoOverviewContainer() {
  const [state, setState] = useMergeState({
    isLoading: false,
    demoAccounts: [],
  });

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await listDemoMerchantAccounts();

      setState({ demoAccounts: response?.data });
    } catch (error) {
      //
    } finally {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {state?.isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={state?.isLoading} />
        </div>
      ) : (
        <div>
          <TableContainer className="mt-10">
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="text-grey">Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="text-grey">Subdomain</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="text-grey">Type</span>
                  </TableCell>
                  <TableCell align="center" />
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "visible" }}>
                {state?.demoAccounts?.map((account: any) => (
                  <TableRow key={account._id}>
                    <TableCell component="th" scope="row">
                      <span className="text-grey text-sm">
                        {account?.businessName}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <a
                        href={getMerchantStoreUrl(account?.subdomain)}
                        target="_blank"
                        rel="noreferrer"
                      >
                        {account?.subdomain}
                      </a>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-sm">
                        {account?.businessType}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

import { takeLatest, put, all, call } from "redux-saga/effects";
import { getMerchantMenu } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { PUBLIC_BUSINESS_PAGE_FETCH_DETAILS_SAGA } from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  publicBusinessPageSetIsLoading,
  publicBusinessPageSetDetails,
} from "./actions";

function* customerMerchantPublicBusinessPageFetchDetailsHandler(
  action: any
): any {
  try {
    yield put(publicBusinessPageSetIsLoading(true));

    const response = yield call(getMerchantMenu, action.payload);

    if (response?.success) {
      yield put(publicBusinessPageSetDetails(response.data));
    }

    yield put(publicBusinessPageSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(publicBusinessPageSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchPublicBusinessPage() {
  yield takeLatest(
    PUBLIC_BUSINESS_PAGE_FETCH_DETAILS_SAGA,
    customerMerchantPublicBusinessPageFetchDetailsHandler
  );
}

import { RESET_APP_STATE } from "app/state/types";
import {
  CUSTOMER_AUTH_SET_CURRENT_USER,
  CUSTOMER_AUTH_SET_IS_LOADING,
  CUSTOMER_AUTH_SET_IS_LOGGED_IN,
  CUSTOMER_AUTH_SET_MESSAGE,
  CUSTOMER_AUTH_SET_MERCHANT_DETAILS,
} from "./types";

type CustomerAuthStateType = {
  message: {
    type: string;
    value: string;
  };
  isLoading: boolean;
  isLoggedIn: boolean;
  user: {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    role: string;
    avatar: string;
    status: string;
    type: string;
    customer: {
      stripeMetadata: {
        platformCustomerId: string;
      };
    };
  };
  merchant: any;
};

const initialState: CustomerAuthStateType = {
  isLoading: false,
  message: {
    type: "",
    value: "",
  },
  isLoggedIn: false,
  user: {
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    role: "",
    avatar: "",
    status: "",
    type: "",
    customer: {
      stripeMetadata: {
        platformCustomerId: "",
      },
    },
  },
  merchant: {},
};

const customerAuthReducer = (
  state: CustomerAuthStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CUSTOMER_AUTH_SET_MESSAGE:
      return { ...state, message: { ...action.payload } };

    case CUSTOMER_AUTH_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case CUSTOMER_AUTH_SET_IS_LOGGED_IN:
      return { ...state, isLoggedIn: action.payload };

    case CUSTOMER_AUTH_SET_CURRENT_USER:
      return { ...state, user: { ...action.payload } };

    case CUSTOMER_AUTH_SET_MERCHANT_DETAILS:
      return { ...state, merchant: action?.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default customerAuthReducer;

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_ALERTS_SET_IS_LOADING,
  MERCHANT_ALERTS_SET_ALERTS,
} from "./types";

type MerchantAlertsStateType = {
  isLoading: boolean;
  alerts: Array<any>;
};

const initialState: MerchantAlertsStateType = {
  isLoading: false,
  alerts: [],
};

const merchantAlertsReducer = (
  state: MerchantAlertsStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_ALERTS_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_ALERTS_SET_ALERTS:
      return { ...state, alerts: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantAlertsReducer;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import Button from "components/Button";
import Spinner from "components/Spinner";
import OrderDialog from "components/Customer/OrderDialog";
import { consumerPlansFetchPlansSaga } from "./state/actions";
import { orderDialogSetConfig } from "components/Customer/OrderDialog/state/actions"; // order dialog actions
import { humanizeSchedule } from "enums/PaymentSchedules";
import { PlanType } from "enums/PlanType";
import { ItemType } from "enums/Products";

export default function ConsumerPlansContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, plans } = useSelector(
    (store: any) => store?.consumerPlans
  );

  const { open: shouldShowOrderDialog } = useSelector(
    (store: any) => store?.orderDialog
  );

  const handleOpenOrderDialog = (plan: any) => {
    dispatch(orderDialogSetConfig({ open: true, plan }));
  };

  const handleCloseOrderDialog = () => {
    dispatch(orderDialogSetConfig({ open: false }));
  };

  const handleOpenPlanDetails = (planId: string) => {
    navigate(`/subscription/details?id=${planId}`);
  };

  useEffect(() => {
    dispatch(consumerPlansFetchPlansSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div className="py-4 flex justify-center">
          <div className="w-11/12 lg:w-2/3">
            <div className="text-grey text-2xl font-semibold">Plans</div>

            <div className="mt-10">
              {plans?.map((plan: any) => (
                <div
                  key={plan?._id}
                  className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 mt-8"
                >
                  <div className="flex justify-between items-center">
                    <div className="text-grey text-xl font-semibold">
                      {plan?.merchant?.businessName}
                    </div>

                    <div>
                      <Chip
                        label={plan?.type}
                        variant="outlined"
                        sx={{
                          marginRight: 2,
                          fontSize: 12,
                          border: "1px solid",
                        }}
                      />
                    </div>
                  </div>
                  <div className="text-grey text-xl">
                    {plan?.product?.title}
                  </div>

                  <div className="text-grey text-sm font-medium mt-1">
                    {plan?.product?.description}
                  </div>

                  {plan?.product?.type === ItemType.PLAN && (
                    <div>
                      {plan?.product?.isOffer && (
                        <div className="text-grey text-sm font-normal mt-2">
                          PROMO OFFER
                        </div>
                      )}

                      {!plan?.product?.isOffer && (
                        <div className="text-grey text-lg font-medium mt-1">
                          ${plan?.product?.price?.amount}{" "}
                          {humanizeSchedule(plan?.product?.price?.schedule)}
                        </div>
                      )}

                      <div className="text-grey text-xs font-medium mt-1">
                        {plan?.product?.price?.type ===
                        PlanType.UNLIMITED_OFFERING
                          ? "UNLIMITED"
                          : `${plan?.product?.price?.quantity} ${
                              plan?.product?.price?.quantity > 1
                                ? "UNITS"
                                : "UNIT"
                            }  ${
                              !plan?.product?.isOffer
                                ? `PER ${plan?.product?.price?.schedule}`
                                : ""
                            } `}
                      </div>
                    </div>
                  )}

                  {plan?.product?.type === ItemType.PRODUCT && (
                    <div className="text-grey text-xs font-medium mt-1">
                      {plan?.quantity} UNITS{" "}
                      {plan?.type !== ItemType.BMPL && `PER ${plan?.schedule}`}
                    </div>
                  )}

                  {plan?.product?.price?.type !==
                    PlanType.UNLIMITED_OFFERING && (
                    <div>
                      <div className="text-grey text-xs font-medium mt-1">
                        {parseFloat(plan?.used).toFixed(2)} UNITS USED
                      </div>

                      <div className="text-grey text-xs font-medium mt-1">
                        {parseFloat(plan?.remaining).toFixed(2)} UNITS REMAINING
                      </div>
                    </div>
                  )}

                  <div className="mt-2 flex justify-end">
                    <div className="mr-4">
                      <Button
                        label="View"
                        onClick={() => handleOpenPlanDetails(plan._id)}
                        style={{
                          borderRadius: 4,
                          fontSize: 14,
                          color: "#000000",
                          background: "rgb(231, 231, 231)",
                          boxShadow: "rgb(231 231 231) 0px 0px 0px 1px inset",
                          fontWeight: 500,
                          height: 40,
                        }}
                        loaderButton
                        loadingPosition="center"
                        loading={isLoading}
                        disabled={isLoading}
                      />
                    </div>

                    <div>
                      <Button
                        label="Use Now"
                        color="secondary"
                        onClick={() => handleOpenOrderDialog(plan)}
                        style={{
                          borderRadius: 4,
                          fontSize: 14,
                          color: "#FFFFFF",
                          fontWeight: 500,
                          height: 40,
                        }}
                        loaderButton
                        loadingPosition="center"
                        loading={isLoading}
                        disabled={isLoading}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>

          {shouldShowOrderDialog && (
            <OrderDialog onClose={handleCloseOrderDialog} />
          )}
        </div>
      )}
    </div>
  );
}

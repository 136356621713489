import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
import MuiTextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import MenuItem from "@mui/material/MenuItem";
import InputBase from "@mui/material/InputBase";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";

const TextField = styled(MuiTextField)(() => ({
  "& .MuiOutlinedInput-root": {
    borderRadius: 3,
    backgroundColor: "#F1F1F1",
    width: 150,
    height: 40,
    "&.Mui-focused fieldset": {
      border: "0.8px solid rgba(112, 110, 110, 0.4)",
    },
  },
}));

const SelectInput = styled(InputBase)(() => ({
  "& .MuiInputBase-input": {
    background: "#FFFFFF",
    border: "1px solid #706E6E",
    borderRadius: 50,
    width: 150,
    fontWeight: 500,
    fontSize: 14,
    padding: "10px 26px 10px 12px",
    "&:focus": {
      background: "#FFFFFF",
      border: "1px solid #706E6E",
      borderRadius: 50,
    },
  },
}));

export default function MerchantBusinessReportsContainer() {
  const [state, setState] = useMergeState({
    from: null,
    to: null,
    exportReportType: "Export Report",
  });

  const handleFromChange = (newDate: Date | null) => {
    setState({ from: newDate });
  };

  const handleToChange = (newDate: Date | null) => {
    setState({ to: newDate });
  };

  const handleChange = (event: SelectChangeEvent) => {
    setState({ exportReportType: event.target.value as string });
  };

  const handleUpdateReport = () => {};

  return (
    <div>
      <div className="flex justify-between items-center w-10/12">
        <div className="text-2xl font-semibold text-grey">
          Profit & Loss (Income Statement)
        </div>

        <div>
          <FormControl sx={{ m: 1 }} variant="standard">
            <Select
              value={state.exportReportType}
              onChange={handleChange}
              input={<SelectInput />}
              renderValue={(selected) => {
                if (!selected) {
                  return "Export Report";
                }

                return selected;
              }}
            >
              <MenuItem value="CSV">CSV</MenuItem>
              <MenuItem value="PDF">PDF</MenuItem>
            </Select>
          </FormControl>
        </div>
      </div>

      <div className="w-10/12">
        <div className="flex justify-between items-center mt-8 bg-lightest-yellow h-20 p-4">
          <div className="flex justify-between items-center w-4/6">
            <div className="text-grey">Date Range</div>

            {/* <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={state.from}
                  onChange={handleFromChange}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div>

            <div>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DesktopDatePicker
                  inputFormat="MM/dd/yyyy"
                  value={state.from}
                  onChange={handleToChange}
                  renderInput={(params: any) => <TextField {...params} />}
                />
              </LocalizationProvider>
            </div> */}
          </div>

          <div>
            <Button
              label="Update Report"
              color="secondary"
              onClick={handleUpdateReport}
              style={{
                borderRadius: 50,
                fontSize: 12,
                color: "#FFFFFF",
                height: 40,
              }}
            />
          </div>
        </div>

        <div className="flex justify-between items-center mt-8">
          <div className="flex flex-col items-center">
            <div className="text-grey text-xxs">INCOME</div>
            <div className="text-grey font-medium text-xl">$30,000</div>
          </div>

          <div className="text-grey font-medium text-xl">-</div>

          <div className="flex flex-col items-center">
            <div className="text-grey text-xxs">COST OF GOODS</div>
            <div className="text-grey font-medium text-xl">$10,000</div>
          </div>

          <div className="text-grey font-medium text-xl">-</div>

          <div className="flex flex-col items-center">
            <div className="text-grey text-xxs">OPERATING EXPENSES</div>
            <div className="text-grey font-medium text-xl">$10,000</div>
          </div>

          <div className="text-grey font-medium text-xl">=</div>

          <div className="flex flex-col items-center">
            <div className="text-grey text-xxs">NET PROFIT</div>
            <div className="font-medium text-xl text-green-3">$10,000</div>
          </div>
        </div>

        <hr className="my-4" />

        <TableContainer
          className="table mb-4"
          style={{
            background: "#FFF9F7",
          }}
        >
          <Table>
            <TableHead>
              <TableRow>
                <TableCell align="left">
                  <span className="text-grey">ACCOUNTS</span>
                </TableCell>
                <TableCell align="right">
                  <span className="text-grey">Jan 01 2022 - Dec 31 2022</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell component="th" scope="row" align="left">
                  <span className="text-grey text-xs">Income</span>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <span className="text-grey text-xs">$30,000</span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="left">
                  <span className="text-grey text-xs">Costs of goods sold</span>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <span className="text-grey text-xs">$10,000</span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  align="left"
                  style={{ background: "#F9F4F3" }}
                >
                  <div className="text-grey text-xs">Gross Profit</div>
                  <div className="text-grey text-xxs mt-2">
                    Percent of Total Income
                  </div>
                </TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  align="right"
                  style={{ background: "#F9F4F3" }}
                >
                  <div className="text-grey text-xs">$10,000</div>
                  <div className="text-grey text-xxs mt-2">10%</div>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="left">
                  <span className="text-grey text-xs">Operating Expenses</span>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <span className="text-grey text-xs">$10,000</span>
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell component="th" scope="row" align="left">
                  <div className="text-grey text-xs">Net Profit</div>
                  <div className="text-grey text-xxs mt-2">
                    Percent of Total Income
                  </div>
                </TableCell>
                <TableCell component="th" scope="row" align="right">
                  <div className="text-grey text-xs">$10,000</div>
                  <div className="text-grey text-xxs mt-2">10%</div>
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </div>
    </div>
  );
}

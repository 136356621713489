import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { OrderStatus } from "enums/orders";
import "./styles.scss";

type Props = {
  value: string;
  onChange: any;
};

export default function OrderStatusSelect(props: Props) {
  const { value, onChange } = props;

  return (
    <FormControl
      className="order-status-select-field"
      style={{
        width: 130,
        height: 35,
        background: value === OrderStatus.PENDING ? "#FFC627" : "#6FB100",
      }}
    >
      <Select
        value={value}
        onChange={onChange}
        onClick={(event) => event.stopPropagation()}
      >
        <MenuItem value={OrderStatus.PENDING}>Pending</MenuItem>
        <MenuItem value={OrderStatus.CONFIRMED}>Confirmed</MenuItem>
        <MenuItem value={OrderStatus.IN_PROGRESS}>In Progress</MenuItem>
        <MenuItem value={OrderStatus.COMPLETED}>Completed</MenuItem>
      </Select>
    </FormControl>
  );
}

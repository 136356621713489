import Rating from "@mui/material/Rating";
import { LoadMoreButton } from "components/LoadMoreButton";

export default function MerchantReviewsContainer() {
  return (
    <div>
      <div className="text-3xl font-semibold text-grey">Reviews</div>

      <div className="profile-box w-3/4 p-5 mt-5">
        <div className="flex justify-between w-1/2">
          <div className="text-2xl font-semibold text-grey">Your Reviews</div>

          <div className="flex items-center">
            <Rating
              size="small"
              value={4.5}
              readOnly
              precision={0.5}
              style={{ color: "#363333" }}
            />

            <div className="text-grey text-xxs">4.5 AVERAGE</div>
          </div>
        </div>

        <hr className="mt-4" />

        <div className="grid grid-cols-2 gap-8">
          <div className="mt-8">
            <Rating value={4} readOnly style={{ color: "#363333" }} />

            <div className="text-xs text-grey">John Smith</div>

            <div className="text-grey text-lg font-semibold mt-4">
              An amazing Service
            </div>

            <div className="text-grey text-xs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim v
            </div>

            <div className="text-grey text-sm font-semibold underline mt-4">
              Write a response
            </div>
          </div>

          <div className="mt-8">
            <Rating value={4} readOnly style={{ color: "#363333" }} />

            <div className="text-xs text-grey">John Smith</div>

            <div className="text-grey text-lg font-semibold mt-4">
              An amazing Service
            </div>

            <div className="text-grey text-xs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim v
            </div>

            <div className="text-grey text-sm font-semibold underline mt-4">
              Write a response
            </div>
          </div>

          <div className="mt-8">
            <Rating value={4} readOnly style={{ color: "#363333" }} />

            <div className="text-xs text-grey">John Smith</div>

            <div className="text-grey text-lg font-semibold mt-4">
              An amazing Service
            </div>

            <div className="text-grey text-xs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim v
            </div>

            <div className="text-grey text-sm font-semibold underline mt-4">
              Write a response
            </div>
          </div>

          <div className="mt-8">
            <Rating value={4} readOnly style={{ color: "#363333" }} />

            <div className="text-xs text-grey">John Smith</div>

            <div className="text-grey text-lg font-semibold mt-4">
              An amazing Service
            </div>

            <div className="text-grey text-xs">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
              enim ad minim v
            </div>

            <div className="text-grey text-sm font-semibold underline mt-4">
              Write a response
            </div>
          </div>
        </div>

        <div className="w-1/4 mt-12">
          <LoadMoreButton fullWidth>Load More</LoadMoreButton>
        </div>
      </div>
    </div>
  );
}

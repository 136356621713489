export const CHERRY_TOKEN = "CHERRY_TOKEN";

export const CHERRY_SUPPORT_EMAIL = "support@cherrypayments.com";

export const MERCHANT_PLATFORM_SUBDOMAIN = "app";

export const MESSAGE_SEVERITY = {
  SUCCESS: "success",
  ERROR: "error",
  WARNING: "warning",
  INFO: "info",
};

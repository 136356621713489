import { useEffect } from "react";
import { useSelector } from "react-redux";
import { nanoid } from "nanoid";
import Button from "components/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Dialog, DialogTitle } from "components/Dialog";
import TextField from "components/TextField";
import { useMergeState } from "utils/custom-hooks";

type Props = {
  onSendInvitation: any;
  onClose: any;
  discountId?: string;
};

export default function MerchantInviteCustomerDialog(props: Props) {
  const { onSendInvitation, onClose, discountId } = props;

  const {
    inviteCustomerDialogConfig: { open, isInviting },
  } = useSelector((store: any) => store?.merchantInvitations);

  const [state, setState] = useMergeState({
    customers: [],
  });

  const handleChange = (event: any, index: number) => {
    const newCustomers = [...state?.customers];

    newCustomers[index][event?.target?.name] = event?.target?.value;

    setState({
      variations: [...newCustomers],
    });
  };

  const handleAddCustomer = () => {
    const newCustomers = [...state?.customers];

    newCustomers.push({
      id: nanoid(),
      email: "",
      phone: "",
      name: "",
    });

    setState({
      customers: newCustomers,
    });
  };

  const handleRemoveCustomer = (index: number) => {
    const newCustomers = [...state?.customers];

    newCustomers.splice(index, 1);

    setState({
      customers: newCustomers,
    });
  };

  const handleSendInvitation = () => {
    onSendInvitation(state?.customers);
  };

  useEffect(() => {
    setState({
      customers: [
        {
          id: nanoid(),
          email: "",
          phone: "",
          name: "",
        },
      ],
    });
  }, []);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      disableEscapeKeyDown
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={onClose}>Invite Customer</DialogTitle>

      <DialogContent dividers>
        {state?.customers?.map((customer: any, index: number) => (
          <div
            key={customer.id}
            className="flex justify-between items-center my-4"
          >
            {!discountId && (
              <div className="w-1/3">
                <TextField
                  fullWidth
                  label="EMAIL"
                  variant="outlined"
                  name="email"
                  value={state.email}
                  onChange={(event) => handleChange(event, index)}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  autoComplete="off"
                  disabled={isInviting}
                />
              </div>
            )}

            <div className="w-1/3 ml-4">
              <TextField
                fullWidth
                label="PHONE"
                variant="outlined"
                name="phone"
                value={state.phone}
                onChange={(event) => handleChange(event, index)}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                type="number"
                onWheel={(event: any) => event.target.blur()}
                onInput={(event) => {
                  if (
                    // @ts-ignore
                    event?.target?.value
                  ) {
                    // @ts-ignore
                    event.target.value = Math.max(
                      0,
                      // @ts-ignore
                      parseInt(event.target.value, 10)
                    )
                      .toString()
                      .slice(0, 10);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+1</InputAdornment>
                  ),
                }}
              />
            </div>

            <div className="w-1/3 ml-4">
              <TextField
                fullWidth
                label="NAME"
                variant="outlined"
                name="name"
                value={state?.name}
                onChange={(event) => handleChange(event, index)}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                disabled={isInviting}
              />
            </div>

            <div className="w-1/4">
              {index === state?.customers?.length - 1 ? (
                <IconButton onClick={handleAddCustomer}>
                  <AddIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleRemoveCustomer(index)}>
                  <RemoveIcon />
                </IconButton>
              )}

              {index !== 0 && index === state?.customers?.length - 1 && (
                <IconButton
                  onClick={() => handleRemoveCustomer(index)}
                  sx={{ ml: 1 }}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </div>
          </div>
        ))}
      </DialogContent>

      <DialogActions>
        <div className="my-2">
          <Button
            label="Send Invitation"
            color="info"
            onClick={handleSendInvitation}
            style={{
              borderRadius: 4,
              fontSize: 14,
              color: "#FFFFFF",
              height: 40,
              width: 150,
            }}
            loaderButton
            loadingPosition="center"
            loading={isInviting}
            disabled={isInviting}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

export const MERCHANT_PLANS_SET_IS_LOADING = "MERCHANT_PLANS_SET_IS_LOADING";
export const MERCHANT_PLANS_SET_PLANS = "MERCHANT_PLANS_SET_PLANS";
export const MERCHANT_PLANS_SET_SELECTED_PLAN =
  "MERCHANT_PLANS_SET_SELECTED_PLAN";
export const MERCHANT_PLANS_SET_PLAN_SUBSCRIBERS =
  "MERCHANT_PLANS_SET_PLAN_SUBSCRIBERS";
export const MERCHANT_PLANS_SET_INVITE_SUBSCRIBER_DIALOG_CONFIG =
  "MERCHANT_PLANS_SET_INVITE_SUBSCRIBER_DIALOG_CONFIG";

// async

export const MERCHANT_PLANS_FETCH_SELECTED_PLAN_SAGA =
  "MERCHANT_PLANS_FETCH_SELECTED_PLAN_SAGA";
export const MERCHANT_PLANS_FETCH_SAGA = "MERCHANT_PLANS_FETCH_SAGA";
export const MERCHANT_PLANS_CREATE_SAGA = "MERCHANT_PLANS_CREATE_SAGA";
export const MERCHANT_PLANS_UPDATE_SAGA = "MERCHANT_PLANS_UPDATE_SAGA";
export const MERCHANT_PLANS_SUBSCRIBERS_FETCH_SAGA =
  "MERCHANT_PLANS_SUBSCRIBERS_FETCH_SAGA";
export const MERCHANT_PLANS_INVITE_SUBSCRIBER_SAGA =
  "MERCHANT_PLANS_INVITE_SUBSCRIBER_SAGA";

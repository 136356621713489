import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import { nanoid } from "nanoid";
import { styled } from "@mui/material/styles";
import Checkbox from "@mui/material/Checkbox";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import InputAdornment from "@mui/material/InputAdornment";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import IconButton from "@mui/material/IconButton";
import Button from "components/Button";
import TextField from "components/TextField";
import ConfirmationDialog from "components/ConfirmationDialog";
import ErrorMessage from "components/ErrorMessage";
import { useMergeState } from "utils/custom-hooks";
import { PaymentType } from "enums/Payments";
import { DiscountRuleType } from "enums/DiscountRules";
import { AvailableSchedules } from "enums/PaymentSchedules";
import { DiscountType } from "enums/Discounts";
import {
  merchantProductsDiscountRulesFetchSelectedRuleSaga,
  merchantProductsDiscountRulesCreateSaga,
  merchantProductsDiscountRulesUpdateSaga,
} from "../../state/actions";

const getRuleId = () => `#${nanoid()}`;

const Input = styled(MuiInput)`
  width: 42px;
`;

export default function MerchantProductsDiscountRulesCreateContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const discountRuleId = searchParams.get("id");

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const { isLoading, discountRule } = useSelector(
    (store: any) => store?.merchantProducts
  );

  const [state, setState] = useMergeState({
    title: "",

    selectedType: PaymentType.SUBSCRIPTION,

    // straight
    allowedSchedules: [],

    [PaymentType.SUBSCRIPTION]: {
      type: DiscountRuleType.STRAIGHT,
      rule: {
        // straight
        discountAmount: 0,

        // slider
        maxDiscountPercentage: 0,
        maxUnits: 0,
        percentageOff: 0,
        unitsToBePurchased: 0,
        percentageOffOnPointScale: 0,
      },
    },

    [PaymentType.BUY_MORE_PAY_LESS]: {
      maxDiscountPercentage: 0,
      maxUnits: 0,
      percentageOff: 0,
      unitsToBePurchased: 0,
      percentageOffOnPointScale: 0,
    },

    rules: [
      {
        id: getRuleId(),
        units: 0,
        discountPercentage: 0,
      },
    ],

    isEditMode: false,
    shouldShowConfirmDeleteDialog: false,
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const handleChangeDiscountAmount = (event: any) => {
    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        rule: {
          ...state[state?.selectedType]?.rule,
          discountAmount: event?.target?.value,
        },
      },
    });
  };

  const handleChangeDiscountRuleType = (event: any) => {
    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        type: event?.target?.value,
      },
    });
  };

  const handleChangeSchedule = (event: any) => {
    const updatedAllowedSchedules = [...state?.allowedSchedules];

    const index = updatedAllowedSchedules.findIndex(
      (elem) => elem === event?.target?.name
    );

    if (index !== -1) {
      updatedAllowedSchedules.splice(index, 1);
    } else {
      updatedAllowedSchedules.push(event?.target?.name);
    }

    setState({
      allowedSchedules: updatedAllowedSchedules,
    });
  };

  const handlePricingChange = (key: string, event: any) => {
    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        rule: {
          ...state[state?.selectedType]?.rule,
          [key]: event?.target?.value,
        },
      },
    });
  };

  const getPercentageOffOnPointScale = (percentageOff: number) =>
    Number(
      (Number(percentageOff) /
        Number(state[state?.selectedType]?.maxDiscountPercentage)) *
        100
    );

  const handleChangePercentageOff = (
    event: Event,
    newValue: number | number[]
  ) => {
    const maxUnits = state[state?.selectedType]?.rule?.maxUnits;

    const maxDiscountPercentage =
      state[state?.selectedType]?.rule?.maxDiscountPercentage;

    const unitsToBePurchased = Number(
      (Number(newValue) / 100) * Number(maxUnits)
    );

    const percentageOff = Number(
      (Number(unitsToBePurchased) / Number(maxUnits)) *
        Number(maxDiscountPercentage)
    );

    const percentageOffOnPointScale = Number(
      (Number(percentageOff) / Number(maxDiscountPercentage)) * 100
    );

    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        rule: {
          ...state[state?.selectedType]?.rule,
          percentageOff,
          percentageOffOnPointScale,
          unitsToBePurchased,
        },
      },
    });
  };

  const handleChangeUnitsToBePurchased = (event: any) => {
    const numberOfUnits = event.target.value;

    const maxUnits = state[state?.selectedType]?.rule?.maxUnits;

    const maxDiscountPercentage =
      state[state?.selectedType]?.rule?.maxDiscountPercentage;

    if (Number(numberOfUnits) > Number(maxUnits)) {
      const percentageOff = Number(maxDiscountPercentage);

      setState({
        [state?.selectedType]: {
          ...state[state?.selectedType],
          rule: {
            ...state[state?.selectedType]?.rule,
            percentageOff,
            percentageOffOnPointScale:
              getPercentageOffOnPointScale(percentageOff),
            unitsToBePurchased: numberOfUnits,
          },
        },
      });

      return;
    }

    const percentageOff = Number(
      (Number(numberOfUnits) / Number(maxUnits)) * Number(maxDiscountPercentage)
    );

    const percentageOffOnPointScale = Number(
      (Number(percentageOff) / Number(maxDiscountPercentage)) * 100
    );

    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        percentageOff,
        percentageOffOnPointScale,
        unitsToBePurchased: numberOfUnits,
      },
    });
  };

  const handleChangeSelectedType = (selectedType: String) => {
    setState({ selectedType });
  };

  const handleChangeRule = (event: any, index: number) => {
    const newRules = [...state?.rules];

    newRules[index][event?.target?.name] = event?.target?.value;

    setState({
      rules: [...newRules],
    });
  };

  const handleAddRule = () => {
    const newRules = [...state?.rules];

    newRules.push({
      id: getRuleId(),
      units: "",
      discountPercentage: 0,
    });

    setState({
      rules: newRules,
    });
  };

  const handleRemoveRule = (index: number) => {
    const newRules = [...state?.rules];

    newRules.splice(index, 1);

    setState({
      rules: newRules,
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.title) {
      payload = { title: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleCreateDiscountRule = async () => {
    if (!isFormValid()) {
      return;
    }

    const SUBSCRIPTION = {
      type: state[PaymentType.SUBSCRIPTION]?.type,
      rule: {},
    };

    const subscriptionDiscountRuleType = state[PaymentType.SUBSCRIPTION]?.type;

    if (subscriptionDiscountRuleType === DiscountRuleType.STRAIGHT) {
      SUBSCRIPTION.rule = {
        discountAmount: Number(
          state[PaymentType.SUBSCRIPTION]?.rule?.discountAmount
        ),
        discountType: DiscountType.PERCENTAGE,
        allowedSchedules: state?.allowedSchedules,
      };
    } else {
      SUBSCRIPTION.rule = {
        maxDiscountPercentage: Number(
          state[PaymentType.SUBSCRIPTION]?.rule?.maxDiscountPercentage
        ),
        maxUnits: Number(state[PaymentType.SUBSCRIPTION]?.rule?.maxUnits),
      };
    }

    const BUY_MORE_PAY_LESS = {
      rule: {
        maxDiscountPercentage: Number(
          state[PaymentType.BUY_MORE_PAY_LESS]?.maxDiscountPercentage
        ),
        maxUnits: Number(state[PaymentType.BUY_MORE_PAY_LESS]?.maxUnits),
      },
    };

    const discount: any = {
      SUBSCRIPTION,
      BUY_MORE_PAY_LESS: null,
    };

    if (
      BUY_MORE_PAY_LESS?.rule?.maxDiscountPercentage > 0 &&
      BUY_MORE_PAY_LESS?.rule?.maxUnits > 0
    ) {
      discount.BUY_MORE_PAY_LESS = BUY_MORE_PAY_LESS;
    }

    if (user?.merchant?.isB2B) {
      discount.BUY_MORE_PAY_LESS = {
        ...BUY_MORE_PAY_LESS,
        rules: [...state?.rules],
      };
    }

    const payload: any = {
      title: state?.title,
      discount,
    };

    if (state?.isEditMode) {
      dispatch(
        merchantProductsDiscountRulesUpdateSaga({
          id: discountRuleId,
          ...payload,
        })
      );
    } else {
      dispatch(merchantProductsDiscountRulesCreateSaga(payload, navigate));
    }
  };

  // const handleOpenConfirmDeleteProductDialog = () => {
  //   setState({ shouldShowConfirmDeleteDialog: true });
  // };

  const handleCloseConfirmDeleteDialog = () => {
    setState({ shouldShowConfirmDeleteDialog: false });
  };

  const handleConfirmDeleteDialog = () => {
    handleCloseConfirmDeleteDialog();
  };

  useEffect(() => {
    if (discountRuleId) {
      dispatch(
        merchantProductsDiscountRulesFetchSelectedRuleSaga(discountRuleId)
      );
      setState({ isEditMode: true });
    }
  }, []);

  useEffect(() => {
    if (state.isEditMode) {
      const payload = {
        title: discountRule?.title,
        allowedSchedules: discountRule?.discount
          ? discountRule?.discount[PaymentType.SUBSCRIPTION]?.rule
              ?.allowedSchedules
          : [],
        [PaymentType.SUBSCRIPTION]: {
          ...state[PaymentType.SUBSCRIPTION],
          type: discountRule?.discount
            ? discountRule?.discount[PaymentType.SUBSCRIPTION]?.type
            : "",
          rule: {
            ...state[PaymentType.SUBSCRIPTION]?.rule,
            discountAmount: discountRule?.discount
              ? discountRule?.discount[PaymentType.SUBSCRIPTION]?.rule
                  ?.discountAmount
              : 0,
            maxDiscountPercentage: discountRule?.discount
              ? discountRule?.discount[PaymentType.SUBSCRIPTION]?.rule
                  ?.maxDiscountPercentage
              : 0,
            maxUnits: discountRule?.discount
              ? discountRule?.discount[PaymentType.SUBSCRIPTION]?.rule?.maxUnits
              : 0,
          },
        },
        [PaymentType.BUY_MORE_PAY_LESS]: {
          ...state[PaymentType.BUY_MORE_PAY_LESS],
          maxDiscountPercentage: discountRule?.discount
            ? discountRule?.discount[PaymentType.BUY_MORE_PAY_LESS]?.rule
                ?.maxDiscountPercentage
            : 0,
          maxUnits: discountRule?.discount
            ? discountRule?.discount[PaymentType.BUY_MORE_PAY_LESS]?.rule
                ?.maxUnits
            : 0,
          rules: discountRule?.discount
            ? discountRule?.discount[PaymentType.BUY_MORE_PAY_LESS]?.rules
            : [
                {
                  id: getRuleId(),
                  units: 0,
                  discountPercentage: 0,
                },
              ],
        },
      };

      setState({ ...payload });
    }
  }, [discountRule]);

  return (
    <div>
      <div>
        <div className="text-4xl font-semibold text-grey mb-4">
          {state.isEditMode ? "Edit discount rule" : "Create discount rule"}
        </div>

        <div className="w-full lg:w-3/5 my-8">
          <div>
            <div className="text-grey font-semibold">
              What do you want to call your discount rule?
            </div>

            <div className="mt-2 mb-2">
              <TextField
                fullWidth
                label="TITLE"
                variant="outlined"
                name="title"
                value={state.title}
                onChange={handleChange}
                required
                error={state?.errors?.title}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />

              {state?.errors?.title && (
                <ErrorMessage message="Title is required" />
              )}
            </div>
          </div>

          <div>
            <hr className="my-4" />

            <div className="text-grey font-semibold mt-4">
              Give customers a discount for buying more upfront
            </div>

            <div className="plan-details-box flex justify-between items-center px-3 py-2 mt-4">
              <div
                className={`flex justify-center items-center cursor-pointer rounded-md text-sm px-6 py-2 w-1/2 ${
                  state?.selectedType === PaymentType.SUBSCRIPTION
                    ? "bg-green text-white"
                    : ""
                }`}
                onClick={() =>
                  handleChangeSelectedType(PaymentType.SUBSCRIPTION)
                }
              >
                Subscription
              </div>

              <div
                className={`flex justify-center items-center cursor-pointer rounded-md text-sm px-6 py-2 w-1/2 ${
                  state?.selectedType === PaymentType.BUY_MORE_PAY_LESS
                    ? "bg-green text-white"
                    : ""
                }`}
                onClick={() =>
                  handleChangeSelectedType(PaymentType.BUY_MORE_PAY_LESS)
                }
              >
                Buy More Pay Less
              </div>
            </div>

            <div className="plan-details-box p-5 mt-2">
              {state?.selectedType === PaymentType.SUBSCRIPTION && (
                <div>
                  <div>
                    <FormControl>
                      <RadioGroup
                        name="type"
                        value={state[state?.selectedType]?.type}
                        onChange={handleChangeDiscountRuleType}
                      >
                        <FormControlLabel
                          value={DiscountRuleType.STRAIGHT}
                          control={<Radio color="secondary" />}
                          label="Straight subscription"
                        />

                        <FormControlLabel
                          value={DiscountRuleType.SLIDER}
                          control={<Radio color="secondary" />}
                          label="Ruleset"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>

                  <hr className="my-4" />

                  {state[state?.selectedType]?.type ===
                  DiscountRuleType.STRAIGHT ? (
                    <div className="mt-3">
                      <div>
                        <div className="text-grey font-medium">
                          Discount for subscription
                        </div>

                        <div className="w-3/4 mt-4">
                          <TextField
                            type="number"
                            variant="outlined"
                            value={
                              state[state?.selectedType]?.rule?.discountAmount
                            }
                            onWheel={(event: any) => event.target.blur()}
                            onChange={handleChangeDiscountAmount}
                            color="secondary"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                              style: {
                                width: 150,
                                height: 40,
                                borderRadius: 6,
                              },
                            }}
                          />
                        </div>
                      </div>

                      <hr className="my-4" />

                      <div>
                        {AvailableSchedules.map((schedule) => (
                          <div key={schedule?.value}>
                            <Checkbox
                              color="secondary"
                              name={schedule?.value}
                              checked={state?.allowedSchedules?.some(
                                (elem: string) => elem === schedule?.value
                              )}
                              onChange={handleChangeSchedule}
                            />
                            <span>{schedule?.label}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  ) : (
                    <div className="mt-3">
                      <div>
                        <div className="text-grey font-medium">
                          Up to Discount
                        </div>

                        <div className="w-3/4 mt-4">
                          <TextField
                            type="number"
                            variant="outlined"
                            value={
                              state[state?.selectedType]?.rule
                                ?.maxDiscountPercentage
                            }
                            onWheel={(event: any) => event.target.blur()}
                            onChange={(event) =>
                              handlePricingChange(
                                "maxDiscountPercentage",
                                event
                              )
                            }
                            color="secondary"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  %
                                </InputAdornment>
                              ),
                              style: {
                                width: 150,
                                height: 40,
                                borderRadius: 6,
                              },
                            }}
                          />
                        </div>
                      </div>

                      <hr className="my-4" />

                      <div className="mt-3">
                        <div className="text-grey font-medium">
                          For Up to Units
                        </div>

                        <div className="w-3/4 mt-4">
                          <TextField
                            type="number"
                            variant="outlined"
                            name="maxUnits"
                            value={state[state?.selectedType]?.rule?.maxUnits}
                            onWheel={(event: any) => event.target.blur()}
                            onChange={(event) =>
                              handlePricingChange("maxUnits", event)
                            }
                            color="secondary"
                            InputProps={{
                              style: {
                                width: 150,
                                height: 40,
                                borderRadius: 6,
                              },
                            }}
                          />
                        </div>
                      </div>

                      <hr className="my-4" />

                      <div className="flex justify-between items-start mt-3">
                        <div className="w-1/2">
                          <div className="text-center mb-4">Percentage Off</div>

                          <Slider
                            value={
                              typeof state[state?.selectedType]
                                ?.percentageOffOnPointScale === "number"
                                ? state[state?.selectedType]
                                    ?.percentageOffOnPointScale
                                : 0
                            }
                            onChange={handleChangePercentageOff}
                          />

                          <div className="text-center font-semibold">
                            {parseFloat(
                              state[state?.selectedType]?.percentageOff
                            ).toFixed(2)}
                            %
                          </div>
                        </div>

                        <div className="w-1/2 text-center">
                          <div className="mb-4">Units To Be Purchased</div>

                          <div>
                            <Input
                              value={
                                state[state?.selectedType]?.unitsToBePurchased
                              }
                              onChange={handleChangeUnitsToBePurchased}
                              inputProps={{
                                step: 10,
                                min: 0,
                                max: 100,
                                type: "number",
                              }}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              )}

              {state?.selectedType === PaymentType.BUY_MORE_PAY_LESS && (
                <div className="mt-3">
                  {state?.rules?.map((rule: any, index: number) => (
                    <div
                      key={rule?.id}
                      className="flex justify-between items-center mt-5"
                    >
                      <div className="w-full md:w-3/4 flex justify-between items-center">
                        <div>
                          <div className="text-grey font-medium">Units</div>

                          <div className="mt-2">
                            <TextField
                              type="number"
                              variant="outlined"
                              name="units"
                              value={state?.rules[index]?.units}
                              onWheel={(event: any) => event.target.blur()}
                              onChange={(event) =>
                                handleChangeRule(event, index)
                              }
                              color="secondary"
                              InputProps={{
                                style: {
                                  height: 40,
                                  borderRadius: 6,
                                },
                              }}
                              fullWidth
                            />
                          </div>
                        </div>

                        <div className="ml-2">
                          <div className="text-grey font-medium">
                            Discount percentage
                          </div>

                          <div className="mt-2">
                            <TextField
                              type="number"
                              variant="outlined"
                              name="discountPercentage"
                              value={state?.rules[index]?.discountPercentage}
                              onWheel={(event: any) => event.target.blur()}
                              onChange={(event) =>
                                handleChangeRule(event, index)
                              }
                              color="secondary"
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    %
                                  </InputAdornment>
                                ),
                                style: {
                                  height: 40,
                                  borderRadius: 6,
                                },
                              }}
                              fullWidth
                            />
                          </div>
                        </div>
                      </div>

                      <div className="w-1/4 flex justify-end mt-8">
                        {index === state?.rules?.length - 1 ? (
                          <IconButton onClick={handleAddRule}>
                            <AddIcon />
                          </IconButton>
                        ) : (
                          <IconButton onClick={() => handleRemoveRule(index)}>
                            <RemoveIcon />
                          </IconButton>
                        )}

                        {index !== 0 && index === state?.rules?.length - 1 && (
                          <IconButton
                            onClick={() => handleRemoveRule(index)}
                            sx={{ ml: 1 }}
                          >
                            <RemoveIcon />
                          </IconButton>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <div className="my-8">
              <div className="w-full">
                <Button
                  label={
                    state.isEditMode
                      ? "Edit Discount Rule"
                      : "Create Discount Rule"
                  }
                  color="secondary"
                  onClick={handleCreateDiscountRule}
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 50,
                  }}
                  fullWidth
                  loaderButton
                  loadingPosition="center"
                  loading={isLoading}
                  disabled={isLoading}
                />
              </div>

              {/* {state.isEditMode && (
                <div className="w-full mt-4">
                  <Button
                    label="Delete Product"
                    color="error"
                    onClick={handleOpenConfirmDeleteProductDialog}
                    style={{
                      borderRadius: 10,
                      fontSize: 14,
                      color: "#FFFFFF",
                      height: 50,
                    }}
                    fullWidth
                    loaderButton
                    loadingPosition="center"
                    loading={isLoading}
                    disabled={isLoading}
                  />
                </div>
              )} */}
            </div>
          </div>
        </div>
      </div>

      {state?.shouldShowConfirmDeleteDialog && (
        <ConfirmationDialog
          open={state?.shouldShowConfirmDeleteDialog}
          onClose={handleCloseConfirmDeleteDialog}
          onConfirm={handleConfirmDeleteDialog}
          title="Delete discount rule?"
          message="Are you sure you want to delete this discount rule? This is an irreversible step."
          confirmButtonLabel="Delete"
        />
      )}
    </div>
  );
}

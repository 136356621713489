import { useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import Divider from "@mui/material/Divider";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { useMergeState } from "utils/custom-hooks";
import { formatDate } from "utils/date";
import { getCustomerOrderDetails } from "api";
import { humanizeSchedule } from "enums/PaymentSchedules";

export default function CustomerOrderReceiptContainer() {
  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("id") || "";

  const [state, setState] = useMergeState({
    merchant: {},
    customer: {},
    cart: {
      items: [],
    },
    createdAt: "",
  });

  const groupByItems = (items: any) => {
    const products: any = [];

    items.forEach((item: any) => {
      let quantity = 0;

      const index = products.findIndex(
        (product: any) => product?._id === item?._id
      );

      if (index !== -1) {
        products[index].quantity += 1;
      } else {
        quantity = 1;
        products.push({ item, quantity });
      }
    });

    console.log("products : ", products);

    return products;
  };

  const getTotalAmount = () => {
    let total = 0;

    const items = groupByItems(state?.cart?.items);

    items?.forEach((elem: any) => {
      total += elem?.item?.price?.totalAmount + elem?.item?.price?.tax;
    });

    return +Number(total).toFixed(2);
  };

  const getTitle = (title: string) => {
    if (title.indexOf("Subscription") !== 0) {
      return `${title} Subscription`;
    }

    return title;
  };

  useEffect(() => {
    const init = async () => {
      try {
        const orderDetails = await getCustomerOrderDetails({ id: orderId });

        setState({
          merchant: orderDetails?.data?.merchant,
          customer: orderDetails?.data?.customer,
          cart: orderDetails?.data?.cart,
          createdAt: orderDetails?.data?.createdAt,
        });
      } catch (error) {
        console.error("error : ", error);
      }
    };

    init();
  }, []);

  return (
    <div className="p-5">
      <div>
        <Divider>Receipt</Divider>
      </div>

      <div className="flex justify-between mt-8">
        <div>
          <div className="text-lg font-semibold">
            {state?.merchant?.businessName}
          </div>
          <div>
            {state?.merchant?.addressLine1}, {state?.merchant?.city}
          </div>
          <div>
            {state?.merchant?.state} {state?.merchant?.zipcode}
          </div>
        </div>

        <div className="w-1/4 text-left">
          <table>
            <tr>
              <th>Invoice # :</th>
              <td>{String(orderId).substr(orderId.length - 7)}</td>
            </tr>
            <tr>
              <th>Invoice Date :</th>
              <td>{formatDate(state?.createdAt, "MM-DD-YYYY")}</td>
            </tr>
            {/* <tr>
              <th>Due Date :</th>
              <td>10-01-2018</td>
            </tr> */}
          </table>
        </div>
      </div>

      <div className="mt-12">
        <div>
          Bill To:{" "}
          <span className="font-semibold">{state?.customer?.name}</span>
        </div>

        {state?.customer?.user?.address && (
          <div>
            <div>
              {state?.customer?.user?.address?.addressLine1},{" "}
              {state?.customer?.user?.address?.city}
            </div>
            <div>
              {state?.customer?.user?.address?.state}{" "}
              {state?.customer?.user?.address?.zipcode}
            </div>
          </div>
        )}
      </div>

      <div style={{ marginTop: 48 }}>
        <TableContainer className="mt-10">
          <Table sx={{ minWidth: 750 }}>
            <TableHead>
              <TableRow>
                <TableCell>
                  <span className="text-grey">Item</span>
                </TableCell>
                <TableCell>
                  <span className="text-grey">Period</span>
                </TableCell>
                <TableCell>
                  <span className="text-grey">Variation</span>
                </TableCell>
                <TableCell>
                  <span className="text-grey">Quantity</span>
                </TableCell>
                {/* <TableCell>
                  <span className="text-grey">Discount</span>
                </TableCell> */}
                <TableCell>
                  <span className="text-grey">Price</span>
                </TableCell>
                <TableCell>
                  <span className="text-grey">Tax</span>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody style={{ overflow: "visible" }}>
              {groupByItems(state?.cart?.items).map(
                ({ item, quantity }: any, index: number) => (
                  <TableRow key={`${item?.product?._id}-${index}`}>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{getTitle(item?.title)}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">
                        {humanizeSchedule(item?.price?.schedule)}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">
                        {item?.price?.variation?.name}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">
                        {!item?.price?.planType
                          ? item?.price?.quantity
                          : quantity}
                      </span>
                    </TableCell>
                    {/* <TableCell component="th" scope="row">
                    <span className="text-grey">
                      {item?.price?.discount || "-"}
                    </span>
                  </TableCell> */}
                    <TableCell component="th" scope="row">
                      <span className="text-grey">
                        {item?.price?.totalAmount}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{item?.price?.tax}</span>
                    </TableCell>
                  </TableRow>
                )
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>

      <div className="flex justify-end mt-12">
        <div className="w-1/4 text-left">
          <table>
            <tr>
              <th>Total : </th>
              <td>
                {" "}
                <span className="ml-2">${getTotalAmount()}</span>
              </td>
            </tr>
            {/* <tr>
              <th>IGST @6% :</th>
              <td>Rs. 95.94</td>
            </tr>
            <tr>
              <th>CGST @6% :</th>
              <td>Rs. 95.94</td>
            </tr> */}
            {/* <tr>
              <th>Net Total :</th>
              <td>Rs. 1790.88</td>
            </tr> */}
          </table>
        </div>
      </div>
    </div>
  );
}

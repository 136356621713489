import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import Link from "@mui/material/Link";
import Spinner from "components/Spinner";
import AddNewPaymentMethod from "./AddNewPaymentMethod";
import {
  customerSettingsFetchPaymentMethodsSaga,
  customerSettingsInitiateSetupIntentSaga,
} from "../state/actions";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";

export default function CustomerSettingsPaymentMethodsContainer() {
  const dispatch = useDispatch();

  const { user } = useSelector((store: any) => store?.customerAuth);

  const {
    isLoading,
    paymentMethods,
    addPaymentMethodConfig: { isAddingNewPaymentMethod, clientSecret },
  } = useSelector((store: any) => store?.customerSettings);

  const [state, setState] = useMergeState({
    stripePromise: null,
  });

  const handleAddNewPaymentMethod = () => {
    dispatch(
      customerSettingsInitiateSetupIntentSaga({
        platformCustomerId: user?.customer?.stripeMetadata?.platformCustomerId,
      })
    );
  };

  useEffect(() => {
    dispatch(customerSettingsFetchPaymentMethodsSaga());
  }, []);

  useEffect(() => {
    if (isAddingNewPaymentMethod && clientSecret) {
      setState({
        stripePromise: loadStripe(
          process.env.REACT_APP_STRIPE_PUBLIC_KEY || ""
        ),
      });
    }
  }, [clientSecret]);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="mt-8">
            <div className="mb-8">
              {!isAddingNewPaymentMethod && (
                <Link // eslint-disable-line
                  component="button"
                  variant="body1"
                  onClick={handleAddNewPaymentMethod}
                >
                  Add new payment method
                </Link>
              )}

              {isAddingNewPaymentMethod &&
                clientSecret &&
                state?.stripePromise && (
                  <div className="lg:w-1/2">
                    <Elements
                      stripe={state?.stripePromise}
                      options={{
                        clientSecret,
                        appearance: {},
                      }}
                    >
                      <AddNewPaymentMethod />
                    </Elements>
                  </div>
                )}
            </div>

            {!isAddingNewPaymentMethod &&
              paymentMethods?.cards?.map((card: any, index: number) => (
                <div key={card?.id}>
                  <hr />
                  <div className="flex flex-col justify-center h-16">
                    <div className="flex items-center text-grey">
                      <img src={Images.CaretRight} className="mx-2" />

                      <div className="text-lg">
                        {String(card?.brand).toUpperCase()}
                      </div>

                      <div className="ml-4 text-sm">
                        <div className="font-semibold">
                          XXXX {card?.lastFourDigits}
                        </div>{" "}
                        <div>
                          Expires ({card?.expiryMonth}/{card?.expiryYear})
                        </div>
                      </div>
                    </div>
                  </div>

                  {paymentMethods?.cards?.length === index + 1 && <hr />}
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_SETTINGS_SET_IS_LOADING,
  MERCHANT_SETTINGS_PAYMENT_PROCESSORS_SET_ACCOUNT_DETAILS,
  MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_CONFIG,
  MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_PROFILE_DETAILS,
  MERCHANT_SETTINGS_TEAMS_SET_DETAILS,
  MERCHANT_SETTINGS_TEAMS_SET_INVITE_MEMBER_DIALOG_CONFIG,
} from "./types";

type MerchantSettingsStateType = {
  isLoading: boolean;
  stripe: any;
  square: any;
  taxes: Array<any>;
  businessProfileconfig: {
    shouldEditBusinessDetails: Boolean;
    isSavingBusinessDetails: Boolean;
    shouldEditMemberDetails: Boolean;
    isSavingMemberDetails: Boolean;
  };
  businessProfile: any;
  members: Array<any>;
  inviteMemberDialogConfig: {
    open: boolean;
    isInvitingMembers: boolean;
  };
};

const initialState: MerchantSettingsStateType = {
  isLoading: false,
  stripe: null,
  square: null,
  taxes: [],
  businessProfileconfig: {
    shouldEditBusinessDetails: false,
    isSavingBusinessDetails: false,
    shouldEditMemberDetails: false,
    isSavingMemberDetails: false,
  },
  businessProfile: {},
  members: [],
  inviteMemberDialogConfig: {
    open: false,
    isInvitingMembers: false,
  },
};

const merchantSettingsReducer = (
  state: MerchantSettingsStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_SETTINGS_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_SETTINGS_PAYMENT_PROCESSORS_SET_ACCOUNT_DETAILS:
      return { ...state, ...action.payload };

    case MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_CONFIG:
      return {
        ...state,
        businessProfileconfig: {
          ...state.businessProfileconfig,
          ...action.payload,
        },
      };

    case MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_PROFILE_DETAILS:
      return { ...state, businessProfile: action.payload };

    case MERCHANT_SETTINGS_TEAMS_SET_DETAILS:
      return { ...state, members: action.payload };

    case MERCHANT_SETTINGS_TEAMS_SET_INVITE_MEMBER_DIALOG_CONFIG:
      return {
        ...state,
        inviteMemberDialogConfig: {
          ...state.inviteMemberDialogConfig,
          ...action.payload,
        },
      };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantSettingsReducer;

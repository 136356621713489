import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  getMerchantProducts,
  getMerchantProductById,
  createMerchantProduct,
  updateMerchantProduct,
  deleteMerchantProduct,
  getMerchantProductsModifiers,
  getMerchantProductModifierById,
  createMerchantProductModifier,
  updateMerchantProductModifier,
  getMerchantProductsDiscountRules,
  getMerchantProductDiscountRuleById,
  createMerchantProductDiscountRule,
  updateMerchantProductDiscountRule,
  cloneMerchantProduct,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_PRODUCTS_FETCH_SAGA,
  MERCHANT_PRODUCTS_FETCH_SELECTED_PRODUCT_SAGA,
  MERCHANT_PRODUCTS_CREATE_SAGA,
  MERCHANT_PRODUCTS_UPDATE_SAGA,
  MERCHANT_PRODUCTS_CLONE_SAGA,
  MERCHANT_PRODUCTS_DELETE_SAGA,
  MERCHANT_PRODUCTS_MODIFIERS_FETCH_SAGA,
  MERCHANT_PRODUCTS_MODIFIERS_FETCH_SELECTED_MODIFIER_SAGA,
  MERCHANT_PRODUCTS_MODIFIERS_CREATE_SAGA,
  MERCHANT_PRODUCTS_MODIFIERS_UPDATE_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SELECTED_RULE_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_CREATE_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_UPDATE_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantProductsSetIsLoading,
  merchantProductsSetProducts,
  merchantProductsSetSelectedProduct,
  merchantProductsModifiersSetModifiers,
  merchantProductsModifiersSetSelectedModifier,
  merchantProductsDiscountRulesSetRules,
  merchantProductsDiscountRulesSetSelectedRule,
} from "./actions";

function* merchantProductsFetchHandler(): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(getMerchantProducts);

    if (response?.success) {
      yield put(merchantProductsSetProducts(response?.data));
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsFetchSelectedProductHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(getMerchantProductById, action?.payload);

    if (response?.success) {
      yield put(merchantProductsSetSelectedProduct(response?.data));
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsCreateHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(createMerchantProduct, action.payload);

    if (response?.success) {
      action.navigate("/products");
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsUpdateHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(updateMerchantProduct, action.payload);

    if (response?.success) {
      yield all([
        yield put(merchantProductsSetSelectedProduct(response?.data)),
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
      ]);
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsCloneHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(cloneMerchantProduct, action.payload);

    if (response?.success) {
      action.navigate("/products");
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsDeleteHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(deleteMerchantProduct, action.payload);

    if (response?.success) {
      action.navigate("/products");
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsModifiersFetchHandler(): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(getMerchantProductsModifiers);

    if (response?.success) {
      yield put(merchantProductsModifiersSetModifiers(response?.data));
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsModifiersFetchSelectedModifierHandler(
  action: any
): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(
      getMerchantProductModifierById,
      action?.payload
    );

    if (response?.success) {
      yield put(merchantProductsModifiersSetSelectedModifier(response?.data));
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsModifiersCreateHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(createMerchantProductModifier, action.payload);

    if (response?.success) {
      action.navigate("/products/modifiers");
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsModifiersUpdateHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(updateMerchantProductModifier, action.payload);

    if (response?.success) {
      yield all([
        yield put(merchantProductsModifiersSetSelectedModifier(response?.data)),
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
      ]);
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsDiscountRulesFetchHandler(): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(getMerchantProductsDiscountRules);

    if (response?.success) {
      yield put(merchantProductsDiscountRulesSetRules(response?.data));
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsDiscountRulesFetchSelectedRuleHandler(
  action: any
): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(
      getMerchantProductDiscountRuleById,
      action?.payload
    );

    if (response?.success) {
      yield put(merchantProductsDiscountRulesSetSelectedRule(response?.data));
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsDiscountRulesCreateHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(
      createMerchantProductDiscountRule,
      action.payload
    );

    if (response?.success) {
      action.navigate("/products/discount-rules");
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantProductsDiscountRulesUpdateHandler(action: any): any {
  try {
    yield put(merchantProductsSetIsLoading(true));

    const response = yield call(
      updateMerchantProductDiscountRule,
      action.payload
    );

    if (response?.success) {
      yield all([
        yield put(merchantProductsDiscountRulesSetSelectedRule(response?.data)),
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
      ]);
    }

    yield put(merchantProductsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantProductsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantProducts() {
  yield takeLatest(MERCHANT_PRODUCTS_FETCH_SAGA, merchantProductsFetchHandler);
  yield takeLatest(
    MERCHANT_PRODUCTS_FETCH_SELECTED_PRODUCT_SAGA,
    merchantProductsFetchSelectedProductHandler
  );
  yield takeLatest(
    MERCHANT_PRODUCTS_CREATE_SAGA,
    merchantProductsCreateHandler
  );
  yield takeLatest(
    MERCHANT_PRODUCTS_UPDATE_SAGA,
    merchantProductsUpdateHandler
  );
  yield takeLatest(MERCHANT_PRODUCTS_CLONE_SAGA, merchantProductsCloneHandler);
  yield takeLatest(
    MERCHANT_PRODUCTS_DELETE_SAGA,
    merchantProductsDeleteHandler
  );

  yield takeLatest(
    MERCHANT_PRODUCTS_MODIFIERS_FETCH_SAGA,
    merchantProductsModifiersFetchHandler
  );
  yield takeLatest(
    MERCHANT_PRODUCTS_MODIFIERS_FETCH_SELECTED_MODIFIER_SAGA,
    merchantProductsModifiersFetchSelectedModifierHandler
  );
  yield takeLatest(
    MERCHANT_PRODUCTS_MODIFIERS_CREATE_SAGA,
    merchantProductsModifiersCreateHandler
  );
  yield takeLatest(
    MERCHANT_PRODUCTS_MODIFIERS_UPDATE_SAGA,
    merchantProductsModifiersUpdateHandler
  );

  yield takeLatest(
    MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SAGA,
    merchantProductsDiscountRulesFetchHandler
  );
  yield takeLatest(
    MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SELECTED_RULE_SAGA,
    merchantProductsDiscountRulesFetchSelectedRuleHandler
  );
  yield takeLatest(
    MERCHANT_PRODUCTS_DISCOUNT_RULES_CREATE_SAGA,
    merchantProductsDiscountRulesCreateHandler
  );
  yield takeLatest(
    MERCHANT_PRODUCTS_DISCOUNT_RULES_UPDATE_SAGA,
    merchantProductsDiscountRulesUpdateHandler
  );
}

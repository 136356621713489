import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  getMerchantDiscounts,
  getMerchantDiscountById,
  createMerchantDiscount,
  updateMerchantDiscount,
  inviteCustomerMerchantDiscount,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_DISCOUNTS_FETCH_SAGA,
  MERCHANT_DISCOUNTS_FETCH_SELECTED_DISCOUNT_SAGA,
  MERCHANT_DISCOUNTS_CREATE_SAGA,
  MERCHANT_DISCOUNTS_UPDATE_SAGA,
  MERCHANT_DISCOUNTS_INVITE_CUSTOMER_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantDiscountsSetIsLoading,
  merchantDiscountsSetDiscounts,
  merchantDiscountsSetSelectedDiscount,
} from "./actions";

function* merchantDiscountsFetchHandler(): any {
  try {
    yield put(merchantDiscountsSetIsLoading(true));

    const response = yield call(getMerchantDiscounts);

    if (response?.success) {
      yield put(merchantDiscountsSetDiscounts(response?.data));
    }

    yield put(merchantDiscountsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantDiscountsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantDiscountsFetchSelectedDiscountHandler(action: any): any {
  try {
    yield put(merchantDiscountsSetIsLoading(true));

    const response = yield call(getMerchantDiscountById, action?.payload);

    if (response?.success) {
      yield put(merchantDiscountsSetSelectedDiscount(response?.data));
    }

    yield put(merchantDiscountsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantDiscountsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantDiscountsCreateHandler(action: any): any {
  try {
    yield put(merchantDiscountsSetIsLoading(true));

    const response = yield call(createMerchantDiscount, action.payload);

    if (response?.success) {
      action.navigate("/customers/discounts");
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(merchantDiscountsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantDiscountsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantDiscountsUpdateHandler(action: any): any {
  try {
    yield put(merchantDiscountsSetIsLoading(true));

    const response = yield call(updateMerchantDiscount, action?.payload);

    if (response?.success) {
      yield all([
        yield put(merchantDiscountsSetSelectedDiscount(response?.data)),
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
      ]);
    }

    yield put(merchantDiscountsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantDiscountsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantDiscountsInviteCustomerHandler(action: any): any {
  try {
    yield put(merchantDiscountsSetIsLoading(true));

    const response = yield call(
      inviteCustomerMerchantDiscount,
      action?.payload
    );

    if (response?.success) {
      yield all([
        yield put(merchantDiscountsSetSelectedDiscount(response?.data)),
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
      ]);
    }

    yield put(merchantDiscountsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantDiscountsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantDiscounts() {
  yield takeLatest(
    MERCHANT_DISCOUNTS_FETCH_SAGA,
    merchantDiscountsFetchHandler
  );
  yield takeLatest(
    MERCHANT_DISCOUNTS_FETCH_SELECTED_DISCOUNT_SAGA,
    merchantDiscountsFetchSelectedDiscountHandler
  );
  yield takeLatest(
    MERCHANT_DISCOUNTS_CREATE_SAGA,
    merchantDiscountsCreateHandler
  );
  yield takeLatest(
    MERCHANT_DISCOUNTS_UPDATE_SAGA,
    merchantDiscountsUpdateHandler
  );
  yield takeLatest(
    MERCHANT_DISCOUNTS_INVITE_CUSTOMER_SAGA,
    merchantDiscountsInviteCustomerHandler
  );
}

import { useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import MerchantNavBar from "components/MerchantNavBar";
import MerchantMobileNavBar from "components/MerchantMobileNavBar";

const shouldRenderNavBar = () => {
  if (
    window.location.pathname.includes("/signup") ||
    window.location.pathname.includes("/login") ||
    window.location.pathname.includes("/setup") ||
    window.location.pathname.includes("/forgot-password") ||
    window.location.pathname.includes("/reset-password") ||
    window.location.pathname.includes("/reset-password-check-expiry") ||
    window.location.pathname.includes("/verify-email") ||
    window.location.pathname.includes("/onboarding")
  ) {
    return false;
  }

  return true;
};

export default function MerchantDefaultLayout() {
  const { isLoggedIn } = useSelector((store: any) => store?.merchantAuth);

  return (
    <div className="lg:flex">
      {isLoggedIn && shouldRenderNavBar() && (
        <div className="hidden lg:block w-1/4">
          <MerchantNavBar />
        </div>
      )}

      {isLoggedIn && shouldRenderNavBar() && (
        <div className="md:block lg:hidden">
          <MerchantMobileNavBar />
        </div>
      )}

      <div className="w-full min-h-screen sm:h-screen overflow-y-scroll overflow-x-scroll p-4 lg:px-12 lg:py-14">
        <Outlet />
      </div>
    </div>
  );
}

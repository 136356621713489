import { RESET_APP_STATE } from "app/state/types";
import { CUSTOMER_ADD_NEW_CARD_DIALOG_SET_CONFIG } from "./types";

type AddNewCardDialogStateType = {
  config: {
    open: boolean;
    isSavingCardDetails: boolean;
    isCreatingSetupIntent: boolean;
    clientSecret: string;
  };
};

const initialState: AddNewCardDialogStateType = {
  config: {
    open: false,
    isSavingCardDetails: false,
    isCreatingSetupIntent: false,
    clientSecret: "",
  },
};

const customerAddNewCardDialogReducer = (
  state: AddNewCardDialogStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CUSTOMER_ADD_NEW_CARD_DIALOG_SET_CONFIG:
      return {
        ...state,
        config: {
          ...state?.config,
          ...action?.payload,
        },
      };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default customerAddNewCardDialogReducer;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { merchantProductsDiscountRulesFetchSaga } from "../state/actions";

export default function MerchantProductsDiscountRulesContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, discountRules } = useSelector(
    (store: any) => store?.merchantProducts
  );

  const handleOpenDetails = (product: any) => {
    navigate(`/products/discount-rules/create?id=${product?._id}`);
  };

  const handleCreate = () => {
    navigate("/products/discount-rules/create");
  };

  useEffect(() => {
    dispatch(merchantProductsDiscountRulesFetchSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="md:flex md:justify-between md:items-center">
            <div className="text-4xl font-semibold text-grey">
              Your Discount Rules
            </div>

            <div className="mt-4 md:mt-0">
              <Button
                label="Create New Discount Rule"
                color="secondary"
                onClick={handleCreate}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  fontWeight: 500,
                  height: 40,
                }}
              />
            </div>
          </div>

          {!discountRules.length && (
            <div className="mt-4">
              <div className="text-xl">
                Create your first discount rule to get started
              </div>

              <div className="w-3/4 mt-4">
                <Button
                  label="Create A Discount Rule To Get Started"
                  color="secondary"
                  onClick={handleCreate}
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 50,
                  }}
                  fullWidth
                />
              </div>
            </div>
          )}

          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-8 mt-6">
            {discountRules.map((rule: any) => (
              <div
                key={rule?._id}
                className="bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md cursor-pointer mb-4 md:mb-0"
                onClick={() => handleOpenDetails(rule)}
              >
                <div className="flex justify-between">
                  <div className="my-2">
                    <div className="text-grey font-semibold text-lg">
                      {rule?.title}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import {
  CUSTOMER_CART_SET_CONFIG,
  CUSTOMER_CART_SET_CART,
  CUSTOMER_CART_ADD_TO_CART,
  CUSTOMER_CART_REMOVE_FROM_CART,
  CUSTOMER_CART_SET_TAXES,
  CUSTOMER_CART_FETCH_SAGA,
  CUSTOMER_CART_SAVE_SAGA,
  CUSTOMER_CART_BUY_SAGA,
  CUSTOMER_CART_FETCH_TAXES_SAGA,
} from "./types";

export const customerCartSetConfig = (payload: any) => ({
  type: CUSTOMER_CART_SET_CONFIG,
  payload,
});

export const customerCartSetCart = (payload: any) => ({
  type: CUSTOMER_CART_SET_CART,
  payload,
});

export const customerCartAddToCart = (payload: any) => ({
  type: CUSTOMER_CART_ADD_TO_CART,
  payload,
});

export const customerCartRemoveFromCart = (payload: any) => ({
  type: CUSTOMER_CART_REMOVE_FROM_CART,
  payload,
});

export const customerCartSetTaxes = (payload: any) => ({
  type: CUSTOMER_CART_SET_TAXES,
  payload,
});

// async

export const customerCartFetchSaga = (payload: any) => ({
  type: CUSTOMER_CART_FETCH_SAGA,
  payload,
});

export const customerCartSaveSaga = (
  payload: any,
  params: { shouldRedirect: boolean; isCorporatePlan?: boolean }
) => ({
  type: CUSTOMER_CART_SAVE_SAGA,
  payload,
  params,
});

export const customerCartBuySaga = (
  payload: any,
  navigate: any,
  event?: any
) => ({
  type: CUSTOMER_CART_BUY_SAGA,
  payload,
  navigate,
  event,
});

export const customerCartFetchTaxes = (payload: any) => ({
  type: CUSTOMER_CART_FETCH_TAXES_SAGA,
  payload,
});

import { RESET_APP_STATE } from "app/state/types";
import { CUSTOMER_CONFIRM_ADD_PAYMENT_METHOD_SET_IS_LOADING } from "./types";

type CustomerConfirmAddPaymentMethodStateType = {
  isLoading: boolean;
};

const initialState: CustomerConfirmAddPaymentMethodStateType = {
  isLoading: false,
};

const customerConfirmAddPaymentMethodReducer = (
  state: CustomerConfirmAddPaymentMethodStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CUSTOMER_CONFIRM_ADD_PAYMENT_METHOD_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default customerConfirmAddPaymentMethodReducer;

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Spinner from "components/Spinner";
import { formatDate } from "utils/date";
import { merchantCustomersFetchSaga } from "./state/actions";

export default function MerchantCustomersContainer() {
  const dispatch = useDispatch();

  const { isLoading, customers } = useSelector(
    (store: any) => store?.merchantCustomers
  );

  useEffect(() => {
    dispatch(merchantCustomersFetchSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey">Customers</div>

          <TableContainer className="mt-10">
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="text-grey">#</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Name</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Email</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Phone</span>
                  </TableCell>
                  <TableCell>
                    <span className="text-grey">Joined On</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "visible" }}>
                {customers.map((customer: any, index: number) => (
                  <TableRow key={customer._id}>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{index + 1}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{customer?.name}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{customer?.email}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">{customer?.phone}</span>
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <span className="text-grey">
                        {formatDate(customer?.createdAt, "lll")}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </div>
      )}
    </div>
  );
}

export const RESET_APP_STATE = "RESET_APP_STATE";
export const CUSTOMER_SUBSCRIPTIONS_SET_IS_LOADING =
  "CUSTOMER_SUBSCRIPTIONS_SET_IS_LOADING";
export const CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTIONS =
  "CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTIONS";
export const CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTION_DETAILS =
  "CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTION_DETAILS";
export const CUSTOMER_SUBSCRIPTIONS_SET_ORDERS =
  "CUSTOMER_SUBSCRIPTIONS_SET_ORDERS";

// async

export const CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SAGA =
  "CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTIONS_SAGA";
export const CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTION_DETAILS_SAGA =
  "CUSTOMER_SUBSCRIPTIONS_FETCH_SUBSCRIPTION_DETAILS_SAGA";
export const CUSTOMER_SUBSCRIPTIONS_PAUSE_SAGA =
  "CUSTOMER_SUBSCRIPTIONS_PAUSE_SAGA";
export const CUSTOMER_SUBSCRIPTIONS_CANCEL_SAGA =
  "CUSTOMER_SUBSCRIPTIONS_CANCEL_SAGA";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Button from "components/Button";
import Spinner from "components/Spinner";
import Images from "assets/images";
import { truncateString, renderVariationString } from "utils/string";
import {
  merchantCommunityCartFetchMasterCartSaga,
  merchantCommunityCartAddToCartSaga,
} from "../state/actions";

export default function MerchantCommunityCartCreateContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, master: cart } = useSelector(
    (store: any) => store?.merchantCommunityCart
  );

  const handleAddToCart = (productId: string) => {
    dispatch(
      merchantCommunityCartAddToCartSaga({ product: productId }, navigate)
    );
  };

  useEffect(() => {
    dispatch(merchantCommunityCartFetchMasterCartSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey mb-4">
            Choose Your Cart
          </div>

          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-8 mt-6">
            {cart.map((elem: any) => (
              <div
                key={elem?.product?._id}
                className="bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md mb-4 md:mb-0"
              >
                <div className="flex justify-between">
                  <div className="my-2">
                    <div className="text-grey font-semibold text-lg">
                      {elem?.product?.title}
                    </div>

                    <div className="text-grey text-sm mt-4">
                      {truncateString(elem?.product?.description, 50)}
                    </div>

                    <div className="hidden md:block text-grey text-sm italic mt-4">
                      {renderVariationString(elem?.product?.variations)}
                    </div>

                    <div className="mt-2">
                      <Button
                        label="Add to Cart"
                        color="info"
                        onClick={() => handleAddToCart(elem?.product?._id)}
                        style={{
                          borderRadius: 100,
                          fontSize: 12,
                          color: "#82A885",
                          background: "#EAF6EF",
                          fontWeight: 700,
                          height: 24,
                        }}
                      />
                    </div>
                  </div>

                  <img
                    src={elem?.product?.images[0] || Images.NoImagePlaceholder}
                    className="w-36 h-42 rounded-r-md object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import {
  MERCHANT_ALERTS_SET_IS_LOADING,
  MERCHANT_ALERTS_SET_ALERTS,
  MERCHANT_ALERTS_FETCH_SAGA,
} from "./types";

export const merchantAlertsSetIsLoading = (payload: any) => ({
  type: MERCHANT_ALERTS_SET_IS_LOADING,
  payload,
});

export const merchantAlertsSetAlerts = (payload: any) => ({
  type: MERCHANT_ALERTS_SET_ALERTS,
  payload,
});

// async

export const merchantAlertsFetchSaga = (payload: { type: string }) => ({
  type: MERCHANT_ALERTS_FETCH_SAGA,
  payload,
});

import { takeLatest, put, all, call } from "redux-saga/effects";
import { getMerchantInvitations, sendMerchantInvitation } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_INVITATIONS_FETCH_INVITATIONS_SAGA,
  MERCHANT_INVITATIONS_SEND_INVITATIONS_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantInvitationsSetIsLoading,
  merchantInvitationsSetInvitations,
  merchantInvitationsSetInviteCustomerDialogConfig,
} from "./actions";

function* merchantInvitationsFetchHandler(): any {
  try {
    yield put(merchantInvitationsSetIsLoading(true));

    const response = yield call(getMerchantInvitations);

    if (response?.success) {
      yield put(merchantInvitationsSetInvitations(response?.data));
    }

    yield put(merchantInvitationsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantInvitationsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantInvitationsSendHandler(action: any): any {
  try {
    yield put(
      merchantInvitationsSetInviteCustomerDialogConfig({ isInviting: true })
    );

    const response = yield call(sendMerchantInvitation, action?.payload);

    if (response?.success) {
      yield all([
        yield put(
          merchantInvitationsSetInviteCustomerDialogConfig({
            open: false,
            isInviting: false,
          })
        ),
        yield put(
          appSetError({
            severity: MESSAGE_SEVERITY.SUCCESS,
            message: response?.message,
          })
        ),
        yield merchantInvitationsFetchHandler(),
      ]);
    }
  } catch (error: any) {
    yield all([
      yield put(
        merchantInvitationsSetInviteCustomerDialogConfig({
          open: false,
          isInviting: false,
        })
      ),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantInvitations() {
  yield takeLatest(
    MERCHANT_INVITATIONS_FETCH_INVITATIONS_SAGA,
    merchantInvitationsFetchHandler
  );
  yield takeLatest(
    MERCHANT_INVITATIONS_SEND_INVITATIONS_SAGA,
    merchantInvitationsSendHandler
  );
}

import { useDispatch, useSelector } from "react-redux";
import { useStripe, useElements, CardElement } from "@stripe/react-stripe-js";
import Button from "components/Button";
import CardSection from "./CardSection";
import { customerAddNewCardDialogSaveCardDetailsSaga } from "./state/actions";

type Props = {
  clientSecret: string;
  isTextToPay: boolean;
  flow: string;
};

export default function CardSetupForm(props: Props) {
  const { clientSecret = "", isTextToPay, flow } = props;

  const stripe = useStripe();

  const elements = useElements();

  const dispatch = useDispatch();

  const {
    user: {
      customer: {
        stripeMetadata: { platformCustomerId },
      },
    },
  } = useSelector((store: any) => store?.customerAuth);

  const {
    config: { isSavingCardDetails },
  } = useSelector((store: any) => store?.customerAddNewDialog);

  const handleSaveCard = async () => {
    if (!stripe || !elements || !clientSecret || !platformCustomerId) {
      return;
    }

    dispatch(
      customerAddNewCardDialogSaveCardDetailsSaga({
        stripe,
        elements,
        CardElement,
        clientSecret,
        platformCustomerId,
        isTextToPay,
        flow,
      })
    );
  };

  const isLoading =
    !stripe || !elements || !clientSecret || isSavingCardDetails;

  return (
    <div>
      <div className="mb-10">
        <CardSection />
      </div>

      <div className="flex justify-end mt-10">
        <Button
          label="Save Card"
          color="info"
          onClick={handleSaveCard}
          style={{
            borderRadius: 100,
            fontSize: 14,
            color: "#FFFFFF",
            height: 40,
            width: 120,
          }}
          loaderButton
          loadingPosition="center"
          loading={isLoading}
          disabled={isLoading}
        />
      </div>
    </div>
  );
}

import {
  CUSTOMER_ADD_NEW_CARD_DIALOG_SET_CONFIG,
  CUSTOMER_ADD_NEW_CARD_DIALOG_CREATE_SETUP_INTENT_SAGA,
  CUSTOMER_ADD_NEW_CARD_DIALOG_SAVE_CARD_DETAILS_SAGA,
} from "./types";

export const customerAddNewCardDialogSetConfig = (payload: any) => ({
  type: CUSTOMER_ADD_NEW_CARD_DIALOG_SET_CONFIG,
  payload,
});

// async

export const customerAddNewCardDialogCreateSetupIntentSaga = (
  payload: any
) => ({
  type: CUSTOMER_ADD_NEW_CARD_DIALOG_CREATE_SETUP_INTENT_SAGA,
  payload,
});

export const customerAddNewCardDialogSaveCardDetailsSaga = (payload: any) => ({
  type: CUSTOMER_ADD_NEW_CARD_DIALOG_SAVE_CARD_DETAILS_SAGA,
  payload,
});

import { all } from "redux-saga/effects";
import appSaga from "app/state/sagas";

// components

import customerCartSaga from "components/Customer/Cart/state/sagas";
import customerAddNewCardDialogSaga from "components/Customer/AddNewCardDialog/state/sagas";
import orderDialogSaga from "components/Customer/OrderDialog/state/sagas";
import orderDetailsDrawerSaga from "components/OrderDetailsDrawer/state/sagas";

// customer
import customerAuthSaga from "containers/consumer/auth/state/sagas";
import customerDashboardSaga from "containers/consumer/dashboard/state/sagas";
import customerCheckoutSaga from "containers/consumer/checkout/state/sagas";
import customerSubscriptionSaga from "containers/consumer/subscription/state/sagas";
import publicBusinessPageSaga from "components/PublicBusinessPage/state/sagas";
import customerConfirmAddPaymentMethodSaga from "containers/consumer/confirm-add-payment-method/state/sagas";
import customerSettingsSaga from "containers/consumer/settings/state/sagas";
import consumerOrdersSaga from "containers/consumer/orders/state/sagas";
import consumerPlansSaga from "containers/consumer/plans/state/sagas";
import consumerTextToPaySaga from "containers/consumer/text-to-pay/state/sagas";

// merchant
import merchantAuthSaga from "containers/merchant/auth/state/sagas";
import merchantDashboardSaga from "containers/merchant/dashboard/state/sagas";
import merchantPlansSaga from "containers/merchant/plans/state/sagas";
import merchantProductsSaga from "containers/merchant/products/state/sagas";
import merchantCommunityCartSaga from "containers/merchant/community-cart/state/sagas";
import merchantSettingsSaga from "containers/merchant/settings/state/sagas";
import merchantUserProfileSaga from "containers/merchant/profile/state/sagas";
import merchantConnectSaga from "containers/merchant/connect/state/sagas";
import merchantOrdersSaga from "containers/merchant/orders/state/sagas";
import merchantAlertsSaga from "containers/merchant/alerts/state/sagas";
import merchantInvitationsSaga from "containers/merchant/invitations/state/sagas";
import merchantCustomersSaga from "containers/merchant/customers/state/sagas";
import merchantDiscountsSaga from "containers/merchant/discounts/state/sagas";

export default function* rootSaga() {
  yield all([
    // components
    customerCartSaga(),
    customerAddNewCardDialogSaga(),
    orderDialogSaga(),
    orderDetailsDrawerSaga(),

    // containers
    appSaga(),

    // customer
    customerAuthSaga(),
    customerDashboardSaga(),
    customerCheckoutSaga(),
    customerSubscriptionSaga(),
    publicBusinessPageSaga(),
    customerConfirmAddPaymentMethodSaga(),
    customerSettingsSaga(),
    consumerOrdersSaga(),
    consumerPlansSaga(),
    consumerTextToPaySaga(),

    // merchant
    merchantAuthSaga(),
    merchantDashboardSaga(),
    merchantPlansSaga(),
    merchantProductsSaga(),
    merchantCommunityCartSaga(),
    merchantSettingsSaga(),
    merchantUserProfileSaga(),
    merchantConnectSaga(),
    merchantOrdersSaga(),
    merchantAlertsSaga(),
    merchantInvitationsSaga(),
    merchantCustomersSaga(),
    merchantDiscountsSaga(),
  ]);
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import Spinner from "components/Spinner";
import { merchantDashboardBusinessPageViewedSaga } from "../state/actions";
import { useMergeState } from "utils/custom-hooks";
import { getMerchantStoreUrl } from "utils/common";

export default function MerchantDashboardGettingStartedContainer() {
  const dispatch = useDispatch();

  const {
    user: {
      merchant: {
        subdomain,
        stripeEnabled,
        squareEnabled,
        ruleSetCreated,
        businessProfileCreated,
        businessPageViewed,
      },
    },
  } = useSelector((store: any) => store?.merchantAuth);

  const { isLoading } = useSelector((store: any) => store?.merchantDashboard);

  const [state, setState] = useMergeState({
    completedPercentage: 0,
  });

  const handleRedirectMerchantMenu = () => {
    if (!businessPageViewed) {
      dispatch(merchantDashboardBusinessPageViewedSaga());
    }

    window.open(getMerchantStoreUrl(subdomain), "_blank");
  };

  useEffect(() => {
    const steps = [
      stripeEnabled,
      squareEnabled,
      ruleSetCreated,
      businessProfileCreated,
      businessPageViewed,
    ];

    let completedCount = 0;

    steps.forEach((elem) => {
      if (elem) {
        completedCount += 1;
      }
    });

    setState({ completedPercentage: (completedCount / steps.length) * 100 });
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="text-4xl font-semibold text-grey mb-4">
            Get Started
          </div>

          <div className="box my-8">
            <div className="text-grey text-3xl font-medium mb-8">
              Let&apos;s get you started
            </div>

            <div className="p-3">
              <div className="bg-white w-full h-4 rounded-3xl border-[1px] relative">
                <div
                  className="bg-[#55AB68] h-full rounded-3xl"
                  style={{ width: `${state?.completedPercentage}%` }}
                />
              </div>
            </div>

            <Link
              to="/settings?tab=PAYMENT_PROCESSORS"
              className="flex justify-between items-center p-2 cursor-pointer relative my-4 hover:shadow-md"
            >
              <div className="flex items-center">
                {squareEnabled ? (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#49935A",
                      }}
                    />
                  </div>
                ) : (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#e7e7e7",
                      }}
                    />
                  </div>
                )}

                <div className="text-grey">
                  Integrate with your Square PoS to add products or add manually
                </div>
              </div>

              <ChevronRightIcon />
            </Link>

            <Link
              to="/products/create"
              className="flex justify-between items-center p-2 cursor-pointer hover:shadow-md my-4"
            >
              <div className="flex items-center">
                {ruleSetCreated ? (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#49935A",
                      }}
                    />
                  </div>
                ) : (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#e7e7e7",
                      }}
                    />
                  </div>
                )}

                <div className="text-grey">
                  Create discount rulesets and apply to products
                </div>
              </div>

              <ChevronRightIcon />
            </Link>

            <Link
              to="/settings?tab=PAYMENT_PROCESSORS"
              className="flex justify-between items-center p-2 cursor-pointer hover:shadow-md my-4"
            >
              <div className="flex items-center">
                {stripeEnabled ? (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#49935A",
                      }}
                    />
                  </div>
                ) : (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#e7e7e7",
                      }}
                    />
                  </div>
                )}

                <div className="text-grey">Link your bank to get paid</div>
              </div>

              <ChevronRightIcon />
            </Link>

            <Link
              to="/settings"
              className="flex justify-between items-center p-2 cursor-pointer hover:shadow-md my-4"
            >
              <div className="flex items-center">
                {businessProfileCreated ? (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#49935A",
                      }}
                    />
                  </div>
                ) : (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#e7e7e7",
                      }}
                    />
                  </div>
                )}

                <div className="text-grey">Create your business profile</div>
              </div>

              <ChevronRightIcon />
            </Link>

            <div
              className="flex justify-between items-center p-2 cursor-pointer hover:shadow-md my-4"
              onClick={handleRedirectMerchantMenu}
            >
              <div className="flex items-center">
                {businessPageViewed ? (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#49935A",
                      }}
                    />
                  </div>
                ) : (
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{
                        color: "#e7e7e7",
                      }}
                    />
                  </div>
                )}

                <div className="text-grey">View your public business page</div>
              </div>

              <ChevronRightIcon />
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  PaymentRequestButtonElement,
  useStripe,
} from "@stripe/react-stripe-js";
import { customerCartBuySaga } from "../Cart/state/actions";
import { getCheckoutAmount } from "utils/common";

export default function Checkout() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const cartId = searchParams.get("id");

  const isCorporateAccount = searchParams.get("corporate") || false;

  const dispatch = useDispatch();

  const stripe = useStripe();

  const {
    config: { merchant },
    items,
    taxes,
  } = useSelector((store: any) => store?.customerCart);

  const {
    user: {
      customer: {
        stripeMetadata: { platformCustomerId },
      },
    },
  } = useSelector((store: any) => store?.customerAuth);

  const [paymentRequest, setPaymentRequest] = useState<any>(null);

  useEffect(() => {
    if (stripe) {
      const checkoutAmount = +Number(
        +Number(
          getCheckoutAmount(items, taxes, merchant?.processingFeesType)
        ).toFixed(2) * 100
      ).toFixed(2);

      const paymentRequest = stripe.paymentRequest({
        country: "US",
        currency: "usd",
        total: {
          label: "Cherry",
          amount: checkoutAmount,
        },
        requestPayerName: true,
        requestPayerEmail: true,
        requestPayerPhone: true,
      });

      paymentRequest?.canMakePayment()?.then((result) => {
        console.log("result : ", result);

        if (result?.applePay) {
          setPaymentRequest(paymentRequest);
        }
      });

      paymentRequest.on("paymentmethod", async (event: any) => {
        console.log("merchant : ", merchant?._id);
        console.log("platformCustomerId : ", platformCustomerId);
        console.log("pmId : ", event?.paymentMethod?.id);

        const payload = {
          mId: merchant?._id,
          cartId,
          items,
          pmId: event?.paymentMethod?.id,
          isCorporateAccount: isCorporateAccount || false,
        };

        dispatch(customerCartBuySaga(payload, navigate, event));
      });
    }
  }, [stripe, merchant, items, taxes]);

  return (
    <div>
      {paymentRequest && (
        <div>
          <div className="text-grey font-medium text-center my-4">-- or --</div>
          <PaymentRequestButtonElement options={{ paymentRequest }} />
        </div>
      )}
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import Spinner from "components/Spinner";
import Button from "components/Button";
import CustomerAddMemberDialog from "components/CustomerAddMemberDialog";
import { useMergeState } from "utils/custom-hooks";
import {
  getCustomerCorporatePlans,
  inviteCorporatePlanMembers,
  inviteCorporatePlanSelf,
  pauseMemberCorporatePlan,
  resumeMemberCorporatePlan,
  cancelMemberCorporatePlan,
} from "api";
import { appSetError } from "app/state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";
import { unixDate } from "utils/date";

export default function ConsumerTeamPlansContainer() {
  const dispatch = useDispatch();

  const [searchParams, setSearchParams] = useSearchParams();

  const selectedTeamPlanId = searchParams.get("planId");

  const { user } = useSelector((store: any) => store?.customerAuth);

  const [state, setState] = useMergeState({
    isLoading: false,
    teamPlans: [],
    planMembers: [],
    selectedTeamPlanId: "",

    shouldShowAddMemberDialog: false,
  });

  const handleOpenAddMemberDialog = () => {
    setState({ shouldShowAddMemberDialog: true });
  };

  const handleCloseAddMemberDialog = () => {
    setState({ shouldShowAddMemberDialog: false });
  };

  const handleChangeTeamPlan = (event: any) => {
    const planId = event.target.value;

    const planMembers = state?.teamPlans?.find(
      (plan: any) => plan?.plan?._id === planId
    )?.members;

    setState({
      selectedTeamPlanId: planId,
      planMembers,
    });

    setSearchParams({ planId });
  };

  const init = async () => {
    try {
      setState({ isLoading: true });

      const response = await getCustomerCorporatePlans();

      const teamPlans = response?.data;

      const planId = selectedTeamPlanId || teamPlans[0]?.plan?._id;

      const planMembers = teamPlans?.find(
        (plan: any) => plan?.plan?._id === planId
      )?.members;

      setState({
        isLoading: false,
        teamPlans,
        selectedTeamPlanId: planId,
        planMembers,
      });

      setSearchParams({ planId });
    } catch (error) {
      setState({ isLoading: false });
    }
  };

  const handleInviteMembers = async (members: Array<any>) => {
    try {
      setState({ isInviting: true });

      await inviteCorporatePlanMembers({
        id: state?.selectedTeamPlanId,
        members,
      });

      await init();
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    } finally {
      setState({ isInviting: false });
      handleCloseAddMemberDialog();
    }
  };

  const handleInviteSelf = async () => {
    try {
      setState({ isInviting: true });

      await inviteCorporatePlanSelf({
        id: state?.selectedTeamPlanId,
      });

      await init();
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    } finally {
      setState({ isInviting: false });
    }
  };

  const shouldShowSelfInvite = () =>
    !state?.planMembers?.some((elem: any) => elem?.email === user?.email);

  const shouldShowAddMemberButton = () => {
    const teamPlan = state?.teamPlans?.find(
      (elem: any) => elem?.plan?._id === state?.selectedTeamPlanId
    );

    if (teamPlan?.members?.length >= teamPlan?.plan?.members) {
      return false;
    }

    return true;
  };

  const availableMemberSlots = () => {
    const teamPlan = state?.teamPlans?.find(
      (elem: any) => elem?.plan?._id === state?.selectedTeamPlanId
    );

    if (teamPlan?.members?.length === teamPlan?.plan?.members) {
      return `${0}/${teamPlan?.plan?.members}`;
    }

    return `${teamPlan?.members?.length}/${teamPlan?.plan?.members}`;
  };

  const shouldShowCancel = (member: any) => {
    if (!member?.subscription?.stripeMetadata?.cancelAtPeriodEnd) {
      return true;
    }

    return false;
  };

  const shouldShowPause = (member: any) => {
    if (!shouldShowCancel(member)) {
      return false;
    }

    if (!member?.subscription?.stripeMetadata?.pauseCollection) {
      return true;
    }

    return false;
  };

  const shouldShowResume = (member: any) => {
    if (shouldShowPause(member) || !shouldShowCancel(member)) {
      return false;
    }

    if (!member?.subscription?.stripeMetadata?.pauseCollection?.resumesAt) {
      return true;
    }

    return false;
  };

  const handlePause = async (memberId: string) => {
    try {
      const response = await pauseMemberCorporatePlan({
        mId: memberId,
      });

      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );

      await init();
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  const handleResume = async (memberId: string) => {
    try {
      const response = await resumeMemberCorporatePlan({
        mId: memberId,
      });

      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );

      await init();
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  const handleCancel = async (memberId: string) => {
    try {
      const response = await cancelMemberCorporatePlan({
        mId: memberId,
      });

      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );

      await init();
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  const isPlanPaused = (member: any) => {
    if (member?.subscription?.stripeMetadata?.pauseCollection) {
      return true;
    }

    return false;
  };

  const isPlanCanceled = (member: any) => {
    if (member?.subscription?.stripeMetadata?.cancelAtPeriodEnd) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      {state?.isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={state?.isLoading} />
        </div>
      ) : (
        <div className="py-4 flex justify-center">
          <div className="w-11/12 lg:w-2/3">
            <div className="text-grey text-2xl font-semibold">Team Plans</div>

            <div className="flex justify-between items-center">
              <div className="w-1/2 my-4">
                <div className="text-grey font-medium mb-2">Plan</div>

                <FormControl variant="outlined" className="input-select-field">
                  <Select
                    variant="outlined"
                    name="selectedTeamPlanId"
                    value={state?.selectedTeamPlanId}
                    onChange={handleChangeTeamPlan}
                    color="secondary"
                  >
                    {state?.teamPlans?.map((plan: any) => (
                      <MenuItem key={plan?.plan?._id} value={plan?.plan?._id}>
                        {plan?.plan?.product?.title}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>

              <div className="text-center">
                {shouldShowAddMemberButton() && (
                  <Button
                    label="Add Member"
                    color="info"
                    onClick={handleOpenAddMemberDialog}
                    startIcon={<AddIcon />}
                    style={{
                      borderRadius: 4,
                      fontSize: 14,
                      color: "#FFFFFF",
                      height: 40,
                    }}
                  />
                )}

                <div className="text-grey text-sm font-medium mt-2">
                  {availableMemberSlots()} available
                </div>
              </div>
            </div>

            {shouldShowSelfInvite() && (
              <div className="mt-8">
                <Button
                  label="One membership is for me"
                  onClick={handleInviteSelf}
                  startIcon={<AddIcon />}
                  style={{
                    borderRadius: 4,
                    fontSize: 14,
                    color: "#000000",
                    background: "rgb(231, 231, 231)",
                    boxShadow: "rgb(231 231 231) 0px 0px 0px 1px inset",
                    fontWeight: 500,
                    height: 40,
                  }}
                />
              </div>
            )}

            <div className="mt-10">
              {!state?.planMembers?.length && (
                <div className="text-grey font-medium text-center">
                  Start by inviting a member to this team plan
                </div>
              )}

              {state?.planMembers?.map((member: any) => (
                <div
                  key={member?._id}
                  className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 mt-8"
                >
                  <div className="flex justify-between">
                    <div className="h-16 flex flex-col justify-between">
                      <div className="text-sm text-grey-2">Member contact</div>
                      <div className="text-lg">{member?.email}</div>

                      {isPlanPaused(member) && !isPlanCanceled(member) && (
                        <div className="text-xs text-grey font-medium mt-1">
                          Plan is paused and redemptions are active until the
                          end of the period (
                          {unixDate(
                            member?.subscription?.stripeMetadata
                              ?.currentPeriodEnd,
                            "MMM DD, YYYY"
                          )}
                          )
                        </div>
                      )}

                      {isPlanCanceled(member) && (
                        <div className="text-xs text-grey font-medium mt-1">
                          Plan is canceled and redemptions are active until the
                          end of the period (
                          {unixDate(
                            member?.subscription?.stripeMetadata?.cancelAt,
                            "MMM DD, YYYY"
                          )}
                          )
                        </div>
                      )}
                    </div>

                    <div className="h-16 flex flex-col justify-between">
                      <div className="text-sm text-grey-2">Registered</div>
                      <div className="text-lg">
                        {member?.status === "ACTIVE" ? "Yes" : "No"}
                      </div>
                    </div>

                    <div className="flex flex-col justify-between">
                      {shouldShowPause(member) && (
                        <div className="mb-3">
                          <Button
                            label="Pause"
                            onClick={() => handlePause(member?._id)}
                            style={{
                              borderRadius: 40,
                              fontSize: 14,
                              color: "#000000",
                              background: "rgb(231, 231, 231)",
                              boxShadow:
                                "rgb(231 231 231) 0px 0px 0px 1px inset",
                              fontWeight: 500,
                              height: 40,
                            }}
                          />
                        </div>
                      )}

                      {shouldShowResume(member) && (
                        <div className="mb-3">
                          <Button
                            label="Resume"
                            onClick={() => handleResume(member?._id)}
                            style={{
                              borderRadius: 40,
                              fontSize: 14,
                              color: "#000000",
                              background: "rgb(231, 231, 231)",
                              boxShadow:
                                "rgb(231 231 231) 0px 0px 0px 1px inset",
                              fontWeight: 500,
                              height: 40,
                            }}
                          />
                        </div>
                      )}

                      {shouldShowCancel(member) && (
                        <Button
                          label="Cancel"
                          onClick={() => handleCancel(member?._id)}
                          style={{
                            borderRadius: 40,
                            fontSize: 14,
                            color: "#FFFFFF",
                            height: 40,
                            background: "#6A8566",
                            fontWeight: 500,
                          }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}

      {state?.shouldShowAddMemberDialog && state?.selectedTeamPlanId && (
        <CustomerAddMemberDialog
          open={state?.shouldShowAddMemberDialog}
          onClose={handleCloseAddMemberDialog}
          isInviting={state?.isInviting}
          onInvite={handleInviteMembers}
        />
      )}
    </div>
  );
}

import { RESET_APP_STATE } from "app/state/types";
import {
  CONSUMER_PLANS_SET_IS_LOADING,
  CONSUMER_PLANS_SET_PLANS,
} from "./types";

type ConsumerPlansStateType = {
  isLoading: boolean;
  plans: Array<any>;
};

const initialState: ConsumerPlansStateType = {
  isLoading: false,
  plans: [],
};

const consumerPlansReducer = (
  state: ConsumerPlansStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CONSUMER_PLANS_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case CONSUMER_PLANS_SET_PLANS:
      return { ...state, plans: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default consumerPlansReducer;

import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
// import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import {
  merchantUserProfileSetConfig,
  merchantUserProfileUpdateProfileDetailsSaga,
} from "./state/actions";

export default function MerchantProfileContainer() {
  const dispatch = useDispatch();

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const {
    config: {
      shouldEditPersonalInfo,
      isSavingPersonalInfo,
      shouldEditPassword,
      isSavingPassword,
    },
  } = useSelector((store: any) => store?.merchantUserProfile);

  const [state, setState] = useMergeState({
    firstName: "",
    lastName: "",
    phone: "",
    email: "",

    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",

    // notification settings
    businessMessages: false,
    reviews: false,
    marketingEmails: false,

    errors: {},
  });

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  // const handleCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setState({
  //     [event.target.name]: event.target.checked,
  //   });
  // };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.firstName) {
      payload = { firstName: true, ...payload };
      isValid = false;
    }

    if (!state.lastName) {
      payload = { lastName: true, ...payload };
      isValid = false;
    }

    if (!state.email) {
      payload = { email: true, ...payload };
      isValid = false;
    }

    if (!state.phone) {
      payload = { phone: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleToggleEditPersonalInfo = () => {
    dispatch(
      merchantUserProfileSetConfig({
        shouldEditPersonalInfo: !shouldEditPersonalInfo,
      })
    );
  };

  const handleToggleEditPassword = () => {
    dispatch(
      merchantUserProfileSetConfig({ shouldEditPassword: !shouldEditPassword })
    );
  };

  const handleSavePersonalInformation = () => {
    if (!isFormValid()) {
      return;
    }

    const payload = {
      firstName: state.firstName,
      lastName: state.lastName,
      email: state.email,
      phone: state.phone,
    };

    dispatch(merchantUserProfileUpdateProfileDetailsSaga(payload));
  };

  const handleSavePassword = () => {};

  useEffect(() => {
    setState({
      firstName: user?.firstName,
      lastName: user?.lastName,
      phone: user?.phone,
      email: user?.email,
    });
  }, []);

  return (
    <div>
      <div className="text-3xl font-semibold text-grey">Your Profile</div>

      <div className="flex justify-between">
        <div className="w-full lg:w-8/12">
          <div className="mt-4 profile-box p-4">
            <div className="flex justify-between items-center">
              <div className="text-lg text-grey font-semibold">
                Personal Information
              </div>

              <div>
                <IconButton onClick={handleToggleEditPersonalInfo}>
                  <EditIcon />
                </IconButton>
              </div>
            </div>

            <div className="mt-8">
              <TextField
                fullWidth
                label="FIRST NAME"
                variant="outlined"
                name="firstName"
                value={state.firstName}
                onChange={handleChange}
                required
                error={state?.errors?.firstName}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                disabled={!shouldEditPersonalInfo}
              />

              {state?.errors?.firstName && (
                <ErrorMessage message="First name is required" />
              )}
            </div>

            <div className="mt-4">
              <TextField
                fullWidth
                label="LAST NAME"
                variant="outlined"
                name="lastName"
                value={state.lastName}
                onChange={handleChange}
                required
                error={state?.errors?.lastName}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                disabled={!shouldEditPersonalInfo}
              />

              {state?.errors?.lastName && (
                <ErrorMessage message="Last name is required" />
              )}
            </div>

            <div className="mt-4">
              <TextField
                fullWidth
                label="PHONE"
                variant="outlined"
                name="phone"
                value={state.phone}
                onChange={handleChange}
                required
                error={state?.errors?.phone}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                disabled={!shouldEditPersonalInfo}
              />

              {state?.errors?.phone && (
                <ErrorMessage message="Phone is required" />
              )}
            </div>

            <div className="mt-4">
              <TextField
                fullWidth
                label="EMAIL"
                variant="outlined"
                name="email"
                value={state.email}
                onChange={handleChange}
                required
                error={state?.errors?.email}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                disabled={!shouldEditPersonalInfo}
              />

              {state?.errors?.email && (
                <ErrorMessage message="Email is required" />
              )}
            </div>

            {shouldEditPersonalInfo && (
              <div className="mt-4">
                <Button
                  label="Save"
                  color="info"
                  onClick={handleSavePersonalInformation}
                  style={{
                    borderRadius: 10,
                    fontSize: 16,
                    color: "#FFFFFF",
                    height: 40,
                  }}
                  loaderButton
                  loadingPosition="center"
                  loading={isSavingPersonalInfo}
                />
              </div>
            )}
          </div>

          {/* <div className="mt-4 profile-box p-4">
            <div className="flex justify-between items-center">
              <div className="text-lg text-grey font-semibold">Password</div>

              <div>
                <IconButton onClick={handleToggleEditPassword}>
                  <EditIcon />
                </IconButton>
              </div>
            </div>

            <div className="mt-8">
              <TextField
                fullWidth
                type="password"
                label="CURRENT PASSWORD"
                variant="outlined"
                name="currentPassword"
                value={state.currentPassword}
                onChange={handleChange}
                required
                error={state?.errors?.currentPassword}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                disabled={!shouldEditPassword}
              />

              {state?.errors?.currentPassword && (
                <ErrorMessage message="Current password is required" />
              )}
            </div>

            <div className="mt-4">
              <TextField
                fullWidth
                type="password"
                label="NEW PASSWORD"
                variant="outlined"
                name="newPassword"
                value={state.newPassword}
                onChange={handleChange}
                required
                error={state?.errors?.newPassword}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                disabled={!shouldEditPassword}
              />

              {state?.errors?.newPassword && (
                <ErrorMessage message="New password is required" />
              )}
            </div>

            <div className="mt-4">
              <TextField
                fullWidth
                type="password"
                label="CONFIRM NEW PASSWORD"
                variant="outlined"
                name="confirmNewPassword"
                value={state.confirmNewPassword}
                onChange={handleChange}
                required
                error={state?.errors?.confirmNewPassword}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                disabled={!shouldEditPassword}
              />

              {state?.errors?.confirmNewPassword && (
                <ErrorMessage message="Please confirm your new password" />
              )}
            </div>

            {shouldEditPassword && (
              <div className="flex justify-between items-center mt-4">
                <Button
                  label="Save"
                  color="info"
                  onClick={handleSavePassword}
                  style={{
                    borderRadius: 10,
                    fontSize: 16,
                    color: "#FFFFFF",
                    height: 40,
                  }}
                  loaderButton
                  loadingPosition="center"
                  loading={isSavingPassword}
                />

                <Link
                  className="text-grey text-sm underline"
                  to="/forgot-password"
                >
                  Forgot password
                </Link>
              </div>
            )}
          </div> */}
        </div>

        {/* <div className="w-1/3">
          <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 mt-4 ml-2">
            <div className="text-2xl font-semibold text-grey mb-3">
              Things You Can Do
            </div>

            <div className="text-green text-lg">Edit your profile</div>

            <div className="text-green text-lg">Change your password</div>

            <div className="text-green text-lg">Update notifications</div>
          </div>

          <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 mt-4 ml-2">
            <div className="text-2xl font-semibold text-grey mb-3">
              Enabled Notifications
            </div>

            <div>
              <Checkbox
                name="businessMessages"
                checked={state.businessMessages}
                onChange={handleCheck}
                color="primary"
              />

              <span className="text-grey text-sm font-semibold">
                Messages sent to the business
              </span>
            </div>

            <div>
              <Checkbox
                name="reviews"
                checked={state.reviews}
                onChange={handleCheck}
                color="primary"
              />

              <span className="text-grey text-sm font-semibold">
                New reviews posted
              </span>
            </div>

            <div>
              <Checkbox
                name="marketingEmails"
                checked={state.marketingEmails}
                onChange={handleCheck}
                color="primary"
              />

              <span className="text-grey text-sm font-semibold">
                Marketing emails from Cherry
              </span>
            </div>
          </div>
        </div> */}
      </div>
    </div>
  );
}

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_CUSTOMERS_SET_IS_LOADING,
  MERCHANT_CUSTOMERS_SET_CUSTOMERS,
} from "./types";

type MerchantCustomersStateType = {
  isLoading: boolean;
  customers: Array<any>;
};

const initialState: MerchantCustomersStateType = {
  isLoading: false,
  customers: [],
};

const merchantCustomersReducer = (
  state: MerchantCustomersStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_CUSTOMERS_SET_IS_LOADING:
      return { ...state, isLoading: action?.payload };

    case MERCHANT_CUSTOMERS_SET_CUSTOMERS:
      return { ...state, customers: action?.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantCustomersReducer;

import { takeLatest, put, all, call, select } from "redux-saga/effects";
import {
  getCustomerCheckoutDetails,
  createStripeSetupIntent,
  getStripePaymentMethods,
  verifyStripeSetupIntent,
  createSubscription,
  createBuyMorePayLessSubscription,
  verifyOTP,
} from "api";
import { CHERRY_TOKEN, MESSAGE_SEVERITY } from "utils/constants";
import { CheckoutSteps } from "enums/Checkout";
import {
  CUSTOMER_CHECKOUT_CREATE_ACCOUNT_SAGA,
  CUSTOMER_CHECKOUT_GET_DETAILS_SAGA,
  CUSTOMER_CHECKOUT_CONFIRM_PAYMENT_SAGA,
  CUSTOMER_CHECKOUT_VERIFY_PAYMENT_SAGA,
  CUSTOMER_CHECKOUT_MAKE_PAYMENT_SAGA,
  CUSTOMER_CHECKOUT_ADD_NEW_PAYMENT_METHOD_SAGA,
  CUSTOMER_CHECKOUT_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA,
  CUSTOMER_CHECKOUT_GET_PAYMENT_METHODS_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  customerCheckoutSetIsLoading,
  customerCheckoutSetConfig,
  customerCheckoutSetDetails,
  customerCheckoutSetVerificationConfig,
} from "./actions";
import {
  customerAuthSetIsLoggedIn,
  customerAuthSetCurrentUser,
} from "../../auth/state/actions"; // customer auth actions
import { MenuType } from "enums/Menu";
import { getSubdomain } from "utils/common";

function* customerCheckoutCreateSetupIntentHandler(payload: any): any {
  try {
    yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: true }));

    const setupIntentResponse = yield call(createStripeSetupIntent, {
      platformCustomerId: payload?.platformCustomerId,
    });

    if (setupIntentResponse?.success) {
      yield put(
        customerCheckoutSetConfig({
          isInitialSetup: true,
          clientSecret: setupIntentResponse?.data?.clientSecret,
          isCheckoutFormLoading: false,
          currentStep: CheckoutSteps[1],
        })
      );
    }

    yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: false }));
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
    yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: false }));
  }
}

function* customerCheckoutGetDetailsHandler(action: any): any {
  try {
    yield put(customerCheckoutSetIsLoading(true));

    const planResponse = yield call(getCustomerCheckoutDetails, action.payload);

    if (planResponse?.success) {
      const merchantId = planResponse?.data?.merchant?._id;

      let payload = {
        merchant: planResponse?.data?.merchant,
        plan: planResponse?.data,
        price: null,
      };

      if (action?.payload?.type === MenuType.SUBSCRIPTIONS) {
        payload = {
          ...payload,
          price: planResponse?.data?.price,
        };
      }

      yield put(customerCheckoutSetDetails(payload));

      const store = yield select();

      if (store?.customerAuth?.isLoggedIn) {
        // get payment methods
        const paymentMethodsResponse = yield call(getStripePaymentMethods);

        if (paymentMethodsResponse?.success) {
          yield put(
            customerCheckoutSetDetails({
              paymentMethods: paymentMethodsResponse?.data,
            })
          );

          if (
            store?.customerCheckout?.checkoutConfig?.currentStep?.id === "2" &&
            !paymentMethodsResponse?.data?.cards?.length
          ) {
            yield customerCheckoutCreateSetupIntentHandler({
              merchantId,
              platformCustomerId:
                store?.customerAuth?.user?.customer?.stripeMetadata
                  ?.platformCustomerId,
            });
          }
        }
      }
    }

    yield put(customerCheckoutSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerCheckoutSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerCheckoutCreateAccountHandler(action: any): any {
  try {
    yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: true }));

    // sign up user
    const signupResponse = yield call(verifyOTP, {
      firstName: action?.payload?.firstName,
      lastName: action?.payload?.lastName,
      email: action?.payload?.email,
      phone: action?.payload?.phone,
      address: action?.payload?.address,
      otp: action?.payload?.otp,
      companyName: action?.payload?.companyName,
      isSignUp: true,
    });

    if (signupResponse?.success) {
      const {
        data: { user, token },
      } = signupResponse;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(customerAuthSetIsLoggedIn(true)),
        yield put(customerAuthSetCurrentUser({ ...user })),
        yield customerCheckoutCreateSetupIntentHandler({
          platformCustomerId:
            user?.customer?.stripeMetadata?.platformCustomerId,
        }),
      ]);
    }

    yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: false }));
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
    yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: false }));
  }
}

function* customerCheckoutConfirmPaymentHandler(action: any): any {
  try {
    const {
      stripe,
      elements,
      platformCustomerId,
      merchantId,
      planId,
      priceId,
    } = action.payload;

    const confirmSetupResponse = yield stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `https://${getSubdomain()}.${
          process.env.REACT_APP_HOST_URL
        }/checkout/verify?platformCustomerId=${platformCustomerId}&merchantId=${merchantId}&planId=${planId}&priceId=${priceId}`,
      },
    });

    if (confirmSetupResponse?.error) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: confirmSetupResponse?.error?.message,
        })
      );
    }
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  }
}

function* customerCheckoutVerifyPaymentHandler(action: any): any {
  try {
    yield put(
      customerCheckoutSetVerificationConfig({
        isLoading: true,
      })
    );

    const response = yield call(verifyStripeSetupIntent, action.payload);

    if (response?.success) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }

    yield put(
      customerCheckoutSetVerificationConfig({
        isLoading: false,
      })
    );
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  } finally {
    setTimeout(action.navigate("/"), 3000);
  }
}

function* customerCheckoutMakePaymentHandler(action: any): any {
  try {
    yield put(customerCheckoutSetConfig({ isMakingPayment: true }));

    // create subscription
    let response: any = {};

    if (action?.payload?.type === MenuType.SUBSCRIPTIONS) {
      response = yield call(createSubscription, action.payload);
    }

    if (action?.payload?.type === MenuType.BUY_MORE_PAY_LESS) {
      response = yield call(createBuyMorePayLessSubscription, action.payload);
    }

    if (response?.success) {
      yield put(
        customerCheckoutSetConfig({
          isMakingPayment: false,
          currentStep: CheckoutSteps[2],
        })
      );
    }
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
    yield put(customerCheckoutSetConfig({ isMakingPayment: false }));
  }
}

function* customerCheckoutAddNewPaymentMethodHandler(action: any): any {
  try {
    yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: true }));

    const setupIntentResponse = yield call(createStripeSetupIntent, {
      platformCustomerId: action?.payload?.platformCustomerId,
    });

    if (setupIntentResponse?.success) {
      yield put(
        customerCheckoutSetConfig({
          isAddingNewPaymentMethod: true,
          clientSecret: setupIntentResponse?.data?.clientSecret,
          isCheckoutFormLoading: false,
          currentStep: CheckoutSteps[1],
        })
      );
    }

    yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: false }));
  } catch (error: any) {
    yield all([
      yield put(customerCheckoutSetConfig({ isCheckoutFormLoading: false })),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerCheckoutConfirmAddNewPaymentMethodHandler(action: any): any {
  try {
    const { stripe, elements, platformCustomerId, merchantId, planId } =
      action.payload;

    const confirmSetupResponse = yield stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `https://${getSubdomain()}.${
          process.env.REACT_APP_HOST_URL
        }/add-payment-method?id=${platformCustomerId}&mId=${merchantId}&plId=${planId}`,
      },
    });

    if (confirmSetupResponse?.error) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: confirmSetupResponse?.error?.message,
        })
      );
    }
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  }
}

function* customerCheckoutGetPaymentMethodsHandler(): any {
  try {
    yield put(customerCheckoutSetConfig({ isFetchingPaymentMethods: true }));

    const paymentMethodsResponse = yield call(getStripePaymentMethods);

    if (paymentMethodsResponse?.success) {
      yield put(
        customerCheckoutSetDetails({
          paymentMethods: paymentMethodsResponse?.data,
        })
      );

      if (paymentMethodsResponse?.data?.cards?.length) {
        yield put(
          customerCheckoutSetConfig({
            paymentMethodId: paymentMethodsResponse?.data?.cards[0]?.id,
          })
        );
      }
    }

    yield put(customerCheckoutSetConfig({ isFetchingPaymentMethods: false }));
  } catch (error: any) {
    yield all([
      yield put(customerCheckoutSetConfig({ isFetchingPaymentMethods: false })),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchCustomerCheckout() {
  yield takeLatest(
    CUSTOMER_CHECKOUT_GET_DETAILS_SAGA,
    customerCheckoutGetDetailsHandler
  );
  yield takeLatest(
    CUSTOMER_CHECKOUT_CREATE_ACCOUNT_SAGA,
    customerCheckoutCreateAccountHandler
  );
  yield takeLatest(
    CUSTOMER_CHECKOUT_CONFIRM_PAYMENT_SAGA,
    customerCheckoutConfirmPaymentHandler
  );
  yield takeLatest(
    CUSTOMER_CHECKOUT_VERIFY_PAYMENT_SAGA,
    customerCheckoutVerifyPaymentHandler
  );
  yield takeLatest(
    CUSTOMER_CHECKOUT_MAKE_PAYMENT_SAGA,
    customerCheckoutMakePaymentHandler
  );
  yield takeLatest(
    CUSTOMER_CHECKOUT_ADD_NEW_PAYMENT_METHOD_SAGA,
    customerCheckoutAddNewPaymentMethodHandler
  );
  yield takeLatest(
    CUSTOMER_CHECKOUT_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA,
    customerCheckoutConfirmAddNewPaymentMethodHandler
  );
  yield takeLatest(
    CUSTOMER_CHECKOUT_GET_PAYMENT_METHODS_SAGA,
    customerCheckoutGetPaymentMethodsHandler
  );
}

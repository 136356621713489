export const CUSTOMER_SETTINGS_SET_IS_LOADING =
  "CUSTOMER_SETTINGS_SET_IS_LOADING";
export const CUSTOMER_SETTINGS_SET_SUBSCRIPTIONS =
  "CUSTOMER_SETTINGS_SET_SUBSCRIPTIONS";
export const CUSTOMER_SETTINGS_SET_PAYMENT_METHODS =
  "CUSTOMER_SETTINGS_SET_PAYMENT_METHODS";
export const CUSTOMER_SETTINGS_ADD_PAYMENT_METHOD_SET_CONFIG =
  "CUSTOMER_SETTINGS_ADD_PAYMENT_METHOD_SET_CONFIG";

// async

export const CUSTOMER_SETTINGS_FETCH_SUBSCRIPTIONS_SAGA =
  "CUSTOMER_SETTINGS_FETCH_SUBSCRIPTIONS_SAGA";
export const CUSTOMER_SETTINGS_FETCH_PAYMENT_METHODS_SAGA =
  "CUSTOMER_SETTINGS_FETCH_PAYMENT_METHODS_SAGA";
export const CUSTOMER_SETTINGS_INITIATE_SETUP_INTENT_SAGA =
  "CUSTOMER_SETTINGS_INITIATE_SETUP_INTENT_SAGA";
export const CUSTOMER_SETTINGS_CONFIRM_SETUP_INTENT_SAGA =
  "CUSTOMER_SETTINGS_CONFIRM_SETUP_INTENT_SAGA";
export const CUSTOMER_SETTINGS_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA =
  "CUSTOMER_SETTINGS_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA";

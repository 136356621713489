import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "components/Button";
import Spinner from "components/Spinner";
import MerchantInviteSubscriberDialog from "components/MerchantInviteSubscriberDialog";
import {
  merchantPlansFetchSubscribersSaga,
  merchantPlansSetInviteSubscriberDialogConfig,
} from "../state/actions";
import { unixDate } from "utils/date";

export default function MerchantSubscribersContainer() {
  const dispatch = useDispatch();

  const {
    isLoading,
    subscribers,
    subscribersPaginationConfig: { pageIndex, pageSize },
    inviteSubscriberDialogConfig: { open: shouldShowInviteSubscriberDialog },
  } = useSelector((store: any) => store?.merchantPlans);

  const handleOpenInviteSubscriberDialog = () => {
    dispatch(merchantPlansSetInviteSubscriberDialogConfig({ open: true }));
  };

  const handleCloseInviteSubscriberDialog = () => {
    dispatch(merchantPlansSetInviteSubscriberDialogConfig({ open: false }));
  };

  useEffect(() => {
    dispatch(merchantPlansFetchSubscribersSaga({ pageIndex, pageSize }));
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="md:flex md:justify-between md:items-center">
            <div className="text-4xl font-semibold text-grey">Subscribers</div>
            <div className="mt-4 md:mt-0">
              <Button
                label="Invite Subscriber"
                color="secondary"
                onClick={handleOpenInviteSubscriberDialog}
                style={{
                  borderRadius: 10,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>
          </div>

          <TableContainer className="my-8">
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="text-grey">Subscription Tier</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="text-grey">Name</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="text-grey">Email Address</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="text-grey">Phone Number</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="text-grey">Initial Subscription Date</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="text-grey">Next Renewal Date</span>
                  </TableCell>
                  <TableCell align="left">
                    <span className="text-grey">Total Paid</span>
                  </TableCell>
                  {/* <TableCell align="left" /> */}
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "visible" }}>
                {subscribers.map((subscriber: any) => (
                  <TableRow key={subscriber._id}>
                    <TableCell component="th" scope="row">
                      <span className="text-grey text-xs">
                        {subscriber?.product?.title}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-xs">
                        {subscriber?.user?.firstName}{" "}
                        {subscriber?.user?.lastName}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-xs">
                        {subscriber?.user?.email || "-"}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-xs">
                        {subscriber?.user?.phone || "-"}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-xs">
                        {unixDate(
                          subscriber?.stripeMetadata?.created,
                          "MM/DD/YYYY"
                        )}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-xs">
                        {unixDate(
                          subscriber?.stripeMetadata?.currentPeriodEnd,
                          "MM/DD/YYYY"
                        )}
                      </span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-xs">
                        ${subscriber?.totalPaid}
                      </span>
                    </TableCell>
                    {/* <TableCell component="th" scope="row" align="left">
                <span className="text-grey text-xs underline cursor-pointer">
                  contact/edit
                </span>
              </TableCell> */}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!subscribers.length && (
            <div className="flex justify-center mt-10">
              <div className="text-grey text-xl">
                Start by inviting a subscriber
              </div>
            </div>
          )}
        </div>
      )}

      {shouldShowInviteSubscriberDialog && (
        <MerchantInviteSubscriberDialog
          onClose={handleCloseInviteSubscriberDialog}
        />
      )}
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Badge, { BadgeProps } from "@mui/material/Badge";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
// import InputAdornment from "@mui/material/InputAdornment";
// import SearchIcon from "@mui/icons-material/Search";
// import SearchField from "components/SearchField";
import NavigationMenuDrawer from "./NavigationMenuDrawer";
import { useMergeState } from "utils/custom-hooks";
import { customerCartSetConfig } from "components/Customer/Cart/state/actions"; // customer cart actions

const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));

export default function CustomerNavBar() {
  const dispatch = useDispatch();

  const { pathname } = useLocation();

  const { isLoggedIn, merchant } = useSelector(
    (store: any) => store?.customerAuth
  );

  const { items } = useSelector((store: any) => store?.customerCart);

  const [state, setState] = useMergeState({
    shouldShowNavigationMenu: false,
    search: "",
    width: window.innerWidth,
  });

  const handleOpenNavigationMenu = () => {
    setState({ shouldShowNavigationMenu: true });
  };

  const handleCloseNavigationMenu = () => {
    setState({ shouldShowNavigationMenu: false });
  };

  // const handleSearch = (event: any) => {
  //   setState({ search: event?.target?.value });
  // };

  const handleOpenCart = () => {
    const isMobile = state?.width <= 768;

    if (pathname.includes("/menu") && !isMobile) {
      return;
    }

    dispatch(customerCartSetConfig({ open: true }));
  };

  const handleWindowSizeChange = () => {
    setState({ width: window.innerWidth });
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div>
      <div className="px-4 lg:px-20 w-full h-16 flex justify-between items-center bg-white backdrop-blur-[174px] border-b-[1px]">
        <div className="flex items-center">
          <div className="mr-2">
            <IconButton onClick={handleOpenNavigationMenu}>
              <MenuIcon />
            </IconButton>
          </div>

          <Link to="/">
            <div className="text-xl font-semibold">
              {merchant?.businessName}
            </div>
          </Link>
        </div>

        <div className="flex justify-between items-center">
          {/* <div className="w-3/4">
            <SearchField
              fullWidth
              variant="outlined"
              name="search"
              placeholder="Search stores, plans, products"
              value={state?.search}
              onChange={handleSearch}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
            />
          </div> */}

          <div className="ml-4">
            <IconButton onClick={handleOpenCart}>
              <StyledBadge badgeContent={items?.length} color="primary">
                <ShoppingCartIcon
                  sx={{ color: (theme) => theme.palette.primary.main }}
                />
              </StyledBadge>
            </IconButton>
          </div>

          {!isLoggedIn && (
            <Link to="/login" className="hidden lg:block ml-4">
              <span className="text-sm font-medium text-grey underline ml-2">
                Sign In
              </span>
            </Link>
          )}
        </div>
      </div>

      <NavigationMenuDrawer
        open={state?.shouldShowNavigationMenu}
        onClose={handleCloseNavigationMenu}
      />
    </div>
  );
}

import { useSelector } from "react-redux";
import { Routes, Route, Navigate } from "react-router-dom";

// layouts
import ConsumerDefaultLayout from "layouts/ConsumerDefaultLayout";

// containers

// signup
import CustomerSignupContainer from "containers/consumer/auth/signup";

// login
import CustomerSigninContainer from "containers/consumer/auth/signin";

// checkout
import CustomerCheckoutContainer from "containers/consumer/checkout";

// checkout
import CustomerTextToPayPaymentContainer from "containers/consumer/text-to-pay/payment";

// dashboard
import CustomerDashboardContainer from "containers/consumer/dashboard";

// subscription
import CustomerSubscriptionContainer from "containers/consumer/subscription";
import CustomerSubscriptionDetailsContainer from "containers/consumer/subscription/details";

// merchant public business page
import PublicBusinessPageContainer from "components/PublicBusinessPage";

// confirm add payment method
import CustomerConfirmAddPaymentMethodContainer from "containers/consumer/confirm-add-payment-method";

// settings
import CustomerSettingsContainer from "containers/consumer/settings";
import CustomerSettingsConfirmSetupIntentContainer from "containers/consumer/settings/confirm-setup-intent";

// orders
import ConsumerOrdersContainer from "containers/consumer/orders";
import ConsumerOrdersReceiptContainer from "containers/consumer/orders/receipt";

// plans
import ConsumerPlansContainer from "containers/consumer/plans";

// team-plans
import ConsumerTeamPlansContainer from "containers/consumer/team-plans";

// components
import PageNotFound from "components/PageNotFound";
import Spinner from "components/Spinner";
import ProtectedRoute from "components/ProtectedRoute";

// enums
import { UserRoles } from "enums/userRoles";

// utils
import { CHERRY_TOKEN } from "utils/constants";
import { useEffect } from "react";

const CustomerRoutesContainer = () => {
  const { isLoggedIn, merchant } = useSelector(
    (store: any) => store?.customerAuth
  );

  const isAppLoading = localStorage.getItem(CHERRY_TOKEN) && !isLoggedIn;

  useEffect(() => {
    let link: any = document.querySelector("link[rel~='icon']");
    let appleTouchIcon: any = document.querySelector(
      "link[rel~='apple-touch-icon']"
    );
    let metaIOSTitle: any = document.querySelector(
      "meta[name~='apple-mobile-web-app-title']"
    );
    let metaIOSWebAppCapable: any = document.querySelector(
      "meta[name~='apple-mobile-web-app-capable']"
    );
    let metaMobileWebAppCapable: any = document.querySelector(
      "meta[name~='mobile-web-app-capable']"
    );

    if (!link) {
      link = document.createElement("link");
      link.rel = "icon";
      document.getElementsByTagName("head")[0].appendChild(link);
    }

    if (!appleTouchIcon) {
      appleTouchIcon = document.createElement("link");
      appleTouchIcon.rel = "apple-touch-icon";
      document.getElementsByTagName("head")[0].appendChild(appleTouchIcon);
    }

    if (!metaIOSTitle) {
      metaIOSTitle = document.createElement("meta");
      metaIOSTitle.name = "apple-mobile-web-app-title";
      document.getElementsByTagName("head")[0].appendChild(metaIOSTitle);
    }

    if (!metaIOSWebAppCapable) {
      metaIOSWebAppCapable = document.createElement("meta");
      metaIOSWebAppCapable.name = "apple-mobile-web-app-capable";
      document
        .getElementsByTagName("head")[0]
        .appendChild(metaIOSWebAppCapable);
    }

    if (!metaMobileWebAppCapable) {
      metaMobileWebAppCapable = document.createElement("meta");
      metaMobileWebAppCapable.name = "mobile-web-app-capable";
      document
        .getElementsByTagName("head")[0]
        .appendChild(metaMobileWebAppCapable);
    }

    document.title = merchant?.businessName || "Cherry";

    metaIOSTitle.content = merchant?.businessName;

    metaIOSWebAppCapable.content = "yes";

    metaMobileWebAppCapable.content = "yes";

    link.href = merchant?.businessLogo;

    appleTouchIcon.href = merchant?.businessLogo;
  }, [merchant]);

  return (
    <div>
      {isAppLoading ? (
        <div className="w-full h-screen flex justify-center mt-10">
          <Spinner loading={isAppLoading} />
        </div>
      ) : (
        <Routes>
          <Route path="signup" element={<CustomerSignupContainer />} />

          <Route path="login" element={<CustomerSigninContainer />} />

          <Route
            path="add-payment-method"
            element={<CustomerConfirmAddPaymentMethodContainer />}
          />

          <Route path="/text-to-pay">
            <Route
              path="payment"
              element={<CustomerTextToPayPaymentContainer />}
            />
          </Route>

          <Route path="/" element={<ConsumerDefaultLayout />}>
            <Route path="" element={<CustomerDashboardContainer />} />

            <Route path="checkout">
              <Route path="" element={<CustomerCheckoutContainer />} />
            </Route>

            <Route path="menu" element={<PublicBusinessPageContainer />} />

            <Route
              element={
                <ProtectedRoute
                  allowedRoles={[UserRoles.ADMIN, UserRoles.MEMBER]}
                />
              }
            >
              <Route
                path="subscription"
                element={<CustomerSubscriptionContainer />}
              />

              <Route
                path="subscription/details"
                element={<CustomerSubscriptionDetailsContainer />}
              />

              <Route path="/settings">
                <Route path="" element={<CustomerSettingsContainer />} />
                <Route
                  path="confirm"
                  element={<CustomerSettingsConfirmSetupIntentContainer />}
                />
              </Route>

              <Route path="/orders">
                <Route path="" element={<ConsumerOrdersContainer />} />
                <Route
                  path="receipt"
                  element={<ConsumerOrdersReceiptContainer />}
                />
              </Route>

              <Route path="/plans" element={<ConsumerPlansContainer />} />

              <Route
                path="/team-plans"
                element={<ConsumerTeamPlansContainer />}
              />
            </Route>

            <Route path="/" element={<Navigate to="" />} />
          </Route>

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      )}
    </div>
  );
};

export default CustomerRoutesContainer;

import { Link } from "react-router-dom";
import Button from "components/Button";
import { humanizeSchedule } from "enums/PaymentSchedules";
import { PlanType } from "enums/PlanType";

type Props = {
  subscription: any;
  onUse: any;
};

export default function CustomerSubscriptionCard(props: Props) {
  const { subscription, onUse } = props;

  return (
    <div
      key={subscription?._id}
      className="profile-box w-[350px] h-[420px] my-4"
    >
      <div className="relative">
        <img
          src={subscription?.merchant?.businessBanner}
          className="rounded-t-md w-full h-[200px]"
        />

        <div>
          <img
            src={subscription?.merchant?.businessLogo}
            className="rounded-full absolute right-0 -mt-10 mr-4 w-20 h-20"
          />
        </div>
      </div>

      <div className="p-4">
        <div className="text-grey text-xl font-semibold">
          {subscription?.plan?.title}
        </div>

        <div className="text-grey text-lg font-medium mt-1">
          ${subscription?.price?.amount}{" "}
          {humanizeSchedule(subscription?.plan?.schedule)}
        </div>

        <div className="text-grey text-xs font-medium mt-1">
          {subscription?.plan?.type === PlanType.UNLIMITED_OFFERING
            ? "UNLIMITED"
            : `${subscription?.plan?.quantityLimit} PER ${subscription?.plan?.schedule}`}
        </div>

        <div className="text-grey text-sm font-medium mt-2">
          {subscription?.plan?.description}
        </div>

        <div className="flex justify-between items-center mt-5">
          <div className="w-2/5">
            <Button
              label="Use Now"
              color="secondary"
              onClick={() => onUse(subscription)}
              style={{
                borderRadius: 100,
                fontSize: 14,
                color: "#FFFFFF",
                height: 40,
              }}
              fullWidth
            />
          </div>

          <div className="flex justify-center w-1/2">
            <Link
              to={`/subscription/details?id=${subscription?._id}`}
              className="underline cursor-pointer text-grey font-medium"
            >
              View Details
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

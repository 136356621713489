import {
  MERCHANT_ORDERS_SET_IS_LOADING,
  MERCHANT_ORDERS_SET_ORDERS_FOR_TODAY,
  MERCHANT_ORDERS_SET_FUTURE_ORDERS,
  MERCHANT_ORDERS_SET_PAST_ORDERS,
  MERCHANT_ORDERS_FETCH_SAGA,
  MERCHANT_ORDERS_UPDATE_SAGA,
} from "./types";

export const merchantOrdersSetIsLoading = (payload: any) => ({
  type: MERCHANT_ORDERS_SET_IS_LOADING,
  payload,
});

export const merchantOrderesSetOrdersForToday = (payload: any) => ({
  type: MERCHANT_ORDERS_SET_ORDERS_FOR_TODAY,
  payload,
});

export const merchantOrderesSetFutureOrders = (payload: any) => ({
  type: MERCHANT_ORDERS_SET_FUTURE_ORDERS,
  payload,
});

export const merchantOrderesSetPastOrders = (payload: any) => ({
  type: MERCHANT_ORDERS_SET_PAST_ORDERS,
  payload,
});

// async

export const merchantOrdersFetchSaga = (payload: { duration: string }) => ({
  type: MERCHANT_ORDERS_FETCH_SAGA,
  payload,
});

export const merchantOrdersUpdateSaga = (id: string, payload: any) => ({
  type: MERCHANT_ORDERS_UPDATE_SAGA,
  id,
  payload,
});

import { RESET_APP_STATE } from "app/state/types";
import {
  CUSTOMER_CHECKOUT_SET_IS_LOADING,
  CUSTOMER_CHECKOUT_SET_CONFIG,
  CUSTOMER_CHECKOUT_SET_DETAILS,
  CUSTOMER_CHECKOUT_SET_VERIFICATION_CONFIG,
} from "./types";
import { CheckoutSteps } from "enums/Checkout";

type CustomerCheckoutStateType = {
  message: {
    type: string;
    value: string;
  };
  isLoading: boolean;
  details: {
    merchant: any;
    plan: any;
    price: any;
    paymentMethods: any;
  };
  checkoutConfig: {
    steps: any;
    currentStep: {
      id?: string;
      label?: string;
      title?: string;
    };
    clientSecret: string;
    isCheckoutFormLoading: boolean;
    isMakingPayment: boolean;
    isInitialSetup: boolean;
    isAddingNewPaymentMethod: boolean;
    isFetchingPaymentMethods: boolean;
    paymentMethodId: string;
    requiresAction: boolean;
  };
  verificationConfig: {
    message: string;
    isLoading: boolean;
  };
};

const initialState: CustomerCheckoutStateType = {
  message: {
    type: "",
    value: "",
  },
  isLoading: false,
  details: {
    merchant: {},
    plan: {},
    price: {},
    paymentMethods: {},
  },
  checkoutConfig: {
    steps: CheckoutSteps,
    currentStep: {},
    clientSecret: "",
    isCheckoutFormLoading: false,
    isMakingPayment: false,
    isInitialSetup: false,
    isAddingNewPaymentMethod: false,
    isFetchingPaymentMethods: false,
    paymentMethodId: "",
    requiresAction: false,
  },
  verificationConfig: {
    message: "",
    isLoading: false,
  },
};

const customerCheckoutReducer = (
  state: CustomerCheckoutStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CUSTOMER_CHECKOUT_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case CUSTOMER_CHECKOUT_SET_DETAILS:
      return { ...state, details: { ...state.details, ...action.payload } };

    case CUSTOMER_CHECKOUT_SET_CONFIG:
      return {
        ...state,
        checkoutConfig: { ...state.checkoutConfig, ...action.payload },
      };

    case CUSTOMER_CHECKOUT_SET_VERIFICATION_CONFIG:
      return {
        ...state,
        verificationConfig: { ...state.verificationConfig, ...action.payload },
      };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default customerCheckoutReducer;

export const truncateString = (input: string, maxAllowedLength: number) =>
  input?.length > maxAllowedLength
    ? `${input?.substring(0, maxAllowedLength)}...`
    : input;

export const humanize = (value: string) =>
  value.replace(/^[\s_]+|[\s_]+$/g, "").replace(/[_\s]+/g, " ");

export const renderVariationString = (variations: Array<any>) => {
  let variationString = "";

  variations?.forEach((elem: any, index: number) => {
    variationString += `$${elem?.price} ${
      index === variations?.length - 1 ? `- ${elem?.name}` : `- ${elem?.name}, `
    }`;
  });

  return truncateString(variationString, 80);
};

export const validateEmail = (email: string) =>
  String(email)
    .trim()
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    );

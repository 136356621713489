import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Spinner from "components/Spinner";
import { merchantConnectSquareAccountSaga } from "../state/actions";

export default function MerchantConnectSquareCallbackContainer() {
  const dispatch = useDispatch();

  const { isLoading } = useSelector((store: any) => store?.merchantConnect);

  const [searchParams] = useSearchParams();

  useEffect(() => {
    const code = searchParams.get("code");

    if (code) {
      dispatch(merchantConnectSquareAccountSaga({ code }));
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : null}
    </div>
  );
}

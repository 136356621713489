import {
  MERCHANT_INVITATIONS_SET_IS_LOADING,
  MERCHANT_INVITATIONS_SET_INVITATIONS,
  MERCHANT_INVITATIONS_SET_INVITE_CUSTOMER_DIALOG_CONFIG,
  MERCHANT_INVITATIONS_FETCH_INVITATIONS_SAGA,
  MERCHANT_INVITATIONS_SEND_INVITATIONS_SAGA,
} from "./types";

export const merchantInvitationsSetIsLoading = (payload: any) => ({
  type: MERCHANT_INVITATIONS_SET_IS_LOADING,
  payload,
});

export const merchantInvitationsSetInvitations = (payload: any) => ({
  type: MERCHANT_INVITATIONS_SET_INVITATIONS,
  payload,
});

export const merchantInvitationsSetInviteCustomerDialogConfig = (
  payload: any
) => ({
  type: MERCHANT_INVITATIONS_SET_INVITE_CUSTOMER_DIALOG_CONFIG,
  payload,
});

// async

export const merchantInvitationsFetchInvitationsSaga = () => ({
  type: MERCHANT_INVITATIONS_FETCH_INVITATIONS_SAGA,
});

export const merchantInvitationsSendInvitationsSaga = (payload: any) => ({
  type: MERCHANT_INVITATIONS_SEND_INVITATIONS_SAGA,
  payload,
});

import {
  MERCHANT_DISCOUNTS_SET_IS_LOADING,
  MERCHANT_DISCOUNTS_SET_DISCOUNTS,
  MERCHANT_DISCOUNTS_SET_SELECTED_DISCOUNT,
  MERCHANT_DISCOUNTS_FETCH_SAGA,
  MERCHANT_DISCOUNTS_FETCH_SELECTED_DISCOUNT_SAGA,
  MERCHANT_DISCOUNTS_CREATE_SAGA,
  MERCHANT_DISCOUNTS_UPDATE_SAGA,
  MERCHANT_DISCOUNTS_INVITE_CUSTOMER_SAGA,
} from "./types";

export const merchantDiscountsSetIsLoading = (payload: any) => ({
  type: MERCHANT_DISCOUNTS_SET_IS_LOADING,
  payload,
});

export const merchantDiscountsSetDiscounts = (payload: any) => ({
  type: MERCHANT_DISCOUNTS_SET_DISCOUNTS,
  payload,
});

export const merchantDiscountsSetSelectedDiscount = (payload: any) => ({
  type: MERCHANT_DISCOUNTS_SET_SELECTED_DISCOUNT,
  payload,
});

// async

export const merchantDiscountsFetchSaga = () => ({
  type: MERCHANT_DISCOUNTS_FETCH_SAGA,
});

export const merchantDiscountsFetchSelectedDiscountSaga = (payload: any) => ({
  type: MERCHANT_DISCOUNTS_FETCH_SELECTED_DISCOUNT_SAGA,
  payload,
});

export const merchantDiscountsCreateSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_DISCOUNTS_CREATE_SAGA,
  payload,
  navigate,
});

export const merchantDiscountsUpdateSaga = (payload: any) => ({
  type: MERCHANT_DISCOUNTS_UPDATE_SAGA,
  payload,
});

export const merchantDiscountsInviteCustomerSaga = (payload: any) => ({
  type: MERCHANT_DISCOUNTS_INVITE_CUSTOMER_SAGA,
  payload,
});

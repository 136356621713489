export const PaymentSchedules = {
  WEEK: "WEEK",
  BI_WEEK: "BI_WEEK",
  MONTH: "MONTH",
  QUATER: "QUATER",
  HALF_YEAR: "HALF_YEAR",
  YEAR: "YEAR",
};

export const AvailableSchedules = [
  {
    label: "Weekly",
    value: PaymentSchedules.WEEK,
  },
  {
    label: "Bi-Weekly",
    value: PaymentSchedules.BI_WEEK,
  },
  {
    label: "Monthly",
    value: PaymentSchedules.MONTH,
  },
];

export const humanizeSchedule = (schedule: string) => {
  const mapper = {
    [PaymentSchedules.WEEK]: "Weekly",
    [PaymentSchedules.BI_WEEK]: "Bi-Weekly",
    [PaymentSchedules.MONTH]: "Monthly",
    [PaymentSchedules.QUATER]: "Quaterly",
    [PaymentSchedules.HALF_YEAR]: "Half yearly",
    [PaymentSchedules.YEAR]: "Yearly",
  };

  return mapper[schedule] || null;
};

import {
  RESET_APP_STATE,
  APP_SET_SUBDOMAIN,
  APP_SET_PLATFORM_TYPE,
  APP_SET_ERROR,
  APP_INIT_SAGA,
  APP_LOGOUT_SAGA,
} from "./types";

export const resetAppState = () => ({
  type: RESET_APP_STATE,
});

export const appSetSubdomain = (payload: string) => ({
  type: APP_SET_SUBDOMAIN,
  payload,
});

export const appSetPlatformType = (payload: string) => ({
  type: APP_SET_PLATFORM_TYPE,
  payload,
});

export const appSetError = (payload: {
  severity: string;
  message: string;
}) => ({
  type: APP_SET_ERROR,
  payload,
});

// async
export const appInitSaga = () => ({
  type: APP_INIT_SAGA,
});

export const appLogoutSaga = () => ({
  type: APP_LOGOUT_SAGA,
});

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Spinner from "components/Spinner";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import {
  merchantSettingsPaymentProcessorsFetchAccountDetailsSaga,
  merchantSettingsPaymentProcessorsConnectStripeAccountSaga,
  merchantSettingsUpdateProcessingFeesType,
} from "../state/actions";
import { ProcessingFeesType } from "enums/Payments";

const PROCESSING_FEES_SETTINGS = [
  {
    label: "Merchant will pay processing fees",
    value: ProcessingFeesType.MERCHANT,
  },
  {
    label: "Customer will pay processing fees",
    value: ProcessingFeesType.CUSTOMER,
  },
  {
    label: "Merchant and customer will split processing fees",
    value: ProcessingFeesType.SPLIT,
  },
];

export default function MerchantSettingsPaymentProcessors() {
  const dispatch = useDispatch();

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const { isLoading, stripe, square, taxes } = useSelector(
    (store: any) => store?.merchantSettings
  );

  const [state, setState] = useMergeState({
    shouldConnectStripe: true,
    shouldConnectSquare: true,
    processingFeesType: ProcessingFeesType.MERCHANT,
  });

  const handleChangeProcessingFeesType = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
    });
    dispatch(
      merchantSettingsUpdateProcessingFeesType({
        processingFeesType: event.target.value,
      })
    );
  };

  const handleCreateStripeConnectAccount = () => {
    dispatch(merchantSettingsPaymentProcessorsConnectStripeAccountSaga());
  };

  const handleConnectStripeOauth = () => {
    window.location.href = process.env.REACT_APP_STRIPE_OAUTH_URL || "";
  };

  const handleConnectSquare = () => {
    window.location.href = process.env.REACT_APP_SQUARE_CONNECT_URL || "";
  };

  useEffect(() => {
    const payload: any = {};

    if (stripe) {
      payload.shouldConnectStripe = false;
    }

    if (square) {
      payload.shouldConnectSquare = false;
    }

    setState(payload);
  }, [stripe, square]);

  useEffect(() => {
    if (user?.merchant?.processingFeesType) {
      setState({ processingFeesType: user?.merchant?.processingFeesType });
    }
  }, [user]);

  useEffect(() => {
    dispatch(merchantSettingsPaymentProcessorsFetchAccountDetailsSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div className="lg:w-3/4">
          <div>
            <div className="text-grey text-lg font-medium">Processing fees</div>

            <div className="mt-4">
              <Select
                fullWidth
                variant="outlined"
                name="processingFeesType"
                value={state?.processingFeesType}
                onChange={handleChangeProcessingFeesType}
                color="secondary"
                style={{
                  height: 40,
                  borderRadius: 6,
                }}
              >
                {PROCESSING_FEES_SETTINGS.map((item: any) => (
                  <MenuItem key={item.label} value={item.value}>
                    {item.label}
                  </MenuItem>
                ))}
              </Select>
            </div>
          </div>

          <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-4 w-full mt-8">
            {state?.shouldConnectStripe ? (
              <div>
                {stripe &&
                stripe?.defaultCurrency &&
                (!stripe?.payoutsEnabled ||
                  !stripe?.chargesEnabled ||
                  !stripe?.detailsSubmitted) ? (
                  <div className="text-grey text-sm mb-4">
                    It seems like you tried setting up Stripe but did not
                    complete the process, please try again.
                  </div>
                ) : (
                  <div className="text-grey text-sm mb-4">
                    We use Stripe to make sure you get paid on time and to keep
                    your personal bank details secure. Click on{" "}
                    <b>&quot;Connect with Stripe&quot;</b>
                    to setup your payments on Stripe.
                  </div>
                )}

                <Button
                  label="Connect With Stripe"
                  color="secondary"
                  onClick={handleConnectStripeOauth}
                  style={{
                    borderRadius: 4,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 40,
                  }}
                />
              </div>
            ) : (
              <div>
                <div className="text-xl font-semibold text-grey">Stripe</div>

                <div className="mt-4">
                  <div className="flex justify-between items-center text-grey my-2">
                    <span className="font-medium">Country</span>
                    <span>{stripe?.country}</span>
                  </div>

                  <div className="flex justify-between items-center my-2">
                    <span className="font-medium">Default Currency</span>
                    <span>
                      {String(stripe?.defaultCurrency)?.toUpperCase()}
                    </span>
                  </div>

                  <div className="flex justify-between items-center my-2">
                    <span className="font-medium">Payouts Enabled</span>
                    <span>{stripe?.payoutsEnabled ? "Yes" : "No"}</span>
                  </div>

                  <div className="flex justify-between items-center my-2">
                    <span className="font-medium">Charges Enabled</span>
                    <span>{stripe?.chargesEnabled ? "Yes" : "No"}</span>
                  </div>

                  <div className="flex justify-between items-center my-2">
                    <span className="font-medium">Details Submitted</span>
                    <span>{stripe?.detailsSubmitted ? "Yes" : "No"}</span>
                  </div>
                </div>
              </div>
            )}
          </div>

          {!user?.merchant?.isB2B && (
            <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-4 w-full mt-8">
              {state?.shouldConnectSquare ? (
                <div>
                  <div className="text-grey text-sm mb-4">
                    We push orders for fulfillments to your existing Square POS.
                    Click on <b>&quot;Connect with Square&quot;</b>
                    to setup your orders on Square.
                  </div>

                  <Button
                    label="Connect With Square"
                    color="info"
                    onClick={handleConnectSquare}
                    style={{
                      borderRadius: 4,
                      fontSize: 14,
                      color: "#FFFFFF",
                      height: 40,
                    }}
                  />
                </div>
              ) : (
                <div>
                  <div className="text-xl font-semibold text-grey">Square</div>

                  <div className="mt-4">
                    <div className="flex justify-between items-center my-2">
                      <span className="font-medium">Business Name</span>
                      <span>{square?.businessName}</span>
                    </div>

                    <div className="flex justify-between items-center text-grey my-2">
                      <span className="font-medium">Country</span>
                      <span>{square?.country}</span>
                    </div>

                    <div className="flex justify-between items-center my-2">
                      <span className="font-medium">Default Currency</span>
                      <span>{String(square?.currency)?.toUpperCase()}</span>
                    </div>
                  </div>

                  <hr className="my-4" />

                  <div>
                    <div className="text-grey font-medium">Taxes</div>

                    <div className="mt-4">
                      {taxes?.map((tax: any, index: number) => (
                        <div key={tax?._id}>
                          <span>{index + 1}) </span>
                          <span>{tax?.taxData?.name}</span> -{" "}
                          <span>{tax?.taxData?.percentage}%</span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
}

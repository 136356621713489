import { useRef, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AddIcon from "@mui/icons-material/Add";
import Button from "components/Button";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import MerchantSelectProductsDialog from "components/MerchantSelectProductsDialog";
import { useMergeState } from "utils/custom-hooks";
import { toBase64 } from "utils/common";
import { HOURS, PAYMENT_SCHEDULE_LIST } from "./helper";
import { PlanType } from "enums/PlanType";
import { PaymentSchedules } from "enums/PaymentSchedules";
import {
  createMerchantPlan,
  getMerchantPlanById,
  hideMerchantPlan,
  updateMerchantPlan,
} from "api";
import { appSetError } from "app/state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";

export default function MerchantCreatePlanContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const planId = searchParams.get("planId") || "";

  const [initialState, setInitialState] = useMergeState({
    isLoading: false,

    title: "",
    description: "",
    type: PlanType.UNLIMITED_OFFERING,
    schedule: PaymentSchedules.MONTH,
    timeLimit: 0, // UNLIMITED_OFFERING
    quantity: 0, // QUANTITY_LIMIT
    // discountAmount: 0, // MONTHLY_DISCOUNT - percentage off
    // annualAdditionalDiscountAmount: 0, // MONTHLY_DISCOUNT - percentage off
    amount: 0,
    images: [],
    products: [],

    isEditMode: false,
    hasPlanPricingDetailsChanged: false,
    shouldOpenSelectProductsDialog: false,
    errors: {},
  });

  const [state, setState] = useMergeState({
    isLoading: false,

    title: "",
    description: "",
    type: PlanType.UNLIMITED_OFFERING,
    schedule: PaymentSchedules.MONTH,
    timeLimit: 0, // UNLIMITED_OFFERING
    quantity: 0, // QUANTITY_LIMIT
    // discountAmount: 0, // MONTHLY_DISCOUNT - percentage off
    // annualAdditionalDiscountAmount: 0, // MONTHLY_DISCOUNT - percentage off
    amount: 0,
    images: [],
    products: [],

    isEditMode: false,
    hasPlanPricingDetailsChanged: false,
    shouldOpenSelectProductsDialog: false,
    errors: {},
  });

  const imagesRef = useRef<any>();

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const handleChangePlanType = (type: String) => {
    setState({ type });
  };

  const handleImagesRef = () => {
    imagesRef.current.click();
  };

  const handleChangeImages = async (event: any) => {
    const { files } = event.target;

    if (files.length < 1 || files.length > 3) return;

    setState({
      images: [...files],
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.title) {
      payload = { title: true, ...payload };
      isValid = false;
    }

    if (!state.description) {
      payload = { description: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleOpenSelectProductsDialog = () => {
    setState({ shouldOpenSelectProductsDialog: true });
  };

  const handleCloseSelectProductsDialog = () => {
    setState({ shouldOpenSelectProductsDialog: false });
  };

  const handleAddProducts = (products: Array<any>) => {
    setState({ products });
  };

  const handleSave = async () => {
    try {
      setState({ isLoading: true });

      if (!isFormValid()) {
        return;
      }

      const shouldEdit =
        state?.isEditMode && !state?.hasPlanPricingDetailsChanged;

      const images = await Promise.all(
        state?.images.map(async (image: any) => {
          if (image?.type) {
            return { base64: await toBase64(image), contentType: image?.type };
          }

          return image;
        })
      );

      let response: any = {};

      if (shouldEdit) {
        response = await updateMerchantPlan({
          id: planId,
          title: state?.title,
          description: state.description,
          images,
          products: state?.products?.map((product: any) => product?._id),
        });
      } else {
        response = await createMerchantPlan({
          title: state?.title,
          description: state.description,
          images,
          planType: state?.type,
          schedule: state?.schedule,
          timeLimit: state?.timeLimit,
          quantity: state?.quantity,
          discountAmount: state?.discountAmount,
          annualAdditionalDiscountAmount: state?.annualAdditionalDiscountAmount,
          amount: state?.amount,
          products: state?.products?.map((product: any) => product?._id),
        });

        navigate("/plans");
      }

      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );

      setState({ isLoading: false });
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    } finally {
      setState({ isLoading: false });
    }
  };

  const handleHidePlan = async () => {
    try {
      const response = await hideMerchantPlan({
        id: planId,
        hidden: !state?.hidden,
      });

      setState({ hidden: !state?.hidden });

      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    }
  };

  useEffect(() => {
    if (!state.isEditMode) {
      return;
    }

    if (
      initialState?.type !== state?.type ||
      initialState?.schedule !== state?.schedule ||
      String(initialState?.amount) !== String(state?.amount)
    ) {
      setState({ hasPlanPricingDetailsChanged: true });
      return;
    }

    setState({ hasPlanPricingDetailsChanged: false });
  }, [state?.type, state?.schedule, state?.amount]);

  const init = async () => {
    try {
      setState({ isLoading: true });

      if (planId) {
        const { data: plan } = await getMerchantPlanById(planId);

        const payload = {
          title: plan?.title,
          description: plan?.description,
          images: plan?.images,
          type: plan?.price?.type,
          schedule: plan?.price?.schedule,
          timeLimit: plan?.price?.timeLimit, // UNLIMITED_OFFERING
          quantity: plan?.price?.quantity, // QUANTITY_LIMIT
          // discountAmount: plan?.discountAmount, // MONTHLY_DISCOUNT - percentage off
          // annualAdditionalDiscountAmount: plan?.annualAdditionalDiscountAmount, // MONTHLY_DISCOUNT - percentage off
          amount: plan?.price?.amount,
          products: plan?.products,
          hidden: plan?.hidden,

          isEditMode: true,
        };

        setState({ ...payload });
        setInitialState({ ...payload });
      }

      setState({ isLoading: false });
    } catch (error) {
      setState({ isLoading: false });
    }
  };

  useEffect(() => {
    init();
  }, []);

  return (
    <div>
      <div>
        <div className="text-4xl font-semibold text-grey mb-4">
          {state.isEditMode ? "Edit plan" : "Create a new plan"}
        </div>

        <div className="w-full lg:w-3/5 my-8">
          <div>
            <div className="text-grey font-semibold">
              What do you want to call your plan?
            </div>

            <div className="mt-2 mb-2">
              <TextField
                fullWidth
                label="TITLE"
                variant="outlined"
                name="title"
                value={state.title}
                onChange={handleChange}
                required
                error={state?.errors?.title}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />

              {state?.errors?.title && (
                <ErrorMessage message="Title is required" />
              )}
            </div>
          </div>

          <div className="mt-5">
            <div className="text-grey font-semibold">
              How would you describe this plan?
            </div>

            <div className="mt-2 mb-2">
              <TextField
                fullWidth
                label="DESCRIPTION"
                variant="outlined"
                name="description"
                value={state.description}
                onChange={handleChange}
                required
                error={state?.errors?.description}
                multiline
                minRows={4}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />

              {state?.errors?.description && (
                <ErrorMessage message="Description is required" />
              )}
            </div>
          </div>

          <div className="mt-5">
            <div className="text-grey font-semibold">
              A picture is worth a thousand words
            </div>

            <div className="text-sm text-grey">
              Upload up to 3 images, these help motivate someone to subscribe by
              giving them a clear sense of the product or service
            </div>

            <div className="flex items-center mt-4">
              {state.images.map((elem: any) => {
                const image = elem?.type ? URL.createObjectURL(elem) : elem;
                return (
                  <img
                    key={image}
                    src={image}
                    alt=""
                    width={100}
                    height={100}
                    className="mr-4"
                  />
                );
              })}

              <div
                className="flex justify-center items-center text-grey text-sm font-medium cursor-pointer ml-4 upload-pictures"
                onClick={handleImagesRef}
              >
                <AddIcon /> Upload pictures
              </div>

              <input
                type="file"
                multiple
                accept="image/*"
                className="hidden"
                ref={imagesRef}
                onChange={handleChangeImages}
              />
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <div className="text-grey font-semibold mt-4">
              Apply to products
            </div>

            <div className="mt-4">
              <Button
                label="Select eligible products"
                color="secondary"
                onClick={handleOpenSelectProductsDialog}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>

            <div className="mt-4">
              {state?.products?.map((product: any, index: number) => (
                <div
                  key={product?._id}
                  className="w-1/2 h-8 my-2 flex justify-between items-center"
                >
                  <div className="text-grey">
                    {index + 1}) {product?.title}
                  </div>

                  {/* <IconButton onClick={() => handleRemoveProduct(product?._id)}>
                    <ClearOutlinedIcon />
                  </IconButton> */}
                </div>
              ))}
            </div>
          </div>

          <hr className="mt-4 mb-4" />

          <div>
            <div className="text-grey font-semibold">
              Determine the plan pricing & limits
            </div>

            <div className="text-sm text-grey">
              Select the pricing structure that works best for your plan
            </div>

            <div className="plan-details-box flex justify-between items-center px-3 py-2 mt-4">
              <div
                className={`flex justify-center items-center cursor-pointer rounded-md text-sm px-6 py-2 w-1/2 ${
                  state.type === PlanType.UNLIMITED_OFFERING
                    ? "bg-green text-white"
                    : ""
                }`}
                onClick={() =>
                  handleChangePlanType(PlanType.UNLIMITED_OFFERING)
                }
              >
                Unlimited Offering
              </div>

              <div
                className={`flex justify-center items-center cursor-pointer rounded-md text-sm px-6 py-2 w-1/2 ${
                  state.type === PlanType.QUANTITY_LIMIT
                    ? "bg-green text-white"
                    : ""
                }`}
                onClick={() => handleChangePlanType(PlanType.QUANTITY_LIMIT)}
              >
                Quantity Limit
              </div>

              {/* <div
              className={`flex justify-center items-center cursor-pointer rounded-md text-sm px-6 py-2 w-2/6 ${
                state.type === PlanType.MONTHLY_DISCOUNT
                  ? "bg-green text-white"
                  : ""
              }`}
              onClick={() => handleChangePlanType(PlanType.MONTHLY_DISCOUNT)}
            >
              Monthly Discount
            </div> */}
            </div>

            <div className="plan-details-box p-5 mt-2">
              <div className="flex justify-center items-center bg-yellow w-fit pl-2 pr-2 p-1 text-xs font-semibold">
                {state?.type === PlanType.UNLIMITED_OFFERING
                  ? "BEST FOR A PRODUCT BASED OFFERING"
                  : state?.type === PlanType.QUANTITY_LIMIT
                  ? "WORKS FOR BOTH PRODUCTS AND SERVICES BASED OFFERING"
                  : "WORKS BEST FOR SERVICES BASED OFFERING"}
              </div>

              <div className="text-grey font-medium mt-3">
                Select your payment schedule
              </div>

              <div className="text-sm text-grey mt-1">
                When is this rate set and when does it renew
              </div>

              <div className="w-3/4 mt-4">
                <Select
                  fullWidth
                  variant="outlined"
                  name="schedule"
                  value={state.schedule}
                  onChange={handleChange}
                  color="secondary"
                  style={{
                    width: 150,
                    height: 40,
                    borderRadius: 6,
                  }}
                >
                  {PAYMENT_SCHEDULE_LIST.map((item: any) => (
                    <MenuItem key={item.label} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </Select>
              </div>

              <hr className="mt-4 mb-4" />

              {state?.type === PlanType.UNLIMITED_OFFERING && (
                <div>
                  <div className="text-grey font-medium mt-3">
                    Set Time Limit Between Uses
                  </div>

                  <div className="text-sm text-grey mt-1">
                    If you would like to set a time limit between re-uses set it
                    here, max 24 hours
                  </div>

                  <div className="w-3/4 mt-4">
                    <Select
                      fullWidth
                      variant="outlined"
                      name="timeLimit"
                      value={state.timeLimit}
                      onChange={handleChange}
                      color="secondary"
                      style={{
                        width: 150,
                        height: 40,
                        borderRadius: 6,
                      }}
                      // endAdornment={
                      //   <InputAdornment
                      //     position="end"
                      //     style={{ marginRight: 15, fontSize: 10 }}
                      //   >
                      //     HOURS
                      //   </InputAdornment>
                      // }
                    >
                      {HOURS.map((item: any) => (
                        <MenuItem key={item.label} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                </div>
              )}

              {state?.type === PlanType.QUANTITY_LIMIT && (
                <div>
                  <div className="text-grey font-medium mt-3">
                    Set Quantity Limit
                  </div>

                  <div className="text-sm text-grey mt-1">
                    This is the number of products or services in this offering
                  </div>

                  <div className="mt-4">
                    <TextField
                      type="number"
                      variant="outlined"
                      name="quantity"
                      value={state.quantity}
                      onChange={handleChange}
                      onWheel={(event: any) => event.target.blur()}
                      color="secondary"
                      InputProps={{
                        style: {
                          width: 150,
                          height: 40,
                          borderRadius: 6,
                        },
                      }}
                    />
                  </div>
                </div>
              )}

              {state?.type === PlanType.MONTHLY_DISCOUNT && (
                <div>
                  <div className="text-grey font-medium mt-3">
                    Set Discount Amout
                  </div>

                  <div className="text-sm text-grey mt-1">
                    This is the percent off to subscribers based on a set rate
                  </div>

                  <div className="mt-4">
                    <TextField
                      type="number"
                      variant="outlined"
                      name="discountAmount"
                      value={state.discountAmount}
                      onChange={handleChange}
                      onWheel={(event: any) => event.target.blur()}
                      color="secondary"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            % PERCENT
                          </InputAdornment>
                        ),
                        style: {
                          width: 175,
                          height: 40,
                          borderRadius: 6,
                        },
                      }}
                    />
                  </div>

                  <div className="mt-4">
                    <div className="text-xs font-medium text-green-3 mb-2">
                      ANNUAL ADDITIONAL DISCOUNT AMOUNT
                    </div>

                    <TextField
                      type="number"
                      variant="outlined"
                      name="annualAdditionalDiscountAmount"
                      value={state.annualAdditionalDiscountAmount}
                      onChange={handleChange}
                      onWheel={(event: any) => event.target.blur()}
                      color="secondary"
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            % PERCENT
                          </InputAdornment>
                        ),
                        style: {
                          width: 175,
                          height: 40,
                          borderRadius: 6,
                        },
                      }}
                    />
                  </div>
                </div>
              )}

              <hr className="mt-4 mb-4" />

              <div className="text-grey font-medium mt-3">
                Price to Subscriber
              </div>

              <div className="text-sm text-grey mt-1">
                How much they will be charged on the basis you set above
              </div>

              <div className="mt-4">
                <TextField
                  fullWidth
                  type="number"
                  variant="outlined"
                  name="amount"
                  value={state.amount}
                  onChange={handleChange}
                  onWheel={(event: any) => event.target.blur()}
                  color="secondary"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">$</InputAdornment>
                    ),
                    style: {
                      width: 150,
                      height: 40,
                      borderRadius: 6,
                    },
                  }}
                />
              </div>
            </div>

            <div className="my-8">
              <div>
                {state.hasPlanPricingDetailsChanged && (
                  <div className="text-primary font-semibold my-4">
                    You have edited the plan&apos;s pricing details, this will
                    create a new plan.
                  </div>
                )}
              </div>

              <div className="w-full">
                <Button
                  label={
                    state.isEditMode && !state.hasPlanPricingDetailsChanged
                      ? "Save Plan"
                      : "Create Plan"
                  }
                  color="secondary"
                  onClick={handleSave}
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 50,
                  }}
                  fullWidth
                  loaderButton
                  loadingPosition="center"
                  loading={state?.isLoading}
                  disabled={state?.isLoading}
                />
              </div>

              {state?.isEditMode && (
                <div className="w-full mt-4">
                  <Button
                    label={`${state?.hidden ? "Unhide" : "Hide"} Plan`}
                    onClick={handleHidePlan}
                    style={{
                      borderRadius: 10,
                      fontSize: 14,
                      color: "#000000",
                      background: "rgb(231, 231, 231)",
                      boxShadow: "rgb(231 231 231) 0px 0px 0px 1px inset",
                      height: 50,
                    }}
                    fullWidth
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {state?.shouldOpenSelectProductsDialog && (
        <MerchantSelectProductsDialog
          open={state?.shouldOpenSelectProductsDialog}
          onClose={handleCloseSelectProductsDialog}
          onSave={handleAddProducts}
        />
      )}
    </div>
  );
}

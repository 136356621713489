import {
  MERCHANT_SETTINGS_SET_IS_LOADING,
  MERCHANT_SETTINGS_PAYMENT_PROCESSORS_SET_ACCOUNT_DETAILS,
  MERCHANT_SETTINGS_PAYMENT_PROCESSORS_CONNECT_STRIPE_ACCOUNT_SAGA,
  MERCHANT_SETTINGS_PAYMENT_PROCESSORS_FETCH_ACCOUNT_DETAILS_SAGA,
  MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_CONFIG,
  MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_PROFILE_DETAILS,
  MERCHANT_SETTINGS_BUSINESS_PROFILE_FETCH_PROFILE_DETAILS_SAGA,
  MERCHANT_SETTINGS_BUSINESS_PROFILE_UPDATE_PROFILE_DETAILS_SAGA,
  MERCHANT_SETTINGS_TEAMS_SET_DETAILS,
  MERCHANT_SETTINGS_TEAMS_SET_INVITE_MEMBER_DIALOG_CONFIG,
  MERCHANT_SETTINGS_TEAMS_FETCH_DETAILS_SAGA,
  MERCHANT_SETTINGS_TEAMS_INVITE_MEMBER_SAGA,
  MERCHANT_SETTINGS_UPDATE_PROCESSING_FEES_TYPE_SAGA,
} from "./types";

export const merchantSettingsSetIsLoading = (payload: any) => ({
  type: MERCHANT_SETTINGS_SET_IS_LOADING,
  payload,
});

export const merchantSettingsPaymentProcessorsSetAccountDetails = (
  payload: any
) => ({
  type: MERCHANT_SETTINGS_PAYMENT_PROCESSORS_SET_ACCOUNT_DETAILS,
  payload,
});

export const merchantSettingsBusinessProfileSetConfig = (payload: any) => ({
  type: MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_CONFIG,
  payload,
});

export const merchantSettingsBusinessProfileSetProfileDetails = (
  payload: any
) => ({
  type: MERCHANT_SETTINGS_BUSINESS_PROFILE_SET_PROFILE_DETAILS,
  payload,
});

export const merchantSettingsTeamsSetDetails = (payload: any) => ({
  type: MERCHANT_SETTINGS_TEAMS_SET_DETAILS,
  payload,
});

export const merchantSettingsTeamsSetInviteMemberDialogConfig = (
  payload: any
) => ({
  type: MERCHANT_SETTINGS_TEAMS_SET_INVITE_MEMBER_DIALOG_CONFIG,
  payload,
});

// async

export const merchantSettingsPaymentProcessorsConnectStripeAccountSaga =
  () => ({
    type: MERCHANT_SETTINGS_PAYMENT_PROCESSORS_CONNECT_STRIPE_ACCOUNT_SAGA,
  });

export const merchantSettingsPaymentProcessorsFetchAccountDetailsSaga = () => ({
  type: MERCHANT_SETTINGS_PAYMENT_PROCESSORS_FETCH_ACCOUNT_DETAILS_SAGA,
});

export const merchantSettingsBusinessProfileFetchProfileDetailsSaga = () => ({
  type: MERCHANT_SETTINGS_BUSINESS_PROFILE_FETCH_PROFILE_DETAILS_SAGA,
});

export const merchantSettingsBusinessProfileUpdateProfileDetailsSaga = (
  payload: any
) => ({
  type: MERCHANT_SETTINGS_BUSINESS_PROFILE_UPDATE_PROFILE_DETAILS_SAGA,
  payload,
});

export const merchantSettingsTeamsFetchDetailsSaga = () => ({
  type: MERCHANT_SETTINGS_TEAMS_FETCH_DETAILS_SAGA,
});

export const merchantSettingsTeamsInviteMemberSaga = (payload: any) => ({
  type: MERCHANT_SETTINGS_TEAMS_INVITE_MEMBER_SAGA,
  payload,
});

export const merchantSettingsUpdateProcessingFeesType = (payload: any) => ({
  type: MERCHANT_SETTINGS_UPDATE_PROCESSING_FEES_TYPE_SAGA,
  payload,
});

import { CardElement } from "@stripe/react-stripe-js";

export default function CardSection() {
  return (
    <label>
      <CardElement
        options={{
          style: {
            base: {
              color: "#F89939",
              fontFamily: '"Helvetica Neue", Helvetica, sans-serif', // eslint-disable-line
              fontSmoothing: "antialiased",
              fontSize: "18px",
              "::placeholder": {
                color: "#aab7c4",
              },
            },
            invalid: {
              color: "#fa755a",
              iconColor: "#fa755a",
            },
          },
        }}
      />
    </label>
  );
}

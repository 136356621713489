import { Outlet } from "react-router-dom";
import CustomerNavBar from "components/Customer/NavBar";

const shouldRenderNavBar = () => {
  if (
    window.location.pathname.includes("/login") ||
    window.location.pathname.includes("/signup") ||
    window.location.pathname.includes("/orders/receipt")
  ) {
    return false;
  }

  return true;
};

export default function ConsumerDefaultLayout() {
  return (
    <div>
      {shouldRenderNavBar() && <CustomerNavBar />}

      <div className="w-full min-h-screen sm:h-screen overflow-y-scroll overflow-x-scroll lg:px-12">
        <Outlet />
      </div>
    </div>
  );
}

import { useEffect } from "react";
import { nanoid } from "nanoid";
import Button from "components/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
// import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import { Dialog, DialogTitle } from "components/Dialog";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import { useMergeState } from "utils/custom-hooks";
import { validateEmail } from "utils/string";

type Props = {
  open: boolean;
  onClose: any;
  isInviting: boolean;
  onInvite: any;
};

export default function CustomerAddMemberDialog(props: Props) {
  const { open, onClose, isInviting, onInvite } = props;

  const [state, setState] = useMergeState({
    members: [],
  });

  const handleChange = (event: any, index: number) => {
    const newMembers = [...state?.members];

    newMembers[index][event?.target?.name] = event?.target?.value;

    setState({
      members: [...newMembers],
      errors: {
        [index]: {
          ...state?.errors?.[index],
          [event?.target?.name]: false,
        },
      },
    });
  };

  const handleAddMember = () => {
    const newMembers = [...state?.members];

    newMembers.push({
      id: nanoid(),
      name: "",
      email: "",
      // phone: "",

      errors: {},
    });

    setState({
      members: newMembers,
    });
  };

  const handleRemoveMember = (index: number) => {
    const newMembers = [...state?.members];

    newMembers.splice(index, 1);

    setState({
      members: newMembers,
    });
  };

  const isFormValid = () => {
    let isValid = true;

    const payload: any = {};

    state?.members?.forEach((elem: any, index: number) => {
      if (!String(elem?.name).trim()) {
        payload[index] = { ...payload[index], name: true };
        isValid = false;
      }

      if (!validateEmail(elem?.email)) {
        payload[index] = { ...payload[index], email: true };
        isValid = false;
      }
    });

    setState({ errors: payload });

    return isValid;
  };

  const handleInvite = () => {
    if (!isFormValid()) {
      return;
    }

    onInvite(state?.members);
  };

  useEffect(() => {
    setState({
      members: [
        {
          id: nanoid(),
          name: "",
          email: "",
          // phone: "",
        },
      ],
    });
  }, []);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose();
        }
      }}
      disableEscapeKeyDown
      open={open}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle onClose={onClose}>Add Member</DialogTitle>

      <DialogContent dividers>
        {state?.members?.map((member: any, index: number) => (
          <div
            key={member?.id}
            className="flex justify-between items-center my-4"
          >
            <div className="w-2/5 mr-4">
              <TextField
                fullWidth
                label="NAME"
                variant="outlined"
                name="name"
                value={member?.name}
                onChange={(event) => handleChange(event, index)}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                required
                error={state?.errors?.[index]?.name}
              />

              {state?.errors?.[index]?.name && (
                <ErrorMessage message="Please enter name" />
              )}
            </div>

            <div className="w-2/5">
              <TextField
                fullWidth
                label="EMAIL"
                variant="outlined"
                name="email"
                value={member?.email}
                onChange={(event) => handleChange(event, index)}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                required
                error={state?.errors?.[index]?.email}
              />

              {state?.errors?.[index]?.email && (
                <ErrorMessage message="Please enter a valid email" />
              )}
            </div>

            {/* <div className="w-2/5 ml-4">
              <TextField
                fullWidth
                label="PHONE"
                variant="outlined"
                name="phone"
                value={member?.phone}
                onChange={(event) => handleChange(event, index)}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                type="number"
                onWheel={(event: any) => event.target.blur()}
                onInput={(event) => {
                  if (
                    // @ts-ignore
                    event?.target?.value
                  ) {
                    // @ts-ignore
                    event.target.value = Math.max(
                      0,
                      // @ts-ignore
                      parseInt(event.target.value, 10)
                    )
                      .toString()
                      .slice(0, 10);
                  }
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+1</InputAdornment>
                  ),
                }}
              />
            </div> */}

            <div className="w-1/4">
              {index === state?.members?.length - 1 ? (
                <IconButton onClick={handleAddMember}>
                  <AddIcon />
                </IconButton>
              ) : (
                <IconButton onClick={() => handleRemoveMember(index)}>
                  <RemoveIcon />
                </IconButton>
              )}

              {index !== 0 && index === state?.members?.length - 1 && (
                <IconButton
                  onClick={() => handleRemoveMember(index)}
                  sx={{ ml: 1 }}
                >
                  <RemoveIcon />
                </IconButton>
              )}
            </div>
          </div>
        ))}
      </DialogContent>

      <DialogActions>
        <div className="my-2">
          <Button
            label="Invite Members"
            color="info"
            onClick={handleInvite}
            style={{
              borderRadius: 4,
              fontSize: 14,
              color: "#FFFFFF",
              height: 40,
              width: 150,
            }}
            loaderButton
            loadingPosition="center"
            loading={isInviting}
            disabled={isInviting}
          />
        </div>
      </DialogActions>
    </Dialog>
  );
}

import {
  CUSTOMER_CONFIRM_ADD_PAYMENT_METHOD_SET_IS_LOADING,
  CUSTOMER_CONFIRM_ADD_PAYMENT_METHOD_SAGA,
} from "./types";

export const customerConfirmAddPaymentMethodSetIsLoading = (payload: any) => ({
  type: CUSTOMER_CONFIRM_ADD_PAYMENT_METHOD_SET_IS_LOADING,
  payload,
});

// async

export const customerConfirmAddPaymentMethodSaga = (
  payload: any,
  navigate: any
) => ({
  type: CUSTOMER_CONFIRM_ADD_PAYMENT_METHOD_SAGA,
  payload,
  navigate,
});

import { useEffect } from "react";
import { useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import { merchantUpdateSettingsMenu } from "api";
import { useMergeState } from "utils/custom-hooks";

export default function MerchantSettingsPlans() {
  const { user } = useSelector((store: any) => store?.merchantAuth);

  const [state, setState] = useMergeState({});

  const handleChange = async (event: any, key: string) => {
    setState({ [key]: event.target.checked });
    await merchantUpdateSettingsMenu({ [key]: event.target.checked });
  };

  useEffect(() => {
    setState({
      onlyShowPlans: user?.merchant?.onlyShowPlans,
      hideOneTimePurchases: user?.merchant?.hideOneTimePurchases,
      shouldShowCorporatePlans: user?.merchant?.shouldShowCorporatePlans,
    });
  }, []);

  return (
    <div>
      <div className="flex items-center">
        <div className="w-1/2">
          <div>
            <span>Only show plans in menu</span>
            <Switch
              checked={state?.onlyShowPlans}
              onChange={(event: any) => handleChange(event, "onlyShowPlans")}
            />
          </div>

          <div className="my-4">
            <span>Hide one-time purchases from products</span>
            <Switch
              checked={state?.hideOneTimePurchases}
              onChange={(event: any) =>
                handleChange(event, "hideOneTimePurchases")
              }
            />
          </div>

          <div className="my-4">
            <span>Allow purchasing corporate plans</span>
            <Switch
              checked={state?.shouldShowCorporatePlans}
              onChange={(event: any) =>
                handleChange(event, "shouldShowCorporatePlans")
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}

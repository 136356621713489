export const MERCHANT_INVITATIONS_SET_IS_LOADING =
  "MERCHANT_INVITATIONS_SET_IS_LOADING";
export const MERCHANT_INVITATIONS_SET_INVITATIONS =
  "MERCHANT_INVITATIONS_SET_INVITATIONS";
export const MERCHANT_INVITATIONS_SET_INVITE_CUSTOMER_DIALOG_CONFIG =
  "MERCHANT_INVITATIONS_SET_INVITE_CUSTOMER_DIALOG_CONFIG";

// async

export const MERCHANT_INVITATIONS_FETCH_INVITATIONS_SAGA =
  "MERCHANT_INVITATIONS_FETCH_INVITATIONS_SAGA";
export const MERCHANT_INVITATIONS_SEND_INVITATIONS_SAGA =
  "MERCHANT_INVITATIONS_SEND_INVITATIONS_SAGA";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import { SelectChangeEvent } from "@mui/material/Select";
import OrderStatusSelect from "components/OrderStatusSelect";
import Spinner from "components/Spinner";
import { OrderScheduleType, OrderType } from "enums/orders";
import { formatDate } from "utils/date";
import { useMergeState } from "utils/custom-hooks";
import { merchantOrdersUpdateSaga } from "containers/merchant/orders/state/actions";
import { orderDetailsDrawerFetchOrderSaga } from "./state/actions";
import { PaymentType } from "enums/Payments";

type Props = {
  open: boolean;
  onClose: any;
};

export default function OrderDrawer(props: Props) {
  const { open, onClose } = props;

  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const orderId = searchParams.get("orderId");

  const { isLoading, order } = useSelector(
    (store: any) => store?.orderDetailsDrawer
  );

  const [state, setState] = useMergeState({ status: "" });

  const handleOrderStatusChange = (event: SelectChangeEvent) => {
    setState({ status: event?.target?.value });

    dispatch(
      merchantOrdersUpdateSaga(order?._id, { status: event?.target?.value })
    );
  };

  useEffect(() => {
    if (orderId) {
      dispatch(orderDetailsDrawerFetchOrderSaga(orderId));
    }
  }, []);

  useEffect(() => {
    setState({ status: order?.status });
  }, [order]);

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={onClose}>
        <div className="w-[250px] p-4 md:w-[450px] md:p-10">
          {isLoading ? (
            <div className="mt-10 flex justify-center">
              <Spinner loading={isLoading} />
            </div>
          ) : (
            <div>
              <div className="flex flex-col items-center">
                <div className="text-grey">
                  {order?.user?.firstName} {order?.user?.lastName}
                </div>

                <div className="text-grey text-xl font-semibold mt-4">
                  {order?.subscription?.plan?.title}
                </div>
              </div>

              <hr className="my-8" />

              <div className="text-grey text-lg font-semibold">
                Order details
              </div>

              {order?.type === OrderType.PURCHASE &&
                order?.cart?.items?.map((item: any) => (
                  <div key={item?.product}>
                    {item?.price?.purchaseType === PaymentType.ONE_TIME && (
                      <div className="text-grey font-medium my-2">
                        <span className="mr-1">{item?.price?.quantity}x</span>
                        <span>{item?.title}</span>
                      </div>
                    )}

                    {item?.price?.purchaseType === PaymentType.SUBSCRIPTION && (
                      <div className="flex justify-between items-center my-2">
                        <span className="text-grey font-medium">
                          {item?.title}
                        </span>
                        <span className="text-grey-2 text-xs">
                          {item?.price?.purchaseType}
                        </span>
                      </div>
                    )}
                  </div>
                ))}

              {order?.type === OrderType.REDEMPTION && (
                <div>
                  <div className="flex justify-between items-center my-2">
                    <span className="text-grey font-medium">
                      {order?.subscription?.product?.title}
                    </span>
                    <span className="text-grey-2 text-xs">
                      {order?.subscription?.type}
                    </span>
                  </div>

                  <div className="flex justify-between items-center my-4">
                    <span className="text-grey">Item</span>
                    <span className="text-grey-2 text-sm">
                      {order?.product?.title}
                    </span>
                  </div>

                  <div className="flex justify-between items-center my-4">
                    <span className="text-grey">Variation</span>
                    <span className="text-grey-2 text-sm">
                      {order?.variation?.name}
                    </span>
                  </div>

                  <div className="my-4">
                    <span className="text-grey">
                      Modifiers ({order?.modifiers?.length})
                    </span>

                    {order?.modifiers?.map((modifier: any, index: number) => (
                      <span
                        key={modifier?.id}
                        className="text-grey-2 text-sm my-2"
                      >
                        {index + 1}) {modifier?.name}
                      </span>
                    ))}
                  </div>
                </div>
              )}

              <div className="md:flex md:justify-between text-grey-2 my-8">
                <div>Ordered at</div>
                <div>{formatDate(order?.createdAt, "lll")}</div>
              </div>

              {order?.scheduleType === OrderScheduleType.SCHEDULED && (
                <div className="md:flex md:justify-between text-grey-2 my-2">
                  <div>Pickup</div>
                  <div>{formatDate(order?.scheduledTime, "lll")}</div>
                </div>
              )}

              {state?.status && (
                <div className="mt-4">
                  <div className="text-grey text-lg font-semibold">
                    Order status
                  </div>

                  <div className="mt-2">
                    <OrderStatusSelect
                      value={state?.status}
                      onChange={handleOrderStatusChange}
                    />
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
}

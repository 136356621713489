import { useDispatch, useSelector } from "react-redux";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import Button from "components/Button";
import { customerSettingsConfirmSetupIntentSaga } from "../state/actions";

export default function PaymentElementsContainer() {
  const dispatch = useDispatch();

  const stripe = useStripe();

  const elements = useElements();

  const {
    user: {
      customer: {
        stripeMetadata: { platformCustomerId },
      },
    },
  } = useSelector((store: any) => store?.customerAuth);

  const handleConfirmSetupIntent = (event: any) => {
    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    dispatch(
      customerSettingsConfirmSetupIntentSaga({
        stripe,
        elements,
        platformCustomerId,
      })
    );
  };

  return (
    <div>
      <PaymentElement />

      <div className="mt-5">
        <Button
          label="Add Payment Method"
          color="info"
          onClick={handleConfirmSetupIntent}
          style={{
            borderRadius: 10,
            fontSize: 16,
            color: "#FFFFFF",
            height: 50,
          }}
          fullWidth
          disabled={!stripe}
        />
      </div>
    </div>
  );
}

import {
  MERCHANT_DASHBOARD_SET_IS_LOADING,
  MERCHANT_DASHBOARD_SET_PLANS,
  MERCHANT_DASHBOARD_SET_STATS,
  MERCHANT_DASHBOARD_INIT_SAGA,
  MERCHANT_DASHBOARD_BUSINESS_PAGE_VIEWED_SAGA,
} from "./types";

export const merchantDashboardSetIsLoading = (payload: any) => ({
  type: MERCHANT_DASHBOARD_SET_IS_LOADING,
  payload,
});

export const merchantDashboardSetPlans = (payload: any) => ({
  type: MERCHANT_DASHBOARD_SET_PLANS,
  payload,
});

export const merchantDashboardSetStats = (payload: any) => ({
  type: MERCHANT_DASHBOARD_SET_STATS,
  payload,
});

// async

export const merchantDashboardInitSaga = () => ({
  type: MERCHANT_DASHBOARD_INIT_SAGA,
});

export const merchantDashboardBusinessPageViewedSaga = () => ({
  type: MERCHANT_DASHBOARD_BUSINESS_PAGE_VIEWED_SAGA,
});

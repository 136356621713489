import { takeLatest, put, call, all } from "redux-saga/effects";
import { getCustomerStore, getCustomerPlans } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  CUSTOMER_DASHBOARD_GET_PLANS_SAGA,
  CUSTOMER_DASHBOARD_GET_STORES_SAGA,
} from "./types";
import {
  customerDashboardSetIsLoading,
  customerDashboardSetPlans,
  customerDashboardSetStores,
} from "./actions";
import { appSetError } from "app/state/actions"; // app actions

function* customerDashboardGetStoresHandler(): any {
  try {
    yield put(customerDashboardSetIsLoading(true));

    const response = yield call(getCustomerStore);

    if (response?.success) {
      yield put(customerDashboardSetStores(response?.data));
    }

    yield put(customerDashboardSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerDashboardSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* customerDashboardGetPlansHandler(): any {
  try {
    yield put(customerDashboardSetIsLoading(true));

    const response = yield call(getCustomerPlans);

    if (response?.success) {
      yield put(customerDashboardSetPlans(response?.data));
    }

    yield put(customerDashboardSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(customerDashboardSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchCustomerDashboard() {
  yield takeLatest(
    CUSTOMER_DASHBOARD_GET_STORES_SAGA,
    customerDashboardGetStoresHandler
  );
  yield takeLatest(
    CUSTOMER_DASHBOARD_GET_PLANS_SAGA,
    customerDashboardGetPlansHandler
  );
}

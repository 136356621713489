import { RESET_APP_STATE } from "app/state/types";
import {
  CUSTOMER_SUBSCRIPTIONS_SET_IS_LOADING,
  CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTIONS,
  CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTION_DETAILS,
  CUSTOMER_SUBSCRIPTIONS_SET_ORDERS,
} from "./types";

type CustomerSubscriptionsStateType = {
  isLoading: boolean;
  subscriptions: Array<any>;
  subscription: any;
  usage: any;
  orders: Array<any>;
};

const initialState: CustomerSubscriptionsStateType = {
  isLoading: false,
  subscriptions: [],
  subscription: {},
  usage: {},
  orders: [],
};

const customerSubscriptionReducer = (
  state: CustomerSubscriptionsStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CUSTOMER_SUBSCRIPTIONS_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };

    case CUSTOMER_SUBSCRIPTIONS_SET_SUBSCRIPTION_DETAILS:
      return { ...state, ...action.payload };

    case CUSTOMER_SUBSCRIPTIONS_SET_ORDERS:
      return { ...state, orders: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default customerSubscriptionReducer;

import {
  CUSTOMER_CHECKOUT_SET_IS_LOADING,
  CUSTOMER_CHECKOUT_SET_CONFIG,
  CUSTOMER_CHECKOUT_SET_DETAILS,
  CUSTOMER_CHECKOUT_SET_VERIFICATION_CONFIG,
  CUSTOMER_CHECKOUT_CREATE_ACCOUNT_SAGA,
  CUSTOMER_CHECKOUT_GET_DETAILS_SAGA,
  CUSTOMER_CHECKOUT_CONFIRM_PAYMENT_SAGA,
  CUSTOMER_CHECKOUT_MAKE_PAYMENT_SAGA,
  CUSTOMER_CHECKOUT_VERIFY_PAYMENT_SAGA,
  CUSTOMER_CHECKOUT_ADD_NEW_PAYMENT_METHOD_SAGA,
  CUSTOMER_CHECKOUT_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA,
  CUSTOMER_CHECKOUT_GET_PAYMENT_METHODS_SAGA,
} from "./types";

export const customerCheckoutSetIsLoading = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_SET_IS_LOADING,
  payload,
});

export const customerCheckoutSetConfig = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_SET_CONFIG,
  payload,
});

export const customerCheckoutSetDetails = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_SET_DETAILS,
  payload,
});

export const customerCheckoutSetVerificationConfig = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_SET_VERIFICATION_CONFIG,
  payload,
});

// async

export const customerCheckoutCreateAccountSaga = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_CREATE_ACCOUNT_SAGA,
  payload,
});

export const customerCheckoutGetDetailsSaga = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_GET_DETAILS_SAGA,
  payload,
});

export const customerCheckoutConfirmPaymentSaga = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_CONFIRM_PAYMENT_SAGA,
  payload,
});

export const customerCheckoutMakePaymentSaga = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_MAKE_PAYMENT_SAGA,
  payload,
});

export const customerCheckoutVerifyPaymentSaga = (
  payload: any,
  navigate: any
) => ({
  type: CUSTOMER_CHECKOUT_VERIFY_PAYMENT_SAGA,
  payload,
  navigate,
});

export const customerCheckoutAddNewPaymentMethodSaga = (payload: any) => ({
  type: CUSTOMER_CHECKOUT_ADD_NEW_PAYMENT_METHOD_SAGA,
  payload,
});

export const customerCheckoutConfirmAddNewPaymentMethodSaga = (
  payload: any
) => ({
  type: CUSTOMER_CHECKOUT_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA,
  payload,
});

export const customerCheckoutGetPaymentMethodSaga = () => ({
  type: CUSTOMER_CHECKOUT_GET_PAYMENT_METHODS_SAGA,
});

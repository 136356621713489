import React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import Button from "components/Button";
import SearchField from "components/SearchField";
import { LoadMoreButton } from "components/LoadMoreButton";
import { useMergeState } from "utils/custom-hooks";

export default function MerchantMessagesContainer() {
  const [state, setState] = useMergeState({
    search: "",
  });

  const handleSendNewMessage = () => {};

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  return (
    <div>
      <div className="flex justify-between items-center">
        <div className="text-3xl font-semibold text-grey">Messages</div>
        <div className="w-2/5 mt-4">
          <SearchField
            fullWidth
            variant="outlined"
            name="search"
            placeholder="Search by name"
            value={state.search}
            onChange={handleChange}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>

        <div className="mt-4">
          <Button
            label="+ Send New Message"
            color="secondary"
            onClick={handleSendNewMessage}
            style={{
              borderRadius: 50,
              fontSize: 16,
              color: "#FFFFFF",
              height: 40,
            }}
          />
        </div>
      </div>

      <TableContainer
        className="table mt-8"
        style={{
          background: "#FFF9F7",
        }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <span className="text-grey">Date</span>
              </TableCell>
              <TableCell align="left">
                <span className="text-grey">From</span>
              </TableCell>
              <TableCell align="left">
                <span className="text-grey">Subject</span>
              </TableCell>
              <TableCell align="left">
                <span className="text-grey">Message</span>
              </TableCell>
              <TableCell align="left" />
            </TableRow>
          </TableHead>
          <TableBody>
            <TableRow>
              <TableCell component="th" scope="row">
                <span className="text-grey text-xs">04/18/22</span>
              </TableCell>
              <TableCell component="th" scope="row" align="left">
                <span className="text-grey text-xs">John Smith</span>
              </TableCell>
              <TableCell component="th" scope="row" align="left">
                <span className="text-grey text-xs">Order issue</span>
              </TableCell>
              <TableCell component="th" scope="row" align="left">
                <span className="text-grey text-xs">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                  do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                  Ut enim ad minim veniam,
                </span>
              </TableCell>
              <TableCell component="th" scope="row" align="left">
                <span className="text-grey text-xs underline cursor-pointer">
                  open
                </span>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>

      <div className="w-1/4 mt-8">
        <LoadMoreButton fullWidth>Load More</LoadMoreButton>
      </div>
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "@mui/material/TextField";
import AddNewCardDialog from "components/Customer/AddNewCardDialog";
import Button from "components/Button";
import ErrorMessage from "components/ErrorMessage";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import {
  customerTextToPaySetConfig,
  customerTextToPayCreateAccountSaga,
  customerTextToPayGetPaymentMethodSaga,
} from "../state/actions";
import { customerAuthGenerateOTPSaga } from "containers/consumer/auth/state/actions"; // auth actions
import { customerAddNewCardDialogSetConfig } from "components/Customer/AddNewCardDialog/state/actions"; // add new card dialog actions

export default function CustomerTextToPayPaymentContainer() {
  const dispatch = useDispatch();

  const [searchParams] = useSearchParams();

  const flow = searchParams.get("flow") || "";

  const isDemo = flow === "DEMO";

  const shouldCollectAddress = searchParams.get("address") || "";

  const { isLoggedIn } = useSelector((store: any) => store?.customerAuth);

  const {
    details: { paymentMethods },
    checkoutConfig: { isCheckoutFormLoading, paymentMethodId },
  } = useSelector((store: any) => store?.customerTextToPay);

  const {
    config: { open: shouldShowAddNewDialog },
  } = useSelector((store: any) => store?.customerAddNewDialog);

  const [state, setState] = useMergeState({
    firstName: "",
    lastName: "",

    addressLine1: "",
    addressLine2: "",
    city: "",
    state: "",
    country: "US",
    zipcode: "",

    otp: "",

    shouldVerifyOTP: false,
    resendOTPTimer: 0,
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const isFormValid = () => {
    if (isDemo) {
      return false;
    }

    let isValid = true;

    let payload = {};

    if (!state.firstName) {
      payload = { firstName: true, ...payload };
      isValid = false;
    }

    if (!state.phone) {
      payload = { phone: true, ...payload };
      isValid = false;
    }

    if (shouldCollectAddress && !state.addressLine1) {
      payload = { addressLine1: true, ...payload };
      isValid = false;
    }

    if (shouldCollectAddress && !state.city) {
      payload = { city: true, ...payload };
      isValid = false;
    }

    if (shouldCollectAddress && !state.state) {
      payload = { state: true, ...payload };
      isValid = false;
    }

    if (shouldCollectAddress && !state.zipcode) {
      payload = { zipcode: true, ...payload };
      isValid = false;
    }

    if (state.shouldVerifyOTP && !state.otp) {
      payload = { otp: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleGenerateOTP = () => {
    if (!isFormValid()) {
      return;
    }

    setState({ shouldVerifyOTP: true, resendOTPTimer: 30 });

    dispatch(customerAuthGenerateOTPSaga({ phone: state.phone }));
  };

  const handleCreateAccount = () => {
    if (!isFormValid()) {
      return;
    }

    const payload: any = {
      firstName: state?.firstName,
      lastName: state?.lastName,
      phone: state?.phone,
      otp: state?.otp,
    };

    if (shouldCollectAddress) {
      payload.address = {
        addressLine1: state?.addressLine1,
        addressLine2: state?.addressLine2,
        city: state?.city,
        state: state?.state,
        country: state?.country,
        zipcode: state?.zipcode,
      };
    }

    dispatch(customerTextToPayCreateAccountSaga(payload));
  };

  const handleReSendOTP = () => {
    if (state?.resendOTPTimer !== 0) {
      return;
    }

    setState({ resendOTPTimer: 30 });

    dispatch(customerAuthGenerateOTPSaga({ phone: state.phone }));
  };

  const handleOpenAddNewCardDialog = () => {
    dispatch(customerAddNewCardDialogSetConfig({ open: true }));
  };

  const handleCloseAddNewCardDialog = () => {
    dispatch(customerAddNewCardDialogSetConfig({ open: false }));
  };

  const handleSelectPaymentMethod = (paymentMethodId: string) => {
    dispatch(customerTextToPaySetConfig({ paymentMethodId }));
  };

  useEffect(() => {
    const timerId =
      state.resendOTPTimer > 0
        ? setInterval(
            () => setState({ resendOTPTimer: state.resendOTPTimer - 1 }),
            1000
          )
        : undefined;

    return () => clearInterval(timerId);
  }, [state.resendOTPTimer]);

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(customerTextToPayGetPaymentMethodSaga());
    }
  }, []);

  return (
    <div>
      <div className="flex justify-center">
        <div className="w-full p-4 md:px-12 md:py-4">
          <div className="flex flex-col items-center">
            <div className="w-full lg:w-2/5 border-[1px] border-solid border-[#f1f1f1] rounded-md p-4 my-2">
              <div
                className={
                  isLoggedIn ? "flex justify-between items-center" : ""
                }
              >
                <div className="text-grey font-semibold text-lg">
                  1. Account Details
                </div>
                {isLoggedIn && <div className="text-grey">Logged In</div>}
              </div>

              {!isLoggedIn && (
                <div className="mt-8">
                  <div className="flex flex-col md:flex-row justify-between items-center">
                    <div className="w-full md:w-1/2">
                      <TextField
                        className="input-text-field"
                        fullWidth
                        placeholder="First name"
                        variant="outlined"
                        name="firstName"
                        value={state.firstName}
                        onChange={handleChange}
                        required
                        error={state?.errors?.firstName}
                        InputProps={{
                          style: {
                            height: 40,
                            fontSize: 12,
                          },
                        }}
                      />

                      {state?.errors?.firstName && (
                        <ErrorMessage message="First name is required" />
                      )}
                    </div>

                    <div className="w-full md:w-1/2 md:ml-2 mt-5 md:mt-0">
                      <TextField
                        className="input-text-field"
                        fullWidth
                        placeholder="Last name"
                        variant="outlined"
                        name="lastName"
                        value={state.lastName}
                        onChange={handleChange}
                        InputProps={{
                          style: {
                            height: 40,
                            fontSize: 12,
                          },
                        }}
                      />
                    </div>
                  </div>

                  {shouldCollectAddress && (
                    <div>
                      <div className="mt-4">
                        <TextField
                          className="input-text-field"
                          fullWidth
                          placeholder="Address"
                          variant="outlined"
                          name="addressLine1"
                          value={state.addressLine1}
                          onChange={handleChange}
                          required
                          error={state?.errors?.addressLine1}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                            style: {
                              fontSize: 12,
                            },
                          }}
                          InputProps={{
                            style: {
                              height: 40,
                              fontSize: 12,
                            },
                          }}
                        />

                        {state?.errors?.addressLine1 && (
                          <ErrorMessage message="Address line 1 is required" />
                        )}
                      </div>

                      <div className="mt-4">
                        <TextField
                          className="input-text-field"
                          fullWidth
                          placeholder="Apartment, suite, etc. (optional)"
                          variant="outlined"
                          name="addressLine2"
                          value={state.addressLine2}
                          onChange={handleChange}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                            style: {
                              fontSize: 12,
                            },
                          }}
                          InputProps={{
                            style: {
                              height: 40,
                              fontSize: 12,
                            },
                          }}
                        />
                      </div>

                      <div className="flex justify-between mt-4">
                        <div className="w-1/3">
                          <TextField
                            className="input-text-field"
                            fullWidth
                            placeholder="City"
                            variant="outlined"
                            name="city"
                            value={state.city}
                            onChange={handleChange}
                            InputProps={{
                              style: {
                                height: 40,
                                fontSize: 12,
                              },
                            }}
                            required
                            error={state?.errors?.city}
                          />

                          {state?.errors?.city && (
                            <ErrorMessage message="City is required" />
                          )}
                        </div>

                        <div className="w-1/3 ml-2">
                          <TextField
                            className="input-text-field"
                            fullWidth
                            placeholder="State"
                            variant="outlined"
                            name="state"
                            value={state.state}
                            onChange={handleChange}
                            InputProps={{
                              style: {
                                height: 40,
                                fontSize: 12,
                              },
                            }}
                            required
                            error={state?.errors?.state}
                          />

                          {state?.errors?.state && (
                            <ErrorMessage message="State is required" />
                          )}
                        </div>

                        <div className="w-1/3 ml-2">
                          <TextField
                            className="input-text-field"
                            fullWidth
                            placeholder="Zip"
                            variant="outlined"
                            name="zipcode"
                            value={state.zipcode}
                            onChange={handleChange}
                            required
                            error={state?.errors?.zipcode}
                            InputProps={{
                              style: {
                                height: 40,
                                fontSize: 12,
                              },
                            }}
                          />

                          {state?.errors?.zipcode && (
                            <ErrorMessage message="Zipcode is required" />
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="flex justify-between items-center mt-5">
                    <div className="w-full md:w-1/2">
                      <TextField
                        className="input-text-field"
                        fullWidth
                        placeholder="Phone"
                        variant="outlined"
                        name="phone"
                        value={state.phone}
                        onChange={handleChange}
                        required
                        error={state?.errors?.phone}
                        autoComplete="off"
                        type="number"
                        onWheel={(event: any) => event.target.blur()}
                        onInput={(event) => {
                          if (
                            // @ts-ignore
                            event?.target?.value
                          ) {
                            // @ts-ignore
                            event.target.value = Math.max(
                              0,
                              // @ts-ignore
                              parseInt(event.target.value, 10)
                            )
                              .toString()
                              .slice(0, 10);
                          }
                        }}
                        InputProps={{
                          startAdornment: (
                            <InputAdornment
                              position="start"
                              style={{
                                // @ts-ignore
                                "& .MuiInputAdornment-root": {
                                  "&.MuiTypography-root": {
                                    fontSize: 12,
                                    color: "#363333",
                                  },
                                },
                              }}
                            >
                              +1
                            </InputAdornment>
                          ),
                          style: {
                            height: 40,
                            fontSize: 12,
                          },
                        }}
                      />

                      {state?.errors?.phone && (
                        <ErrorMessage message="Phone is required" />
                      )}
                    </div>

                    {state.shouldVerifyOTP && (
                      <div className="w-1/2 ml-2">
                        <TextField
                          className="input-text-field"
                          fullWidth
                          label="OTP"
                          variant="outlined"
                          name="otp"
                          value={state.otp}
                          onChange={handleChange}
                          required
                          error={state?.errors?.otp}
                          InputLabelProps={{
                            shrink: true,
                            disableAnimation: true,
                            style: {
                              fontSize: 12,
                            },
                          }}
                          InputProps={{
                            style: {
                              height: 40,
                              fontSize: 12,
                            },
                          }}
                          autoComplete="off"
                          type="number"
                          onWheel={(event: any) => event.target.blur()}
                        />

                        {state?.errors?.otp && (
                          <ErrorMessage message="OTP is required" />
                        )}
                      </div>
                    )}
                  </div>

                  {state.shouldVerifyOTP && (
                    <div>
                      <div
                        className={`${
                          state.resendOTPTimer === 0
                            ? "text-grey"
                            : "text-grey-2"
                        }  text-sm mt-2 flex justify-end`}
                      >
                        <span
                          className={`${
                            state?.resendOTPTimer === 0
                              ? "underline cursor-pointer"
                              : ""
                          } mr-1`}
                          onClick={handleReSendOTP}
                        >
                          Resend OTP
                        </span>
                        {state?.resendOTPTimer > 1 && (
                          <span className="mr-1">
                            in {state?.resendOTPTimer}{" "}
                            {state?.resendOTPTimer > 1 ? "seconds" : "second"}
                          </span>
                        )}
                      </div>
                    </div>
                  )}

                  <div className="mt-5 w-1/2">
                    <Button
                      label={`${
                        state.shouldVerifyOTP ? "Verify OTP" : "Confirm account"
                      }`}
                      color="secondary"
                      onClick={
                        state.shouldVerifyOTP
                          ? handleCreateAccount
                          : handleGenerateOTP
                      }
                      style={{
                        borderRadius: 10,
                        fontSize: 14,
                        color: "#FFFFFF",
                        height: 40,
                      }}
                      fullWidth
                      loaderButton
                      loadingPosition="center"
                      loading={isCheckoutFormLoading}
                    />
                  </div>
                </div>
              )}
            </div>

            <div className="w-full lg:w-2/5 border-[1px] border-solid border-[#f1f1f1] rounded-md p-4 my-2">
              <div className="text-grey font-semibold text-lg">
                2. Payment Details
              </div>
              <div className="flex justify-center">
                <div className="w-11/12">
                  {!!paymentMethods?.cards?.length && (
                    <div className="mt-8">
                      <div className="text-grey text-sm font-semibold">
                        Saved payment methods
                      </div>

                      {paymentMethods?.cards?.map((card: any) => (
                        <div key={card?.id} className="mt-4">
                          <div
                            className={`flex justify-between items-center h-16 cursor-pointer hover:bg-red-100 ${
                              paymentMethodId === card?.id ? "bg-red-100" : ""
                            }`}
                            onClick={() => handleSelectPaymentMethod(card?.id)}
                          >
                            <div className="flex items-center text-grey">
                              <img src={Images.CaretRight} className="mx-2" />

                              <div className="text-lg">
                                {String(card?.brand).toUpperCase()}
                              </div>

                              <div className="ml-4 text-sm">
                                <div className="font-semibold">
                                  XXXX {card?.lastFourDigits}
                                </div>{" "}
                                <div>
                                  Expires ({card?.expiryMonth}/
                                  {card?.expiryYear})
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}

                      <hr className="my-4 border-4" />
                    </div>
                  )}

                  {isLoggedIn && (
                    <div className="mt-4">
                      <div className="text-grey text-sm font-semibold">
                        Add new payment method
                      </div>

                      <div className="px-4 mt-2">
                        <div>
                          <div
                            className="flex justify-between items-center h-16 cursor-pointer hover:bg-red-100"
                            onClick={handleOpenAddNewCardDialog}
                          >
                            <div className="text-grey font-semibold">
                              Credit/Debit Card
                            </div>
                            <img
                              src={Images.CaretRight}
                              className="w-4 h-4 mx-2"
                            />
                          </div>
                          <hr />
                        </div>

                        <div>
                          <div className="flex justify-between items-center h-16 cursor-pointer hover:bg-red-100">
                            <div className="text-grey font-semibold">
                              PayPal (coming soon)
                            </div>
                            <img
                              src={Images.CaretRight}
                              className="w-4 h-4 mx-2"
                            />
                          </div>
                          <hr />
                        </div>

                        <div>
                          <div className="flex justify-between items-center h-16 cursor-pointer hover:bg-red-100">
                            <div className="text-grey font-semibold">
                              Venmo (coming soon)
                            </div>
                            <img
                              src={Images.CaretRight}
                              className="w-4 h-4 mx-2"
                            />
                          </div>
                          <hr />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isDemo && (
        <div className="text-center my-8">
          This is a demo page, details won&apos;t be saved
        </div>
      )}

      {shouldShowAddNewDialog && (
        <AddNewCardDialog
          onClose={handleCloseAddNewCardDialog}
          isTextToPay
          flow={flow}
        />
      )}
    </div>
  );
}

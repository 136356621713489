import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner";
import BuyDialog from "components/Customer/BuyDialog";
import { MenuType } from "enums/Menu";
import { PlanType } from "enums/PlanType";
import { humanizeSchedule } from "enums/PaymentSchedules";
import { getSubdomain } from "utils/common";
import { publicBusinessPageFetchDetailsSaga } from "../state/actions";
import { buyDialogSetConfig } from "components/Customer/BuyDialog/state/actions"; // buy dialog actions
import Images from "assets/images";
import { truncateString } from "utils/string";

type Props = {
  merchantId: string | null;
};

export default function MerchantSubscriptions(props: Props) {
  const { merchantId } = props;

  const dispatch = useDispatch();

  const { isLoading, subscriptions } = useSelector(
    (store: any) => store?.publicBusinessPage
  );

  const { open: shouldShowBuyDialog } = useSelector(
    (store: any) => store?.buyDialog
  );

  const handleOpenBuyDialog = (product: any) => {
    dispatch(
      buyDialogSetConfig({
        open: true,
        product,
        menuType: MenuType.PRODUCTS,
      })
    );
  };

  const handleCloseBuyDialog = () => {
    dispatch(buyDialogSetConfig({ open: false }));
  };

  useEffect(() => {
    const payload: any = {
      type: MenuType.SUBSCRIPTIONS,
    };

    if (merchantId) {
      payload.id = merchantId;
    } else {
      payload.subdomain = getSubdomain();
    }

    dispatch(publicBusinessPageFetchDetailsSaga(payload));
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-4">
            {subscriptions?.map((product: any) => (
              <div
                key={product?._id}
                className="flex justify-between items-center bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md mt-4 cursor-pointer"
                onClick={() => handleOpenBuyDialog(product)}
              >
                <div className="w-3/5 flex flex-col justify-between">
                  <div className="my-2">
                    <div className="text-grey text-sm font-semibold">
                      {truncateString(product?.title, 20)}
                    </div>

                    <div className="text-grey text-sm font-normal mt-1">
                      {truncateString(product?.description, 50)}
                    </div>

                    <div className=" text-grey text-sm mt-1">
                      ${product?.price?.amount}{" "}
                      {humanizeSchedule(product?.price?.schedule)}
                    </div>

                    <div className=" text-grey text-sm mt-1">
                      {product?.price?.type === PlanType.UNLIMITED_OFFERING
                        ? "UNLIMITED"
                        : `${product?.price?.quantity} UNITS PER ${product?.price?.schedule}`}
                    </div>
                  </div>
                </div>

                <img
                  src={product?.images[0] || Images.NoImagePlaceholder}
                  className="w-32 h-32 rounded-r-md object-cover"
                />
              </div>
            ))}
          </div>
        </div>
      )}

      {shouldShowBuyDialog && (
        <BuyDialog onClose={handleCloseBuyDialog} isStandalonePlan />
      )}
    </div>
  );
}

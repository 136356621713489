import {
  MERCHANT_CUSTOMERS_SET_IS_LOADING,
  MERCHANT_CUSTOMERS_SET_CUSTOMERS,
  MERCHANT_CUSTOMERS_FETCH_SAGA,
} from "./types";

export const merchantCustomersSetIsLoading = (payload: any) => ({
  type: MERCHANT_CUSTOMERS_SET_IS_LOADING,
  payload,
});

export const merchantCustomersSetCustomers = (payload: any) => ({
  type: MERCHANT_CUSTOMERS_SET_CUSTOMERS,
  payload,
});

// async

export const merchantCustomersFetchSaga = () => ({
  type: MERCHANT_CUSTOMERS_FETCH_SAGA,
});

import IosShareOutlinedIcon from "@mui/icons-material/IosShareOutlined";
import DialogContent from "@mui/material/DialogContent";
import { Dialog } from "components/Dialog";

type Props = {
  open: boolean;
  onClose: any;
  merchant: any;
};

export default function IOSInstallDialog(props: Props) {
  const { open, onClose, merchant } = props;

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="sm"
      sx={{
        "& .MuiDialogContent-root": {
          padding: 0,
        },
      }}
    >
      <DialogContent>
        <div className="pt-4 pb-2">
          <div className="flex justify-center">
            <img
              src={merchant?.businessLogo}
              className="rounded-full w-16 h-16"
            />
          </div>

          <div className="text-grey text-center font-semibold my-2">
            Install the {merchant?.businessName} app
          </div>

          <div className="text-grey text-center text-sm mt-2 mb-6">
            Install this application on your home screen to easily access it on
            the go.
          </div>

          <hr className="pb-1" />

          <div className="text-grey text-center text-sm my-2">
            Just tap{" "}
            <IosShareOutlinedIcon
              sx={{ height: 18, width: 18, color: "#3395FF" }}
            />{" "}
            then &quot;Add to Home Screen&quot;
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

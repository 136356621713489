import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DialogContent from "@mui/material/DialogContent";
import { Dialog, DialogTitle } from "components/Dialog";
import Spinner from "components/Spinner";
import CardSetupForm from "./CardSetupForm";
import { customerAddNewCardDialogCreateSetupIntentSaga } from "./state/actions";

const stripePromise = loadStripe(
  process.env?.REACT_APP_STRIPE_PUBLIC_KEY || ""
);

type Props = {
  onClose: any;
  isTextToPay?: boolean;
  flow?: string;
};

export default function AddNewCardDialog(props: Props) {
  const { onClose, isTextToPay = false, flow = "" } = props;

  const dispatch = useDispatch();

  const {
    user: {
      customer: {
        stripeMetadata: { platformCustomerId },
      },
    },
  } = useSelector((store: any) => store?.customerAuth);

  const {
    config: { open, isCreatingSetupIntent, clientSecret },
  } = useSelector((store: any) => store?.customerAddNewDialog);

  useEffect(() => {
    dispatch(
      customerAddNewCardDialogCreateSetupIntentSaga({
        platformCustomerId,
      })
    );
  }, []);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          onClose(event);
        }
      }}
      open={open}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
    >
      <DialogTitle onClose={onClose}>
        <span className="text-xl font-semibold">Add New Card</span>
      </DialogTitle>

      <DialogContent dividers>
        {isCreatingSetupIntent && (
          <div className="flex justify-center mt-4">
            <Spinner loading={isCreatingSetupIntent} />
          </div>
        )}

        {stripePromise && clientSecret && !isCreatingSetupIntent && (
          <div className="mt-4">
            <Elements stripe={stripePromise}>
              <CardSetupForm
                clientSecret={clientSecret}
                isTextToPay={isTextToPay}
                flow={flow}
              />
            </Elements>
          </div>
        )}
      </DialogContent>
    </Dialog>
  );
}

export const RESET_APP_STATE = "RESET_APP_STATE";
export const CUSTOMER_DASHBOARD_SET_IS_LOADING =
  "CUSTOMER_DASHBOARD_SET_IS_LOADING";
export const CUSTOMER_DASHBOARD_SET_STORES = "CUSTOMER_DASHBOARD_SET_STORES";
export const CUSTOMER_DASHBOARD_SET_PLANS = "CUSTOMER_DASHBOARD_SET_PLANS";

// async

export const CUSTOMER_DASHBOARD_GET_STORES_SAGA =
  "CUSTOMER_DASHBOARD_GET_STORES_SAGA";
export const CUSTOMER_DASHBOARD_GET_PLANS_SAGA =
  "CUSTOMER_DASHBOARD_GET_PLANS_SAGA";

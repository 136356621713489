import { useDispatch } from "react-redux";
import moment from "moment-timezone";
import PulseLoader from "react-spinners/PulseLoader";
import { Dialog } from "components/Dialog";
import DialogContent from "@mui/material/DialogContent";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CircleIcon from "@mui/icons-material/Circle";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import { formatDate } from "utils/date";
import { MESSAGE_SEVERITY } from "utils/constants";
import { appSetError } from "app/state/actions";
import { getAvailableSlots } from "api";

type Props = {
  open: boolean;
  onClose: any;
  subscription: any;
};

export default function BookingDialog(props: Props) {
  const { open, onClose, subscription } = props;

  const dispatch = useDispatch();

  const [state, setState] = useMergeState({
    scheduledDate: moment(),

    morningSlots: [],
    afternoonSlots: [],
    eveningSlots: [],

    isFetchingAvailability: false,
  });

  const getSlotLabel = (startAt: string) => moment(startAt).format("hh:mm a");

  const handleSelectSlot = (slot: any) => {
    console.log("slot : ", slot);
  };
  const fetchAvailableSlots = async ({
    startAt,
    endAt,
  }: {
    startAt: string;
    endAt: string;
  }) => {
    try {
      setState({ isFetchingAvailability: true });

      const response = await getAvailableSlots({
        startAt,
        endAt,
        subscriptionId: subscription?._id,
      });

      setState({
        morningSlots: response?.data?.morningSlots,
        afternoonSlots: response?.data?.afternoonSlots,
        eveningSlots: response?.data?.eveningSlots,
      });
    } catch (error: any) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      );
    } finally {
      setState({ isFetchingAvailability: false });
    }
  };

  const handleDateChange = async (newValue: any) => {
    setState({ scheduledDate: newValue });

    let startAt: any = moment(newValue).startOf("D").format().split("-");

    startAt.pop();

    startAt = `${startAt.join("-")}Z`;

    let endAt: any = moment(newValue)
      .add(1, "day")
      .startOf("D")
      .format()
      .split("-");

    endAt.pop();

    endAt = `${endAt.join("-")}Z`;

    console.log("startAt : ", startAt);

    console.log("endAt : ", endAt);

    await fetchAvailableSlots({ startAt, endAt });
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      maxWidth="lg"
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          // width: 500,
          height: 600,
        },
        "& .MuiDialogContent-root": {
          padding: 1,
        },
      }}
    >
      <DialogContent>
        <div className="px-4">
          <div className="flex justify-end">
            <IconButton onClick={onClose}>
              <CloseIcon />
            </IconButton>
          </div>

          <div className="flex">
            <div className="w-1/3">
              <div className="flex flex-col justify-between bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 h-[400px]">
                <div>
                  <div>YOUR PLAN</div>

                  <hr className="my-2" />

                  <div>{subscription?.product?.title}</div>
                </div>

                <div>
                  <hr className="my-2" />

                  <div className="flex justify-between items-center">
                    <div>Remaining Amount</div>
                    <div className="text-green-3 font-medium">6/10</div>
                  </div>
                </div>
              </div>
            </div>

            <div className="w-3/5 ml-8">
              <div className="text-grey text-2xl font-medium ">
                Schedule your appointment
              </div>

              <div className="flex mt-4">
                <div className="flex items-center">
                  <div className="mr-2">
                    <CheckCircleIcon
                      sx={{ color: "#143324", height: 22, width: 22 }}
                    />
                  </div>

                  <div className="text-new-green text-sm">DATE & TIME</div>
                </div>

                <div className="flex items-center ml-8">
                  <div className="mr-2">
                    <CircleIcon
                      sx={{ color: "#143324", height: 22, width: 22 }}
                    />
                  </div>
                  <div className="text-grey text-sm">CONFIRMATION</div>
                </div>
              </div>

              <hr className="my-2" />

              <div className="flex">
                <div>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DateCalendar
                      defaultValue={state?.scheduledDate}
                      value={state?.scheduledDate}
                      onChange={handleDateChange}
                      views={["day"]}
                      disablePast
                      sx={{
                        color: "#143324",
                      }}
                    />
                  </LocalizationProvider>
                </div>

                <div className="ml-4">
                  <div className="text-grey text-lg font-medium">
                    Availability on {formatDate(state?.scheduledDate, "ll")}
                  </div>

                  <div className="text-grey text-sm">
                    Time zone: Pacific Time Zone (PDT)
                  </div>

                  {state?.isFetchingAvailability ? (
                    <div className="flex justify-center mt-10">
                      <PulseLoader
                        color="#143324"
                        loading={state?.isFetchingAvailability}
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="mt-4">
                        <div className="text-sm font-medium">MORNING</div>

                        <div className="grid grid-cols-3 gap-3 mt-2">
                          {state?.morningSlots?.map((slot: any) => (
                            <Button
                              key={slot?.startAt}
                              label={getSlotLabel(slot?.startAt)}
                              color="secondary"
                              variant="contained"
                              onClick={() => handleSelectSlot(slot)}
                              style={{
                                borderRadius: 4,
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#FFFFFF",
                                backgroundColor: "#143324",
                                height: 35,
                                width: 110,
                              }}
                            />
                          ))}
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="text-sm font-medium">AFTERNOON</div>

                        <div className="grid grid-cols-3 gap-3 mt-2">
                          {state?.afternoonSlots?.map((slot: any) => (
                            <Button
                              key={slot?.startAt}
                              label={getSlotLabel(slot?.startAt)}
                              color="secondary"
                              variant="contained"
                              onClick={() => handleSelectSlot(slot)}
                              style={{
                                borderRadius: 4,
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#FFFFFF",
                                backgroundColor: "#143324",
                                height: 35,
                                width: 110,
                              }}
                            />
                          ))}
                        </div>
                      </div>

                      <div className="mt-4">
                        <div className="text-sm font-medium">EVENING</div>

                        <div className="grid grid-cols-3 gap-3 mt-2">
                          {state?.eveningSlots?.map((slot: any) => (
                            <Button
                              key={slot?.startAt}
                              label={getSlotLabel(slot?.startAt)}
                              color="secondary"
                              variant="contained"
                              onClick={() => handleSelectSlot(slot)}
                              style={{
                                borderRadius: 4,
                                fontWeight: 500,
                                fontSize: 14,
                                color: "#FFFFFF",
                                backgroundColor: "#143324",
                                height: 35,
                                width: 110,
                              }}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export const MERCHANT_AUTH_SET_MESSAGE = "MERCHANT_AUTH_SET_MESSAGE";
export const MERCHANT_AUTH_SET_IS_LOADING = "MERCHANT_AUTH_SET_IS_LOADING";
export const MERCHANT_AUTH_SET_IS_LOGGED_IN = "MERCHANT_AUTH_SET_IS_LOGGED_IN";
export const MERCHANT_AUTH_SET_CURRENT_USER = "MERCHANT_AUTH_SET_CURRENT_USER";

// async
export const MERCHANT_AUTH_ONBOARD_SAGA = "MERCHANT_AUTH_ONBOARD_SAGA";
export const MERCHANT_AUTH_SIGNUP_SAGA = "MERCHANT_AUTH_SIGNUP_SAGA";
export const MERCHANT_AUTH_SIGNIN_SAGA = "MERCHANT_AUTH_SIGNIN_SAGA";
export const MERCHANT_AUTH_SETUP_SAGA = "MERCHANT_AUTH_SETUP_SAGA";
export const MERCHANT_AUTH_FORGOT_PASSWORD_SEND_INSTRUCTIONS_SAGA =
  "MERCHANT_AUTH_FORGOT_PASSWORD_SEND_INSTRUCTIONS_SAGA";
export const MERCHANT_AUTH_CHECK_RESET_PASSWORD_LINK_EXPIRY_SAGA =
  "MERCHANT_AUTH_CHECK_RESET_PASSWORD_LINK_EXPIRY_SAGA";
export const MERCHANT_AUTH_RESET_PASSWORD_SAGA =
  "MERCHANT_AUTH_RESET_PASSWORD_SAGA";

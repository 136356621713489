import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import PeopleOutlinedIcon from "@mui/icons-material/PeopleOutlined";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import PersonOutlinedIcon from "@mui/icons-material/PersonOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import RestaurantMenuOutlinedIcon from "@mui/icons-material/RestaurantMenuOutlined";
import { appLogoutSaga } from "app/state/actions";
import { useMergeState } from "utils/custom-hooks";
import { UserRoles } from "enums/userRoles";

type Props = {
  open: boolean;
  onClose: any;
};

const PLANS_NAV = {
  id: "2",
  icon: LoyaltyOutlinedIcon,
  title: "Plans",
  path: "/plans",
  private: true,
};

const ORDERS_NAV = {
  id: "3",
  icon: ShoppingBagOutlinedIcon,
  title: "Orders",
  path: "/orders",
  private: true,
};

const SIGN_UP_NAV = {
  id: "4",
  icon: PersonOutlinedIcon,
  title: "Sign Up Or Sign In",
  path: "/login",
  handler: "",
};

const SETTINGS_NAV = {
  id: "5",
  icon: SettingsOutlinedIcon,
  title: "Settings",
  path: "/settings",
  private: true,
};

const SIGN_OUT_NAV = {
  id: "6",
  icon: PersonOutlinedIcon,
  title: "Sign Out",
  path: "",
  handler: "SIGN_OUT",
};

const TEAM_PLANS_NAV = {
  id: "7",
  icon: PeopleOutlinedIcon,
  title: "Team Plans",
  path: "/team-plans",
  private: true,
};

const STORE_MENU = {
  id: "8",
  icon: RestaurantMenuOutlinedIcon,
  title: "Menu",
  path: "/menu",
  private: false,
};

const NAVS: any = [
  {
    id: "1",
    icon: HomeOutlinedIcon,
    title: "Home",
    path: "/",
    private: false,
  },
];

export default function NavigationMenuDrawer(props: Props) {
  const { open, onClose } = props;

  const dispatch = useDispatch();

  const { isLoggedIn, user } = useSelector((store: any) => store?.customerAuth);

  const [state, setState] = useMergeState({
    navs: NAVS,
    selectedNav: {},
  });

  const handleSelectNav = (nav: any) => {
    onClose();

    if (nav?.id === state?.selectedNav?.id) {
      return;
    }

    setState({ selectedNav: nav });

    if (nav?.handler && nav?.handler === "SIGN_OUT") {
      dispatch(appLogoutSaga());
    }
  };

  useEffect(() => {
    let newNavs = [...state?.navs];

    if (isLoggedIn) {
      newNavs = [...NAVS, PLANS_NAV];

      if (user?.corporate && user?.role === UserRoles.ADMIN) {
        newNavs = [...newNavs, TEAM_PLANS_NAV];
      }

      newNavs = [
        ...newNavs,
        ORDERS_NAV,
        STORE_MENU,
        SETTINGS_NAV,
        SIGN_OUT_NAV,
      ];
    } else {
      newNavs = [...newNavs, STORE_MENU, SIGN_UP_NAV];
    }

    setState({ navs: newNavs });
  }, [isLoggedIn]);

  return (
    <Drawer anchor="left" open={open} onClose={onClose}>
      <div className="w-[320px]">
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>

        <div className="mt-20">
          <hr />
          {state?.navs?.map((nav: any) => (
            <div key={nav?.id}>
              <Link to={nav?.path} onClick={() => handleSelectNav(nav)}>
                <div className="flex items-center">
                  <div className="ml-8 mr-4">
                    <nav.icon
                      sx={{
                        color: "#0000008A",
                      }}
                    />
                  </div>
                  <div className="text-grey text-lg font-semibold my-4">
                    {nav?.title}
                  </div>
                </div>
              </Link>
              <hr />
            </div>
          ))}
        </div>
      </div>
    </Drawer>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import Spinner from "components/Spinner";
import Images from "assets/images";
import { PlanType } from "enums/PlanType";
import { humanizeSchedule } from "enums/PaymentSchedules";
import { truncateString } from "utils/string";
import { customerDashboardGetPlansSaga } from "./state/actions";

export default function CustomerDashboardContainer() {
  const dispatch = useDispatch();

  const { isLoggedIn, merchant } = useSelector(
    (store: any) => store?.customerAuth
  );

  const { isLoading, plans } = useSelector(
    (store: any) => store?.customerDashboard
  );

  useEffect(() => {
    if (isLoggedIn) {
      dispatch(customerDashboardGetPlansSaga());
    }
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div className="w-full p-4 lg:px-12 lg:py-4">
          <div className="my-4">
            <div className="relative">
              <img
                src={merchant?.businessBanner}
                className="rounded-md w-full h-[300px] md:h-[400px] object-cover"
              />

              <div>
                <img
                  src={merchant?.businessLogo}
                  className="rounded-full absolute right-0 -mt-10 mr-4 w-24 h-24"
                />
              </div>
            </div>

            <div className="text-4xl font-semibold mt-12 mb-2">
              Welcome to {merchant?.businessName}
            </div>

            <div className="text-grey-2 text-sm font-semibold">
              {merchant?.addressLine1}, {merchant?.state} - {merchant?.zipcode}
            </div>

            <hr className="my-4" />

            <div className="my-8">
              <div className="text-xl font-semibold">Your Memberships</div>

              {!plans?.length ? (
                <div className="text-grey text-sm font-medium my-2">
                  Check out the great plans from stores in your neighbourhood!
                </div>
              ) : (
                <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-8">
                  {plans?.map((plan: any) => (
                    <Link
                      key={plan?._id}
                      to={`/subscription/details?id=${plan?._id}`}
                      className="flex justify-between items-center bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md mt-4 cursor-pointer"
                    >
                      <div className="md:w-3/5 flex flex-col justify-between">
                        <div className="my-2">
                          <div className="text-grey text-lg font-semibold">
                            {plan?.product?.title}
                          </div>

                          <div className="text-grey text-sm font-normal mt-2">
                            {truncateString(plan?.product?.description, 20)}
                          </div>

                          {plan?.product?.isOffer && (
                            <div className="text-grey text-sm font-normal mt-2">
                              PROMO OFFER
                            </div>
                          )}

                          {!plan?.product?.isOffer &&
                            plan?.product?.price?.type && (
                              <div className="text-grey text-sm font-normal mt-2">
                                ${plan?.product?.price?.amount}{" "}
                                {humanizeSchedule(
                                  plan?.product?.price?.schedule
                                )}
                              </div>
                            )}

                          <div className="text-grey text-sm font-medium mt-2">
                            {!plan?.product?.price?.type
                              ? `$${plan?.product?.variations[0]?.price}`
                              : plan?.product?.price?.type ===
                                PlanType.UNLIMITED_OFFERING
                              ? "UNLIMITED"
                              : `${plan?.product?.price?.quantity} ${
                                  plan?.product?.price?.quantity > 1
                                    ? "UNITS"
                                    : "UNIT"
                                }  ${
                                  !plan?.product?.isOffer
                                    ? `PER ${plan?.product?.price?.schedule}`
                                    : ""
                                } `}
                          </div>
                        </div>
                      </div>

                      <img
                        src={
                          plan?.product?.images[0] || Images.NoImagePlaceholder
                        }
                        className="w-32 h-32 rounded-r-md object-cover"
                      />
                    </Link>
                  ))}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

import { RESET_APP_STATE } from "app/state/types";
import { BUY_DIALOG_SET_CONFIG } from "./types";

type BuyDialogStateType = {
  open: boolean;
  isLoading: boolean;
  product: any;
  cart: Array<any>;
  menuType: string;
};

const initialState: BuyDialogStateType = {
  open: false,
  isLoading: false,
  product: {},
  cart: [],
  menuType: "",
};

const buyDialogReducer = (
  state: BuyDialogStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case BUY_DIALOG_SET_CONFIG:
      return { ...state, ...action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default buyDialogReducer;

import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { useStripe, useElements } from "@stripe/react-stripe-js";
import Spinner from "components/Spinner";
import { StripePaymentStatus } from "enums/Payments";
import { appSetError } from "app/state/actions";
import {
  customerCartSetCart,
  customerCartSetConfig,
} from "../Cart/state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";

type Props = {
  clientSecret: string;
  onClose: any;
};

export default function NextStepsForm(props: Props) {
  const { clientSecret = "", onClose } = props;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const stripe = useStripe();

  const elements = useElements();

  const [isNextActionLoading, setIsNextActionLoading] = useState(true);

  const isLoading =
    !stripe || !elements || !clientSecret || isNextActionLoading;

  useEffect(() => {
    const init = async () => {
      if (!stripe || !elements || !clientSecret) {
        return;
      }

      // @ts-ignore
      const response = await stripe?.handleNextAction({
        clientSecret,
      });

      console.log("response : ", response);

      if (response?.error) {
        setIsNextActionLoading(false);

        dispatch(
          appSetError({
            severity: MESSAGE_SEVERITY.ERROR,
            message: `${response?.error?.code} - ${response?.error?.message}`,
          })
        );

        onClose();

        return;
      }

      if (response?.paymentIntent?.status === StripePaymentStatus.SUCCEEDED) {
        dispatch(
          customerCartSetConfig({
            open: false,
            isLoading: false,
            isCheckoutLoading: false,
            cartId: "",
            merchant: {},
          })
        );

        dispatch(customerCartSetCart([]));

        navigate("/orders");
      }
    };

    init();
  }, [stripe, elements, clientSecret]);

  return isLoading ? (
    <div className="flex justify-center mt-4">
      <Spinner loading={isLoading} />
    </div>
  ) : null;
}

import {
  RESET_APP_STATE,
  APP_SET_SUBDOMAIN,
  APP_SET_PLATFORM_TYPE,
  APP_SET_ERROR,
} from "./types";

type AppStateType = {
  subdomain: string;
  platformType: string;
  error: {
    severity: string;
    message: string;
  };
};

const initialState: AppStateType = {
  subdomain: "",
  platformType: "",
  error: {
    severity: "",
    message: "",
  },
};

const appReducer = (state: AppStateType = initialState, action: any) => {
  switch (action?.type) {
    case APP_SET_SUBDOMAIN:
      return { ...state, subdomain: action.payload };

    case APP_SET_PLATFORM_TYPE:
      return { ...state, platformType: action.payload };

    case APP_SET_ERROR:
      return { ...state, error: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default appReducer;

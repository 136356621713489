import {
  ORDER_DIALOG_SET_CONFIG,
  ORDER_DIALOG_CREATE_ORDER_SAGA,
} from "./types";

export const orderDialogSetConfig = (payload: any) => ({
  type: ORDER_DIALOG_SET_CONFIG,
  payload,
});

// async

export const orderDialogCreateOrderSaga = (payload: any, navigate: any) => ({
  type: ORDER_DIALOG_CREATE_ORDER_SAGA,
  payload,
  navigate,
});

import {
  MERCHANT_PRODUCTS_SET_IS_LOADING,
  MERCHANT_PRODUCTS_SET_SETTINGS_CONFIG,
  MERCHANT_PRODUCTS_SET_PRODUCTS,
  MERCHANT_PRODUCTS_SET_SELECTED_PRODUCT,
  MERCHANT_PRODUCTS_MODIFIERS_SET_MODIFIERS,
  MERCHANT_PRODUCTS_MODIFIERS_SET_SELECTED_MODIFIER,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_RULES,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_SELECTED_RULE,
  MERCHANT_PRODUCTS_FETCH_SAGA,
  MERCHANT_PRODUCTS_FETCH_SELECTED_PRODUCT_SAGA,
  MERCHANT_PRODUCTS_CREATE_SAGA,
  MERCHANT_PRODUCTS_UPDATE_SAGA,
  MERCHANT_PRODUCTS_CLONE_SAGA,
  MERCHANT_PRODUCTS_DELETE_SAGA,
  MERCHANT_PRODUCTS_MODIFIERS_FETCH_SAGA,
  MERCHANT_PRODUCTS_MODIFIERS_FETCH_SELECTED_MODIFIER_SAGA,
  MERCHANT_PRODUCTS_MODIFIERS_CREATE_SAGA,
  MERCHANT_PRODUCTS_MODIFIERS_UPDATE_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SELECTED_RULE_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_CREATE_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_UPDATE_SAGA,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_DELETE_SAGA,
} from "./types";

export const merchantProductsSetIsLoading = (payload: any) => ({
  type: MERCHANT_PRODUCTS_SET_IS_LOADING,
  payload,
});

export const merchantProductsSetSettingsConfig = (payload: any) => ({
  type: MERCHANT_PRODUCTS_SET_SETTINGS_CONFIG,
  payload,
});

export const merchantProductsSetProducts = (payload: any) => ({
  type: MERCHANT_PRODUCTS_SET_PRODUCTS,
  payload,
});

export const merchantProductsSetSelectedProduct = (payload: any) => ({
  type: MERCHANT_PRODUCTS_SET_SELECTED_PRODUCT,
  payload,
});

export const merchantProductsModifiersSetModifiers = (payload: any) => ({
  type: MERCHANT_PRODUCTS_MODIFIERS_SET_MODIFIERS,
  payload,
});

export const merchantProductsModifiersSetSelectedModifier = (payload: any) => ({
  type: MERCHANT_PRODUCTS_MODIFIERS_SET_SELECTED_MODIFIER,
  payload,
});

export const merchantProductsDiscountRulesSetRules = (payload: any) => ({
  type: MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_RULES,
  payload,
});

export const merchantProductsDiscountRulesSetSelectedRule = (payload: any) => ({
  type: MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_SELECTED_RULE,
  payload,
});

// async

export const merchantProductsFetchSaga = () => ({
  type: MERCHANT_PRODUCTS_FETCH_SAGA,
});

export const merchantProductsFetchSelectedProductSaga = (payload: any) => ({
  type: MERCHANT_PRODUCTS_FETCH_SELECTED_PRODUCT_SAGA,
  payload,
});

export const merchantProductsCreateSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_PRODUCTS_CREATE_SAGA,
  payload,
  navigate,
});

export const merchantProductsUpdateSaga = (payload: any) => ({
  type: MERCHANT_PRODUCTS_UPDATE_SAGA,
  payload,
});

export const merchantProductsCloneSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_PRODUCTS_CLONE_SAGA,
  payload,
  navigate,
});

export const merchantProductsDeleteSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_PRODUCTS_DELETE_SAGA,
  payload,
  navigate,
});

export const merchantProductsModifiersFetchSaga = () => ({
  type: MERCHANT_PRODUCTS_MODIFIERS_FETCH_SAGA,
});

export const merchantProductsModifiersFetchSelectedModiferSaga = (
  payload: any
) => ({
  type: MERCHANT_PRODUCTS_MODIFIERS_FETCH_SELECTED_MODIFIER_SAGA,
  payload,
});

export const merchantProductsModifiersCreateSaga = (
  payload: any,
  navigate: any
) => ({
  type: MERCHANT_PRODUCTS_MODIFIERS_CREATE_SAGA,
  payload,
  navigate,
});

export const merchantProductsModifiersUpdateSaga = (payload: any) => ({
  type: MERCHANT_PRODUCTS_MODIFIERS_UPDATE_SAGA,
  payload,
});

export const merchantProductsDiscountRulesFetchSaga = () => ({
  type: MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SAGA,
});

export const merchantProductsDiscountRulesFetchSelectedRuleSaga = (
  payload: any
) => ({
  type: MERCHANT_PRODUCTS_DISCOUNT_RULES_FETCH_SELECTED_RULE_SAGA,
  payload,
});

export const merchantProductsDiscountRulesCreateSaga = (
  payload: any,
  navigate: any
) => ({
  type: MERCHANT_PRODUCTS_DISCOUNT_RULES_CREATE_SAGA,
  payload,
  navigate,
});

export const merchantProductsDiscountRulesUpdateSaga = (payload: any) => ({
  type: MERCHANT_PRODUCTS_DISCOUNT_RULES_UPDATE_SAGA,
  payload,
});

export const merchantProductsDiscountRulesDeleteSaga = (payload: any) => ({
  type: MERCHANT_PRODUCTS_DISCOUNT_RULES_DELETE_SAGA,
  payload,
});

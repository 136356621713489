export const MERCHANT_DISCOUNTS_SET_IS_LOADING =
  "MERCHANT_DISCOUNTS_SET_IS_LOADING";
export const MERCHANT_DISCOUNTS_SET_DISCOUNTS =
  "MERCHANT_DISCOUNTS_SET_DISCOUNTS";
export const MERCHANT_DISCOUNTS_SET_SELECTED_DISCOUNT =
  "MERCHANT_DISCOUNTS_SET_SELECTED_DISCOUNT";

// async

export const MERCHANT_DISCOUNTS_FETCH_SAGA = "MERCHANT_DISCOUNTS_FETCH_SAGA";
export const MERCHANT_DISCOUNTS_FETCH_SELECTED_DISCOUNT_SAGA =
  "MERCHANT_DISCOUNTS_FETCH_SELECTED_DISCOUNT_SAGA";
export const MERCHANT_DISCOUNTS_CREATE_SAGA = "MERCHANT_DISCOUNTS_CREATE_SAGA";
export const MERCHANT_DISCOUNTS_UPDATE_SAGA = "MERCHANT_DISCOUNTS_UPDATE_SAGA";
export const MERCHANT_DISCOUNTS_INVITE_CUSTOMER_SAGA =
  "MERCHANT_DISCOUNTS_INVITE_CUSTOMER_SAGA";

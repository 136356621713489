import {
  MERCHANT_CONNECT_SET_IS_LOADING,
  MERCHANT_CONNECT_GET_STRIPE_ACCOUNT_LINK_SAGA,
  MERCHANT_CONNECT_SQUARE_ACCOUNT_SAGA,
  MERCHANT_CONNECT_OAUTH_STRIPE_ACCOUNT_SAGA,
} from "./types";

export const merchantConnectSetIsLoading = (payload: any) => ({
  type: MERCHANT_CONNECT_SET_IS_LOADING,
  payload,
});

// async

export const merchantConnectGetStripeAccountLinkSaga = () => ({
  type: MERCHANT_CONNECT_GET_STRIPE_ACCOUNT_LINK_SAGA,
});

export const merchantConnectSquareAccountSaga = (payload: {
  code: string;
}) => ({
  type: MERCHANT_CONNECT_SQUARE_ACCOUNT_SAGA,
  payload,
});

export const merchantConnectOauthStripeAccountSaga = (payload: {
  code: string;
}) => ({
  type: MERCHANT_CONNECT_OAUTH_STRIPE_ACCOUNT_SAGA,
  payload,
});

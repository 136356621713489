import { useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import InputAdornment from "@mui/material/InputAdornment";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import Button from "components/Button";
import { useMergeState } from "utils/custom-hooks";
import Images from "assets/images";
import { merchantAuthOnboardSaga } from "../state/actions";

export default function MerchantOnboardingContainer() {
  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading } = useSelector((store: any) => store?.merchantAuth);

  const [state, setState] = useMergeState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    confirmPassword: "",
    businessName: "",
    businessSubdomain: "",
    errors: {},
  });

  const subdomainInputRef: any = useRef(null);

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.firstName) {
      payload = { firstName: true, ...payload };
      isValid = false;
    }

    if (!state.lastName) {
      payload = { lastName: true, ...payload };
      isValid = false;
    }

    if (!state.email) {
      payload = { email: true, ...payload };
      isValid = false;
    }

    if (!state.phone) {
      payload = { phone: true, ...payload };
      isValid = false;
    }

    if (!state.password) {
      payload = { password: true, ...payload };
      isValid = false;
    }

    if (!state.confirmPassword || state.confirmPassword !== state.password) {
      payload = { confirmPassword: true, ...payload };
      isValid = false;
    }

    if (!state.businessName) {
      payload = { businessName: true, ...payload };
      isValid = false;
    }

    if (!state.businessSubdomain) {
      payload = { businessSubdomain: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const handleChangeBusinessSubdomain = (event: any) => {
    setState({
      businessSubdomain: String(event.target.value).toLowerCase(),
      errors: {
        businessSubdomain: false,
      },
    });
  };

  const handleSubdomainInputClick = () => {
    subdomainInputRef?.current?.focus();
  };

  const handleCreateMerchant = () => {
    if (!isFormValid()) {
      return;
    }

    const payload = { ...state };

    delete payload.errors;

    dispatch(merchantAuthOnboardSaga(payload, navigate));
  };

  return (
    <div className="min-h-screen">
      <div className="flex justify-center">
        <img src={Images.CherryLogo} alt="Cherry" className="h-14 mt-4" />
      </div>

      <div className="flex justify-center">
        <div className="w-10/12 mt-8 mb-12">
          <div className="lg:flex">
            <div className="rounded-2xl px-8 py-2 lg:w-1/2">
              <div className="text-xl font-semibold my-1">Start free trial</div>

              <div className="w-full mt-5">
                <TextField
                  fullWidth
                  label="FIRST NAME"
                  variant="outlined"
                  name="firstName"
                  value={state.firstName}
                  onChange={handleChange}
                  required
                  error={state?.errors?.firstName}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.firstName && (
                  <ErrorMessage message="First name is required" />
                )}
              </div>

              <div className="w-full mt-8">
                <TextField
                  fullWidth
                  label="LAST NAME"
                  variant="outlined"
                  name="lastName"
                  value={state.lastName}
                  onChange={handleChange}
                  required
                  error={state?.errors?.lastName}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.lastName && (
                  <ErrorMessage message="Last name is required" />
                )}
              </div>

              <div className="w-full mt-8">
                <TextField
                  fullWidth
                  label="EMAIL"
                  variant="outlined"
                  name="email"
                  value={state.email}
                  onChange={handleChange}
                  required
                  error={state?.errors?.email}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.email && (
                  <ErrorMessage message="Email is required" />
                )}
              </div>

              <div className="w-full mt-8">
                <TextField
                  fullWidth
                  label="PHONE"
                  variant="outlined"
                  name="phone"
                  type="number"
                  value={state.phone}
                  onChange={handleChange}
                  onWheel={(event: any) => event.target.blur()}
                  onInput={(event) => {
                    if (
                      // @ts-ignore
                      event?.target?.value
                    ) {
                      // @ts-ignore
                      event.target.value = Math.max(
                        0,
                        // @ts-ignore
                        parseInt(event.target.value, 10)
                      )
                        .toString()
                        .slice(0, 10);
                    }
                  }}
                  required
                  error={state?.errors?.phone}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">+1</InputAdornment>
                    ),
                  }}
                />

                {state?.errors?.phone && (
                  <ErrorMessage message="Phone is required" />
                )}
              </div>

              <div className="w-full mt-8">
                <TextField
                  fullWidth
                  label="PASSWORD"
                  variant="outlined"
                  name="password"
                  type="password"
                  value={state.password}
                  onChange={handleChange}
                  required
                  error={state?.errors?.password}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.password && (
                  <ErrorMessage message="Password is required" />
                )}
              </div>

              <div className="w-full mt-8">
                <TextField
                  fullWidth
                  label="CONFIRM PASSWORD"
                  variant="outlined"
                  name="confirmPassword"
                  type="password"
                  value={state.confirmPassword}
                  onChange={handleChange}
                  required
                  error={state?.errors?.confirmPassword}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.confirmPassword && (
                  <ErrorMessage message="Confirm password is required" />
                )}
              </div>

              <div className="w-full mt-8">
                <TextField
                  fullWidth
                  label="BUSINESS NAME"
                  variant="outlined"
                  name="businessName"
                  value={state.businessName}
                  onChange={handleChange}
                  required
                  error={state?.errors?.businessName}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                />

                {state?.errors?.businessName && (
                  <ErrorMessage message="Business name is required" />
                )}
              </div>

              <div className="w-full mt-8">
                <div
                  className={`subdomain-textfield ${
                    state?.errors?.businessSubdomain ? "error" : ""
                  } `}
                  onClick={handleSubdomainInputClick}
                >
                  <div
                    className={`label ${
                      state?.errors?.businessSubdomain ? "error" : ""
                    }`}
                  >
                    BUSINESS WEBSITE URL*
                  </div>
                  <div className="flex">
                    <input
                      name="businessSubdomain"
                      type="text"
                      ref={subdomainInputRef}
                      value={state?.businessSubdomain}
                      onChange={handleChangeBusinessSubdomain}
                      placeholder="businessname"
                      onKeyPress={(event: any) => {
                        const regex = new RegExp("^[a-zA-Z]");

                        const key = String.fromCharCode(
                          !event.charCode ? event.which : event.charCode
                        );

                        if (!regex.test(key)) {
                          event.preventDefault();
                          return false;
                        }
                      }}
                    />
                    <span className="text-grey text-lg font-semibold overflow-hidden flex-nowrap overflow-ellipsis">
                      .cherrypayments.com
                    </span>
                  </div>
                </div>

                {state?.errors?.businessSubdomain && (
                  <ErrorMessage message="Business website URL is required" />
                )}
              </div>

              <div className="w-full mt-8">
                <Button
                  label="Create Store"
                  color="info"
                  onClick={handleCreateMerchant}
                  style={{
                    borderRadius: 10,
                    fontSize: 16,
                    color: "#FFFFFF",
                    height: 50,
                  }}
                  fullWidth
                  loaderButton
                  loadingPosition="center"
                  loading={isLoading}
                />
              </div>
            </div>

            <div className="ml-0 md:ml-12 mt-12 md:mt-0 lg:w-1/2">
              <img src={Images.App2} className="md:w-[420px] md:h-[860px]" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import Avatar from "@mui/material/Avatar";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountBalanceOutlinedIcon from "@mui/icons-material/AccountBalanceOutlined";
import LoyaltyOutlinedIcon from "@mui/icons-material/LoyaltyOutlined";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import ShoppingBasketOutlinedIcon from "@mui/icons-material/ShoppingBasketOutlined";
import PaidOutlinedIcon from "@mui/icons-material/PaidOutlined";
import NotificationsOutlinedIcon from "@mui/icons-material/NotificationsOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import Groups2OutlinedIcon from "@mui/icons-material/Groups2Outlined";
import DiscountOutlinedIcon from "@mui/icons-material/DiscountOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import { useMergeState } from "utils/custom-hooks";
import { getMerchantStoreUrl } from "utils/common";
import Images from "../../assets/images";
import { appLogoutSaga } from "app/state/actions"; // app actions
import { merchantDashboardBusinessPageViewedSaga } from "containers/merchant/dashboard/state/actions"; // dashboard actions

const SettingsChildNavs = [
  {
    id: "61",
    title: "General",
    path: "",
  },
  {
    id: "62",
    title: "Team",
    path: "/team",
  },
];

const SettingsNav = {
  id: "6",
  icon: SettingsOutlinedIcon,
  title: "Settings",
  path: "/settings",
  childNavs: SettingsChildNavs,
};

export const NAVS = [
  {
    id: "1",
    icon: AccountBalanceOutlinedIcon,
    title: "Dashboard",
    path: "/dashboard",
    childNavs: [
      {
        id: "11",
        title: "Get Started",
        path: "/getting-started",
      },
      {
        id: "12",
        title: "Overview",
        path: "/overview",
      },
    ],
  },
  {
    id: "2",
    icon: LoyaltyOutlinedIcon,
    title: "Plans",
    path: "/plans",
    childNavs: [
      {
        id: "21",
        title: "View Plans",
        path: "",
      },
      {
        id: "22",
        title: "Create Plan",
        path: "/create",
      },
      {
        id: "23",
        title: "View Subscribers",
        path: "/subscribers",
      },
      {
        id: "24",
        title: "View Plan Discounts",
        path: "/discounts",
      },
    ],
  },
  {
    id: "3",
    icon: LocalMallOutlinedIcon,
    title: "Products",
    path: "/products",
    childNavs: [
      {
        id: "31",
        title: "View Products",
        path: "",
      },
      // {
      //   id: "32",
      //   title: "Create New Product",
      //   path: "/create",
      // },
      {
        id: "33",
        title: "View Modifiers",
        path: "/modifiers",
      },
      // {
      //   id: "34",
      //   title: "Create New Modifier",
      //   path: "/modifiers/create",
      // },
      {
        id: "35",
        title: "View Discount Rule Sets",
        path: "/discount-rules",
      },
      {
        id: "36",
        title: "Inventory",
        path: "/inventory",
      },
    ],
  },
  // {
  //   id: "4",
  //   icon: ShoppingBasketOutlinedIcon,
  //   title: "Community Cart",
  //   path: "/community-cart",
  //   childNavs: [
  //     {
  //       id: "31",
  //       title: "View My Cart",
  //       path: "/",
  //     },
  //     {
  //       id: "32",
  //       title: "Choose Your Cart",
  //       path: "/create",
  //     },
  //   ],
  // },
  // {
  //   id: "3",
  //   icon: Images.Communication,
  //   title: "Communications",
  //   path: "/communications",
  //   childNavs: [
  //     {
  //       id: "31",
  //       title: "Messages",
  //       path: "/communications/messages",
  //     },
  //     {
  //       id: "32",
  //       title: "Reviews",
  //       path: "/communications/reviews",
  //     },
  //     {
  //       id: "33",
  //       title: "Marketing Posts",
  //       path: "/communications/marketing-posts",
  //     },
  //   ],
  // },
  // {
  //   id: "4",
  //   icon: Images.Reports,
  //   title: "Reports",
  //   path: "/reports",
  // },
  {
    id: "7",
    icon: PaidOutlinedIcon,
    title: "Orders",
    path: "/orders",
    childNavs: [],
  },
  // {
  //   id: "8",
  //   icon: NotificationsOutlinedIcon,
  //   title: "Alerts",
  //   path: "/alerts",
  //   childNavs: [],
  // },
  // {
  //   id: "9",
  //   icon: GroupAddOutlinedIcon,
  //   title: "Invitations",
  //   path: "/invitations",
  //   childNavs: [],
  // },
  {
    id: "10",
    icon: Groups2OutlinedIcon,
    title: "Customers",
    path: "/customers",
    childNavs: [
      {
        id: "100",
        title: "View Customers",
        path: "",
      },
      {
        id: "101",
        title: "View Invitations",
        path: "/invitations",
      },
      {
        id: "111",
        title: "View Discounts",
        path: "/discounts",
      },
    ],
  },
  // {
  //   id: "11",
  //   icon: DiscountOutlinedIcon,
  //   title: "Discounts",
  //   path: "/discounts",
  //   childNavs: [
  //     {
  //       id: "111",
  //       title: "View Discounts",
  //       path: "",
  //     },
  //   ],
  // },
];

export default function NavBar() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const [state, setState] = useMergeState({
    navs: [],
    selectedNav: NAVS[0],
    selectedChildNav: NAVS[0]?.childNavs?.length ? NAVS[0]?.childNavs[0] : null,
    profileMenuAnchorEl: null,
    navbarMenuAnchorEl: null,
  });

  const handleNavChange = (nav: any) => {
    setState({ selectedNav: nav, selectedChildNav: nav.childNavs[0] });

    if (nav.childNavs[0]) {
      navigate(`${nav.path}${nav.childNavs[0].path}`);
    } else {
      navigate(`${nav.path}`);
    }
  };

  const handleChildNavChange = (childNav: any) => {
    setState({ selectedChildNav: childNav });
    navigate(`${state.selectedNav.path}${childNav.path}`);
  };

  const handleOpenProfileMenu = (event: any) => {
    setState({ profileMenuAnchorEl: event.currentTarget });
  };

  const handleCloseProfileMenu = () => {
    setState({ profileMenuAnchorEl: null });
  };

  const handleRedirectMerchantMenu = () => {
    if (!user?.merchant?.businessPageViewed) {
      dispatch(merchantDashboardBusinessPageViewedSaga());
    }

    window.open(getMerchantStoreUrl(user?.merchant?.subdomain), "_blank");
  };

  const handleLogout = () => {
    handleCloseProfileMenu();
    dispatch(appLogoutSaga());
  };

  useEffect(() => {
    const mainNav = pathname.split("/")[1];
    const nestedNav = pathname.split("/")[2];

    const topNav = NAVS.find((elem: any) => elem.path === `/${mainNav}`);

    if (topNav?.path) {
      setState({ selectedNav: topNav });

      if (nestedNav) {
        const childNav = topNav?.childNavs?.find(
          (elem: any) => elem.path === `/${nestedNav}`
        );

        if (childNav?.path) {
          setState({ selectedChildNav: childNav });
        }
      } else {
        setState({ selectedChildNav: topNav?.childNavs[0] });
      }
    }
  }, [pathname]);

  useEffect(() => {
    let navs = [...NAVS];

    if (user?.merchant?.isB2B) {
      navs = [...navs].filter((elem) => elem?.title !== "Community Cart");
    }

    if (user?.merchant?.demoAccount) {
      SettingsChildNavs.push({
        id: "63",
        title: "Demo",
        path: "/demo",
      });
    }

    navs = [...navs, SettingsNav];

    setState({ navs });
  }, []);

  return (
    <div className="w-full h-screen">
      <div className="flex">
        <div className="w-16 max-w-[80px] flex flex-col justify-between">
          <div className="flex flex-col items-center">
            <img
              src={Images.Cherries}
              alt="C"
              className="h-10 rounded-md mt-6"
            />

            <div className="w-full flex flex-col items-center my-6">
              {state.navs.map((nav: any) => (
                <div
                  key={nav.id}
                  className={`w-full  flex justify-center py-1 ${
                    state.selectedNav?.id === nav.id
                      ? "bg-[#F6F7F8] border-l-2 border-[#F36]"
                      : ""
                  }`}
                >
                  {nav?.icon && (
                    <Tooltip title={nav.title} placement="right">
                      <IconButton onClick={() => handleNavChange(nav)}>
                        <nav.icon
                          sx={{
                            color:
                              state.selectedNav?.id === nav.id ? "#0a2032" : "",
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                  )}
                </div>
              ))}
            </div>
          </div>

          <div className="flex flex-col items-center mb-4">
            <div className="my-4">
              <Tooltip title="Preview Menu" placement="right">
                <IconButton onClick={handleRedirectMerchantMenu}>
                  <VisibilityOutlinedIcon />
                </IconButton>
              </Tooltip>
            </div>

            <div>
              <IconButton onClick={handleOpenProfileMenu}>
                <Avatar sx={{ width: 34, height: 34 }}>
                  {String(user?.firstName?.charAt(0)).toUpperCase()}
                  {String(user?.lastName?.charAt(0)).toUpperCase()}
                </Avatar>
              </IconButton>
            </div>

            <Menu
              anchorEl={state.profileMenuAnchorEl}
              open={Boolean(state.profileMenuAnchorEl)}
              onClose={handleCloseProfileMenu}
              transformOrigin={{ horizontal: "left", vertical: "top" }}
              anchorOrigin={{ horizontal: "left", vertical: "top" }}
            >
              <MenuItem onClick={handleLogout}>
                <ListItemIcon>
                  <LogoutIcon fontSize="small" />
                </ListItemIcon>
                Logout
              </MenuItem>
            </Menu>
          </div>
        </div>

        <div className="w-full max-w-[200px] h-screen bg-[#F6F7F8] flex flex-col justify-between items-center">
          <div className="py-8 w-10/12">
            <div className="flex text-2xl font-semibold">
              {state.selectedNav?.title}
            </div>

            <div className="mt-8 flex flex-col">
              {state.selectedNav?.childNavs.map((childNav: any) => (
                <div
                  key={childNav.id}
                  className={`w-full h-full flex mb-4 text-gray-500 text-sm ${
                    state.selectedChildNav?.id === childNav.id
                      ? "text-gray-900 font-medium"
                      : ""
                  }`}
                >
                  <div
                    className="cursor-pointer"
                    onClick={() => handleChildNavChange(childNav)}
                  >
                    {childNav.title}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

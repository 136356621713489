import { Link } from "react-router-dom";
import Images from "assets/images";

export default function MerchantReportsContainer() {
  return (
    <div>
      <div className="text-3xl font-semibold text-grey">Reports</div>

      <div className="flex justify-between">
        <div className="profile-box p-5 mt-4">
          <div className="flex justify-between">
            <div className="w-2/5">
              <div className="text-2xl font-medium text-grey">
                Financial Statement
              </div>

              <div className="text-grey text-sm mt-2">
                Get a clear picture of how your business is doing. Use these
                core statements to better understand your financial health.
              </div>
            </div>

            <div className="w-2/5 mt-8">
              <Link to="/reports/income/business">
                <div className="flex justify-between items-center">
                  <div className="text-lg font-medium text-green-3">
                    Profit & Loss (Income Statement)
                  </div>
                  <div>
                    <img src={Images.CaretRight} alt="" />
                  </div>
                </div>
                <div className="text-sm text-grey mt-2">
                  Shows your business’s net profit and summarizes your revenues
                  and expenses in a given time period.
                </div>
              </Link>

              <hr className="my-4" />

              <Link to="/reports/income/customer">
                <div className="flex justify-between items-center">
                  <div className="text-lg font-medium text-green-3">
                    Income By Customer
                  </div>
                  <div>
                    <img src={Images.CaretRight} alt="" />
                  </div>
                </div>
                <div className="text-sm text-grey mt-2">
                  Shows your business&apos;s net profit and summarizes your
                  revenues and expenses in a given time period.
                </div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

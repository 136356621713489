import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  getMerchantStats,
  getMerchantPlans,
  merchantBusinessPageViewed,
} from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import {
  MERCHANT_DASHBOARD_INIT_SAGA,
  MERCHANT_DASHBOARD_BUSINESS_PAGE_VIEWED_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantDashboardSetIsLoading,
  merchantDashboardSetPlans,
  merchantDashboardSetStats,
} from "./actions";

function* merchantDashboardInitHandler(): any {
  try {
    yield put(merchantDashboardSetIsLoading(true));

    const merchantStatsResponse = yield call(getMerchantStats);

    if (merchantStatsResponse?.success) {
      yield put(merchantDashboardSetStats(merchantStatsResponse?.data));
    }

    const merchantPlansResponse = yield call(getMerchantPlans);

    if (merchantPlansResponse?.success) {
      yield put(merchantDashboardSetPlans(merchantPlansResponse?.data));
    }

    yield put(merchantDashboardSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantDashboardSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantDashboardBusinessPageViewedHandler(): any {
  try {
    yield call(merchantBusinessPageViewed);
  } catch (error: any) {
    yield all([
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantDashboard() {
  yield takeLatest(MERCHANT_DASHBOARD_INIT_SAGA, merchantDashboardInitHandler);
  yield takeLatest(
    MERCHANT_DASHBOARD_BUSINESS_PAGE_VIEWED_SAGA,
    merchantDashboardBusinessPageViewedHandler
  );
}

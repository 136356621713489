import {
  MERCHANT_USER_PROFILE_SET_IS_LOADING,
  MERCHANT_USER_PROFILE_SET_CONFIG,
  MERCHANT_USER_UPDATE_PROFILE_DETAILS_SAGA,
} from "./types";

export const merchantUserProfileSetIsLoading = (payload: any) => ({
  type: MERCHANT_USER_PROFILE_SET_IS_LOADING,
  payload,
});

export const merchantUserProfileSetConfig = (payload: any) => ({
  type: MERCHANT_USER_PROFILE_SET_CONFIG,
  payload,
});

// async

export const merchantUserProfileUpdateProfileDetailsSaga = (payload: any) => ({
  type: MERCHANT_USER_UPDATE_PROFILE_DETAILS_SAGA,
  payload,
});

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_USER_PROFILE_SET_IS_LOADING,
  MERCHANT_USER_PROFILE_SET_CONFIG,
} from "./types";

type MerchantUserProfileStateType = {
  isLoading: boolean;
  config: {
    shouldEditPersonalInfo: Boolean;
    isSavingPersonalInfo: Boolean;
    shouldEditPassword: Boolean;
    isSavingPassword: Boolean;
  };
};

const initialState: MerchantUserProfileStateType = {
  isLoading: false,
  config: {
    shouldEditPersonalInfo: false,
    isSavingPersonalInfo: false,
    shouldEditPassword: false,
    isSavingPassword: false,
  },
};

const merchantUserProfileReducer = (
  state: MerchantUserProfileStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_USER_PROFILE_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_USER_PROFILE_SET_CONFIG:
      return { ...state, config: { ...state.config, ...action.payload } };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantUserProfileReducer;

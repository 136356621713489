import { useDispatch, useSelector } from "react-redux";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import CustomerCart from "components/Customer/Cart";
import { customerCartSetConfig } from "components/Customer/Cart/state/actions"; // customer cart actions

export default function CustomerCartDrawer() {
  const dispatch = useDispatch();

  const {
    config: { open },
  } = useSelector((store: any) => store?.customerCart);

  const handleClose = () => {
    dispatch(customerCartSetConfig({ open: false }));
  };

  return (
    <Drawer anchor="right" open={open} onClose={handleClose}>
      <div className="w-[300px] md:w-[400px] p-4">
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            left: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <CustomerCart />
      </div>
    </Drawer>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Switch from "@mui/material/Switch";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";
import Box from "@mui/material/Box";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Slider from "@mui/material/Slider";
import MuiInput from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { Dialog } from "components/Dialog";
import Button from "components/Button";
import { PaymentType } from "enums/Payments";
import { AvailableSchedules, PaymentSchedules } from "enums/PaymentSchedules";
import { MenuType } from "enums/Menu";
import { DiscountRuleType } from "enums/DiscountRules";
import { useMergeState } from "utils/custom-hooks";
import { buyDialogSetConfig } from "./state/actions";
import {
  customerCartAddToCart,
  customerCartRemoveFromCart,
  customerCartSetCart,
  customerCartSetConfig,
  customerCartFetchTaxes,
} from "components/Customer/Cart/state/actions"; // customer cart actions
import Images from "assets/images";

const Input = styled(MuiInput)`
  width: 42px;
`;

type Props = {
  onClose: any;
  isStandalonePlan?: boolean;
};

const TABS = [
  {
    label: "Order Once",
    value: PaymentType.ONE_TIME,
  },
  {
    label: "Subscribe",
    value: PaymentType.SUBSCRIPTION,
  },
  // {
  //   label: "Buy More Pay Less",
  //   value: PaymentType.BUY_MORE_PAY_LESS,
  // },
];

const Accordion = styled((props) => (
  // @ts-ignore
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
  // border: `1px solid ${theme.palette.divider}`,
  "&:not(:last-child)": {
    borderBottom: 0,
  },
  "&:before": {
    display: "none",
  },
}));

const AccordionSummary = styled((props) => (
  <MuiAccordionSummary
    expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />}
    {...props}
  />
))(({ theme }) => ({
  padding: 0,
  // backgroundColor:
  //   theme.palette.mode === "dark"
  //     ? "rgba(255, 255, 255, .05)"
  //     : "rgba(0, 0, 0, .03)",
  // flexDirection: "row-reverse",
  "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
    transform: "rotate(90deg)",
  },
  // "& .MuiAccordionSummary-content": {
  // marginLeft: theme.spacing(1),
  // },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: 0,
  // borderTop: "1px solid rgba(0, 0, 0, .125)",
}));

export default function BuyDialog(props: Props) {
  const { onClose, isStandalonePlan = false } = props;

  const dispatch = useDispatch();

  const { merchant, planDiscount } = useSelector(
    (store: any) => store?.publicBusinessPage
  );

  const { open, isLoading, product, menuType } = useSelector(
    (store: any) => store?.buyDialog
  );

  const { items: cartItems, taxes } = useSelector(
    (store: any) => store?.customerCart
  );

  const [state, setState] = useMergeState({
    tabs: [],
    selectedTab: {},
    selectedType: "",
    width: window.innerWidth,

    selectedVariation: {},
    selectedModifiers: [],

    planType: "personal",

    [PaymentType.ONE_TIME]: {
      amount: 0,
      quantity: 0,
    },

    [PaymentType.SUBSCRIPTION]: {
      type: "",

      // straight
      discountAmount: 0,
      allowedSchedules: [],
      schedule: "",

      // slider
      maxDiscountPercentage: 0,
      maxUnits: 0,
      percentageOff: 0,
      percentageOffOnPointScale: 0,

      amount: 0,
      quantity: 0,

      yearly: false,
    },

    [PaymentType.BUY_MORE_PAY_LESS]: {
      maxDiscountPercentage: 0,
      maxUnits: 0,
      percentageOff: 0,
      percentageOffOnPointScale: 0,

      amount: 0,
      quantity: 0,
    },
  });

  const isMobile = state?.width <= 768;

  const shouldShowDiscountSlider = () => {
    if (isStandalonePlan) {
      return false;
    }

    if (state?.selectedType === PaymentType.ONE_TIME) {
      return false;
    }

    if (
      state?.selectedType === PaymentType.SUBSCRIPTION &&
      state[PaymentType.SUBSCRIPTION]?.type === DiscountRuleType.STRAIGHT
    ) {
      return false;
    }

    return true;
  };

  const canUpdateQuantity = () => {
    if (isStandalonePlan) {
      return false;
    }

    return [PaymentType.ONE_TIME, PaymentType.SUBSCRIPTION].includes(
      state?.selectedType
    );
  };

  const handleTabChange = (event: any, newTabType: number) => {
    setState({
      selectedTab: TABS.find((elem: any) => elem.value === newTabType),
      selectedType: newTabType,
    });
  };

  const handleChangeSchedule = (event: any) => {
    setState({
      [PaymentType.SUBSCRIPTION]: {
        ...state[PaymentType.SUBSCRIPTION],
        schedule: event?.target?.value,
      },
    });
  };

  const getPercentageOffOnPointScale = (percentageOff: number) =>
    Number(
      (Number(percentageOff) /
        Number(state[state?.selectedType]?.maxDiscountPercentage)) *
        100
    );

  const handleChangePercentageOff = (
    event: Event,
    newValue: number | number[]
  ) => {
    const maxUnits = state[state?.selectedType]?.maxUnits;

    const maxDiscountPercentage =
      state[state?.selectedType]?.maxDiscountPercentage;

    const quantity = Math.round(
      Number((Number(newValue) / 100) * Number(maxUnits))
    );

    const percentageOff = Number(
      (Number(quantity) / Number(maxUnits)) * Number(maxDiscountPercentage)
    );

    const percentageOffOnPointScale = Number(
      (Number(percentageOff) / Number(maxDiscountPercentage)) * 100
    );

    const totalPrice =
      Number(state?.selectedVariation?.price) * Math.ceil(quantity);

    const discountedPrice: any =
      totalPrice - (totalPrice * percentageOff) / 100;

    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        percentageOff,
        percentageOffOnPointScale,
        quantity,
        amount: parseFloat(discountedPrice).toFixed(2),
      },
    });
  };

  const handleChangeQuantity = (event: any, variation: any = null) => {
    const quantity = event.target.value;

    const maxUnits = state[state?.selectedType]?.maxUnits;

    const maxDiscountPercentage =
      state[state?.selectedType]?.maxDiscountPercentage;

    if (Number(quantity) > Number(maxUnits)) {
      const percentageOff = Number(maxDiscountPercentage);

      const totalPrice = variation
        ? Number(variation?.price) * Math.ceil(quantity)
        : Number(state?.selectedVariation?.price) * Math.ceil(quantity);

      const discountedPrice: any =
        totalPrice - (totalPrice * percentageOff) / 100;

      setState({
        [state?.selectedType]: {
          ...state[state?.selectedType],
          percentageOff,
          percentageOffOnPointScale:
            getPercentageOffOnPointScale(percentageOff),
          amount: parseFloat(discountedPrice).toFixed(2),
          quantity,
        },
      });

      return;
    }

    const percentageOff = Number(
      (Number(quantity) / Number(maxUnits)) * Number(maxDiscountPercentage)
    );

    const percentageOffOnPointScale = Number(
      (Number(percentageOff) / Number(maxDiscountPercentage)) * 100
    );

    const totalPrice = variation
      ? Number(variation?.price) * Math.ceil(quantity)
      : Number(state?.selectedVariation?.price) * Math.ceil(quantity);

    const discountedPrice: any =
      totalPrice - (totalPrice * percentageOff) / 100;

    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        percentageOff,
        percentageOffOnPointScale,
        amount: parseFloat(discountedPrice).toFixed(2),
        quantity,
      },
    });
  };

  const handleIncreaseQuantity = () => {
    const quantity = state[state?.selectedType]?.quantity + 1;

    let amount = Number(quantity) * Number(state?.selectedVariation?.price);

    if (
      state?.selectedType === PaymentType.SUBSCRIPTION &&
      state[PaymentType.SUBSCRIPTION]?.type === DiscountRuleType.STRAIGHT
    ) {
      amount -=
        amount *
        (Number(state[PaymentType.SUBSCRIPTION]?.discountAmount) / 100);
    }

    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        amount,
        quantity,
      },
    });
  };

  const handleDecreaseQuantity = () => {
    const quantity = state[state?.selectedType]?.quantity - 1;

    let amount = Number(quantity) * Number(state?.selectedVariation?.price);

    if (
      state?.selectedType === PaymentType.SUBSCRIPTION &&
      state[PaymentType.SUBSCRIPTION]?.type === DiscountRuleType.STRAIGHT
    ) {
      amount -=
        amount *
        (Number(state[PaymentType.SUBSCRIPTION]?.discountAmount) / 100);
    }

    if (quantity - 1 < 0) {
      return;
    }

    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        amount,
        quantity,
      },
    });
  };

  const handleIncreaseMembers = () => {
    const quantity = state[state?.selectedType]?.quantity + 1;

    const amount = Number(quantity) * Number(product?.price?.amount);

    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        amount,
        quantity,
      },
    });
  };

  const handleDecreaseMembers = () => {
    const quantity = state[state?.selectedType]?.quantity - 1;

    const amount = Number(quantity) * Number(product?.price?.amount);

    if (quantity - 1 < 0) {
      return;
    }

    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        amount,
        quantity,
      },
    });
  };

  const isCorporatePlan = state?.planType === "corporate";

  const getPlanDiscountPercentage = () => {
    console.log(
      "planDiscount?.teamPlanDiscounts : ",
      planDiscount?.teamPlanDiscounts
    );

    const quantity = state[state?.selectedType]?.quantity;

    console.log("quantity : ", quantity);

    let teamPlanDiscount = {
      discountPercentage: 0,
      yearlyDiscountPercentage: 0,
    };

    if (state?.planType === "personal") {
      teamPlanDiscount.yearlyDiscountPercentage =
        planDiscount?.individualYearlyDiscountPercentage;

      return teamPlanDiscount;
    }

    for (let i = 0; i < planDiscount?.teamPlanDiscounts?.length; i++) {
      const elem = planDiscount?.teamPlanDiscounts[i];

      if (quantity <= elem?.members) {
        teamPlanDiscount = elem;
        break;
      }

      teamPlanDiscount = elem;
    }

    return {
      discountPercentage: teamPlanDiscount?.discountPercentage,
      yearlyDiscountPercentage: teamPlanDiscount?.yearlyDiscountPercentage,
    };
  };

  const handleSwitchYearly = (event: any) => {
    setState({
      [state?.selectedType]: {
        ...state[state?.selectedType],
        yearly: event?.target?.checked,
      },
    });
  };

  const getAmount = parseFloat(state[state?.selectedType]?.amount).toFixed(2);

  const getDiscountedAmount = () => {
    let discountAmount = parseFloat(
      String(
        Number(state[state?.selectedType]?.amount) -
          Number(state[state?.selectedType]?.amount) *
            Number(getPlanDiscountPercentage()?.discountPercentage / 100)
      )
    ).toFixed(2);

    console.log("state : ", state);

    if (state[state?.selectedType]?.yearly) {
      discountAmount = parseFloat(
        String(
          Number(discountAmount) -
            Number(discountAmount) *
              Number(
                getPlanDiscountPercentage()?.yearlyDiscountPercentage / 100
              )
        )
      ).toFixed(2);
    }

    console.log("discountAmount : ", discountAmount);

    return discountAmount;
  };

  const getLabel = () => {
    if (
      getPlanDiscountPercentage()?.discountPercentage > 0 ||
      (state[state?.selectedType]?.yearly &&
        getPlanDiscountPercentage()?.yearlyDiscountPercentage > 0)
    ) {
      return (
        <div>
          Add to Cart - $<span className="line-through">{getAmount}</span>
          <span className="ml-2">${getDiscountedAmount()}</span>
          {state[state?.selectedType]?.yearly && (
            <div className="text-xs text-center">One year commitment</div>
          )}
        </div>
      );
    }

    return `Add to Cart - ${getAmount}`;
  };

  const handleSelectVariation = (variation: any) => {
    let payload = { selectedVariation: variation };

    if (state?.selectedType === PaymentType.ONE_TIME) {
      payload = {
        ...payload,
        [state?.selectedType]: {
          ...state[state?.selectedType],
          quantity: 1,
          amount: Number(variation?.price),
        },
      };
    } else if (
      state?.selectedType === PaymentType.SUBSCRIPTION &&
      state[PaymentType.SUBSCRIPTION]?.type === DiscountRuleType.STRAIGHT
    ) {
      payload = {
        ...payload,
        [state?.selectedType]: {
          ...state[state?.selectedType],
          quantity: 1,
          amount:
            Number(variation?.price) -
            Number(variation?.price) *
              (Number(state[PaymentType.SUBSCRIPTION]?.discountAmount) / 100),
        },
      };
    } else {
      handleChangeQuantity(
        {
          target: { value: state[state?.selectedType]?.quantity },
        },
        variation
      );
    }

    setState(payload);
  };

  const handleSelectModifiers = (modifier: any) => {
    const newModifiers = [...state?.selectedModifiers];

    const existingModifier = newModifiers.find(
      (elem) => elem.id === modifier.id
    );

    if (existingModifier) {
      newModifiers.splice(
        newModifiers.findIndex((elem) => elem.id === modifier.id),
        1
      );
    } else {
      newModifiers.push(modifier);
    }

    let payload = { selectedModifiers: newModifiers };

    if (state.selectedType === PaymentType.ONE_TIME) {
      payload = {
        ...payload,
        [state?.selectedType]: {
          ...state[state?.selectedType],
          amount: existingModifier
            ? state[state?.selectedType]?.amount - Number(modifier?.price)
            : state[state?.selectedType]?.amount + Number(modifier?.price),
        },
      };
    }

    if (
      state.selectedType === PaymentType.SUBSCRIPTION ||
      state.selectedType === PaymentType.BUY_MORE_PAY_LESS
    ) {
      payload = {
        ...payload,
        [state?.selectedType]: {
          ...state[state?.selectedType],
          percentageOff: 0,
          percentageOffOnPointScale: 0,
          quantity: 0,
          amount: 0,
        },
      };
    }

    setState(payload);
  };

  const productAlreadyInCart = () =>
    cartItems?.some((item: any) => item?.product === product?._id);

  const getTax = (amount: number) => {
    let taxAmount = 0;

    taxes?.forEach((tax: any) => {
      taxAmount += amount * (Number(tax?.taxData?.percentage) / 100);
    });

    return Number(taxAmount);
  };

  const handleAddToCart = () => {
    if (isStandalonePlan) {
      if (productAlreadyInCart()) {
        dispatch(customerCartRemoveFromCart(product?._id));
      } else {
        const payload: any = {
          product: product?._id,
          stripeProductId: product?.stripeProductId,
          menuType: MenuType.SUBSCRIPTIONS,
          type: product?.type,
          title: product?.title,
          description: product?.description,
          images: product?.images,
          price: {
            planType: product?.price?.type,
            purchaseType: PaymentType.SUBSCRIPTION,
            amount: Number(product?.price?.amount),
            quantity: Number(product?.price?.quantity),
            schedule: product?.price?.schedule,
            tax: 0,
            totalAmount: 0,
            discountPercentage: 0,
            yearlyDiscountPercentage: 0,
            yearlyPlan: state[state?.selectedType]?.yearly,
          },
        };

        if (state?.planType === "corporate") {
          payload.price.corporatePlan = {
            members: Number(state[state?.selectedType]?.quantity),
          };
        }

        const { discountPercentage, yearlyDiscountPercentage } =
          getPlanDiscountPercentage();

        if (discountPercentage > 0 || yearlyDiscountPercentage > 0) {
          payload.price.totalAmount = +Number(getDiscountedAmount()).toFixed(2);
          payload.price.discountPercentage = discountPercentage;
          payload.price.yearlyDiscountPercentage = yearlyDiscountPercentage;
        } else {
          payload.price.totalAmount = +Number(
            state[state?.selectedType]?.amount
          ).toFixed(2);
        }

        payload.price.tax = +Number(
          getTax(payload?.price?.totalAmount)
        ).toFixed(2);

        dispatch(
          customerCartSetConfig({
            merchant,
            isCorporatePlan,
          })
        );
        dispatch(customerCartAddToCart(payload));
        dispatch(buyDialogSetConfig({ open: false }));
      }

      return;
    }

    const itemIndex = cartItems.findIndex(
      (elem: any) =>
        elem.product === product?._id &&
        elem?.price?.variation?.id === state?.selectedVariation?.id &&
        elem?.price.purchaseType === state?.selectedType
    );

    if (itemIndex !== -1) {
      const updatedCartItems = [...cartItems];

      const item = updatedCartItems[itemIndex];

      const newQuantity =
        Number(item?.price?.quantity) +
        Number(state[state?.selectedType]?.quantity);

      updatedCartItems[itemIndex].price.quantity = newQuantity;
      updatedCartItems[itemIndex].price.totalAmount = +Number(
        newQuantity * Number(state?.selectedVariation?.price)
      ).toFixed(2);

      dispatch(customerCartSetCart([...updatedCartItems]));
    } else {
      let price: any = {
        purchaseType: state?.selectedType,
        variation: state?.selectedVariation,
        modifiers: state?.selectedModifiers,
        amount: Number(state?.selectedVariation?.price),
        quantity: Number(state[state?.selectedType]?.quantity),
        totalAmount: +Number(state[state?.selectedType]?.amount).toFixed(2),
        tax: Number(state?.selectedVariation?.price),
      };

      if (state?.selectedType === PaymentType.SUBSCRIPTION) {
        price = {
          ...price,
          schedule: PaymentSchedules.MONTH,
          discountRuleType: state[PaymentType.SUBSCRIPTION]?.type,
        };

        if (
          state[PaymentType.SUBSCRIPTION]?.type === DiscountRuleType.STRAIGHT
        ) {
          price = {
            ...price,
            discountPercentage: Number(
              state[PaymentType.SUBSCRIPTION]?.discountAmount
            ),
            schedule: state[PaymentType.SUBSCRIPTION]?.schedule,
          };
        } else {
          price = {
            ...price,
            discountPercentage: Number(
              state[state?.selectedType]?.maxDiscountPercentage
            ),
          };
        }
      }

      if (state?.selectedType === PaymentType.BUY_MORE_PAY_LESS) {
        price = {
          ...price,
          schedule: PaymentSchedules.YEAR,
          discountRuleType: state[PaymentType.SUBSCRIPTION]?.type,
          discountPercentage: Number(
            state[state?.selectedType]?.maxDiscountPercentage
          ),
        };
      }

      price.tax = +Number(getTax(price?.totalAmount)).toFixed(2);

      const payload = {
        product: product?._id,
        stripeProductId: product?.stripeProductId,
        menuType,
        type: product?.type,
        title: product?.title,
        description: product?.description,
        images: product?.images,
        price,
      };

      dispatch(customerCartAddToCart(payload));
    }

    dispatch(customerCartSetConfig({ merchant }));
    dispatch(buyDialogSetConfig({ open: false }));
  };

  const handleWindowSizeChange = () => {
    setState({ width: window.innerWidth });
  };

  const getAvailableSchedules = () =>
    AvailableSchedules.filter((elem) =>
      state[PaymentType.SUBSCRIPTION]?.allowedSchedules?.includes(elem?.value)
    );

  const handleChangePlanType = (planType: string) => {
    let payload = { planType };

    if (planType === "personal") {
      payload = {
        ...payload,
        [PaymentType.SUBSCRIPTION]: {
          ...state[PaymentType.SUBSCRIPTION],
          amount: Number(product?.price?.amount),
          quantity: 1,
        },
      };
    }

    setState(payload);
  };

  useEffect(() => {
    dispatch(customerCartFetchTaxes({ mId: merchant?._id }));

    let tabs = [TABS[0]];

    let selectedType = PaymentType.ONE_TIME;

    if (merchant?.hideOneTimePurchases) {
      tabs = [];
      selectedType = PaymentType.SUBSCRIPTION;
    }

    let payload = {
      tabs,
      selectedType,
      selectedVariation: product?.variations[0],
      [PaymentType.ONE_TIME]: {
        ...state[PaymentType.ONE_TIME],
        amount: product?.variations[0]?.price,
        quantity: 1,
      },
    };

    if (isStandalonePlan) {
      payload = {
        ...payload,
        selectedType: PaymentType.SUBSCRIPTION,
        [PaymentType.SUBSCRIPTION]: {
          ...state[PaymentType.SUBSCRIPTION],
          amount: Number(product?.price?.amount),
          quantity: 1,
        },
      };
    }

    if (menuType !== MenuType.COMMUNITY_CART && product?.discountRule) {
      if (product?.discountRule?.discount[PaymentType.SUBSCRIPTION]) {
        tabs = [...tabs, TABS[1]];

        const type =
          product?.discountRule?.discount[PaymentType.SUBSCRIPTION]?.type;

        let subscription: any = {
          type,
        };

        if (type === DiscountRuleType.STRAIGHT) {
          subscription = {
            ...subscription,
            // straight
            discountAmount:
              product?.discountRule?.discount[PaymentType.SUBSCRIPTION]?.rule
                ?.discountAmount,
            allowedSchedules:
              product?.discountRule?.discount[PaymentType.SUBSCRIPTION]?.rule
                ?.allowedSchedules,
            amount:
              product?.variations[0]?.price -
              Number(product?.variations[0]?.price) *
                (Number(
                  product?.discountRule?.discount[PaymentType.SUBSCRIPTION]
                    ?.rule?.discountAmount
                ) /
                  100),
            quantity: 1,
          };
        } else {
          subscription = {
            ...subscription,
            // slider
            maxDiscountPercentage:
              product?.discountRule?.discount[PaymentType.SUBSCRIPTION]?.rule
                ?.maxDiscountPercentage,
            maxUnits:
              product?.discountRule?.discount[PaymentType.SUBSCRIPTION]?.rule
                ?.maxUnits,
          };
        }

        payload = {
          ...payload,
          tabs,
          selectedTab: tabs[0],
          selectedType,
          [PaymentType.SUBSCRIPTION]: {
            ...state[PaymentType.SUBSCRIPTION],
            ...subscription,
          },
        };
      }

      // if (product?.discountRule?.discount[PaymentType.BUY_MORE_PAY_LESS]) {
      //   tabs = [...tabs, TABS[2]];

      //   payload = {
      //     ...payload,
      //     tabs,
      //     [PaymentType.BUY_MORE_PAY_LESS]: {
      //       ...state[PaymentType.BUY_MORE_PAY_LESS],
      //       maxDiscountPercentage:
      //         product?.discountRule?.discount[PaymentType.BUY_MORE_PAY_LESS]
      //           ?.rule?.maxDiscountPercentage,
      //       maxUnits:
      //         product?.discountRule?.discount[PaymentType.BUY_MORE_PAY_LESS]
      //           ?.rule?.maxUnits,
      //     },
      //   };
      // }
    }

    setState({ ...payload });

    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <Dialog
      onClose={onClose}
      open={open}
      sx={{
        "& .MuiDialog-paper": {
          width: 500,
        },
        "& .MuiDialogContent-root": {
          padding: 3,
        },
      }}
    >
      <DialogContent dividers>
        <div>
          <div key={product?._id} className="mb-4">
            <div className="flex justify-center mb-6">
              <img
                src={product?.images[0] || Images.NoImagePlaceholder}
                className="rounded-lg object-cover w-[500px] h-[300px]"
              />
            </div>

            <div className="text-grey text-2xl mb-2">{product?.title}</div>

            {/* <div className="text-grey text-sm font-normal mb-4">
              {merchant?.businessName} - {merchant?.addressLine1},{" "}
              {merchant?.city}, {merchant?.state} {merchant?.zipcode}
            </div> */}

            <div className="text-grey text-sm font-normal whitespace-pre-line">
              {product?.description}
            </div>

            {isStandalonePlan && (
              <div className="mt-4">
                <div className="text-grey">Subscription</div>

                <hr className="my-1" />

                <div className="text-sm mt-4">
                  {merchant?.shouldShowCorporatePlans
                    ? "Please select one to get started"
                    : "Please select to get started"}
                </div>

                <div className="block md:flex justify-between my-4">
                  <div className="text-center">
                    <Button
                      label="Personal Plan"
                      color="info"
                      variant={
                        state?.planType === "personal"
                          ? "contained"
                          : "outlined"
                      }
                      onClick={() => handleChangePlanType("personal")}
                      style={{
                        borderRadius: 4,
                        fontSize: 14,
                        color:
                          state?.planType !== "personal"
                            ? "#000000"
                            : "#FFFFFF",
                        height: 40,
                        width: 150,
                      }}
                    />
                  </div>

                  {merchant?.shouldShowCorporatePlans && (
                    <div className="text-center mt-4 md:mt-0">
                      <Button
                        label="Corporate Plan"
                        color="info"
                        variant={
                          state?.planType === "corporate"
                            ? "contained"
                            : "outlined"
                        }
                        onClick={() => handleChangePlanType("corporate")}
                        style={{
                          borderRadius: 4,
                          fontSize: 14,
                          color:
                            state?.planType !== "corporate"
                              ? "#000000"
                              : "#FFFFFF",
                          height: 40,
                          width: 150,
                        }}
                      />

                      {isCorporatePlan && (
                        <div className="flex flex-col justify-center items-center">
                          <div className="text-xs my-2">
                            Number of team members you are buying for
                          </div>

                          <div className="flex items-center">
                            <IconButton onClick={handleDecreaseMembers}>
                              <RemoveCircleOutlineIcon />
                            </IconButton>

                            <div className="mx-4 bg-[#f0f0f0] flex justify-center items-center rounded-md w-12 h-8">
                              <span>
                                {state[state?.selectedType]?.quantity}
                              </span>
                            </div>

                            <IconButton onClick={handleIncreaseMembers}>
                              <AddCircleOutlineIcon />
                            </IconButton>
                          </div>

                          {planDiscount?._id &&
                            getPlanDiscountPercentage()?.discountPercentage >
                              0 && (
                              <div className="text-grey text-sm mt-4">
                                Save{" "}
                                {
                                  getPlanDiscountPercentage()
                                    ?.discountPercentage
                                }
                                %
                              </div>
                            )}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            {state?.selectedTab?.value &&
              menuType !== MenuType.COMMUNITY_CART &&
              product?.discountRule &&
              !isStandalonePlan && (
                <div className="my-4">
                  <Box sx={{ width: "100%" }}>
                    <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                      <Tabs
                        value={state?.selectedTab?.value}
                        onChange={handleTabChange}
                        // @ts-ignore
                        variant={isMobile ? "scrollable" : ""}
                        TabIndicatorProps={{
                          style: {
                            backgroundColor: "#6A8566",
                          },
                        }}
                      >
                        {state?.tabs?.map((tab: any) => (
                          <Tab
                            key={tab.label}
                            label={tab.label}
                            value={tab.value}
                            style={{
                              textTransform: "none",
                              color: "#363333",
                              fontSize: 14,
                              fontFamily: "Poppins",
                              paddingLeft: 8,
                            }}
                          />
                        ))}
                      </Tabs>
                    </Box>
                  </Box>
                </div>
              )}

            {!isStandalonePlan && (
              <div className="grid grid-cols-1 sm:grid-cols-2 gap-3 mt-8">
                {product?.variations?.map((variation: any) => (
                  <Chip
                    key={variation?.id}
                    label={variation?.name}
                    onClick={() => handleSelectVariation(variation)}
                    variant={
                      state?.selectedVariation?.id === variation?.id
                        ? "filled"
                        : "outlined"
                    }
                    style={{
                      borderRadius: 4,
                      border:
                        state?.selectedVariation?.id === variation?.id
                          ? "1px solid #000000"
                          : "",
                    }}
                  />
                ))}
              </div>
            )}

            <div className="mt-4">
              {state?.selectedType === PaymentType.ONE_TIME &&
                !isStandalonePlan &&
                product?.modifiers?.map((modifier: any) => (
                  <div key={modifier._id}>
                    {/* @ts-ignore */}
                    <Accordion>
                      {/* @ts-ignore */}
                      <AccordionSummary>
                        <div className="text-grey text-sm font-medium mb-2">
                          {modifier?.title}
                        </div>
                      </AccordionSummary>

                      <AccordionDetails>
                        <div className="grid grid-cols-3 gap-3 my-4">
                          {modifier?.modifiers?.map((elem: any) => (
                            <Chip
                              key={elem?.id}
                              label={elem?.name}
                              onClick={() => handleSelectModifiers(elem)}
                              variant={
                                state?.selectedModifiers?.some(
                                  (item: any) => item?.id === elem?.id
                                )
                                  ? "filled"
                                  : "outlined"
                              }
                              style={{
                                borderRadius: 4,
                                border: state?.selectedModifiers?.some(
                                  (item: any) => item?.id === elem?.id
                                )
                                  ? "1px solid #000000"
                                  : "",
                              }}
                            />
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                ))}
            </div>

            {state?.selectedType === PaymentType.SUBSCRIPTION &&
              state[PaymentType.SUBSCRIPTION]?.type ===
                DiscountRuleType.STRAIGHT &&
              !isStandalonePlan && (
                <div className="mt-12">
                  <div className="text-grey text-sm font-medium">
                    Subscribe and Save{" "}
                    {state[PaymentType.SUBSCRIPTION]?.discountAmount}%
                  </div>

                  <div className="w-1/2 md:w-1/3 mt-8">
                    <FormControl
                      variant="outlined"
                      className="input-select-field"
                    >
                      <Select
                        fullWidth
                        displayEmpty
                        variant="outlined"
                        value={state[PaymentType.SUBSCRIPTION]?.schedule}
                        renderValue={(selected) => {
                          if (selected.length === 0) {
                            return <span>Schedule</span>;
                          }

                          return AvailableSchedules.find(
                            (elem) => elem.value === selected
                          )?.label;
                        }}
                        onChange={handleChangeSchedule}
                        color="secondary"
                      >
                        <MenuItem disabled value="">
                          <span>Schedule</span>
                        </MenuItem>

                        {getAvailableSchedules().map((type: any) => (
                          <MenuItem key={type?.value} value={type?.value}>
                            {type?.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </div>
                </div>
              )}

            {shouldShowDiscountSlider() && (
              <div className="mt-12">
                <div className="block md:flex justify-between items-start mt-3">
                  <div className="w-full md:w-1/2">
                    <div className="flex justify-between items-center mb-2">
                      <div className="text-grey text-sm font-normal">
                        Percentage Off
                      </div>

                      <div className="font-normal">
                        {parseFloat(
                          state[state?.selectedType]?.percentageOff
                        ).toFixed(2)}
                        %
                      </div>
                    </div>

                    <Slider
                      value={
                        typeof state[state?.selectedType]
                          ?.percentageOffOnPointScale === "number"
                          ? state[state?.selectedType]
                              ?.percentageOffOnPointScale
                          : 0
                      }
                      sx={{
                        ml: isMobile ? 0 : 1,
                      }}
                      onChange={handleChangePercentageOff}
                    />
                  </div>

                  <div className="text-center">
                    <div className="w-full md:w-1/2 flex justify-between items-center md:block">
                      <div className=" text-grey text-sm font-normal md:mb-2">
                        Units
                        {state?.selectedType === PaymentType.SUBSCRIPTION &&
                          "/Month"}
                      </div>

                      <div>
                        <Input
                          value={state[state?.selectedType]?.quantity}
                          onChange={handleChangeQuantity}
                          autoFocus
                          inputProps={{
                            type: "number",
                            style: {
                              fontSize: 18,
                            },
                          }}
                          onKeyPress={(event: any) => {
                            const regex = new RegExp("^[0-9]");

                            const key = String.fromCharCode(
                              !event.charCode ? event.which : event.charCode
                            );

                            if (!regex.test(key)) {
                              event.preventDefault();
                              return false;
                            }
                          }}
                          disableUnderline
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </DialogContent>

      <DialogActions
        style={{
          paddingLeft: 30,
          paddingRight: 30,
        }}
      >
        <div
          className={`w-full flex ${
            isStandalonePlan
              ? "flex-col justify-center md:flex-row md:justify-between items-center"
              : "flex-col md:flex-row md:justify-between md:items-center py-2"
          }`}
        >
          {canUpdateQuantity() && (
            <div className="flex items-center">
              <IconButton onClick={handleDecreaseQuantity}>
                <RemoveCircleOutlineIcon />
              </IconButton>

              <div className="mx-4 bg-[#f0f0f0] flex justify-center items-center rounded-md w-12 h-8">
                <span>{state[state?.selectedType]?.quantity}</span>
              </div>

              <IconButton onClick={handleIncreaseQuantity}>
                <AddCircleOutlineIcon />
              </IconButton>
            </div>
          )}

          <div className="flex items-center">
            {getPlanDiscountPercentage()?.yearlyDiscountPercentage > 0 && (
              <div>
                <span className="text-xs mr-2">
                  Yearly (Save{" "}
                  {getPlanDiscountPercentage()?.yearlyDiscountPercentage}%)
                </span>

                <Switch
                  size="small"
                  checked={state?.yearly}
                  onChange={handleSwitchYearly}
                />
              </div>
            )}
          </div>

          <div
            className={`mt-4 md:mt-0 ${
              state?.selectedType === PaymentType.ONE_TIME ? "md:ml-10" : ""
            }`}
          >
            <Button
              label={getLabel()}
              color="secondary"
              onClick={handleAddToCart}
              style={{
                borderRadius: 4,
                fontSize: 14,
                color: "#FFFFFF",
                height: state[state?.selectedType]?.yearly ? 50 : 40,
              }}
              loaderButton
              loadingPosition="center"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

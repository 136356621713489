import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import SettingsIcon from "@mui/icons-material/Settings";
import Spinner from "components/Spinner";
import { customerSettingsFetchSubscriptionsSaga } from "../state/actions";
import { humanizeSchedule } from "enums/PaymentSchedules";
import { PlanType } from "enums/PlanType";
import Images from "assets/images";

export default function CustomerSettingsSubscriptionsContainer() {
  const dispatch = useDispatch();

  const { isLoading, subscriptions } = useSelector(
    (store: any) => store?.customerSettings
  );

  useEffect(() => {
    dispatch(customerSettingsFetchSubscriptionsSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="mt-8">
            {subscriptions?.map((subscription: any, index: number) => (
              <div key={subscription?._id}>
                <hr />
                <div className="flex flex-col justify-center h-40">
                  <div className="flex items-center text-grey">
                    <img src={Images.CaretRight} className="mx-2" />

                    <div className="w-full">
                      <div className="text-grey text-xl font-semibold">
                        {subscription?.product?.title}
                      </div>

                      <div className="text-grey text-lg font-medium mt-1">
                        ${subscription?.product?.price?.amount}{" "}
                        {humanizeSchedule(
                          subscription?.product?.price?.schedule
                        )}
                      </div>

                      <div className="text-grey text-xs font-medium mt-1">
                        {subscription?.product?.price?.type ===
                        PlanType.UNLIMITED_OFFERING
                          ? "UNLIMITED"
                          : `${subscription?.product?.price?.quantity} PER ${subscription?.product?.price?.schedule}`}
                      </div>

                      <div className="text-grey text-sm font-medium mt-2">
                        {subscription?.product?.description}
                      </div>
                    </div>

                    <div>
                      <Link
                        to={`/subscription/details?id=${subscription?._id}&fromSettings=true`}
                      >
                        <SettingsIcon />
                      </Link>
                    </div>
                  </div>
                </div>

                {subscriptions?.length === index + 1 && <hr />}
              </div>
            ))}
          </div>

          {!subscriptions.length && (
            <div className="flex justify-center">
              <div className="text-grey text-xl">
                Start by purchasing a subscription!
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

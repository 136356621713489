import { takeLatest, put, call } from "redux-saga/effects";
import { confirmAddPaymentMethod } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { CUSTOMER_CONFIRM_ADD_PAYMENT_METHOD_SAGA } from "./types";
import { appSetError } from "app/state/actions"; // app actions
import { customerConfirmAddPaymentMethodSetIsLoading } from "./actions";

function* customerConfirmAddPaymentMethodHandler(action: any): any {
  try {
    yield put(customerConfirmAddPaymentMethodSetIsLoading(true));

    const response = yield call(confirmAddPaymentMethod, action.payload);

    if (response?.success) {
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.SUCCESS,
          message: response?.message,
        })
      );
    }
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
  } finally {
    setTimeout(
      action.navigate(`/checkout?planId=${action?.payload?.planId}`),
      3000
    );
  }
}

export default function* watchCustomerConfirmAddPaymentMethod() {
  yield takeLatest(
    CUSTOMER_CONFIRM_ADD_PAYMENT_METHOD_SAGA,
    customerConfirmAddPaymentMethodHandler
  );
}

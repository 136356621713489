import {
  CUSTOMER_DASHBOARD_SET_IS_LOADING,
  CUSTOMER_DASHBOARD_SET_PLANS,
  CUSTOMER_DASHBOARD_SET_STORES,
  CUSTOMER_DASHBOARD_GET_STORES_SAGA,
  CUSTOMER_DASHBOARD_GET_PLANS_SAGA,
} from "./types";

export const customerDashboardSetIsLoading = (payload: any) => ({
  type: CUSTOMER_DASHBOARD_SET_IS_LOADING,
  payload,
});

export const customerDashboardSetStores = (payload: any) => ({
  type: CUSTOMER_DASHBOARD_SET_STORES,
  payload,
});

export const customerDashboardSetPlans = (payload: any) => ({
  type: CUSTOMER_DASHBOARD_SET_PLANS,
  payload,
});

// async

export const customerDashboardGetStoresSaga = () => ({
  type: CUSTOMER_DASHBOARD_GET_STORES_SAGA,
});

export const customerDashboardGetPlansSaga = () => ({
  type: CUSTOMER_DASHBOARD_GET_PLANS_SAGA,
});

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import moment from "moment-timezone";
import Spinner from "components/Spinner";
import Button from "components/Button";
import OrderDialog from "components/Customer/OrderDialog";
import OrderDetailsDialog from "components/Customer/OrderDetailsDialog";
import BookingDialog from "components/Customer/BookingDialog";
import {
  customerSubscriptionsFetchSubscriptionDetailsSaga,
  customerSubscriptionsPauseSaga,
  customerSubscriptionsCancelSaga,
} from "../state/actions";
import { orderDialogSetConfig } from "components/Customer/OrderDialog/state/actions"; // order dialog actions
import { PlanType } from "enums/PlanType";
import { ItemType } from "enums/Products";
import { formatDate, unixDate } from "utils/date";
import { useMergeState } from "utils/custom-hooks";
import { truncateString } from "utils/string";
import { humanizeSchedule } from "enums/PaymentSchedules";
import { OrderType } from "enums/orders";
import { getPlanTitle } from "utils/common";

export default function CustomerPlanDetailsContainer() {
  const [searchParams] = useSearchParams();

  const dispatch = useDispatch();

  const subscriptionId = searchParams.get("id");

  const fromSettings = searchParams.get("fromSettings");

  const { isLoading, subscription, usage, orders } = useSelector(
    (store: any) => store?.customerSubscription
  );

  const { open: shouldShowOrderDialog } = useSelector(
    (store: any) => store?.orderDialog
  );

  const [state, setState] = useMergeState({
    shouldShowSettings: false,
    shouldShowOrderDetailsDialog: false,
    shouldShowBookingDialog: false,
    selectedOrder: null,
  });

  const handleOpenOrderDialog = (plan: any) => {
    dispatch(orderDialogSetConfig({ open: true, plan }));
  };

  const handleCloseOrderDialog = () => {
    dispatch(orderDialogSetConfig({ open: false }));
  };

  const handleOpenOrderDetailsDialog = (selectedOrder: any) => {
    setState({ shouldShowOrderDetailsDialog: true, selectedOrder });
  };

  const handleCloseOrderDetailsDialog = (shouldRefresh = false) => {
    setState({ shouldShowOrderDetailsDialog: false, selectedOrder: null });

    if (shouldRefresh) {
      dispatch(
        customerSubscriptionsFetchSubscriptionDetailsSaga({ subscriptionId })
      );
    }
  };

  const handleOpenBookingDialog = () => {
    setState({ shouldShowBookingDialog: true });
  };

  const handleCloseBookingDialog = () => {
    setState({ shouldShowBookingDialog: false });
  };

  const handleUse = () => {
    handleOpenOrderDialog(subscription);
  };

  const handlePause = () => {
    dispatch(
      customerSubscriptionsPauseSaga({ subscriptionId: subscription?._id })
    );
  };

  const handleCancel = () => {
    dispatch(
      customerSubscriptionsCancelSaga({ subscriptionId: subscription?._id })
    );
  };

  const shouldShowPauseAndCancel = () => {
    if (
      state?.shouldShowSettings &&
      !subscription?.stripeMetadata?.cancelAtPeriodEnd &&
      !subscription?.stripeMetadata?.pauseCollection
    ) {
      return true;
    }

    return false;
  };

  useEffect(() => {
    if (fromSettings) {
      setState({ shouldShowSettings: true });
    }

    dispatch(
      customerSubscriptionsFetchSubscriptionDetailsSaga({ subscriptionId })
    );
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div className="flex justify-center mt-2 mb-8">
          <div className="w-10/12 md:w-3/4">
            <div className="my-4">
              <div className="relative">
                <img
                  src={subscription?.merchant?.businessBanner}
                  className="rounded-md w-full h-[300px] object-cover"
                />

                <div>
                  <img
                    src={subscription?.merchant?.businessLogo}
                    className="rounded-full absolute right-0 -mt-10 mr-4 w-24 h-24"
                  />
                </div>
              </div>

              <div className="text-4xl font-bold mt-12 mb-2">
                {subscription?.merchant?.businessName}
              </div>

              <div className="text-grey-2 text-sm font-semibold my-1">
                {subscription?.merchant?.addressLine1},{" "}
                {subscription?.merchant?.state} -{" "}
                {subscription?.merchant?.zipcode}
              </div>
            </div>

            <div className="sm:w-full bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5">
              <div className="flex flex-col md:flex-row">
                <div className="w-full md:w-1/2">
                  <div className="text-grey text-xl font-semibold">
                    Your Subscription
                  </div>

                  <div className="text-grey font-medium mt-4">
                    {subscription?.product?.title}
                  </div>

                  {subscription?.product?.isOffer ? (
                    <div className="text-grey text-sm font-normal mt-2">
                      PROMO OFFER
                    </div>
                  ) : (
                    <div className="text-grey text-sm font-medium mt-2 bg-white w-fit h-6 flex justify-center items-center rounded-md p-2">
                      ${subscription?.amount} |{" "}
                      {subscription?.product?.price?.type ===
                      PlanType.UNLIMITED_OFFERING
                        ? "UNLIMITED"
                        : `${subscription?.quantity} | ${humanizeSchedule(
                            subscription?.schedule
                          )}`}
                    </div>
                  )}

                  <div className="w-10/12 text-grey mt-2">
                    {subscription?.product?.description}
                  </div>

                  {subscription?.stripeMetadata?.pauseCollection && (
                    <div className="text-primary text-sm font-semibold mt-2">
                      Current subscription is paused, you can redeem your uses
                      through the current period (
                      {unixDate(
                        subscription?.stripeMetadata?.currentPeriodEnd,
                        "ll"
                      )}
                      )
                    </div>
                  )}

                  {subscription?.stripeMetadata?.cancelAtPeriodEnd && (
                    <div className="text-primary text-sm font-semibold mt-2">
                      Current subscription is cancelled, you can redeem your
                      uses through the current period (
                      {unixDate(subscription?.stripeMetadata?.cancelAt, "ll")})
                    </div>
                  )}

                  <div className="block lg:flex w-3/4 justify-between mt-4">
                    <div className="flex mb-3">
                      <Button
                        label="Use Now"
                        color="secondary"
                        onClick={handleUse}
                        style={{
                          borderRadius: 40,
                          fontSize: 14,
                          color: "#FFFFFF",
                          fontWeight: 500,
                          height: 40,
                        }}
                        fullWidth
                      />

                      {/* <div className="ml-4">
                        <Button
                          label="Schedule"
                          color="secondary"
                          onClick={handleOpenBookingDialog}
                          style={{
                            borderRadius: 40,
                            fontSize: 14,
                            color: "#FFFFFF",
                            fontWeight: 500,
                            height: 40,
                          }}
                          fullWidth
                        />
                      </div> */}
                    </div>

                    {shouldShowPauseAndCancel() && (
                      <div className="mb-3">
                        <Button
                          label="Pause"
                          onClick={handlePause}
                          style={{
                            borderRadius: 40,
                            fontSize: 14,
                            color: "#000000",
                            background: "rgb(231, 231, 231)",
                            boxShadow: "rgb(231 231 231) 0px 0px 0px 1px inset",
                            fontWeight: 500,
                            height: 40,
                          }}
                        />
                      </div>
                    )}

                    {shouldShowPauseAndCancel() && (
                      <Button
                        label="Cancel"
                        onClick={handleCancel}
                        style={{
                          borderRadius: 40,
                          fontSize: 14,
                          color: "#FFFFFF",
                          height: 40,
                          background: "#6A8566",
                          fontWeight: 500,
                        }}
                      />
                    )}
                  </div>
                </div>

                <div className="w-full md:w-1/2 mt-4 md:mt-0">
                  <div className="text-grey text-xl font-semibold">
                    Your Usage
                  </div>

                  {subscription?.type === ItemType.BMPL ? (
                    <div className="text-grey font-medium mt-4">
                      Valid starting {formatDate(subscription?.createdAt, "ll")}
                    </div>
                  ) : (
                    <div className="text-grey font-medium mt-4">
                      From {unixDate(usage?.currentPeriodStart, "ll")} To{" "}
                      {unixDate(usage?.currentPeriodEnd, "ll")}
                    </div>
                  )}

                  {subscription?.product?.price?.type ===
                    PlanType.UNLIMITED_OFFERING &&
                    usage?.nextTimeToUse && (
                      <div className="w-full text-grey font-semibold mt-4">
                        {moment.unix(usage?.nextTimeToUse).fromNow(true)}{" "}
                        remaining until next use
                      </div>
                    )}

                  {subscription?.product?.price?.type ===
                    PlanType.QUANTITY_LIMIT && (
                    <div className="mt-4">
                      <div className="flex justify-between items-center mb-2">
                        <div className="w-1/2 text-green-3 font-semibold text-center">
                          {usage?.usedPercentage}% used
                        </div>

                        <div className="w-1/2 text-grey font-semibold text-center">
                          {usage?.remainingPercentage}% remaining
                        </div>
                      </div>

                      <div className="bg-white w-full h-6 rounded-3xl border-[1px] relative">
                        <div
                          className="bg-green-3 h-full rounded-3xl"
                          style={{ width: `${usage?.usedPercentage}%` }}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="mt-12">
              <div className="text-grey text-xl font-semibold">
                Past Orders Or Visits
              </div>

              <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 ld:gap-3 lg:grid-cols-4">
                {orders?.map((order: any) => (
                  <div
                    key={order?._id}
                    className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md flex flex-col justify-between w-[220px] h-[170px] p-4 my-4"
                  >
                    <div className="text-grey text-xl font-semibold">
                      {truncateString(
                        order?.type === OrderType.REDEMPTION
                          ? getPlanTitle(order)
                          : order?.subscription?.product?.title,
                        15
                      )}
                    </div>

                    <div className="text-grey text-sm my-1">
                      {order?.variation?.name}
                      {/* {order?.modifiers?.length ? "," : ""}{" "}
                      {order?.modifiers?.map(
                        (elem: any) =>
                          `${elem?.name} ($${parseFloat(elem?.price).toFixed(
                            2
                          )})`
                      )}{" "} */}
                    </div>

                    <div className="text-grey text-sm my-1">
                      {formatDate(order?.createdAt, "lll")}
                    </div>

                    <div className="my-2">
                      <Button
                        label="Order Info"
                        color="secondary"
                        onClick={() => handleOpenOrderDetailsDialog(order)}
                        style={{
                          borderRadius: 40,
                          fontWeight: 500,
                          fontSize: 14,
                          color: "#FFFFFF",
                          height: 40,
                        }}
                      />
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>

          {shouldShowOrderDialog && (
            <OrderDialog onClose={handleCloseOrderDialog} />
          )}

          {state?.shouldShowOrderDetailsDialog && state?.selectedOrder && (
            <OrderDetailsDialog
              open={state?.shouldShowOrderDetailsDialog}
              onClose={handleCloseOrderDetailsDialog}
              order={state?.selectedOrder}
            />
          )}

          {state?.shouldShowBookingDialog && (
            <BookingDialog
              open={state?.shouldShowBookingDialog}
              onClose={handleCloseBookingDialog}
              subscription={subscription}
            />
          )}
        </div>
      )}
    </div>
  );
}

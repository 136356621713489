import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import DialogContent from "@mui/material/DialogContent";
import { Dialog } from "components/Dialog";
import NextStepsForm from "./NextStepsForm";
import { customerCheckoutSetConfig } from "containers/consumer/checkout/state/actions";

export default function StripeNextActions() {
  const dispatch = useDispatch();

  const {
    checkoutConfig: { requiresAction: open, clientSecret },
  } = useSelector((store: any) => store?.customerCheckout);

  const {
    config: { merchant },
  } = useSelector((store: any) => store?.customerCart);

  const [stripePromise, setStripePromise] = useState<any>(null);

  const handleClose = () => {
    dispatch(
      customerCheckoutSetConfig({ requiresAction: false, clientSecret: null })
    );
  };

  useEffect(() => {
    if (!stripePromise) {
      setStripePromise(
        loadStripe(process.env?.REACT_APP_STRIPE_PUBLIC_KEY || "", {
          stripeAccount: merchant?.stripeAccountId,
        })
      );
    }
  }, [merchant, stripePromise]);

  return (
    <Dialog
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleClose();
        }
      }}
      open={open}
      maxWidth="xs"
      fullWidth
      disableEscapeKeyDown
    >
      <DialogContent>
        {stripePromise && clientSecret && (
          <Elements stripe={stripePromise}>
            <NextStepsForm clientSecret={clientSecret} onClose={handleClose} />
          </Elements>
        )}
      </DialogContent>
    </Dialog>
  );
}

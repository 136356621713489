import { takeLatest, put, all, call } from "redux-saga/effects";
import { getMerchantAlerts } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { MERCHANT_ALERTS_FETCH_SAGA } from "./types";
import { appSetError } from "app/state/actions"; // app actions
import { merchantAlertsSetAlerts, merchantAlertsSetIsLoading } from "./actions";

function* merchantAlertsFetchHandler(action: any): any {
  try {
    yield put(merchantAlertsSetIsLoading(true));

    const response = yield call(getMerchantAlerts, action.payload);

    if (response?.success) {
      yield put(merchantAlertsSetAlerts(response?.data));
    }

    yield put(merchantAlertsSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantAlertsSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantAlerts() {
  yield takeLatest(MERCHANT_ALERTS_FETCH_SAGA, merchantAlertsFetchHandler);
}

import { RESET_APP_STATE } from "app/state/types";
import {
  PUBLIC_BUSINESS_PAGE_SET_IS_LOADING,
  PUBLIC_BUSINESS_PAGE_SET_DETAILS,
} from "./types";

type PublicBusinessPageStateType = {
  isLoading: boolean;
  merchant: any;
  products: Array<any>;
  subscriptions: Array<any>;
  communityCart: Array<any>;
  planDiscount: any;
};

const initialState: PublicBusinessPageStateType = {
  isLoading: false,
  merchant: {},
  products: [],
  subscriptions: [],
  communityCart: [],
  planDiscount: {},
};

const publicBusinessPageReducer = (
  state: PublicBusinessPageStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case PUBLIC_BUSINESS_PAGE_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case PUBLIC_BUSINESS_PAGE_SET_DETAILS:
      return { ...state, ...action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default publicBusinessPageReducer;

export const UserRoles = {
  ADMIN: "ADMIN",
  MEMBER: "MEMBER",
};

export const getUserRoles = [
  {
    label: "Admin",
    value: UserRoles.ADMIN,
  },
  {
    label: "Member",
    value: UserRoles.MEMBER,
  },
];

import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Button from "components/Button";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import { Dialog, DialogTitle } from "components/Dialog";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import { useMergeState } from "utils/custom-hooks";
import { merchantPlansInviteSubscriberSaga } from "containers/merchant/plans/state/actions"; // merchant plans actions

type Props = {
  onClose: any;
};

export default function MerchantInviteSubscriberDialog(props: Props) {
  const { onClose } = props;

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const {
    inviteSubscriberDialogConfig: { open, isLoading },
  } = useSelector((store: any) => store?.merchantPlans);

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const [state, setState] = useMergeState({
    phone: "",
    message: `Hey, ${user?.merchant?.businessName} is inviting you to look at their updated menu powered by Cherry.`,
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.phone) {
      payload = { phone: true, ...payload };
      isValid = false;
    }

    if (!state.message) {
      payload = { message: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleNavigateToSettings = () => {
    navigate("/settings?tab=PAYMENT_PROCESSORS");
    onClose();
  };

  const handleSendInvitation = () => {
    if (!isFormValid()) {
      return;
    }

    dispatch(
      merchantPlansInviteSubscriberSaga({
        phone: state.phone,
        message: state.message,
      })
    );
  };

  return (
    <Dialog onClose={onClose} open={open} maxWidth="sm" fullWidth>
      <DialogTitle onClose={onClose}>Invite Subscriber</DialogTitle>

      <DialogContent dividers>
        {!user?.merchant?.stripeEnabled ? (
          <div className="text-grey my-2" onClick={handleNavigateToSettings}>
            You haven&apos;t connected with Stripe yet, in order to invite
            subscribers please setup payments on Stripe from{" "}
            <span className="underline cursor-pointer">here</span>.
          </div>
        ) : (
          <div>
            <div className="my-4">
              <TextField
                fullWidth
                label="PHONE"
                variant="outlined"
                name="phone"
                type="number"
                value={state.phone}
                onWheel={(event: any) => event.target.blur()}
                onInput={(event) => {
                  if (
                    // @ts-ignore
                    event?.target?.value
                  ) {
                    // @ts-ignore
                    event.target.value = Math.max(
                      0,
                      // @ts-ignore
                      parseInt(event.target.value, 10)
                    )
                      .toString()
                      .slice(0, 10);
                  }
                }}
                onChange={handleChange}
                required
                error={state?.errors?.phone}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">+1</InputAdornment>
                  ),
                }}
                autoComplete="off"
                disabled={isLoading}
              />

              {state?.errors?.phone && (
                <ErrorMessage message="Phone is required" />
              )}
            </div>

            <div className="my-8">
              <TextField
                fullWidth
                label="INVITATION MESSAGE"
                variant="outlined"
                name="message"
                value={state.message}
                onChange={handleChange}
                required
                error={state?.errors?.message}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
                multiline
                minRows={4}
                disabled={isLoading}
              />

              {state?.errors?.message && (
                <ErrorMessage message="Message is required" />
              )}
            </div>
          </div>
        )}
      </DialogContent>

      <DialogActions>
        {!user?.merchant?.stripeEnabled ? (
          <div className="my-2">
            <Button
              label="Okay"
              color="info"
              onClick={onClose}
              style={{
                borderRadius: 100,
                fontSize: 14,
                color: "#FFFFFF",
              }}
            />
          </div>
        ) : (
          <div className="my-2">
            <Button
              label="Send Invitation"
              color="info"
              onClick={handleSendInvitation}
              style={{
                borderRadius: 100,
                fontSize: 14,
                color: "#FFFFFF",
                height: 40,
                width: 150,
              }}
              loaderButton
              loadingPosition="center"
              loading={isLoading}
              disabled={isLoading}
            />
          </div>
        )}
      </DialogActions>
    </Dialog>
  );
}

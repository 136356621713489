import {
  MERCHANT_COMMUNITY_CART_SET_IS_LOADING,
  MERCHANT_COMMUNITY_CART_SET_MASTER_CART,
  MERCHANT_COMMUNITY_CART_SET_MY_CART,
  MERCHANT_COMMUNITY_CART_FETCH_MASTER_CART_SAGA,
  MERCHANT_COMMUNITY_CART_FETCH_MY_CART_SAGA,
  MERCHANT_COMMUNITY_CART_ADD_TO_CART_SAGA,
} from "./types";

export const merchantCommunityCartSetIsLoading = (payload: any) => ({
  type: MERCHANT_COMMUNITY_CART_SET_IS_LOADING,
  payload,
});

export const merchantCommunityCartSetMasterCart = (payload: any) => ({
  type: MERCHANT_COMMUNITY_CART_SET_MASTER_CART,
  payload,
});

export const merchantCommunityCartSetMyCart = (payload: any) => ({
  type: MERCHANT_COMMUNITY_CART_SET_MY_CART,
  payload,
});

// async

export const merchantCommunityCartFetchMasterCartSaga = () => ({
  type: MERCHANT_COMMUNITY_CART_FETCH_MASTER_CART_SAGA,
});

export const merchantCommunityCartFetchMyCartSaga = () => ({
  type: MERCHANT_COMMUNITY_CART_FETCH_MY_CART_SAGA,
});

export const merchantCommunityCartAddToCartSaga = (
  payload: any,
  navigate: any
) => ({
  type: MERCHANT_COMMUNITY_CART_ADD_TO_CART_SAGA,
  payload,
  navigate,
});

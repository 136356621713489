import { useDispatch } from "react-redux";
import { useNavigate, Link } from "react-router-dom";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import Menu from "@mui/material/Menu";
import Collapse from "@mui/material/Collapse";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import Images from "assets/images";
import { useMergeState } from "utils/custom-hooks";
import { appLogoutSaga } from "app/state/actions";
import { NAVS } from "components/MerchantNavBar";

export default function MerchantMobileNavBar() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [state, setState] = useMergeState({
    accountMenuAnchor: null,
    selectedNav: {},
  });

  const handleOpenNavBarMenu = (event: any) => {
    setState({ navbarMenuAnchorEl: event.currentTarget });
  };

  const handleCloseNavBarMenu = () => {
    setState({ navbarMenuAnchorEl: null, selectedNav: {} });
  };

  const handleSelectNavSection = (nav: any) => {
    if (nav?.id === state?.selectedNav?.id) {
      setState({ selectedNav: {} });
    } else {
      setState({ selectedNav: nav });
    }

    if (!nav?.childNavs?.length) {
      handleCloseNavBarMenu();
      navigate(nav?.path);
    }
  };

  const handleSelectChildNav = (nav: any, childNav: any) => {
    handleCloseNavBarMenu();
    navigate(`${nav?.path}${childNav?.path}`);
  };

  const handleLogout = () => {
    handleCloseNavBarMenu();
    dispatch(appLogoutSaga());
  };

  return (
    <div className="p-4 w-full h-16 flex justify-between items-center bg-red-light">
      <Link to="/">
        <img src={Images.CherryLogo} alt="Cherry" className="h-6" />
      </Link>

      <IconButton onClick={handleOpenNavBarMenu}>
        <MenuIcon />
      </IconButton>

      <Menu
        anchorEl={state.navbarMenuAnchorEl}
        open={Boolean(state.navbarMenuAnchorEl)}
        onClose={handleCloseNavBarMenu}
      >
        <List
          sx={{ width: "100%", maxWidth: 500, bgcolor: "background.paper" }}
          component="nav"
        >
          {NAVS.map((nav) => (
            <div key={nav?.id}>
              <ListItemButton onClick={() => handleSelectNavSection(nav)}>
                <ListItemText primary={nav?.title} />

                {!!nav?.childNavs?.length && (
                  <div>
                    {state?.selectedNav?.id === nav?.id ? (
                      <ExpandLess
                        sx={{
                          ml: 2,
                        }}
                      />
                    ) : (
                      <ExpandMore
                        sx={{
                          ml: 2,
                        }}
                      />
                    )}
                  </div>
                )}
              </ListItemButton>

              <Collapse
                in={state?.selectedNav?.id === nav?.id}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {nav?.childNavs?.map((childNav: any) => (
                    <ListItemButton
                      key={childNav?.id}
                      sx={{ pl: 4 }}
                      onClick={() => handleSelectChildNav(nav, childNav)}
                    >
                      <ListItemText primary={childNav?.title} />
                    </ListItemButton>
                  ))}
                </List>
              </Collapse>
            </div>
          ))}

          <ListItemButton onClick={handleLogout}>
            <ListItemText primary="Logout" />
          </ListItemButton>
        </List>
      </Menu>
    </div>
  );
}

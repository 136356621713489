import {
  CONSUMER_PLANS_SET_IS_LOADING,
  CONSUMER_PLANS_SET_PLANS,
  CONSUMER_PLANS_FETCH_PLANS_SAGA,
} from "./types";

export const consumerPlansSetIsLoading = (payload: any) => ({
  type: CONSUMER_PLANS_SET_IS_LOADING,
  payload,
});

export const consumerPlansSetPlans = (payload: any) => ({
  type: CONSUMER_PLANS_SET_PLANS,
  payload,
});

// async

export const consumerPlansFetchPlansSaga = () => ({
  type: CONSUMER_PLANS_FETCH_PLANS_SAGA,
});

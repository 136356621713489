export const DiscountType = {
  PERCENTAGE: "PERCENTAGE",
  AMOUNT: "AMOUNT",
};

export const DiscountTypeList = [
  {
    label: "%",
    value: DiscountType.PERCENTAGE,
  },
  {
    label: "$",
    value: DiscountType.AMOUNT,
  },
];

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "components/Button";

export default function MerchantCommunicationsContainer() {
  const handleCreateNewPost = () => {};

  return (
    <div>
      <div className="text-2xl font-semibold text-grey">Communications</div>
      <div className="text-grey">
        Sending out updates is a great way to stay on top of your
        customer&apos;s minds.
      </div>

      <div className="flex justify-between">
        <div className="w-8/12">
          <div className="mt-4 profile-box p-4">
            <div className="text-lg text-grey font-semibold">
              Recent Marketing Posts
            </div>

            <div className="grid grid-cols-2 gap-2">
              <div className="flex justify-between marketing-post-box p-5 mt-5">
                <div className="w-1/2">Image</div>

                <div className="w-full">
                  <div className="text-grey text-sm">cafe & Juice Bar</div>
                  <div className="text-grey text-xl font-semibold mt-2">
                    Farmers Daughter
                  </div>
                  <div className="text-grey text-sm mt-2">
                    The Farmers Daughter is a plant-based restaurant, cafe,
                    juice bar and vegetable slaughterhouse. Beloved by the xandx
                    alike
                  </div>
                </div>
              </div>

              <div className="flex justify-between marketing-post-box p-5 mt-5">
                <div className="w-1/2">Image</div>

                <div className="w-full">
                  <div className="text-grey text-sm">cafe & Juice Bar</div>
                  <div className="text-grey text-xl font-semibold mt-2">
                    Farmers Daughter
                  </div>
                  <div className="text-grey text-sm mt-2">
                    The Farmers Daughter is a plant-based restaurant, cafe,
                    juice bar and vegetable slaughterhouse. Beloved by the xandx
                    alike
                  </div>
                </div>
              </div>
            </div>

            <div className="mt-8 flex justify-end">
              <Button
                label="Create New Post"
                color="secondary"
                onClick={handleCreateNewPost}
                style={{
                  borderRadius: 10,
                  fontSize: 16,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>
          </div>

          <div className="mt-4 profile-box p-4">
            <div className="flex items-center">
              <div className="text-lg text-grey font-semibold mr-4">
                Messages
              </div>
              <div className="bg-green text-sm text-white flex justify-center items-center rounded-full w-10">
                3
              </div>
            </div>

            <TableContainer
              className="table mt-4"
              style={{
                background: "#FFF9F7",
              }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <span className="text-grey">Date</span>
                    </TableCell>
                    <TableCell align="left">
                      <span className="text-grey">From</span>
                    </TableCell>
                    <TableCell align="left">
                      <span className="text-grey">Subject</span>
                    </TableCell>
                    <TableCell align="left" />
                  </TableRow>
                </TableHead>
                <TableBody>
                  <TableRow>
                    <TableCell component="th" scope="row">
                      <span className="text-grey text-xs">04/18/22</span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      <span className="text-grey text-xs">John Smith</span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      <span className="text-grey text-xs">Order issue</span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="left">
                      <span className="text-grey text-xs underline cursor-pointer">
                        open
                      </span>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>

            <div className="mt-8 flex justify-end">
              <Button
                label="View All Messages"
                color="info"
                onClick={handleCreateNewPost}
                style={{
                  borderRadius: 10,
                  fontSize: 16,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export const MERCHANT_ORDERS_SET_IS_LOADING = "MERCHANT_ORDERS_SET_IS_LOADING";
export const MERCHANT_ORDERS_SET_ORDERS_FOR_TODAY =
  "MERCHANT_ORDERS_SET_ORDERS_FOR_TODAY";
export const MERCHANT_ORDERS_SET_FUTURE_ORDERS =
  "MERCHANT_ORDERS_SET_FUTURE_ORDERS";
export const MERCHANT_ORDERS_SET_PAST_ORDERS =
  "MERCHANT_ORDERS_SET_PAST_ORDERS";

// async

export const MERCHANT_ORDERS_FETCH_SAGA = "MERCHANT_ORDERS_FETCH_SAGA";
export const MERCHANT_ORDERS_UPDATE_SAGA = "MERCHANT_ORDERS_UPDATE_SAGA";

import { takeLatest, put, all, call } from "redux-saga/effects";
import { getMerchantCustomers } from "api";
import { MESSAGE_SEVERITY } from "utils/constants";
import { MERCHANT_CUSTOMERS_FETCH_SAGA } from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  merchantCustomersSetIsLoading,
  merchantCustomersSetCustomers,
} from "./actions";

function* merchantCustomersFetchHandler(): any {
  try {
    yield put(merchantCustomersSetIsLoading(true));

    const response = yield call(getMerchantCustomers);

    if (response?.success) {
      yield put(merchantCustomersSetCustomers(response?.data));
    }

    yield put(merchantCustomersSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantCustomersSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantCustomers() {
  yield takeLatest(
    MERCHANT_CUSTOMERS_FETCH_SAGA,
    merchantCustomersFetchHandler
  );
}

import { useSelector } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { UserTypes } from "enums/userTypes";

type Props = {
  allowedRoles?: Array<string>;
};

export default function ProtectedRoute(props: Props): any {
  const { allowedRoles } = props;

  const { platformType } = useSelector((store: any) => store?.app);

  let isLoggedIn = false;
  let role = null;

  const merchantAuth = useSelector((store: any) => store?.merchantAuth);

  const customerAuth = useSelector((store: any) => store?.customerAuth);

  if (platformType === UserTypes.MERCHANT) {
    isLoggedIn = merchantAuth.isLoggedIn;
    role = merchantAuth.user.role;
  }

  if (platformType === UserTypes.CONSUMER) {
    isLoggedIn = customerAuth.isLoggedIn;
    role = customerAuth.user.role;
  }

  return isLoggedIn && allowedRoles?.includes(role) ? (
    <Outlet />
  ) : (
    <Navigate to="/login" replace />
  );
}

export const CheckoutStepLabel = {
  ACCOUNT_DETAILS: "ACCOUNT_DETAILS",
  DELIVERY_DETAILS: "DELIVERY_DETAILS",
  PAYMENT_DETAILS: "PAYMENT_DETAILS",
  CONFIRMATION: "CONFIRMATION",
};

export const CheckoutSteps = [
  {
    id: "1",
    label: CheckoutStepLabel.ACCOUNT_DETAILS,
  },
  // {
  //   id: "2",
  //   label: CheckoutStepLabel.DELIVERY_DETAILS,
  // },
  {
    id: "3",
    label: CheckoutStepLabel.PAYMENT_DETAILS,
  },
  {
    id: "4",
    label: CheckoutStepLabel.CONFIRMATION,
  },
];

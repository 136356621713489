import { Provider } from "react-redux";
import { ThemeProvider } from "@mui/material/styles";

// store
import store from "store";

// MUI theme
import theme from "mui";

// containers
import RoutesContainer from "containers/routes";

export default function App() {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <RoutesContainer />
      </ThemeProvider>
    </Provider>
  );
}

import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import Checkout from "./Checkout";

const stripePromise = loadStripe(
  process.env?.REACT_APP_STRIPE_PUBLIC_KEY || ""
);

export default function ApplePay() {
  return (
    <Elements stripe={stripePromise}>
      <Checkout />
    </Elements>
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { BrowserRouter } from "react-router-dom";

// enums
import { UserTypes } from "enums/userTypes";

// merchant routes
import MerchantRoutesContainer from "./merchant";

// customer routes
import ConsumerRoutesContainer from "./consumer";

// components
import Alert from "components/Alert";
import CustomerCartDrawer from "components/Customer/CartDrawer";

import { appInitSaga } from "../../app/state/actions"; // app actions

const RoutesContainer = () => {
  const dispatch = useDispatch();

  const { platformType } = useSelector((store: any) => store?.app);

  useEffect(() => {
    dispatch(appInitSaga());
  }, []);

  return (
    <BrowserRouter>
      <Alert />
      <CustomerCartDrawer />

      {platformType === UserTypes.MERCHANT && <MerchantRoutesContainer />}

      {platformType === UserTypes.CONSUMER && (
        <div>
          <ConsumerRoutesContainer />

          <div className="flex justify-center">
            <div className="text-green-3 h-10">Powered by Cherry</div>
          </div>
        </div>
      )}
    </BrowserRouter>
  );
};

export default RoutesContainer;

import Button from "components/Button";

export default function MerchantMarketingPostsContainer() {
  const handleCreateNewPost = () => {};

  return (
    <div>
      <div className="text-3xl font-semibold text-grey">Marketing Posts</div>

      <div className="flex justify-between">
        <div className="profile-box w-8/12 p-5 mt-4">
          <div className="flex justify-between">
            <div className="text-2xl font-semibold text-grey">
              Recent Marketing Posts
            </div>

            <div className="">
              <Button
                label="+ Create New Post"
                color="secondary"
                onClick={handleCreateNewPost}
                style={{
                  borderRadius: 10,
                  fontSize: 16,
                  color: "#FFFFFF",
                  height: 40,
                }}
                fullWidth
              />
            </div>
          </div>

          <div>
            <div className="flex justify-between marketing-post-box p-5 mt-5">
              <div className="w-2/5">Image</div>

              <div className="w-2/5">
                <div className="text-grey text-sm">cafe & Juice Bar</div>
                <div className="text-grey text-xl font-semibold mt-2">
                  Farmers Daughter
                </div>
                <div className="text-grey text-sm mt-2">
                  The Farmers Daughter is a plant-based restaurant, cafe, juice
                  bar and vegetable slaughterhouse. Beloved by the xandx alike
                </div>
              </div>

              <div className="w-2/5">Stats</div>
            </div>
          </div>
        </div>

        <div className="w-1/3">
          <div className="bg-white border-[1px] rounded-lg border-solid border-[#f1f1f1] shadow-md p-5 mt-4 ml-2">
            <div className="text-2xl font-semibold text-grey mb-3">
              Things You Can Do
            </div>

            <div className="text-green text-lg">Create A Post</div>

            <div className="text-green text-lg">Offer A Discount</div>
          </div>
        </div>
      </div>
    </div>
  );
}

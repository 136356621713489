import { RESET_APP_STATE } from "app/state/types";
import {
  CUSTOMER_SETTINGS_SET_IS_LOADING,
  CUSTOMER_SETTINGS_SET_SUBSCRIPTIONS,
  CUSTOMER_SETTINGS_SET_PAYMENT_METHODS,
  CUSTOMER_SETTINGS_ADD_PAYMENT_METHOD_SET_CONFIG,
} from "./types";

type CustomerSettingsStateType = {
  isLoading: boolean;
  subscriptions: Array<any>;
  paymentMethods: Array<any>;
  addPaymentMethodConfig: {
    isAddingNewPaymentMethod: boolean;
    clientSecret: string;
  };
};

const initialState: CustomerSettingsStateType = {
  isLoading: false,
  subscriptions: [],
  paymentMethods: [],
  addPaymentMethodConfig: {
    isAddingNewPaymentMethod: false,
    clientSecret: "",
  },
};

const customerSettingsReducer = (
  state: CustomerSettingsStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CUSTOMER_SETTINGS_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case CUSTOMER_SETTINGS_SET_SUBSCRIPTIONS:
      return { ...state, subscriptions: action.payload };

    case CUSTOMER_SETTINGS_SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.payload };

    case CUSTOMER_SETTINGS_ADD_PAYMENT_METHOD_SET_CONFIG:
      return { ...state, addPaymentMethodConfig: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default customerSettingsReducer;

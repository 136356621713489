// Logos
import CherryMerchantLogo from "./cherry_merchant.svg";
// import CherryLogo from "./cherry.svg";
import CherryCLogo from "./cherry_c_logo.svg";
import CherryNewLogo from "./cherry_new_logo.svg";
import CherryLogoNew from "./cherry_logo_new.svg";
import Logo2 from "./logo_2.svg";
import Logo7 from "./logo_7.svg";
import Logo8 from "./logo_8.svg";
import Logo9 from "./logo_9.svg";
import Logo10 from "./logo_10.svg";
import Logo11 from "./logo_11.svg";
import Logo12 from "./logo_12.svg";
import LogoPaid from "./logo_paid.svg";
import LogoPaid2 from "./logo_paid_2.svg";
import LogoC from "./logo_c.svg";
import LogoC2 from "./logo_c_2.svg";
import CherryLogo from "./CherryLogo.svg";
import Cherries from "./Cherries.svg";

import VisaLogo from "./visa_logo.png";
import MasterCardLogo from "./mastercard_logo.png";

import Banner1 from "./banner_1.webp";
import Banner2 from "./banner_2.webp";
import Banner3 from "./banner_3.jpg";

// Favicons
import CherryFavicon from "./cherry_favicon.svg";

// icons
import Communication from "./communication.svg";
import Home from "./home.svg";
import ManagePlans from "./manage_plans.svg";
import Reports from "./reports.svg";
import Profile from "./profile.svg";
import Settings from "./settings.svg";
import CaretDown from "./caret_down.svg";
import CaretUp from "./caret_up.svg";
import Help from "./help.svg";
import SecureLock from "./secure_lock.svg";
import CaretRight from "./caret_right.svg";
import CaretRightGreen from "./caret_right_green.svg";
import Search from "./search.svg";
import Alerts from "./alerts.svg";
import Orders from "./orders.svg";
import Products from "./products.svg";
import CommunityCart from "./community_cart.svg";
import Preview from "./preview.svg";

// images
import App1 from "./app_1.svg";
import App2 from "./app_2.png";
import NoImagePlaceholder from "./no_image_placeholder.svg";

// examples
import Example1 from "./example1.png";

export default {
  // Logos
  CherryMerchantLogo,
  CherryLogo,
  CherryCLogo,
  CherryNewLogo,
  CherryLogoNew,
  VisaLogo,
  MasterCardLogo,
  Logo2,
  Logo7,
  Logo8,
  Logo9,
  Logo10,
  Logo11,
  Logo12,
  LogoPaid,
  LogoPaid2,
  LogoC,
  LogoC2,
  Cherries,

  Banner1,
  Banner2,
  Banner3,

  // Favicons
  CherryFavicon,

  Communication,
  Home,
  ManagePlans,
  Reports,
  Profile,
  Settings,
  CaretDown,
  CaretUp,
  Help,
  SecureLock,
  CaretRight,
  CaretRightGreen,
  Search,
  Alerts,
  Orders,
  Products,
  CommunityCart,
  Preview,
  Example1,

  App1,
  App2,
  NoImagePlaceholder,
};

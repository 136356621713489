export const MERCHANT_DASHBOARD_SET_IS_LOADING =
  "MERCHANT_DASHBOARD_SET_IS_LOADING";
export const MERCHANT_DASHBOARD_SET_PLANS = "MERCHANT_DASHBOARD_SET_PLANS";
export const MERCHANT_DASHBOARD_SET_STATS = "MERCHANT_DASHBOARD_SET_STATS";

// async

export const MERCHANT_DASHBOARD_INIT_SAGA = "MERCHANT_DASHBOARD_INIT_SAGA";
export const MERCHANT_DASHBOARD_BUSINESS_PAGE_VIEWED_SAGA =
  "MERCHANT_DASHBOARD_BUSINESS_PAGE_VIEWED_SAGA";

export default function MerchantCustomerReportsContainer() {
  return (
    <div>
      <div className="flex justify-between items-center w-10/12">
        <div className="text-2xl font-semibold text-grey">
          Income By Customer
        </div>
      </div>
    </div>
  );
}

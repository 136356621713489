import {
  CUSTOMER_AUTH_SET_CURRENT_USER,
  CUSTOMER_AUTH_SET_IS_LOADING,
  CUSTOMER_AUTH_SET_IS_LOGGED_IN,
  CUSTOMER_AUTH_SET_MESSAGE,
  CUSTOMER_AUTH_SET_MERCHANT_DETAILS,
  CUSTOMER_AUTH_SIGNUP_SAGA,
  CUSTOMER_AUTH_SIGNIN_SAGA,
  CUSTOMER_AUTH_GENERATE_OTP_SAGA,
  CUSTOMER_AUTH_VERIFY_OTP_SAGA,
  CUSTOMER_AUTH_FETCH_MERCHANT_DETAILS_SAGA,
} from "./types";

export const customerAuthSetMessage = (payload: any) => ({
  type: CUSTOMER_AUTH_SET_MESSAGE,
  payload,
});

export const customerAuthSetIsLoggedIn = (payload: any) => ({
  type: CUSTOMER_AUTH_SET_IS_LOGGED_IN,
  payload,
});

export const customerAuthSetIsLoading = (payload: any) => ({
  type: CUSTOMER_AUTH_SET_IS_LOADING,
  payload,
});

export const customerAuthSetCurrentUser = (payload: any) => ({
  type: CUSTOMER_AUTH_SET_CURRENT_USER,
  payload,
});

export const customerAuthSetMerchantDetails = (payload: any) => ({
  type: CUSTOMER_AUTH_SET_MERCHANT_DETAILS,
  payload,
});

// async

export const customerAuthSignupSaga = (payload: any, navigate: any) => ({
  type: CUSTOMER_AUTH_SIGNUP_SAGA,
  payload,
  navigate,
});

export const customerAuthSigninSaga = (payload: any, navigate: any) => ({
  type: CUSTOMER_AUTH_SIGNIN_SAGA,
  payload,
  navigate,
});

export const customerAuthGenerateOTPSaga = (payload: any) => ({
  type: CUSTOMER_AUTH_GENERATE_OTP_SAGA,
  payload,
});

export const customerAuthVerifyOTPSaga = (payload: any, navigate: any) => ({
  type: CUSTOMER_AUTH_VERIFY_OTP_SAGA,
  payload,
  navigate,
});

export const customerAuthFetchMerchantDetailsSaga = (payload: any) => ({
  type: CUSTOMER_AUTH_FETCH_MERCHANT_DETAILS_SAGA,
  payload,
});

import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  onboardMerchant,
  signupMerchantMember,
  signin,
  setupMerchant,
  sendPasswordResetInstructions,
  checkResetPasswordLinkExpiry,
  resetPassword,
} from "api";
import {
  CHERRY_TOKEN,
  MERCHANT_PLATFORM_SUBDOMAIN,
  MESSAGE_SEVERITY,
} from "utils/constants";
import {
  MERCHANT_AUTH_ONBOARD_SAGA,
  MERCHANT_AUTH_SIGNUP_SAGA,
  MERCHANT_AUTH_SIGNIN_SAGA,
  MERCHANT_AUTH_SETUP_SAGA,
  MERCHANT_AUTH_FORGOT_PASSWORD_SEND_INSTRUCTIONS_SAGA,
  MERCHANT_AUTH_CHECK_RESET_PASSWORD_LINK_EXPIRY_SAGA,
  MERCHANT_AUTH_RESET_PASSWORD_SAGA,
} from "./types";
import {
  merchantAuthSetIsLoading,
  merchantAuthSetIsLoggedIn,
  merchantAuthSetCurrentUser,
} from "./actions";
import { appSetError } from "app/state/actions"; // app actions

function* merchantAuthOnboardHandler(action: any): any {
  try {
    yield put(merchantAuthSetIsLoading(true));

    const response = yield call(onboardMerchant, action.payload);

    if (response?.success) {
      window.location.href = `https://${MERCHANT_PLATFORM_SUBDOMAIN}.${process.env.REACT_APP_HOST_URL}/setup/${response?.data?.id}`;
    }

    yield put(merchantAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantAuthSignupHandler(action: any): any {
  try {
    yield put(merchantAuthSetIsLoading(true));

    const response = yield call(signupMerchantMember, action.payload);

    if (response?.success) {
      const {
        data: { user, token },
      } = response;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(merchantAuthSetIsLoggedIn(true)),
        yield put(merchantAuthSetCurrentUser({ ...user })),
      ]);

      action.navigate("/dashboard/getting-started");
    }

    yield put(merchantAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantAuthSigninHandler(action: any): any {
  try {
    yield put(merchantAuthSetIsLoading(true));

    const response = yield call(signin, action.payload);

    if (response?.success) {
      const {
        data: { user, token },
      } = response;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(merchantAuthSetIsLoggedIn(true)),
        yield put(merchantAuthSetCurrentUser({ ...user })),
      ]);

      action.navigate("/dashboard/overview");
    }

    yield put(merchantAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantAuthSetupHandler(action: any): any {
  try {
    yield put(merchantAuthSetIsLoading(true));

    const response = yield call(setupMerchant, action.payload);

    if (response?.success) {
      const {
        data: { user, token },
      } = response;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(merchantAuthSetIsLoggedIn(true)),
        yield put(merchantAuthSetCurrentUser({ ...user })),
      ]);

      action.navigate("/dashboard/getting-started");
    }

    yield put(merchantAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantAuthForgotPasswordSendInstructionsHandler(action: any): any {
  try {
    yield put(merchantAuthSetIsLoading(true));
    yield call(sendPasswordResetInstructions, action.payload);
    yield put(merchantAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantAuthCheckResetPasswordLinkExpiryHandler(action: any): any {
  try {
    yield put(merchantAuthSetIsLoading(true));

    const response = yield call(checkResetPasswordLinkExpiry, action.payload);

    if (response?.success) {
      action.navigate(`/reset-password?email=${response?.data?.email}`);
    }

    yield put(merchantAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

function* merchantAuthResetPasswordHandler(action: any): any {
  try {
    yield put(merchantAuthSetIsLoading(true));

    const response = yield call(resetPassword, action.payload);

    if (response?.success) {
      const {
        data: { user, token },
      } = response;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(merchantAuthSetIsLoggedIn(true)),
        yield put(merchantAuthSetCurrentUser({ ...user })),
      ]);

      action.navigate("/dashboard/overview");
    }

    yield put(merchantAuthSetIsLoading(false));
  } catch (error: any) {
    yield all([
      yield put(merchantAuthSetIsLoading(false)),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchMerchantAuth() {
  yield takeLatest(MERCHANT_AUTH_ONBOARD_SAGA, merchantAuthOnboardHandler);
  yield takeLatest(MERCHANT_AUTH_SIGNUP_SAGA, merchantAuthSignupHandler);
  yield takeLatest(MERCHANT_AUTH_SIGNIN_SAGA, merchantAuthSigninHandler);
  yield takeLatest(MERCHANT_AUTH_SETUP_SAGA, merchantAuthSetupHandler);
  yield takeLatest(
    MERCHANT_AUTH_FORGOT_PASSWORD_SEND_INSTRUCTIONS_SAGA,
    merchantAuthForgotPasswordSendInstructionsHandler
  );
  yield takeLatest(
    MERCHANT_AUTH_CHECK_RESET_PASSWORD_LINK_EXPIRY_SAGA,
    merchantAuthCheckResetPasswordLinkExpiryHandler
  );
  yield takeLatest(
    MERCHANT_AUTH_RESET_PASSWORD_SAGA,
    merchantAuthResetPasswordHandler
  );
}

export const RESET_APP_STATE = "RESET_APP_STATE";
export const MERCHANT_CONNECT_SET_IS_LOADING =
  "MERCHANT_CONNECT_SET_IS_LOADING";

// async

export const MERCHANT_CONNECT_GET_STRIPE_ACCOUNT_LINK_SAGA =
  "MERCHANT_CONNECT_GET_STRIPE_ACCOUNT_LINK_SAGA";
export const MERCHANT_CONNECT_SQUARE_ACCOUNT_SAGA =
  "MERCHANT_CONNECT_SQUARE_ACCOUNT_SAGA";
export const MERCHANT_CONNECT_OAUTH_STRIPE_ACCOUNT_SAGA =
  "MERCHANT_CONNECT_OAUTH_STRIPE_ACCOUNT_SAGA";

export const CUSTOMER_TEXT_TO_PAY_SET_IS_LOADING =
  "CUSTOMER_TEXT_TO_PAY_SET_IS_LOADING";
export const CUSTOMER_TEXT_TO_PAY_SET_CONFIG =
  "CUSTOMER_TEXT_TO_PAY_SET_CONFIG";
export const CUSTOMER_TEXT_TO_PAY_SET_DETAILS =
  "CUSTOMER_TEXT_TO_PAY_SET_DETAILS";

// async

export const CUSTOMER_TEXT_TO_PAY_CREATE_ACCOUNT_SAGA =
  "CUSTOMER_TEXT_TO_PAY_CREATE_ACCOUNT_SAGA";

export const CUSTOMER_TEXT_TO_PAY_GET_PAYMENT_METHODS_SAGA =
  "CUSTOMER_TEXT_TO_PAY_GET_PAYMENT_METHODS_SAGA";

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Chip from "@mui/material/Chip";
import CheckBoxIcon from "@mui/icons-material/CheckBox";
import Button from "components/Button";
import Spinner from "components/Spinner";
import { truncateString, renderVariationString } from "utils/string";
import Images from "assets/images";
import { isMasterAccount } from "utils/common";
import { merchantProductsFetchSaga } from "./state/actions";

export default function MerchantProductsContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { isLoading, products } = useSelector(
    (store: any) => store?.merchantProducts
  );

  const handleOpenProductDetails = (product: any) => {
    let path = `/products/create?id=${product?._id}`;

    if (product?.forCC) {
      path = `/products/create?id=${product?._id}&cc=true`;
    }

    navigate(path);
  };

  const handleCreateProduct = (forCC: boolean) => {
    let path = "/products/create";

    if (forCC) {
      path = "/products/create?cc=true";
    }

    navigate(path);
  };

  useEffect(() => {
    dispatch(merchantProductsFetchSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="md:flex md:justify-between md:items-center">
            <div className="text-4xl font-semibold text-grey">
              Your Products
            </div>

            <div className="md:flex mt-4 md:mt-0">
              {isMasterAccount() && (
                <div className="mr-4 mb-4 md:mb-0">
                  <Button
                    label="Create New Product for CC"
                    color="secondary"
                    onClick={() => handleCreateProduct(true)}
                    style={{
                      borderRadius: 4,
                      fontSize: 14,
                      color: "#FFFFFF",
                      fontWeight: 500,
                      height: 40,
                    }}
                  />
                </div>
              )}

              <Button
                label="Create New Product"
                color="secondary"
                onClick={() => handleCreateProduct(false)}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  fontWeight: 500,
                  height: 40,
                }}
              />
            </div>
          </div>

          {!products.length && (
            <div className="mt-4">
              <div className="text-xl">
                Create your first product to get started
              </div>

              <div className="w-3/4 mt-4">
                <Button
                  label="Create A Product To Get Started"
                  color="secondary"
                  onClick={() => handleCreateProduct(false)}
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 50,
                  }}
                  fullWidth
                />
              </div>
            </div>
          )}

          <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-8 mt-6">
            {products.map((product: any) => (
              <div
                key={product?._id}
                className="bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md cursor-pointer mb-4 md:mb-0"
                onClick={() => handleOpenProductDetails(product)}
              >
                <div className="flex justify-between">
                  <div className="my-2">
                    <div className="flex items-center">
                      <div className="text-grey font-semibold text-lg">
                        {truncateString(product?.title, 30)}
                      </div>

                      {isMasterAccount() && product?.forCC && (
                        <Chip
                          label="CC"
                          variant="outlined"
                          sx={{
                            ml: 2,
                            fontSize: 12,
                            border: "1px solid",
                          }}
                        />
                      )}
                    </div>

                    <div className="text-grey text-sm mt-2">
                      {truncateString(product?.description, 50)}
                    </div>

                    <div className="hidden md:block text-grey text-sm italic mt-2">
                      {truncateString(
                        renderVariationString(product?.variations),
                        40
                      )}
                    </div>

                    {product?.showOnMenu && (
                      <div className="mt-2">
                        <CheckBoxIcon color="secondary" />
                      </div>
                    )}

                    {product?.deleted && (
                      <div className="mt-2">
                        <Chip
                          label="Deleted"
                          variant="outlined"
                          sx={{
                            fontSize: 12,
                            border: "1px solid",
                          }}
                        />
                      </div>
                    )}
                  </div>

                  <img
                    src={product?.images[0] || Images.NoImagePlaceholder}
                    className="w-36 h-36 rounded-r-md object-cover"
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}

import { RESET_APP_STATE } from "app/state/types";
import {
  CUSTOMER_CART_SET_CONFIG,
  CUSTOMER_CART_SET_CART,
  CUSTOMER_CART_ADD_TO_CART,
  CUSTOMER_CART_REMOVE_FROM_CART,
  CUSTOMER_CART_SET_TAXES,
} from "./types";

type CustomerCartStateType = {
  config: {
    open: boolean;
    isLoading: boolean;
    isCheckoutLoading: boolean;
    cartId: string;
    isCorporatePlan: boolean;
    merchant: any;
  };
  items: Array<any>;
  taxes: Array<any>;
};

const initialState: CustomerCartStateType = {
  config: {
    open: false,
    isLoading: false,
    isCheckoutLoading: false,
    cartId: "",
    isCorporatePlan: false,
    merchant: {},
  },
  items: [],
  taxes: [],
};

const customerCartReducer = (
  state: CustomerCartStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case CUSTOMER_CART_SET_CONFIG:
      return {
        ...state,
        config: {
          ...state?.config,
          ...action.payload,
        },
      };

    case CUSTOMER_CART_SET_CART:
      return { ...state, items: action.payload };

    case CUSTOMER_CART_ADD_TO_CART:
      let newItems: Array<any> = [];

      if (state?.items) {
        newItems = [...state?.items];
      }

      newItems = [...newItems, action?.payload];

      return { ...state, items: newItems };

    case CUSTOMER_CART_REMOVE_FROM_CART:
      const index = state?.items.findIndex(
        (elem) => elem.product === action.payload
      );

      const updatedCart = [...state?.items];

      updatedCart.splice(index, 1);

      return { ...state, items: [...updatedCart] };

    case CUSTOMER_CART_SET_TAXES:
      return { ...state, taxes: action?.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default customerCartReducer;

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_ORDERS_SET_IS_LOADING,
  MERCHANT_ORDERS_SET_ORDERS_FOR_TODAY,
  MERCHANT_ORDERS_SET_FUTURE_ORDERS,
  MERCHANT_ORDERS_SET_PAST_ORDERS,
} from "./types";

type MerchantOrdersStateType = {
  isLoading: boolean;
  todayOrders: Array<any>;
  futureOrders: Array<any>;
  pastOrders: Array<any>;
};

const initialState: MerchantOrdersStateType = {
  isLoading: false,
  todayOrders: [],
  futureOrders: [],
  pastOrders: [],
};

const merchantOrdersReducer = (
  state: MerchantOrdersStateType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_ORDERS_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_ORDERS_SET_ORDERS_FOR_TODAY:
      return { ...state, todayOrders: action.payload };

    case MERCHANT_ORDERS_SET_FUTURE_ORDERS:
      return { ...state, futureOrders: action.payload };

    case MERCHANT_ORDERS_SET_PAST_ORDERS:
      return { ...state, pastOrders: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantOrdersReducer;

import { RESET_APP_STATE } from "app/state/types";
import {
  MERCHANT_PRODUCTS_SET_IS_LOADING,
  MERCHANT_PRODUCTS_SET_SETTINGS_CONFIG,
  MERCHANT_PRODUCTS_SET_PRODUCTS,
  MERCHANT_PRODUCTS_SET_SELECTED_PRODUCT,
  MERCHANT_PRODUCTS_MODIFIERS_SET_MODIFIERS,
  MERCHANT_PRODUCTS_MODIFIERS_SET_SELECTED_MODIFIER,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_RULES,
  MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_SELECTED_RULE,
} from "./types";

type MerchantProductsType = {
  isLoading: boolean;
  settingsConfig: {};
  products: Array<any>;
  product: any;
  modifiers: Array<any>;
  modifier: any;
  discountRules: Array<any>;
  discountRule: any;
};

const initialState: MerchantProductsType = {
  isLoading: false,
  settingsConfig: {},
  products: [],
  product: {},
  modifiers: [],
  modifier: {},
  discountRules: [],
  discountRule: {},
};

const merchantProductsReducer = (
  state: MerchantProductsType = initialState,
  action: any
) => {
  switch (action.type) {
    case MERCHANT_PRODUCTS_SET_IS_LOADING:
      return { ...state, isLoading: action.payload };

    case MERCHANT_PRODUCTS_SET_SETTINGS_CONFIG:
      return {
        ...state,
        settingsConfig: { ...state?.settingsConfig, ...action?.payload },
      };

    case MERCHANT_PRODUCTS_SET_PRODUCTS:
      return { ...state, products: action.payload };

    case MERCHANT_PRODUCTS_SET_SELECTED_PRODUCT:
      return { ...state, product: action.payload };

    case MERCHANT_PRODUCTS_MODIFIERS_SET_MODIFIERS:
      return { ...state, modifiers: action.payload };

    case MERCHANT_PRODUCTS_MODIFIERS_SET_SELECTED_MODIFIER:
      return { ...state, modifier: action.payload };

    case MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_RULES:
      return { ...state, discountRules: action.payload };

    case MERCHANT_PRODUCTS_DISCOUNT_RULES_SET_SELECTED_RULE:
      return { ...state, discountRule: action.payload };

    case RESET_APP_STATE:
      return { ...initialState };

    default:
      return { ...state };
  }
};

export default merchantProductsReducer;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import CustomerCart from "components/Customer/Cart";
import IOSInstallDialog from "components/Customer/IOSInstallDialog";
import MerchantProducts from "./products";
import MerchantSubscriptions from "./subscriptions";
import MerchantCommunityCart from "./community-cart";
import { MenuType } from "enums/Menu";
import { useMergeState } from "utils/custom-hooks";

const TABS = [
  {
    label: "Menu",
    value: MenuType.PRODUCTS,
  },
  {
    label: "Subscriptions",
    value: MenuType.SUBSCRIPTIONS,
  },
  {
    label: "Community Cart",
    value: MenuType.COMMUNITY_CART,
  },
];

const isIOSDevice = () =>
  [
    "iPad Simulator",
    "iPhone Simulator",
    "iPod Simulator",
    "iPad",
    "iPhone",
    "iPod",
  ].includes(navigator?.platform) ||
  // iPad on iOS 13 detection
  (navigator.userAgent.includes("Mac") && "ontouchend" in document);

export default function PublicBusinessPage() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { merchant } = useSelector((store: any) => store?.publicBusinessPage);

  const selectedTabType = searchParams.get("tab");

  const merchantId = searchParams.get("mId");

  const showIOSInstallPopup = searchParams.get("showIOSInstallPopup");

  const [state, setState] = useMergeState({
    tabs: [],
    selectedTab: {},
    shouldShowTabs: true,
    width: window.innerWidth,

    shouldShowIOSInstallPopup: false,
  });

  const handleTabChange = (event: any, newTabType: number) => {
    setState({
      selectedTab: TABS.find((elem: any) => elem.value === newTabType),
    });
  };

  const isMobile = state?.width <= 768;

  const handleWindowSizeChange = () => {
    setState({ width: window.innerWidth });
  };

  const handleCloseIOSInstallDialog = () => {
    setState({ shouldShowIOSInstallPopup: false });
  };

  useEffect(() => {
    let tab = "";

    let params = {};

    if (merchantId) {
      params = { mId: merchantId };
    }

    if (state?.selectedTab?.value !== selectedTabType) {
      if (selectedTabType && !state?.selectedTab?.value) {
        tab = selectedTabType;
      } else if (state?.selectedTab?.value) {
        tab = state?.selectedTab?.value;
      } else {
        tab = MenuType.PRODUCTS;
      }

      const selectedTab = TABS.find((elem) => elem.value === tab) || TABS[0];

      params = { ...params, tab };

      setSearchParams({ ...params });

      setState({ selectedTab });
    }
  }, [state?.selectedTab]);

  useEffect(() => {
    let tabs = [TABS[0]];

    if (!merchant?.isB2B) {
      tabs = [...tabs, TABS[1]];
    }

    const payload: any = {
      tabs,
    };

    if (merchant?.onlyShowPlans) {
      payload.shouldShowTabs = false;
      [payload.selectedTab] = [TABS[1]];
    }

    if (showIOSInstallPopup && isIOSDevice()) {
      searchParams.delete("showIOSInstallPopup");
      payload.shouldShowIOSInstallPopup = true;
    }

    setState(payload);
  }, [merchant]);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  return (
    <div className="flex justify-center">
      <div className="w-full p-4 lg:px-12 lg:py-4">
        <div className="my-4">
          <div className="relative">
            <img
              src={merchant?.businessBanner}
              className="rounded-md w-full h-[300px] object-cover"
            />

            <div>
              <img
                src={merchant?.businessLogo}
                className="rounded-full absolute right-0 -mt-10 mr-4 w-24 h-24"
              />
            </div>
          </div>

          <div className="text-4xl font-bold mt-12 mb-2">
            {merchant?.businessName}
          </div>

          <div className="text-grey-2 text-sm font-semibold my-1">
            {merchant?.addressLine1}, {merchant?.state} - {merchant?.zipcode}
          </div>
        </div>

        <hr />

        {state?.selectedTab?.value && state?.shouldShowTabs && (
          <div>
            <div className="md:w-3/5">
              <Tabs
                value={state?.selectedTab?.value}
                onChange={handleTabChange}
                // @ts-ignore
                variant={isMobile ? "scrollable" : "fullWidth"}
                indicatorColor="secondary"
                // sx={{
                //   "& .MuiTab-root": {
                //     flex: 1,
                //     justifyContent: "center",
                //     minWidth: 10,
                //     paddingLeft: 0,
                //   },
                // }}
              >
                {state?.tabs?.map((tab: any) => (
                  <Tab
                    key={tab.label}
                    label={tab.label}
                    value={tab.value}
                    style={{
                      textTransform: "none",
                      color: "#363333",
                      fontSize: 14,
                      fontFamily: "Poppins",
                      fontWeight: 600,
                    }}
                  />
                ))}
              </Tabs>
            </div>
            <hr />
          </div>
        )}

        <div className="my-10">
          {state?.selectedTab?.value === MenuType.PRODUCTS &&
            !merchant?.onlyShowPlans && (
              <MerchantProducts merchantId={merchantId} />
            )}

          {(state?.selectedTab?.value === MenuType.SUBSCRIPTIONS ||
            merchant?.onlyShowPlans) && (
            <MerchantSubscriptions merchantId={merchantId} />
          )}

          {state?.selectedTab?.value === MenuType.COMMUNITY_CART &&
            !merchant?.onlyShowPlans && (
              <MerchantCommunityCart merchantId={merchantId} />
            )}
        </div>
      </div>

      <div className="hidden md:block w-1/3 border-l-[1px]">
        <CustomerCart />
      </div>

      {merchant && state?.shouldShowIOSInstallPopup && (
        <IOSInstallDialog
          open={state?.shouldShowIOSInstallPopup}
          onClose={handleCloseIOSInstallDialog}
          merchant={merchant}
        />
      )}
    </div>
  );
}

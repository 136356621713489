import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import moment from "moment-timezone";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDateTimePicker } from "@mui/x-date-pickers/DesktopDateTimePicker";
import Select from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Radio from "@mui/material/Radio";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
// import IconButton from "@mui/material/IconButton";
// import ClearOutlinedIcon from "@mui/icons-material/ClearOutlined";
import Button from "components/Button";
import TextField from "components/TextField";
import ErrorMessage from "components/ErrorMessage";
import MerchantSelectProductsDialog from "components/MerchantSelectProductsDialog";
import { useMergeState } from "utils/custom-hooks";
import { DiscountType, DiscountTypeList } from "enums/Discounts";
import {
  merchantDiscountsFetchSelectedDiscountSaga,
  merchantDiscountsCreateSaga,
  merchantDiscountsUpdateSaga,
} from "../state/actions";

const MULTIPLE_ORDER_VALUES = {
  NO: "NO",
  UPTO_THREE: "UPTO_THREE",
  UNLIMITED: "UNLIMITED",
};

const MULTIPLE_ORDER_OPTIONS = [
  {
    label: "No",
    value: MULTIPLE_ORDER_VALUES.NO,
  },
  {
    label: "Yes, up to three",
    value: MULTIPLE_ORDER_VALUES.UPTO_THREE,
  },
  {
    label: "Yes, unlimited",
    value: MULTIPLE_ORDER_VALUES.UNLIMITED,
  },
];

const DELIVERY_VALUES = {
  PICK_UP_STORE: "PICK_UP_STORE",
  SHIP: "SHIP",
  WALLET: "WALLET",
};

const DELIVERY_OPTIONS = [
  {
    label: "Pick up in store",
    value: DELIVERY_VALUES.PICK_UP_STORE,
  },
  {
    label: "Have shipped at additional fee",
    value: DELIVERY_VALUES.SHIP,
  },
  {
    label: "Receive as a digital wallet or gift card",
    value: DELIVERY_VALUES.WALLET,
  },
];

export default function MerchantDiscountsCreateContainer() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const [searchParams] = useSearchParams();

  const discountId = searchParams.get("id");

  const { user } = useSelector((store: any) => store?.merchantAuth);

  const { isLoading, discount } = useSelector(
    (store: any) => store?.merchantDiscounts
  );

  const [state, setState] = useMergeState({
    title: "",
    products: [],
    discountType: "",
    discountAmount: "",
    discountSchedule: {
      startTime: moment(),
      endTime: moment().endOf("day"),
    },
    multipleOrders: MULTIPLE_ORDER_VALUES.NO,
    delivery: DELIVERY_VALUES.PICK_UP_STORE,
    expiresAt: moment(),
    message: "",

    shouldOpenSelectProductsDialog: false,
    isEditMode: false,
    errors: {},
  });

  const handleChange = (event: any) => {
    setState({
      [event.target.name]: event.target.value,
      errors: {
        [event.target.name]: false,
      },
    });
  };

  // const handleTimeChange = (newValue: any) => {
  //   setState({ scheduledTime: newValue });
  // };

  const handleExpiry = (date: any) => {
    console.log("date : ", moment(date).unix());
    setState({ expiresAt: date });
  };

  const isFormValid = () => {
    let isValid = true;

    let payload = {};

    if (!state.title) {
      payload = { title: true, ...payload };
      isValid = false;
    }

    setState({ errors: { ...payload } });

    return isValid;
  };

  const handleOpenSelectProductsDialog = () => {
    setState({ shouldOpenSelectProductsDialog: true });
  };

  const handleCloseSelectProductsDialog = () => {
    setState({ shouldOpenSelectProductsDialog: false });
  };

  const handleAddProducts = (products: Array<any>) => {
    setState({ products });
  };

  // const handleRemoveProduct = (productId: string) => {
  //
  // };

  const handleResetMessage = () => {
    let message = "";

    if (
      state?.products?.length &&
      state?.discountType &&
      state?.discountAmount
    ) {
      let productString = "";

      state?.products?.forEach((product: any, index: number) => {
        productString += `${index + 1}) for ${product?.title}\n`;
      });

      const discountPercentageOrAmount =
        state?.discountType === DiscountType.AMOUNT ? "$" : "%";

      let quantityString = "";

      if (state?.multipleOrders === MULTIPLE_ORDER_VALUES.NO) {
        quantityString =
          "Would you like to buy this and take advantage of this promotion?\n\nYes = Confirm\nNo = Another time";
      }

      if (state?.multipleOrders === MULTIPLE_ORDER_VALUES.UPTO_THREE) {
        quantityString =
          "How many would you like to buy? Reply:\n\n1 for One\n2 for Two\n3 for Three";
      }

      if (state?.multipleOrders === MULTIPLE_ORDER_VALUES.UNLIMITED) {
        quantityString = "How many would you like to buy?";
      }

      if (state?.products?.length === 1) {
        message = `Hi ${user?.merchant?.businessName} Crew! Till the end of the day, our members get ${state?.discountAmount}${discountPercentageOrAmount} off ${state?.products[0]?.title}. ${quantityString}`;
      } else {
        message = `Hi ${user?.merchant?.businessName} Crew! Till the end of the day, our members get ${state?.discountAmount}${discountPercentageOrAmount} off on the following products.\n\n${productString}\nWhich one would you like?`;
      }

      message = `${message}\n\nNo need to reply if you are not interested :)`;
    }

    setState({ message });
  };

  const handleSaveDiscount = async () => {
    if (!isFormValid()) {
      return;
    }

    const payload: any = {
      title: state?.title,
      discountType: state?.discountType,
      discountAmount: Number(state?.discountAmount),
      discountSchedule: state?.discountSchedule,
      products: state?.products?.map((product: any) => product?._id),
      multipleOrders: state?.multipleOrders,
      delivery: state?.delivery,
      expiresAt: moment(state?.expiresAt).format(),
      message: state?.message,
    };

    console.log("payload : ", payload);

    if (state?.isEditMode) {
      dispatch(
        merchantDiscountsUpdateSaga({
          id: discountId,
          ...payload,
        })
      );
    } else {
      dispatch(merchantDiscountsCreateSaga(payload, navigate));
    }
  };

  useEffect(() => {
    if (discountId) {
      dispatch(merchantDiscountsFetchSelectedDiscountSaga(discountId));
      setState({ isEditMode: true });
    }
  }, []);

  useEffect(() => {
    if (state?.isEditMode) {
      setState({
        title: discount?.title,
        discountType: discount?.discountType,
        discountAmount: discount?.discountAmount,
        products: discount?.products,
        multipleOrders: discount?.multipleOrders,
        delivery: discount?.delivery,
        expiresAt: moment(discount?.expiresAt),
        message: discount?.message,
      });
    }
  }, [discount]);

  return (
    <div>
      <div className="w-full lg:w-3/5">
        <div className="text-3xl font-medium text-grey mb-4">
          Build Your Text To Pay Promotion
        </div>

        <div className="text-xl text-grey-2 mb-4">
          Your selections below will create the natural language communication
          between your customers and Cherry.
        </div>

        <hr />

        <div className="my-8">
          <div>
            <div className="text-grey font-semibold">
              What do you want to call this promotion?
            </div>

            <div className="text-grey-2 text-sm my-2">
              This will not be shown to customers, this is for your tracking.
            </div>

            <div className="my-4">
              <TextField
                fullWidth
                label="TITLE"
                variant="outlined"
                name="title"
                value={state.title}
                onChange={handleChange}
                required
                error={state?.errors?.title}
                InputLabelProps={{
                  shrink: true,
                  disableAnimation: true,
                }}
                autoComplete="off"
              />

              {state?.errors?.title && (
                <ErrorMessage message="Title is required" />
              )}
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <div className="text-grey font-semibold mt-4">
              Select eligible products
            </div>

            <div className="mt-4">
              <Button
                label="Open product list"
                color="secondary"
                onClick={handleOpenSelectProductsDialog}
                style={{
                  borderRadius: 4,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>

            <div className="mt-4">
              {state?.products?.map((product: any, index: number) => (
                <div
                  key={product?._id}
                  className="w-1/2 h-8 my-2 flex justify-between items-center"
                >
                  <div className="text-grey">
                    {index + 1}) {product?.title}
                  </div>

                  {/* <IconButton onClick={() => handleRemoveProduct(product?._id)}>
          <ClearOutlinedIcon />
        </IconButton> */}
                </div>
              ))}
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <div className="text-grey font-semibold">Set up your discount</div>

            <div className="flex mt-4">
              <div>
                <TextField
                  fullWidth
                  label="DISCOUNT AMOUNT"
                  variant="outlined"
                  name="discountAmount"
                  type="number"
                  value={state?.discountAmount}
                  onChange={handleChange}
                  onWheel={(event: any) => event.target.blur()}
                  InputLabelProps={{
                    shrink: true,
                    disableAnimation: true,
                  }}
                  style={{
                    width: 150,
                  }}
                />
              </div>

              <div className="w-1/3 ml-8">
                <FormControl variant="outlined" className="input-select-field">
                  <InputLabel shrink disableAnimation className="input-label">
                    DISCOUNT TYPE % OR $
                  </InputLabel>

                  <Select
                    fullWidth
                    variant="outlined"
                    label="DISCOUNT TYPE % OR $"
                    name="discountType"
                    value={state?.discountType}
                    onChange={handleChange}
                    color="secondary"
                    style={{
                      height: 55,
                      width: 160,
                    }}
                  >
                    {DiscountTypeList.map((type: any) => (
                      <MenuItem key={type?.value} value={type?.value}>
                        {type?.label}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </div>
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <div className="text-grey font-semibold">
              Will you allow multiple orders
            </div>

            <div className="mt-2">Select one</div>

            <div>
              {MULTIPLE_ORDER_OPTIONS.map((option, index) => (
                <span
                  key={option?.value}
                  className={`${index === 0 ? "" : "ml-4"}`}
                >
                  <Radio
                    color="info"
                    checked={state?.multipleOrders === option?.value}
                    onChange={handleChange}
                    value={option?.value}
                    name="multipleOrders"
                  />

                  <span>{option?.label}</span>
                </span>
              ))}
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <div className="text-grey font-semibold">
              With these products, will the customer
            </div>

            <div className="mt-2">Select one</div>

            <div>
              {DELIVERY_OPTIONS.map((option) => (
                <div key={option?.value}>
                  <Radio
                    color="info"
                    checked={state?.delivery === option?.value}
                    onChange={handleChange}
                    value={option?.value}
                    name="delivery"
                  />

                  <span>{option?.label}</span>
                </div>
              ))}
            </div>
          </div>

          <hr className="my-4" />

          <div>
            <div className="text-grey font-semibold">
              When does this offer expire?
            </div>

            <div className="mt-4">
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DesktopDateTimePicker
                  value={state?.expiresAt}
                  onChange={handleExpiry}
                />
              </LocalizationProvider>
            </div>
          </div>

          <hr className="my-4" />

          <div className="my-2">
            <div className="flex items-center mb-4">
              <div className="text-grey font-semibold my-2">
                Offer message
                <Tooltip
                  title="Feel free to edit this message, but make sure to include the discount and products in the message. Our language model recognizes replies by product name."
                  placement="right-start"
                >
                  <InfoOutlinedIcon sx={{ width: 15, height: 15, ml: 1 }} />
                </Tooltip>
              </div>

              <div className="ml-8">
                <Button
                  label="Reset to default"
                  color="info"
                  onClick={handleResetMessage}
                  style={{
                    borderRadius: 10,
                    fontSize: 14,
                    color: "#FFFFFF",
                    height: 30,
                  }}
                />
              </div>
            </div>

            <TextField
              fullWidth
              variant="outlined"
              name="message"
              value={state?.message}
              onChange={handleChange}
              InputLabelProps={{
                shrink: true,
                disableAnimation: true,
              }}
              multiline
              minRows={4}
            />

            <div className="text-grey-2 text-xs font-medium mt-2">
              Feel free to edit this message, but make sure to include the
              discount and products in the message. Our language model
              recognizes replies by product name.
            </div>
          </div>

          <div className="my-8">
            <div className="w-full">
              <Button
                label={state.isEditMode ? "Edit Discount" : "Create Discount"}
                color="secondary"
                onClick={handleSaveDiscount}
                style={{
                  borderRadius: 10,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 50,
                }}
                fullWidth
                loaderButton
                loadingPosition="center"
                loading={isLoading}
                disabled={isLoading}
              />
            </div>
          </div>
        </div>
      </div>

      {state?.shouldOpenSelectProductsDialog && (
        <MerchantSelectProductsDialog
          open={state?.shouldOpenSelectProductsDialog}
          onClose={handleCloseSelectProductsDialog}
          onSave={handleAddProducts}
        />
      )}
    </div>
  );
}

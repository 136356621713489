import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Spinner from "components/Spinner";
import BuyDialog from "components/Customer/BuyDialog";
import { publicBusinessPageFetchDetailsSaga } from "../state/actions";
import { MenuType } from "enums/Menu";
import { getSubdomain } from "utils/common";
import { buyDialogSetConfig } from "components/Customer/BuyDialog/state/actions";
import { truncateString } from "utils/string";
import Images from "assets/images";

type Props = {
  merchantId: string | null;
};

export default function MerchantProducts(props: Props) {
  const { merchantId } = props;

  const dispatch = useDispatch();

  const { isLoading, products } = useSelector(
    (store: any) => store?.publicBusinessPage
  );

  const { open: shouldShowBuyDialog } = useSelector(
    (store: any) => store?.buyDialog
  );

  const handleOpenBuyDialog = (product: any) => {
    dispatch(
      buyDialogSetConfig({
        open: true,
        product,
        menuType: MenuType.PRODUCTS,
      })
    );
  };

  const handleCloseBuyDialog = () => {
    dispatch(buyDialogSetConfig({ open: false }));
  };

  const handleScrollToCategory = (category: string) => {
    document?.getElementById(category)?.scrollIntoView();
  };

  useEffect(() => {
    const payload: any = {
      type: MenuType.PRODUCTS,
    };

    if (merchantId) {
      payload.id = merchantId;
    } else {
      payload.subdomain = getSubdomain();
    }

    dispatch(publicBusinessPageFetchDetailsSaga(payload));
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          {Object.keys(products).length !== 1 && (
            <div className="w-full h-2">
              <div className="flex overflow-x-auto relative hidden-scrollbar">
                {Object.keys(products)?.map((category: any) => (
                  <div
                    key={category}
                    className="text-sm font-semibold mr-6 cursor-pointer hover:border-b-2 hover:border-primary"
                    onClick={() => handleScrollToCategory(category)}
                  >
                    {category}
                  </div>
                ))}
              </div>

              <hr />
            </div>
          )}

          {Object.keys(products)?.map((category: any) => (
            <div id={category} key={category} className="my-10">
              {Object.keys(products).length !== 1 && (
                <span className="text-xl font-semibold mb-10">{category}</span>
              )}

              <div className="grid sm:grid-cols-1 md:grid-cols-2 md:gap-2 lg:grid-cols-2 lg:gap-4">
                {products[category]?.map((product: any) => (
                  <div
                    key={product?._id}
                    className="flex justify-between items-center bg-white border-[1px] border-solid border-[#e7e7e7] pl-4 rounded-md mt-4 cursor-pointer"
                    onClick={() => handleOpenBuyDialog(product)}
                  >
                    <div className="w-3/5 h-32 flex flex-col justify-between">
                      <div className="my-2">
                        <div className="text-grey text-sm font-semibold">
                          {truncateString(product?.title, 30)}
                        </div>

                        <div className="text-grey text-sm font-normal mt-2">
                          {truncateString(product?.description, 30)}
                        </div>

                        <div className=" text-grey text-sm mt-2">
                          ${product?.variations[0]?.price}
                        </div>
                      </div>
                    </div>

                    <img
                      src={product?.images[0] || Images.NoImagePlaceholder}
                      className="w-32 h-32 rounded-r-md object-cover"
                    />
                  </div>
                ))}
              </div>
            </div>
          ))}

          {shouldShowBuyDialog && <BuyDialog onClose={handleCloseBuyDialog} />}
        </div>
      )}
    </div>
  );
}

import { takeLatest, put, all, call } from "redux-saga/effects";
import {
  createStripeSetupIntent,
  getStripePaymentMethods,
  verifyOTP,
} from "api";
import { CHERRY_TOKEN, MESSAGE_SEVERITY } from "utils/constants";
import { CheckoutSteps } from "enums/Checkout";
import {
  CUSTOMER_TEXT_TO_PAY_CREATE_ACCOUNT_SAGA,
  CUSTOMER_TEXT_TO_PAY_GET_PAYMENT_METHODS_SAGA,
} from "./types";
import { appSetError } from "app/state/actions"; // app actions
import {
  customerTextToPaySetConfig,
  customerTextToPaySetDetails,
} from "./actions";
import {
  customerAuthSetIsLoggedIn,
  customerAuthSetCurrentUser,
} from "../../auth/state/actions"; // customer auth actions

function* customerCheckoutCreateSetupIntentHandler(payload: any): any {
  try {
    yield put(customerTextToPaySetConfig({ isCheckoutFormLoading: true }));

    const setupIntentResponse = yield call(createStripeSetupIntent, {
      platformCustomerId: payload?.platformCustomerId,
    });

    if (setupIntentResponse?.success) {
      yield put(
        customerTextToPaySetConfig({
          isInitialSetup: true,
          clientSecret: setupIntentResponse?.data?.clientSecret,
          isCheckoutFormLoading: false,
          currentStep: CheckoutSteps[1],
        })
      );
    }

    yield put(customerTextToPaySetConfig({ isCheckoutFormLoading: false }));
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
    yield put(customerTextToPaySetConfig({ isCheckoutFormLoading: false }));
  }
}

function* customerTextToPayCreateAccountHandler(action: any): any {
  try {
    yield put(customerTextToPaySetConfig({ isCheckoutFormLoading: true }));

    // sign up user
    const signupResponse = yield call(verifyOTP, {
      firstName: action?.payload?.firstName,
      lastName: action?.payload?.lastName,
      phone: action?.payload?.phone,
      address: action?.payload?.address,
      otp: action?.payload?.otp,
      isSignUp: true,
    });

    if (signupResponse?.success) {
      const {
        data: { user, token },
      } = signupResponse;

      localStorage.setItem(CHERRY_TOKEN, token);

      yield all([
        yield put(customerAuthSetIsLoggedIn(true)),
        yield put(customerAuthSetCurrentUser({ ...user })),
        yield customerCheckoutCreateSetupIntentHandler({
          platformCustomerId:
            user?.customer?.stripeMetadata?.platformCustomerId,
        }),
      ]);
    }

    yield put(customerTextToPaySetConfig({ isCheckoutFormLoading: false }));
  } catch (error: any) {
    yield put(
      appSetError({
        severity: MESSAGE_SEVERITY.ERROR,
        message: error?.message,
      })
    );
    yield put(customerTextToPaySetConfig({ isCheckoutFormLoading: false }));
  }
}

function* customerTextToPayGetPaymentMethodsHandler(): any {
  try {
    yield put(customerTextToPaySetConfig({ isFetchingPaymentMethods: true }));

    const paymentMethodsResponse = yield call(getStripePaymentMethods);

    if (paymentMethodsResponse?.success) {
      yield put(
        customerTextToPaySetDetails({
          paymentMethods: paymentMethodsResponse?.data,
        })
      );
    }

    yield put(customerTextToPaySetConfig({ isFetchingPaymentMethods: false }));
  } catch (error: any) {
    yield all([
      yield put(
        customerTextToPaySetConfig({ isFetchingPaymentMethods: false })
      ),
      yield put(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: error?.message,
        })
      ),
    ]);
  }
}

export default function* watchCustomerTextToPay() {
  yield takeLatest(
    CUSTOMER_TEXT_TO_PAY_CREATE_ACCOUNT_SAGA,
    customerTextToPayCreateAccountHandler
  );
  yield takeLatest(
    CUSTOMER_TEXT_TO_PAY_GET_PAYMENT_METHODS_SAGA,
    customerTextToPayGetPaymentMethodsHandler
  );
}

import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "@mui/material/Avatar";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Button from "components/Button";
import Spinner from "components/Spinner";
import MerchantInviteMemberDialog from "components/MerchantInviteMemberDialog";
import {
  merchantSettingsTeamsFetchDetailsSaga,
  merchantSettingsTeamsSetInviteMemberDialogConfig,
} from "../state/actions";
import { wrapFullName } from "utils/common";

export default function MerchantSettingsTeamContainer() {
  const dispatch = useDispatch();

  const {
    isLoading,
    members,
    inviteMemberDialogConfig: { open: shouldShowInviteMemberDialog },
  } = useSelector((store: any) => store?.merchantSettings);

  const handleOpenInviteMemberDialog = () => {
    dispatch(merchantSettingsTeamsSetInviteMemberDialogConfig({ open: true }));
  };

  const handleCloseInviteMemberDialog = () => {
    dispatch(merchantSettingsTeamsSetInviteMemberDialogConfig({ open: false }));
  };

  useEffect(() => {
    dispatch(merchantSettingsTeamsFetchDetailsSaga());
  }, []);

  return (
    <div>
      {isLoading ? (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          <div className="flex items-center justify-between">
            <div className="text-4xl font-semibold text-grey">Team</div>
            <div>
              <Button
                label="Invite Member"
                color="secondary"
                onClick={handleOpenInviteMemberDialog}
                style={{
                  borderRadius: 10,
                  fontSize: 14,
                  color: "#FFFFFF",
                  height: 40,
                }}
              />
            </div>
          </div>

          <TableContainer className="mt-10">
            <Table sx={{ minWidth: 750 }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <span className="text-grey">Name</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="text-grey">Role</span>
                  </TableCell>
                  <TableCell align="center">
                    <span className="text-grey">Status</span>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "visible" }}>
                {members.map((member: any) => (
                  <TableRow key={member._id}>
                    <TableCell component="th" scope="row">
                      <div className="flex items-center">
                        <Avatar sx={{ width: 34, height: 34 }}>
                          {member?.status === "INVITED" ? (
                            String(member?.email?.charAt(0)).toUpperCase()
                          ) : (
                            <span>
                              {String(
                                member?.firstName?.charAt(0)
                              ).toUpperCase()}
                              {String(
                                member?.lastName?.charAt(0)
                              ).toUpperCase()}
                            </span>
                          )}
                        </Avatar>
                        <span className="text-grey text-sm ml-2">
                          {member?.status === "INVITED"
                            ? member?.email
                            : wrapFullName(member?.firstName, member?.lastName)}
                        </span>
                      </div>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-xs">{member?.role}</span>
                    </TableCell>
                    <TableCell component="th" scope="row" align="center">
                      <span className="text-grey text-xs">
                        {member?.status}
                      </span>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>

          {!members.length && (
            <div className="flex justify-center mt-10">
              <div className="text-grey text-xl">
                Start by inviting a member
              </div>
            </div>
          )}
        </div>
      )}

      {shouldShowInviteMemberDialog && (
        <MerchantInviteMemberDialog onClose={handleCloseInviteMemberDialog} />
      )}
    </div>
  );
}

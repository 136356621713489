export const CUSTOMER_CART_SET_CONFIG = "CUSTOMER_CART_SET_CONFIG";
export const CUSTOMER_CART_SET_CART = "CUSTOMER_CART_SET_CART";
export const CUSTOMER_CART_ADD_TO_CART = "CUSTOMER_CART_ADD_TO_CART";
export const CUSTOMER_CART_REMOVE_FROM_CART = "CUSTOMER_CART_REMOVE_FROM_CART";
export const CUSTOMER_CART_SET_TAXES = "CUSTOMER_CART_SET_TAXES";

// async

export const CUSTOMER_CART_FETCH_SAGA = "CUSTOMER_CART_FETCH_SAGA";
export const CUSTOMER_CART_SAVE_SAGA = "CUSTOMER_CART_SAVE_SAGA";
export const CUSTOMER_CART_BUY_SAGA = "CUSTOMER_CART_BUY_SAGA";
export const CUSTOMER_CART_FETCH_TAXES_SAGA = "CUSTOMER_CART_FETCH_TAXES_SAGA";

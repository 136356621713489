import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import moment from "moment-timezone";
import { nanoid } from "nanoid";
import Switch from "@mui/material/Switch";
import IconButton from "@mui/material/IconButton";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "components/Button";
import Spinner from "components/Spinner";
import {
  customerCartSetConfig,
  customerCartSetCart,
  customerCartRemoveFromCart,
  customerCartFetchSaga,
  customerCartSaveSaga,
  customerCartBuySaga,
} from "./state/actions";
import { ItemType } from "enums/Products";
import Images from "assets/images";
import { PaymentType, ProcessingFeesType } from "enums/Payments";
import { DiscountRuleType } from "enums/DiscountRules";
import {
  getSubtotal,
  getTaxes,
  getProcessingFees,
  getCheckoutAmount,
} from "utils/common";

const wrapPurchaseType = (purchaseType: string) => {
  const mapper = {
    [PaymentType.ONE_TIME]: "ONE_TIME",
    [PaymentType.SUBSCRIPTION]: "SUBSCRIPTION",
    [PaymentType.BUY_MORE_PAY_LESS]: "BMPL",
  };

  return mapper[purchaseType] || "";
};

export default function CustomerCart() {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const { pathname } = useLocation();

  const isCheckout = pathname === "/checkout";

  const [searchParams] = useSearchParams();

  const cartId = searchParams.get("id");

  const isCorporateAccount = searchParams.get("corporate") || false;

  const { isLoggedIn } = useSelector((store: any) => store?.customerAuth);

  const {
    config: {
      open: isCartOpen,
      isLoading,
      isCheckoutLoading,
      isCorporatePlan,
      merchant,
    },
    items,
    taxes,
  } = useSelector((store: any) => store?.customerCart);

  const {
    checkoutConfig: { paymentMethodId },
  } = useSelector((store: any) => store?.customerCheckout);

  const isCartEmpty = !items?.length;

  const getDiscountedAmount = (
    discountRuleType: string,
    quantity: number,
    maxUnits: number,
    maxDiscountPercentage: number,
    discountPercentage: number,
    amount: number
  ) => {
    if (discountRuleType === DiscountRuleType.STRAIGHT) {
      const totalPrice = Number(amount) * Number(quantity);

      const discountedPrice: any =
        totalPrice - (totalPrice * Number(discountPercentage)) / 100;

      return +Number(discountedPrice).toFixed(2);
    }

    if (discountRuleType === DiscountRuleType.SLIDER) {
      if (Number(quantity) > Number(maxUnits)) {
        const percentageOff = Number(maxDiscountPercentage);

        const totalPrice = Number(amount) * Math.ceil(quantity);

        const discountedPrice: any =
          totalPrice - (totalPrice * percentageOff) / 100;

        return +Number(discountedPrice).toFixed(2);
      }

      const percentageOff = Number(
        (Number(quantity) / Number(maxUnits)) * Number(maxDiscountPercentage)
      );

      const totalPrice = Number(amount) * Math.ceil(quantity);

      const discountedPrice: any =
        totalPrice - (totalPrice * percentageOff) / 100;

      return +Number(discountedPrice).toFixed(2);
    }
  };

  const handleIncreaseQuantity = (product: any) => {
    const {
      product: productId,
      price: {
        purchaseType,
        discountRuleType,
        quantity,
        amount,
        maxUnits,
        maxDiscountPercentage,
        discountPercentage,
      },
    } = product;

    const newQuantity = Number(quantity) + 1;

    const newCart = [...items];

    const index = newCart.findIndex(
      (elem) =>
        elem?.product === productId &&
        elem?.price?.variation?.id === product?.price?.variation?.id &&
        elem?.price.purchaseType === purchaseType
    );

    if (
      purchaseType === PaymentType.SUBSCRIPTION ||
      purchaseType === PaymentType.BUY_MORE_PAY_LESS
    ) {
      newCart[index].price.totalAmount = getDiscountedAmount(
        discountRuleType,
        newQuantity,
        maxUnits,
        maxDiscountPercentage,
        discountPercentage,
        amount
      );
    } else {
      newCart[index].price.totalAmount = +Number(
        newQuantity * Number(amount)
      ).toFixed(2);
    }

    newCart[index].price.quantity = newQuantity;

    dispatch(customerCartSetCart([...newCart]));
  };

  const handleDecreaseQuantity = (product: any) => {
    const {
      product: productId,
      price: {
        purchaseType,
        discountRuleType,
        quantity,
        amount,
        maxUnits,
        maxDiscountPercentage,
        discountPercentage,
      },
    } = product;

    const newQuantity = Number(quantity) - 1;

    if (newQuantity === 0) {
      dispatch(customerCartRemoveFromCart(product?.product));
      return;
    }

    const newCart = [...items];

    const index = newCart.findIndex(
      (elem) =>
        elem?.product === productId &&
        elem?.price?.variation?.id === product?.price?.variation?.id &&
        elem?.price.purchaseType === purchaseType
    );

    if (
      purchaseType === PaymentType.SUBSCRIPTION ||
      purchaseType === PaymentType.BUY_MORE_PAY_LESS
    ) {
      newCart[index].price.totalAmount = getDiscountedAmount(
        discountRuleType,
        newQuantity,
        maxUnits,
        maxDiscountPercentage,
        discountPercentage,
        amount
      );
    } else {
      newCart[index].price.totalAmount = +Number(
        newQuantity * Number(amount)
      ).toFixed(2);
    }

    newCart[index].price.quantity = newQuantity;

    dispatch(customerCartSetCart([...newCart]));
  };

  const handleRemovePlan = (product: any) => {
    dispatch(customerCartRemoveFromCart(product?.product));
  };

  const checkoutAmount = getCheckoutAmount(
    items,
    taxes,
    merchant?.processingFeesType
  );

  const getCheckoutButtonLabel = () => {
    if (isCheckout) {
      return "Place Order";
    }

    return `Checkout - $${parseFloat(String(checkoutAmount)).toFixed(2)}`;
  };

  const annualOrMonthly = items[0]?.price?.yearlyPlan ? "annual" : "monthly";

  const getSubscriptionQuantity = () => {
    const subOrSubs = items?.length > 1 ? "subscriptions" : "subscription";
    return `${items?.length} ${subOrSubs}`;
  };

  const isPlanDiscountApplied = (item: any) =>
    item?.price?.discountPercentage > 0;

  const getItemAmount = (item: any) => {
    console.log("item : ", item);

    if (item?.price?.corporatePlan) {
      return +Number(
        Number(item?.price?.amount) *
          Number(item?.price?.corporatePlan?.members)
      ).toFixed(2);
    }

    return +Number(item?.price?.amount).toFixed(2);
  };

  const getDiscountedItemAmount = (item: any) => {
    const itemAmount = getItemAmount(item);

    return +Number(
      Number(itemAmount) -
        Number(itemAmount) * Number(item?.price?.discountPercentage / 100)
    ).toFixed(2);
  };

  const handleSwitchYearly = (item: any, event: any) => {
    const updatedCartItems = [...items];

    const index = updatedCartItems?.findIndex(
      (elem) => elem?.product === item?.product
    );

    updatedCartItems[index].price.yearlyPlan = event?.target?.checked;

    const discountedAmount = getDiscountedItemAmount(item);

    if (event?.target?.checked) {
      updatedCartItems[index].price.totalAmount = +Number(
        Number(discountedAmount) -
          Number(discountedAmount) *
            Number(item?.price?.yearlyDiscountPercentage / 100)
      ).toFixed(2);
    } else {
      updatedCartItems[index].price.totalAmount = discountedAmount;
    }

    dispatch(customerCartSetCart([...updatedCartItems]));
  };

  const isCheckoutButtonDisabled = isCheckout
    ? !isLoggedIn ||
      !paymentMethodId ||
      !merchant?._id ||
      !items?.length ||
      !cartId
    : isCheckoutLoading;

  const handleCheckout = () => {
    if (isCartOpen && !isCheckout) {
      dispatch(customerCartSetConfig({ open: false }));
    }

    const payload = {
      mId: merchant?._id,
      cartId,
      items,
      pmId: paymentMethodId,
      isCorporateAccount: isCorporateAccount || false,
    };

    if (isCheckout) {
      dispatch(customerCartBuySaga(payload, navigate));
      return;
    }

    dispatch(
      customerCartSaveSaga(
        {
          id: nanoid(),
          items,
          mId: merchant._id,
        },
        {
          shouldRedirect: true,
          isCorporatePlan,
        }
      )
    );
  };

  useEffect(() => {
    if (cartId) {
      dispatch(customerCartFetchSaga({ id: cartId }));
    }
  }, []);

  return (
    <div className="w-full min-h-screen h-full px-4 py-10 bg-white">
      {isLoading ? (
        <div className="mt-10 flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      ) : (
        <div>
          {isCartEmpty ? (
            <div className="flex justify-center">
              <span className="text-grey font-semibold">
                Your cart is empty...
              </span>
            </div>
          ) : (
            <div className="flex flex-col">
              <div className="text-grey text-sm">YOUR CART</div>

              <hr className="w-full my-2" />

              {items?.map((item: any) => (
                <div key={`${item?.product}-${nanoid()}`} className="w-full">
                  {item?.price?.yearlyDiscountPercentage > 0 && (
                    <div>
                      <div className="flex items-center mb-2">
                        <span className="text-xs mr-1 text-grey-2 font-medium">
                          Monthly
                        </span>

                        <Switch
                          size="small"
                          checked={item?.price?.yearlyPlan}
                          onChange={(event: any) =>
                            handleSwitchYearly(item, event)
                          }
                        />

                        <span className="text-xs font-medium ml-1">
                          Yearly{" "}
                          <span className="text-green-2">
                            (save {item?.price?.yearlyDiscountPercentage}%)
                          </span>
                        </span>
                      </div>
                    </div>
                  )}

                  <div className="flex">
                    <div className="">
                      <img
                        src={item?.images[0] || Images.NoImagePlaceholder}
                        className="hidden sm:block w-20 h-16 rounded-md object-cover"
                      />
                    </div>

                    <div className="w-full flex justify-between ml-2">
                      <div className="w-full flex flex-col justify-between">
                        <div className="w-full flex justify-between items-center">
                          <div className="text-grey text-sm font-medium">
                            {item?.title}
                          </div>

                          {isPlanDiscountApplied(item) ? (
                            <div className="text-grey-2 text-sm line-through font-medium">
                              ${getItemAmount(item)}
                            </div>
                          ) : (
                            <div className="text-grey-2 text-sm font-medium">
                              ${item?.price?.totalAmount}
                            </div>
                          )}
                        </div>

                        <div className="text-grey text-sm font-normal">
                          {item?.price?.variation?.name}
                        </div>

                        <div className="w-full flex justify-between items-center">
                          <div className="text-grey-2 text-xs font-medium">
                            {wrapPurchaseType(item?.price?.purchaseType)}
                          </div>

                          {isPlanDiscountApplied(item) && (
                            <div className="text-grey text-sm font-medium">
                              ${item?.price?.totalAmount}
                            </div>
                          )}
                        </div>

                        {item?.price?.corporatePlan?.members && (
                          <div className="text-grey-2 text-xs font-medium">
                            QTY: {item?.price?.corporatePlan?.members}
                          </div>
                        )}
                      </div>

                      {item?.type === ItemType.PLAN && (
                        <div>
                          <IconButton
                            onClick={() => handleRemovePlan(item)}
                            sx={{ padding: 0, paddingLeft: 1 }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </div>
                      )}

                      {item?.type === ItemType.PRODUCT && (
                        <div className="flex items-center">
                          <IconButton
                            onClick={() => handleDecreaseQuantity(item)}
                          >
                            <RemoveCircleOutlineIcon />
                          </IconButton>

                          <div className="mx-2 bg-[#f0f0f0] flex justify-center items-center rounded-md w-12 h-8">
                            <span>{item?.price?.quantity}</span>
                          </div>

                          <IconButton
                            onClick={() => handleIncreaseQuantity(item)}
                          >
                            <AddCircleOutlineIcon />
                          </IconButton>
                        </div>
                      )}
                    </div>
                  </div>

                  <hr className="my-4" />
                </div>
              ))}

              <div className="w-full">
                <div>
                  <div className="flex justify-between items-center text-grey text-sm my-2">
                    <div>Sub total</div>
                    <div>
                      ${parseFloat(String(getSubtotal(items))).toFixed(2)}
                    </div>
                  </div>

                  <div className="flex justify-between items-center text-grey text-sm my-2">
                    <div>Tax</div>
                    <div>
                      ${parseFloat(String(getTaxes(items, taxes))).toFixed(2)}
                    </div>
                  </div>

                  {merchant?.processingFeesType ===
                    ProcessingFeesType.CUSTOMER && (
                    <div className="flex justify-between items-center text-grey text-sm my-2">
                      <div>Fees</div>$
                      {parseFloat(String(getProcessingFees(items))).toFixed(2)}
                    </div>
                  )}
                </div>

                <div className="flex justify-between items-center h-10">
                  <span className="text-grey text-lg font-medium">
                    Today&apos;s Charge
                  </span>
                  <span className="text-green-2 text-lg font-medium">
                    ${parseFloat(String(checkoutAmount)).toFixed(2)}
                  </span>
                </div>

                <div className="text-grey text-xs mt-4">
                  We&apos;ll charge you $
                  {parseFloat(String(checkoutAmount)).toFixed(2)} now, which is
                  discounted {annualOrMonthly} rate for{" "}
                  {getSubscriptionQuantity()}. Your subscription is billed
                  monthly and will automatically renew for $
                  {parseFloat(String(checkoutAmount)).toFixed(2)} on{" "}
                  {moment().add(30, "days").format("MMM DD, YYYY")}. You can
                  cancel any time before this date.
                </div>

                <div className="mt-5">
                  <Button
                    label={getCheckoutButtonLabel()}
                    color="info"
                    onClick={handleCheckout}
                    style={{
                      borderRadius: 10,
                      fontSize: 14,
                      color: "#FFFFFF",
                      height: 40,
                    }}
                    fullWidth
                    loaderButton
                    loadingPosition="center"
                    loading={isCheckoutLoading}
                    disabled={isCheckoutButtonDisabled}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      )}
    </div>
  );
}

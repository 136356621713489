import { styled } from "@mui/material/styles";
import MuiButton from "@mui/material/Button";

export const LoadMoreButton = styled(MuiButton)(() => ({
  backgroundColor: "#F5F5F5",
  textTransform: "none",
  fontFamily: "Neue",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: 15,
  color: "#363333",
  border: "1px solid #706E6E",
  borderRadius: 10,
}));

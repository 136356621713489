import {
  MERCHANT_AUTH_SET_CURRENT_USER,
  MERCHANT_AUTH_SET_IS_LOADING,
  MERCHANT_AUTH_SET_IS_LOGGED_IN,
  MERCHANT_AUTH_SET_MESSAGE,
  MERCHANT_AUTH_ONBOARD_SAGA,
  MERCHANT_AUTH_SIGNUP_SAGA,
  MERCHANT_AUTH_SIGNIN_SAGA,
  MERCHANT_AUTH_SETUP_SAGA,
  MERCHANT_AUTH_FORGOT_PASSWORD_SEND_INSTRUCTIONS_SAGA,
  MERCHANT_AUTH_CHECK_RESET_PASSWORD_LINK_EXPIRY_SAGA,
  MERCHANT_AUTH_RESET_PASSWORD_SAGA,
} from "./types";

export const merchantAuthSetMessage = (payload: any) => ({
  type: MERCHANT_AUTH_SET_MESSAGE,
  payload,
});

export const merchantAuthSetIsLoggedIn = (payload: any) => ({
  type: MERCHANT_AUTH_SET_IS_LOGGED_IN,
  payload,
});

export const merchantAuthSetIsLoading = (payload: any) => ({
  type: MERCHANT_AUTH_SET_IS_LOADING,
  payload,
});

export const merchantAuthSetCurrentUser = (payload: any) => ({
  type: MERCHANT_AUTH_SET_CURRENT_USER,
  payload,
});

// async

export const merchantAuthOnboardSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_AUTH_ONBOARD_SAGA,
  payload,
  navigate,
});

export const merchantAuthSignupSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_AUTH_SIGNUP_SAGA,
  payload,
  navigate,
});

export const merchantAuthSigninSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_AUTH_SIGNIN_SAGA,
  payload,
  navigate,
});

export const merchantAuthSetupSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_AUTH_SETUP_SAGA,
  payload,
  navigate,
});

export const merchantAuthForgotPasswordSendInstructionsSaga = (
  payload: any
) => ({
  type: MERCHANT_AUTH_FORGOT_PASSWORD_SEND_INSTRUCTIONS_SAGA,
  payload,
});

export const merchantAuthCheckResetPasswordLinkExpirySaga = (
  payload: any,
  navigate: any
) => ({
  type: MERCHANT_AUTH_CHECK_RESET_PASSWORD_LINK_EXPIRY_SAGA,
  payload,
  navigate,
});

export const merchantAuthResetPasswordSaga = (payload: any, navigate: any) => ({
  type: MERCHANT_AUTH_RESET_PASSWORD_SAGA,
  payload,
  navigate,
});

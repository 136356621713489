export const RESET_APP_STATE = "RESET_APP_STATE";
export const CUSTOMER_CHECKOUT_SET_IS_LOADING =
  "CUSTOMER_CHECKOUT_SET_IS_LOADING";
export const CUSTOMER_CHECKOUT_SET_DETAILS = "CUSTOMER_CHECKOUT_SET_DETAILS";
export const CUSTOMER_CHECKOUT_SET_CONFIG = "CUSTOMER_CHECKOUT_SET_CONFIG";
export const CUSTOMER_CHECKOUT_SET_VERIFICATION_CONFIG =
  "CUSTOMER_CHECKOUT_SET_VERIFICATION_CONFIG";

// async

export const CUSTOMER_CHECKOUT_GET_DETAILS_SAGA =
  "CUSTOMER_CHECKOUT_GET_DETAILS_SAGA";
export const CUSTOMER_CHECKOUT_CREATE_ACCOUNT_SAGA =
  "CUSTOMER_CHECKOUT_CREATE_ACCOUNT_SAGA";
export const CUSTOMER_CHECKOUT_CONFIRM_PAYMENT_SAGA =
  "CUSTOMER_CHECKOUT_CONFIRM_PAYMENT_SAGA";
export const CUSTOMER_CHECKOUT_MAKE_PAYMENT_SAGA =
  "CUSTOMER_CHECKOUT_MAKE_PAYMENT_SAGA";
export const CUSTOMER_CHECKOUT_VERIFY_PAYMENT_SAGA =
  "CUSTOMER_CHECKOUT_VERIFY_PAYMENT_SAGA";
export const CUSTOMER_CHECKOUT_ADD_NEW_PAYMENT_METHOD_SAGA =
  "CUSTOMER_CHECKOUT_ADD_NEW_PAYMENT_METHOD_SAGA";
export const CUSTOMER_CHECKOUT_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA =
  "CUSTOMER_CHECKOUT_CONFIRM_ADD_NEW_PAYMENT_METHOD_SAGA";

export const CUSTOMER_CHECKOUT_GET_PAYMENT_METHODS_SAGA =
  "CUSTOMER_CHECKOUT_GET_PAYMENT_METHODS_SAGA";

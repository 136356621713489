import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams, useNavigate } from "react-router-dom";
import Spinner from "components/Spinner";
import { appSetError } from "app/state/actions";
import { customerConfirmAddPaymentMethodSaga } from "./state/actions";
import { MESSAGE_SEVERITY } from "utils/constants";

export default function CustomerConfirmAddPaymentMethodContainer() {
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();

  const dispatch = useDispatch();

  const { isLoading } = useSelector(
    (store: any) => store?.customerConfirmAddPaymentMethod
  );

  const setupIntentId = searchParams.get("setup_intent");

  const platformCustomerId = searchParams.get("id");

  const merchantId = searchParams.get("mId");

  const planId = searchParams.get("plId");

  useEffect(() => {
    if (!setupIntentId || !platformCustomerId || !merchantId || !planId) {
      dispatch(
        appSetError({
          severity: MESSAGE_SEVERITY.ERROR,
          message: "Something went wrong, please contact support.",
        })
      );
      return;
    }

    dispatch(
      customerConfirmAddPaymentMethodSaga(
        {
          setupIntentId,
          platformCustomerId,
          merchantId,
          planId,
        },
        navigate
      )
    );
  }, []);

  return (
    <div>
      {isLoading && (
        <div className="mt-10 w-full h-screen flex justify-center">
          <Spinner loading={isLoading} />
        </div>
      )}
    </div>
  );
}

import {
  CUSTOMER_TEXT_TO_PAY_SET_IS_LOADING,
  CUSTOMER_TEXT_TO_PAY_SET_CONFIG,
  CUSTOMER_TEXT_TO_PAY_SET_DETAILS,
  CUSTOMER_TEXT_TO_PAY_CREATE_ACCOUNT_SAGA,
  CUSTOMER_TEXT_TO_PAY_GET_PAYMENT_METHODS_SAGA,
} from "./types";

export const customerTextToPaySetIsLoading = (payload: any) => ({
  type: CUSTOMER_TEXT_TO_PAY_SET_IS_LOADING,
  payload,
});

export const customerTextToPaySetConfig = (payload: any) => ({
  type: CUSTOMER_TEXT_TO_PAY_SET_CONFIG,
  payload,
});

export const customerTextToPaySetDetails = (payload: any) => ({
  type: CUSTOMER_TEXT_TO_PAY_SET_DETAILS,
  payload,
});

// async

export const customerTextToPayCreateAccountSaga = (payload: any) => ({
  type: CUSTOMER_TEXT_TO_PAY_CREATE_ACCOUNT_SAGA,
  payload,
});

export const customerTextToPayGetPaymentMethodSaga = () => ({
  type: CUSTOMER_TEXT_TO_PAY_GET_PAYMENT_METHODS_SAGA,
});
